import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Customer, CustomerType } from "../../types/slices/customerType";

const initialState: CustomerType = {
    status: ApiStatus.FETCHED,
    customers: [],
    errorMessage: undefined,
    editCustomer: {} as Customer,
    selectedCustomer: {} as Customer,
};

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        onFetchCustomer(state, { payload }: { payload: Customer[] }) {
            state.status = ApiStatus.FETCHED;
            state.customers = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditCustomer(state, { payload }: { payload: Customer }) {
            state.editCustomer = payload;
        },
        onSetSelectedCustomer(state, { payload }: { payload: Customer }) {
            state.selectedCustomer = payload;
        },
    },
});

export const selectCustomerState = (state: RootState) => state.customerSlice;
export default customerSlice.reducer;

export const {
    onFetchCustomer,
    changeStatus,
    onSetEditCustomer,
    onSetSelectedCustomer,
} = customerSlice.actions;
