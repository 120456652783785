import { api } from "./configs/axiosConfigs";

export const structureD1API = {
    getStructureD1: async (data?: any) => {
        const response = await api
            .get('/structureD1', { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    createStructureD1: async (data: any, required: number) => {
        const response = await api
            .post('/structureD1/register', { ...data, required })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    editStructureD1: async (id: number, data: any, required: number) => {
        const response = await api
            .patch(`/structureD1/${id}`, { ...data, required })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    deleteStructureD1: async (id: number) => {
        const response = await api
            .delete(`/structureD1/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
};