import { api } from "./configs/axiosConfigs"

export const NotificationAPI = {
    getNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getNotViewNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/notView',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getViewNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/view',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getTypeNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/typeNotifications'
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getSentNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/sent',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    createNotification: async (data: any) => {
        const response = await api.post(
            '/notifications/register',
            {
                ...data,
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    changeSeenNotification: async (idnotify: number) => {
        const response = await api.patch(
            `/notifications/changeSeen/${idnotify}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    changeSeenMassiveNotification: async (idcompany: number) => {
        const response = await api.patch(
            `/notifications/changeSeenMassive`,
            {
                idcompany: idcompany
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    deleteSentNotification: async (idnotify: number) => {
        const response = await api.delete(
            `/notifications/sent/${idnotify}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
}