import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDriverStore } from "../../hooks/useDriverStore";
import { Driver } from "../../types/slices/driverType";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { CustomPhoneInput } from "../../components/common/CustomPhoneInput/CustomPhoneInput";

type DriverDataForm = {
    id: number
    rut: string
    name: string
    surname: string
    email: string
    phone: string
}

export const DriverForm: FC = () => {

    const navigate = useNavigate()
    const { status: driverStatus, selectedDriver, createDriver, editDriver, setSelectedDriver } = useDriverStore()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<DriverDataForm>({
        id: 0,
        rut: '',
        name: '',
        surname: '',
        email: '',
        phone: ''
    })

    useEffect(() => {
        if (Object.entries(selectedDriver).length > 0) {
            setIsEdit(true)
            setData({
                id: selectedDriver.id,
                rut: selectedDriver.rut,
                name: selectedDriver.name,
                surname: selectedDriver.surname,
                email: selectedDriver.email,
                phone: selectedDriver.phone
            })
            setSelectedDriver({} as Driver)
        }
    }, [])

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.name) errors.name = "Nombre es requerido";
        if (!values.surname) errors.surname = "Nombre es requerido";
        if (!values.rut) errors.rut = "RUT es requerido";
        if (!values.email) errors.email = "Email es requerido";
        if (!values.phone) errors.phone = "Celular es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const method = !isEdit ? createDriver(values) : editDriver(data.id, values)
        const response = await method
        if (response === true) {
            navigate({ pathname: RoutesMap.DRIVER })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.COMPANY })
    }

    return (
        <CustomForm>
            {driverStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo Conductor' : 'Editar Conductor'}
                text={!isEdit ? 'Ingrese los datos del nuevo conductor' : 'Ingrese los datos del conductor'}
                goBack={RoutesMap.DRIVER}
            >
            </CustomFormHeader>
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombres:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text"
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name && touched.name ? true : false}
                                                helperText={errors.name && touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Apellidos:</FormLabel>
                                            <TextField
                                                id="surname"
                                                type="text"
                                                name="surname"
                                                fullWidth
                                                size="small"
                                                value={values.surname}
                                                onChange={handleChange}
                                                error={errors.surname && touched.surname ? true : false}
                                                helperText={errors.surname && touched.surname ? errors.surname : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>RUT:</FormLabel>
                                            <TextField
                                                id="rut"
                                                type="text"
                                                name="rut"
                                                fullWidth
                                                size="small"
                                                value={values.rut}
                                                onChange={handleChange}
                                                error={errors.rut && touched.rut ? true : false}
                                                helperText={errors.rut && touched.rut ? errors.rut : ''}
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: 10
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Email:</FormLabel>
                                            <TextField
                                                id="email"
                                                type="email"
                                                name="email"
                                                fullWidth
                                                size="small"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={errors.email && touched.email ? true : false}
                                                helperText={errors.email && touched.email ? errors.email : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Celular:</FormLabel>
                                            <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}