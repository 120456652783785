import { api } from "./configs/axiosConfigs";

export const structureD2API = {
    getStructureD2: async (data?: any) => {
        const response = await api
            .get('/structureD2', { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    createStructureD2: async (data: any, required: number) => {
        const response = await api
            .post('/structureD2/register', { ...data, required })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    editStructureD2: async (id: number, data: any, required: number) => {
        const response = await api
            .patch(`/structureD2/${id}`, { ...data, required })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    deleteStructureD2: async (id: number) => {
        const response = await api
            .delete(`/structureD2/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    getGroup: async (data?: any) => {
        const response = await api
            .get('/structureD2/group', { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
};