import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { ModelVehicle, ModelVehicleType } from "../../types/slices/modelVehicleType";

const initialState: ModelVehicleType = {
    status: ApiStatus.FETCHED,
    modelVehicles: [],
    errorMessage: undefined,
    editModelVehicle: {} as ModelVehicle,
    selectedModelVehicle: {} as ModelVehicle,
};

const modelVehicleSlice = createSlice({
    name: "modelVehicle",
    initialState,
    reducers: {
        onFetchModelVehicle(state, { payload }: { payload: ModelVehicle[] }) {
            state.status = ApiStatus.FETCHED;
            state.modelVehicles = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditModelVehicle(state, { payload }: { payload: ModelVehicle }) {
            state.editModelVehicle = payload;
        },
        onSetSelectedModelVehicle(state, { payload }: { payload: ModelVehicle }) {
            state.selectedModelVehicle = payload;
        },
    }
});

export const selectModelVehicleState = (state: RootState) => state.modelVehicleSlice;
export default modelVehicleSlice.reducer;

export const {
    onFetchModelVehicle,
    changeStatus,
    onSetEditModelVehicle,
    onSetSelectedModelVehicle,
} = modelVehicleSlice.actions