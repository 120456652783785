import React, { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useStructureD1Store } from "../../hooks/useStructureD1Store";
import { StructureD1 } from "../../types/slices/structureD1Type";
import { RoutesMap } from "../../types";
import { Checkbox, Divider, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useTypeVehicleStore } from "../../hooks/useTypeVehicleStore";
import { useGroupActivityD1Store } from "../../hooks/useGroupActivityD1Store";

type StructureD1DataForm = {
    id: number;
    work_done: string;
    idgroup_activity_d1: number;
    required: string;
    vehicle_type: string[] | null;
}

type errorsForm = {
    work_done?: string;
    idgroup_activity_d1?: string;
    vehicle_type?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StructureD1Form: FC = () => {
    const navigate = useNavigate();
    const {
        status: structureD1Status,
        selectedStructureD1,
        setSelectedStructureD1,
        createStructureD1Store,
        editStructureD1Store
    } = useStructureD1Store();

    const { typeVehicles, getTypeVehicles } = useTypeVehicleStore()
    const { groupActivitiesD1, getGroupStructureD1Store } = useGroupActivityD1Store()

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<StructureD1DataForm>({
        id: 0,
        work_done: "",
        idgroup_activity_d1: 0,
        required: "",
        vehicle_type: [] as string[],
    })

    useEffect(() => {
        getGroupStructureD1Store();
        getTypeVehicles();
        if (Object.entries(selectedStructureD1).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedStructureD1.id,
                work_done: selectedStructureD1.work_done,
                idgroup_activity_d1: selectedStructureD1.idgroup_activity_d1,
                required: selectedStructureD1.required,
                vehicle_type: selectedStructureD1.vehicle_type || [],
            });
            setSelectedStructureD1({} as StructureD1);
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            work_done: values.work_done,
            idgroup_activity_d1: values.idgroup_activity_d1,
            required: values.required,
            vehicle_type: values.vehicle_type.map((id: number) =>
                id.toString()
            ),
        };

        const method = !isEdit
            ? createStructureD1Store(payload)
            : editStructureD1Store(data.id, payload);

        const response = await method;
        if (response === true) {
            navigate({ pathname: RoutesMap.STRUCTURE_D1 });
        }
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.STRUCTURE_D1 });
    };

    const validateForm = (values: any) => {
        let errors: errorsForm = {};
        if (!values.work_done) errors.work_done = "Actividad requerida";
        if (!values.idgroup_activity_d1) errors.idgroup_activity_d1 = "Id de grupo de actividad requerida";
        return errors;
    };

    return (
        <CustomForm>
            {structureD1Status === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            <CustomFormHeader
                title={!isEdit ? "Nuevo trabajo" : "Editar trabajo"}
                text={
                    !isEdit
                        ? "Ingrese los datos de la nueva actividad"
                        : "Ingrese los datos de la actividad"
                }
                goBack={RoutesMap.STRUCTURE_D1}
            ></CustomFormHeader>
            <CustomFormBody>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validate={(values) => validateForm(values)}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Trabajo realizado:</FormLabel>
                                        <TextField
                                            id="work_done"
                                            type="text"
                                            name="work_done"
                                            fullWidth
                                            size="small"
                                            value={values.work_done}
                                            onChange={handleChange}
                                            error={
                                                errors.work_done && touched.work_done
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.work_done && touched.work_done
                                                    ? errors.work_done
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Grupo de actividad:</InputLabel>
                                        <Select
                                            id="idgroup_activity_d1"
                                            name="idgroup_activity_d1"
                                            fullWidth
                                            value={values.idgroup_activity_d1}
                                            onChange={handleChange}
                                            error={
                                                errors.idgroup_activity_d1 &&
                                                    touched.idgroup_activity_d1
                                                    ? true
                                                    : false
                                            }
                                            style={{ height: '52%' }}
                                        >
                                            {groupActivitiesD1?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name_group}</MenuItem>))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Tipo de máquinas:</InputLabel>
                                        <Select
                                            id="vehicle_type"
                                            name="vehicle_type"
                                            multiple
                                            fullWidth
                                            value={values.vehicle_type}
                                            onChange={(event) => {
                                                const selectedValues = event.target.value;
                                                setFieldValue('vehicle_type', selectedValues);
                                            }}
                                            error={
                                                errors.vehicle_type &&
                                                    touched.vehicle_type
                                                    ? true
                                                    : false
                                            }
                                            style={{ height: '52%' }}
                                            renderValue={(selected) =>
                                                selected?.map((value) => typeVehicles.find((option) => option.id.toString() === value)?.name)
                                                    .join(', ')
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {typeVehicles?.map((option) => (
                                                <MenuItem key={option.id} value={option.id.toString()}>
                                                    <Checkbox checked={values.vehicle_type?.includes(option.id.toString())} />
                                                    {option.name}
                                                </MenuItem>))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={
                                        !isEdit ? "Guardar" : "Actualizar"
                                    }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default StructureD1Form