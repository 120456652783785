import { api } from "./configs/axiosConfigs";

export const AxleAPI = {
    getAxle: async (data?: any) => {
        const response = await api
            .get('/vehicle/axle', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    }
}