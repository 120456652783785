import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { DET, DETType } from "../../types/slices/DETType";
import { RootState } from "../store";

const initialState : DETType = {
    status: ApiStatus.FETCHED,
    DETs: [],
    errorMessage: undefined,
    selectedDET: {} as DET,
    editDET: {} as DET
}

const DETSlice = createSlice({
    name: 'DET',
    initialState,
    reducers: {
        onFetchDETs (state, { payload }: { payload: DET[] }) {
            state.status       = ApiStatus.FETCHED
            state.DETs         = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedDET (state, { payload }: { payload: DET }) {
            state.selectedDET = payload
        },
        onSetEditDET (state, { payload }: { payload: DET }) {
            state.editDET = payload
        },
        onSetIdWorkOrderDET (state, { payload }: { payload: number }) {
            state.editDET.idwork_order = payload
        }
    }
})

export const selectDETState = (state: RootState) => state.DETSlice
export default DETSlice.reducer

export const {
    onFetchDETs,
    changeStatus,
    onSetSelectedDET,
    onSetEditDET,
    onSetIdWorkOrderDET
} = DETSlice.actions