import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks";
import { useCustomerStore } from "../../hooks/useCustomerStore";
import { useTechnicalReportStore } from "../../hooks/useTechnicalReportStore";
import { Customer } from "../../types/slices/customerType";
import { Role } from "../../types/roles/roleTypes";
import { Technical } from "../../types/slices/technicalType";
import { RepairRequest } from "../../types/slices/repairRequestType";
import { TypeAttention } from "../../types/slices/typeAttentionType";
import { WorkDone } from "../../types/slices/workDondeType";
import { ReplacementPart } from "../../types/slices/replacementPartType";
import { useTechnicalStore } from "../../hooks/useTechnical";
import { useRepairRequest } from "../../hooks/useRepairRequest";
import { useTypeAttention } from "../../hooks/useTypeAttention";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";
import { useReplacementPartStore } from "../../hooks/useReplacementPartStore";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ApiStatus } from "../../types/api/status";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { Autocomplete, FormLabel, Grid, InputAdornment, TextField, Divider, CircularProgress, Checkbox, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Tooltip, Button, IconButton } from "@mui/material";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { StyledTableCell } from "../../toolbox/helpers/accordion-styled";
import { TechnicalReportImage } from "./TechnicalReportImage";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ImageFile } from "../../types/slices/technicalreportType";

type TechnicalReportDataForm = {
    date_attention:string
    start_time:string
    end_time:string
}

const date = new Date()
const año = date.getFullYear()
const mes = String(date.getMonth() + 1).padStart(2, '0')
const día = String(date.getDate()).padStart(2, '0')
const today = `${año}-${mes}-${día}`;

export const TechnicalReportForm:FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { technicals, getTechnicalStore } = useTechnicalStore()
    const { repairRequests, getRepairRequestStore } = useRepairRequest()
    const { typeAttentions, getTypeAttentionStore } = useTypeAttention()
    const { replacementParts, getReplacementParts } = useReplacementPartStore()
    const { workDones, getWorkDones } = useWorkDoneStore()
    const { status, editTechnicalReport, images, createTechnicalReport, updateTechnicalReport, setFetchImages } = useTechnicalReportStore()

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [selectedTechnical, setSelectedTechnical] = useState<Technical|null>(null)
    const [selectedRepairRequest, setSelectedRepairRequest] = useState<RepairRequest|null>(null)
    const [selectedTypeAttention, setSelectedTypeAttention] = useState<TypeAttention|null>(null)
    const [selectedWorkDones, setSelectedWorkDones] = useState<WorkDone[]>([])
    const [selectedReplacementParts, setSelectedReplacementParts] = useState<ReplacementPart[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showModalImage, setShowModalImage] = useState<boolean>(false)
    const [data, setData] = useState<TechnicalReportDataForm>({
        date_attention: today,
        end_time: '',
        start_time: ''
    })
    const userRole:Role|any = user.userType

    useEffect(()=>{
        infoApi()
        setDataEdit()
    },[])

    useEffect(()=>{
        if (isEdit && repairRequests.length > 0) {
            const request = repairRequests.find(request => request.id === editTechnicalReport.idrequest)
            if (!request) return
            setSelectedRepairRequest(request)
        }
    },[isEdit, repairRequests])

    useEffect(()=>{
        if (isEdit && technicals.length > 0) {
            const technical = technicals.find(technical => technical.id === editTechnicalReport.idtechnical)
            if (!technical) return
            setSelectedTechnical(technical)
        }
    },[isEdit, technicals])

    useEffect(()=>{
        if (isEdit && typeAttentions.length > 0) {
            const type = typeAttentions.find(type => type.id === editTechnicalReport.idtype_attention)
            if (!type) return
            setSelectedTypeAttention(type)
        }
    },[isEdit, typeAttentions])

    useEffect(() => {
        if (isEdit && workDones.length > 0) {
            const Works: any = editTechnicalReport?.works_dones?.map(workDone => {
                const work = workDones?.find(work => work.id === workDone.id)
                if (work) return work
            })
            setSelectedWorkDones(Works || [])
        }
    }, [isEdit, workDones])

    useEffect(() => {
        if (isEdit && replacementParts.length > 0) {
            const replaceParts: any = editTechnicalReport?.replacement_parts?.map(replacementPart => {
                const part = replacementParts?.find(part => part.id === replacementPart.id)
                if (part) return part
            })
            setSelectedReplacementParts(replaceParts || [])
        }
    }, [isEdit, replacementParts])

    const infoApi = async () => {
        setLoading(true)
        await getTechnicalStore()
        await getRepairRequestStore({ status_request: 1 })
        await getTypeAttentionStore()
        await getWorkDones()
        await getReplacementParts()
        setLoading(false)
    }

    const setDataEdit = () => {
        if (Object.entries(editTechnicalReport).length > 0) {
            setIsEdit(true)
            setData({
                date_attention: editTechnicalReport.date_attention,
                end_time: editTechnicalReport.end_time,
                start_time: editTechnicalReport.start_time
            })
            const Imagenes:ImageFile[] = editTechnicalReport.images?.map((image, index) => ({
                id: image.id,
                n: index+1,
                image: image.image,
                file_name: image.file_name,
                comment: image.comment,
                status: image.status
            }))
            setFetchImages(Imagenes)
        }
    } 

    const validateForm = (values) => {
        let errors:any = {}
        if (!values.date_attention) errors.date_attention = "el campo es requerido"
        if (!values.end_time) errors.end_time = "el campo es requerido"
        if (!values.start_time) errors.start_time = "el campo es requerido"
        return errors
    }

    const onCancel = () => {
        navigate({ pathname: RoutesMap.TECHNICAL_REPORT })
    }

    const onSubmit = async (values: any) => {
        const works_dones:any = selectedWorkDones.map(work => work.id)
        const replacement_parts:any = selectedReplacementParts.map(part => part.id)
        const idtechnical = userRole === Role.TECHNICAL ? user.data?.id?.toString() : selectedTechnical?.id.toString()

        const formData = new FormData()
        formData.append('idtechnical', idtechnical || '')
        formData.append('date_attention', values.date_attention)
        formData.append('idrepair_request', selectedRepairRequest?.id.toString() || '')
        formData.append('idtype_attention', selectedTypeAttention?.id.toString() || '')
        formData.append('works_dones',  '['+works_dones+']')
        formData.append('start_time', values.start_time)
        formData.append('end_time', values.end_time)
        formData.append('replacement_parts',  '['+replacement_parts+']')
        images.forEach((image, index) => {
            formData.append(`images[${index}][id]`, image.id?.toString() || '')
            formData.append(`images[${index}][image]`, image.image)
            formData.append(`images[${index}][file_name]`, image.file_name)
            formData.append(`images[${index}][comment]`, image.comment)
        });
        formData.append('num_images', images.length.toString())

        const response = await (!isEdit ? createTechnicalReport(formData) : updateTechnicalReport(editTechnicalReport.id, formData))
        if (response.id) navigate(`/technical-report-signature/${response.id}`)
    }

    const deleteImageTable = (n:number) => {
        const newImages = images.filter(image => image.n !== n)
        if (!newImages) return
        setFetchImages(newImages)
    }

    const showImage = (n:number) => {
        const imageToShow:any = images.find(image => image.n === n)
        if (!imageToShow) return
        if (imageToShow.id == null) {
            const imageUrl = URL.createObjectURL(imageToShow.image)
            window.open(imageUrl, '_blank')
        } else {
            const url = imageToShow.image
            const win:any = window.open(`${process.env.REACT_APP_ROOT_URL}/` + url, '_blank')
            win.focus()
        }
    }

    return (
        <CustomForm>
            {loading && <CustomBackdrop open={true}/>}
            { status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader 
                goBack={RoutesMap.TECHNICAL_REPORT}
                title={'Informe técnico'}
                text={ !isEdit ? 'Ingrese los datos para generar un nuevo informe técnico.' : 'Ingrese los datos para actualizar el informe técnico.'}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Solicitud:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-repair-request"
                                                value={selectedRepairRequest}
                                                options={repairRequests || []}
                                                getOptionLabel={(option) => option.correlative || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedRepairRequest(newValue);
                                                }}
                                                readOnly={isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombre del cliente:</FormLabel>
                                            <TextField
                                                id="customer_rut"
                                                type="text"
                                                name="customer_rut"
                                                fullWidth
                                                size="small"
                                                value={selectedRepairRequest?.customer || ''}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>RUT del cliente:</FormLabel>
                                            <TextField
                                                id="customer_rut"
                                                type="text"
                                                name="customer_rut"
                                                fullWidth
                                                size="small"
                                                value={selectedRepairRequest?.rut_customer || ''}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        {
                                            userRole !== Role.TECHNICAL && <>
                                                <Grid item xs={12} md={4}>
                                                    <FormLabel>Técnico:</FormLabel>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-technical"
                                                        value={selectedTechnical}
                                                        options={technicals || []}
                                                        getOptionLabel={(option) => option.name + ' ' + option.surname || ""}
                                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} size="small" />}
                                                        onChange={(e, newValue) => {
                                                            setSelectedTechnical(newValue);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormLabel>RUT del técnico:</FormLabel>
                                                    <TextField
                                                        id="technical_rut"
                                                        type="text"
                                                        name="technical_rut"
                                                        fullWidth
                                                        size="small"
                                                        value={selectedTechnical?.rut || ''}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            readOnly: true
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Tipo de atención:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-type-attention"
                                                value={selectedTypeAttention}
                                                options={typeAttentions || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedTypeAttention(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Fecha de atención:</FormLabel>
                                            <TextField
                                                id="date_attention"
                                                type="date"
                                                name="date_attention"
                                                fullWidth
                                                size="small"
                                                value={values.date_attention}
                                                onChange={handleChange}
                                                error={errors.date_attention && touched.date_attention ? true : false}
                                                helperText={errors.date_attention && touched.date_attention ? errors.date_attention : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Hora de inicio de la atención:</FormLabel>
                                            <TextField
                                                id="start_time"
                                                type="time"
                                                name="start_time"
                                                fullWidth
                                                size="small"
                                                value={values.start_time}
                                                onChange={handleChange}
                                                error={errors.start_time && touched.start_time ? true : false}
                                                helperText={errors.start_time && touched.start_time ? errors.start_time : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Hora de culmino de la atención:</FormLabel>
                                            <TextField
                                                id="end_time"
                                                type="time"
                                                name="end_time"
                                                fullWidth
                                                size="small"
                                                value={values.end_time}
                                                onChange={handleChange}
                                                error={errors.end_time && touched.end_time ? true : false}
                                                helperText={errors.end_time && touched.end_time ? errors.end_time    : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Trabajos realizados:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-work-done"
                                                value={selectedWorkDones || []}
                                                options={workDones || []}
                                                getOptionLabel={(option) => option?.description || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedWorkDones(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.description}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Repuestos Utilizados:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-replacement-parts"
                                                value={selectedReplacementParts || []}
                                                options={replacementParts || []}
                                                getOptionLabel={(option) => option?.name || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedReplacementParts(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12} sx={{ textAlign: "right" }}>
                                                <Tooltip title={'Agregar imágenes'} >
                                                    <Button
                                                        onClick={() => setShowModalImage(true)}
                                                        sx={{
                                                            border: "solid 1px #73B2FF",
                                                            m: "10px",
                                                            color: "#73B2FF",
                                                            "&:hover": {
                                                                bgcolor: "#73B2FF",
                                                                color: "#fff",
                                                            },
                                                        }}
                                                    >
                                                        {"Agregar imágenes"}
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead sx={{ background: "#D8E8F7" }}>
                                                        <TableRow>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>Nombre de la imagen</StyledTableCell>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>Comentario</StyledTableCell>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>Opciones</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            images.length > 0
                                                            ?
                                                            images.map((row, index) =>
                                                                (<TableRow key={index}>
                                                                    <TableCell align="center">{index + 1}</TableCell>
                                                                    <TableCell align="center">{row.file_name}</TableCell>
                                                                    <TableCell align="center">{row.comment}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Tooltip title="Eliminar">
                                                                            <IconButton size="small" color="inherit"
                                                                                onClick={() => deleteImageTable(row.n)}
                                                                            >
                                                                                <DeleteIcon fontSize='small'/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Ver">
                                                                            <IconButton size="small" color="inherit"
                                                                                onClick={() => showImage(row.n)}
                                                                            >
                                                                                <VisibilityIcon fontSize='small'/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>)
                                                            )
                                                            :
                                                            (<TableRow>
                                                                <TableCell colSpan={7}>
                                                                    <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                        No ha agregado alguna imagen
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>)
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={'Guardar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
            {
                showModalImage && (
                    <TechnicalReportImage
                        open={showModalImage}
                        closeModal={()=> setShowModalImage(false)}
                    />
                )
            }
        </CustomForm>
    )
}