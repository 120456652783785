import { useDispatch, useSelector } from "react-redux"
import { selectWorkOrderState, changeStatus, onFetchWorkOrders, onSetEditWorkOrder, onSetIdRequestWorkOrder } from "../redux/slices/workOrderSlice"
import { ApiStatus } from "../types/api/status"
import { WorkOrderAPI } from "../apis/WorkOrderAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { WorkOrder } from "../types/slices/workOrderType"

export const useWorkOrderStore = () => {
    const dispatch = useDispatch()
    const { workOrders, status, editWorkOrder } = useSelector(selectWorkOrderState)

    const getWorkOrders = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.getWorkOrders(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchWorkOrders(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getOneWorkOrder = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.getOne(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchWorkOrders(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createWorkOrder = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.create(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return response.data.detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateWorkOrder = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.update(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return response.data.detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteWorkOrder = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.delete(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditWorkOrder = (workOrder: WorkOrder) => {
        try {
            dispatch(onSetEditWorkOrder(workOrder))
        } catch (error) {
            console.log(error)
        }
    }

    const validatePreOT = async (id: number, status: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.validatePreOT(id, status)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setIdRequestWorkOrder = (idrequest: number) => {
        try {
            dispatch(onSetIdRequestWorkOrder(idrequest))
        } catch (error) {
            console.log(error)
        }
    }

    const getWorkOrderPDF = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.getPDF(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message)
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    const signature = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.signature(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getWorkOrderReport = async (params) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkOrderAPI.getReport(params)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    return {
        workOrders,
        status,
        editWorkOrder,
        getWorkOrders,
        createWorkOrder,
        updateWorkOrder,
        deleteWorkOrder,
        setEditWorkOrder,
        validatePreOT,
        setIdRequestWorkOrder,
        getOneWorkOrder,
        getWorkOrderPDF,
        signature,
        getWorkOrderReport
    }
}