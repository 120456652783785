import { useDispatch, useSelector } from "react-redux";
import { RepairRequestAPI } from "../apis/RepairRequestAPI";
import { selectRepairRequestState, changeStatus, onFetchRepairRequest, onSetEditRepairRequest, onSetSelectedRepairRequest } from '../redux/slices/repairRequestSlice';
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { RepairRequest } from "../types/slices/repairRequestType";

export const useRepairRequest = () => {
    const dispatch = useDispatch();
    const {
        repairRequests,
        status,
        editRepairRequest,
        selectedRepairRequest
    } = useSelector(selectRepairRequestState);


    const getRepairRequestStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await RepairRequestAPI.getRepairRequest(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchRepairRequest(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const getOneRepairRequest = async (id:number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await RepairRequestAPI.getOne(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchRepairRequest(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    }

    const createRepairRequestStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await RepairRequestAPI.createRepairRequest(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editRepairRequestStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await RepairRequestAPI.editRepairRequest(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteRepairRequestStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await RepairRequestAPI.deleteRepairRequest(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedRepairRequest = (repairRequest: RepairRequest) => {
        try {
            dispatch(onSetSelectedRepairRequest(repairRequest));
        } catch (error) {
            console.log(error);
        }
    }

    const setEditRepairRequest = (repairRequest: RepairRequest) => {
        try {
            dispatch(onSetEditRepairRequest(repairRequest));
        } catch (error) {
            console.log(error);
        }
    }

    const getRepairRequestPDF = async (id:number) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RepairRequestAPI.getPDF(id)
          if (!response?.status) {                
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message)
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          return response.data
        } catch (error) {
          console.log(error)
        }
    }

    const getReportRepairRequest = async (params) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await RepairRequestAPI.getReport(params)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const validateRepairRequest = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await RepairRequestAPI.validate(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    return {
        //states
        repairRequests,
        status,
        editRepairRequest,
        selectedRepairRequest,
        //actions
        getRepairRequestStore,
        createRepairRequestStore,
        editRepairRequestStore,
        deleteRepairRequestStore,
        setSelectedRepairRequest,
        setEditRepairRequest,
        getOneRepairRequest,
        getRepairRequestPDF,
        getReportRepairRequest,
        validateRepairRequest
    }
}