import { useDispatch, useSelector } from "react-redux";
import { VehiclesAPI } from "../apis/VehiclesAPI";
import {
    selectVehiclesState,
    changeStatus,
    onFetchVehicles,
    onSetEditVehicles,
    onSetSelectedVehicles,
    onFilterVehicles,
} from "../redux/slices/vehiclesSlice"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Vehicles } from "../types/slices/vehiclesType";

export const useVehicleStore = () => {
    const dispatch = useDispatch();
    const {
        vehicles,
        status: vehicleStatus,
        editVehicles,
        selectedVehicles,
    } = useSelector(selectVehiclesState)
    const idcompany = 1;

    const getVehicles = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await VehiclesAPI.getVehicles(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchVehicles(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createVehicleStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await VehiclesAPI.createVehicles(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editVehicleStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await VehiclesAPI.editVehicles(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteVehicleStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await VehiclesAPI.deleteVehicles(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedVehicle = (vehicle: Vehicles) => {
        try {
            dispatch(onSetSelectedVehicles(vehicle));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditVehicle = (vehicle: Vehicles) => {
        try {
            dispatch(onSetEditVehicles(vehicle));
        } catch (error) {
            console.log(error);
        }
    };

    const filterVehicles = async (idtrademark: number, idmodel: number, idtype_vehicle: number, idcontrata: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await VehiclesAPI.filterVehicles(idtrademark, idmodel, idtype_vehicle, idcontrata);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchVehicles(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    }

    return {
        //states
        vehicles,
        vehicleStatus,
        editVehicles,
        selectedVehicles,
        //actions
        getVehicles,
        createVehicleStore,
        editVehicleStore,
        deleteVehicleStore,
        setSelectedVehicle,
        setEditVehicle,
        filterVehicles,
    }
}
