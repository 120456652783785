import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useAdministratorStore } from "../../hooks/useAdministratorStore";
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { Administrator } from "../../types/slices/administratorType";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useUserStore } from "../../hooks/useUserStore";
import ContactMailIcon from '@mui/icons-material/ContactMail';

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name', label: 'NOMBRES', align: 'center' },
    { type: 'text', field: 'surname', label: 'APELLIDOS', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'email', label: 'EMAIL', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELEFONO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const AdministratorView : FC = () => {
    
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status: adminStatus, administrators, setSelectedAdmin, deleteAdmin, getAdministrators } = useAdministratorStore()
    const { forgotPasswordUser } = useUserStore()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ page, setPage ] = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ rowSelected, setRowSelected ]           = useState<Administrator>({} as Administrator)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);

    
    useEffect(()=>{
        getAdminApi()
    },[search])

    const getAdminApi = async () => {
        try {
          setLoading(true)
          let payload:any = {}
          if (search !== '') {
            payload = {
                ...payload,
                search
            }
          }
          await getAdministrators(payload)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        navigate({ pathname: RoutesMap.ADMINISTRATOR_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedAdmin(rowSelected)
        navigate({ pathname: RoutesMap.ADMINISTRATOR_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteAdmin(rowSelected.id)
        if(response === true){
            setSelectedAdmin({} as Administrator)
            setShowModalConfirm(false)
            getAdminApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const sendCredentials = async (rowSelected) => {
        setLoading(true)
        await forgotPasswordUser(rowSelected.rut, rowSelected.iduser_type)
        setLoading(false)
    }

    return (<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Administradores'}
                columns={columns || []}
                loading={loading}
                rows={administrators || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                onHelper={sendCredentials}
                tooltip_helper={'Enviar credenciales'}
                icon_helper={<ContactMailIcon fontSize="small"/>}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                    status2={adminStatus == ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}
