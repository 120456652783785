import { FC, useEffect, useState } from "react";
import { Autocomplete, IconButton, FormLabel, Button, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, TablePagination, Checkbox, Divider } from '@mui/material';
import { UserType, UserTypeERP } from "../../types/slices/userType";
import { useUserStore } from "../../hooks/useUserStore";
import { withStyles } from "@mui/styles";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { C } from "@fullcalendar/core/internal-common";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

type user = {
    order:number
    rut:string
    name:string
    last_name:string
    email:string
    selected:boolean
}

export const SettingImportERP:FC = () => {
    const { search } = useSelector(selectHeaderState)
    const navigate = useNavigate()
    const { userTypes, userTypesERP, usersERP, getUserTypes, getUserTypesERP, importUsersERP, getUsersERP, clearUsersERP } = useUserStore()

    const [ dataTable, setDataTable ] = useState<user[]>([])
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ loading2, setLoading2 ] = useState<boolean>(false)
    const [ loading3, setLoading3 ] = useState<boolean>(false)
    const [ selectedUserType, setSelectedUserType ] = useState<UserType|null>(null)
    const [ selectedUserTypeERP, setSelectedUserTypeERP ] = useState<UserTypeERP|null>(null)
    const [ page, setPage ] = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ readOnly, setReadOnly ] = useState<boolean>(false)

    useEffect(()=>{
        getComboBoxes()
    },[])

    useEffect(()=>{
        if (selectedUserTypeERP && selectedUserType) getUserERPApi()
    },[search])

    useEffect(()=>{
        setAllDataTable()
    },[usersERP])

    const getComboBoxes = async () => {
        setLoading(true)
        await getUserTypesERP()
        await getUserTypes()
        setLoading(false)
    }

    const handleAllChecked = () => {
        const data = dataTable?.map(row => {
            return {
                ...row,
                selected: Object.values(dataTable).every(value => value.selected === false) ? true : false
            }
        })
        setDataTable(data || [])
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const getUserERPApi = async () => {
        if(!selectedUserTypeERP) return
        if(!selectedUserType) return
        setLoading2(true)
        setReadOnly(true)
        let payload:any = {
            idcharge_erp: selectedUserTypeERP.id,
            idtype: selectedUserType.id
        }
        if (search != '') {
            payload = {
                ...payload,
                search
            }
        }
        await getUsersERP(payload)
        setLoading2(false)
    }

    const setAllDataTable = () => {
        const data = usersERP?.map((row, index) => {
            return {
                order: index+1,
                rut: row.RUT2,
                name: row.NOMBRES,
                last_name: row.APELLIDO_PATERNO + ' ' + row.APELLIDO_MATERNO,
                email: row.EMAIL || 'No registrado',
                selected: false
            }
        })
        setDataTable(data || [])
    }

    const onChangeCheckBox = (e:any, rut:string) => {
        const { checked } = e.target;
        const data = dataTable?.map(row => {
            if (rut === row.rut) {
                return {
                    ...row,
                    selected: checked ? true : false
                }
            }
            return row
        })
        setDataTable(data || [])
    }

    const handleSubmit = async () => {
        if (!selectedUserTypeERP) return CustomSnackbar('warning', 'Seleccione un cargo del ERP SOFTNET.')
        if (!selectedUserType) return CustomSnackbar('warning', 'Seleccione un cargo de TVP APP')
        if (Object.values(dataTable).every(value => value.selected === false)) return CustomSnackbar('warning', 'Seleccione un usuario.')
        setLoading3(true)
        const a_rut = dataTable?.filter(row => row.selected === true).map(row => row.rut)
        const payload = {
            rut_employees: a_rut,
            idtype: selectedUserType.id
        }
        const response = await importUsersERP(payload)
        setLoading3(false)
        if (response === true) clearData()
    }

    const clearData = () => {
        setDataTable([])
        setSelectedUserType(null)
        setSelectedUserTypeERP(null)
        setReadOnly(false)
    }

    const searchUsers = (search:string) => {
        const data = usersERP?.filter(user => 
            user.RUT2.match(search) || 
            user.NOMBRES.toLowerCase().match(search.toLowerCase()) || 
            user.APELLIDO_MATERNO.toLowerCase().match(search.toLowerCase()) || 
            user.APELLIDO_PATERNO.toLowerCase().match(search.toLowerCase()))
            .map((row, index) => {
            return {
                order: index+1,
                rut: row.RUT2,
                name: row.NOMBRES,
                last_name: row.APELLIDO_PATERNO + ' ' + row.APELLIDO_MATERNO,
                email: row.EMAIL || 'No registrado',
                selected: false
            }
        })
        setDataTable(data || [])
    }

    return (
        <Grid container>
            {loading3 && <CustomBackdrop open={true}/>}
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
                <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
                    IMPORTAR USUARIOS DEL ERP SOFTNET
                </Typography>
            </Grid>
            <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }} spacing={2}>
                <Grid item xs={12} md={4.5}>
                    <FormLabel>Cargo del ERP:</FormLabel>
                    {
                        loading
                        ?
                        <Skeleton variant="rectangular" width="100%" height={40} />
                        :
                        <Autocomplete
                            disablePortal
                            id="combo-box-user-type-erp"
                            value={selectedUserTypeERP}
                            options={userTypesERP || []}
                            getOptionLabel={(option) => option.nombre || ""}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            fullWidth
                            renderInput={(params) => <TextField {...params} size="small" />}
                            onChange={(e, newValue) => {
                                setSelectedUserTypeERP(newValue);
                            }}
                            readOnly={readOnly}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={4.5}>
                    <FormLabel>Importar a:</FormLabel>
                    {
                        loading
                        ?
                        <Skeleton variant="rectangular" width="100%" height={40} />
                        :
                        <Autocomplete
                            disablePortal
                            id="combo-box-user-type"
                            value={selectedUserType}
                            options={userTypes || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            fullWidth
                            renderInput={(params) => <TextField {...params} size="small" />}
                            onChange={(e, newValue) => {
                                setSelectedUserType(newValue)
                                if(newValue === null) clearUsersERP()
                            }}
                            readOnly={readOnly}
                        />
                    }
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
                    {
                        readOnly
                        ?
                        <>
                        <Button
                            onClick={handleSubmit}
                            sx={{
                                marginTop: "15px",
                                marginRight: "7px",
                                border: "solid 1px #73B2FF",
                                color: "#73B2FF",
                                "&:hover": {
                                    bgcolor: "#73B2FF",
                                    color: "#fff",
                                }
                            }}
                        >
                            Importar
                        </Button>
                        <Button
                            onClick={clearData}
                            sx={{
                                marginTop: "15px",
                                marginLeft: "7px",
                                border: "solid 1px #73B2FF",
                                color: "#73B2FF",
                                "&:hover": {
                                    bgcolor: "#73B2FF",
                                    color: "#fff",
                                }
                            }}
                        >
                            Cancelar
                        </Button>
                        </>
                        :
                        <Button
                            onClick={getUserERPApi}
                            sx={{
                                marginTop: "15px",
                                border: "solid 1px #73B2FF",
                                color: "#73B2FF",
                                "&:hover": {
                                    bgcolor: "#73B2FF",
                                    color: "#fff",
                                }
                            }}
                        >
                            Buscar
                        </Button>
                    }

                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid item xs={12} sx={{ textAlign: "left", marginBottom: '10px' }}>
                        <Tooltip title={Object.values(dataTable).every(value => value.selected === false) ? "Seleccionar todos los usuarios" : "Deseleccionar todos los usuarios"}>
                            <Button 
                                onClick={handleAllChecked} 
                                size="small"
                                sx={{ margin: '10px 0', background: '#FFFFFF', color: '#73B2FF', border: 'solid 1px #73B2FF', '&:hover': { bgcolor: '#73B2FF', color: '#FFFFFF' } }}>
                                {Object.values(dataTable).every(value => value.selected === false) ? "Seleccionar todo" : "Deseleccionar todo"}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ background: "#D8E8F7" }}>
                                <TableRow>
                                    <StyledTableCell className="headTable" align="center" colSpan={1} sx={{ width: '10px'}}></StyledTableCell>
                                    <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                    <StyledTableCell className="headTable" align="center" colSpan={1}>RUT</StyledTableCell>
                                    <StyledTableCell className="headTable" align="center" colSpan={1}>APELLIDOS</StyledTableCell>
                                    <StyledTableCell className="headTable" align="center" colSpan={1}>NOMBRES</StyledTableCell>
                                    <StyledTableCell className="headTable" align="center" colSpan={1}>CORREO</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { 
                                    !readOnly && (
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                    Seleccione un cargo del ERP y un cargo de la aplicación
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    selectedUserTypeERP && selectedUserType && loading2 && (
                                        <TableRow>
                                            <TableCell align="center">
                                                <Skeleton variant="rectangular" width="100%" height={20} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="rectangular" width="100%" height={20} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="rectangular" width="100%" height={20} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="rectangular" width="100%" height={20} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="rectangular" width="100%" height={20} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="rectangular" width="100%" height={20} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    selectedUserTypeERP && selectedUserType && !loading2 && readOnly &&  (
                                        dataTable?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) =>
                                            <TableRow key={index}>
                                                <TableCell align="center" sx={{ width: '10px'}}>
                                                    <Checkbox color="primary" onChange={(e) => onChangeCheckBox(e, row.rut)} checked={row.selected} />
                                                </TableCell>
                                                <TableCell align="center">{row.order}</TableCell>
                                                <TableCell align="center">{row.rut}</TableCell>
                                                <TableCell align="center">{row.name}</TableCell>
                                                <TableCell align="center">{row.last_name}</TableCell>
                                                <TableCell align="center">{row.email}</TableCell>
                                            </TableRow>
                                        )
                                    )
                                }
                                {
                                    selectedUserTypeERP && selectedUserType && dataTable.length === 0 &&  !loading2 && readOnly &&  (
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                    No hay data
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        dataTable.length > 1 &&
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[10, 25, 100]}
                            count={dataTable.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    }
                </Grid>
                <Divider/>
            </Grid>
        </Grid>
    )
}