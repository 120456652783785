import { useState } from "react"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Typography, Grid, Button, CircularProgress } from "@mui/material"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useWorkOrderStore } from "../../hooks/useWorkOrderStore"
import { ApiStatus } from "../../types/api/status"
import Radio from '@mui/material/Radio';

export const WorkOrderModalValidate: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, onList, rowSelected } = props

    const { status, validatePreOT } = useWorkOrderStore()

    const [statusValidate, setStatusValidate] = useState<number>(1)

    const handleSubmit = async () => {
        const response = await validatePreOT(rowSelected.id, statusValidate)
        if (response === true) {
            onList()
            closeModal()
        }
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalBody>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontWeight: 'bolder' }}>
                        VALIDAR ORDEN DE TRABAJO
                    </Typography>
                </Grid>
                <Grid container>
                    {/* <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <FormLabel>{'Motivo(s):'}</FormLabel>
                        <TextareaAutosize 
                            id="razon"
                            name="razon"
                            style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }} 
                            value={
                                rowSelected.name_fails_resource?.map(element => element.name).join('\n')
                            }
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <FormLabel>Descripción:</FormLabel>
                        <TextareaAutosize 
                            id="description"
                            name="description"
                            style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }} 
                            value={rowSelected.description_request}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <FormLabel>Revisión Técnica:</FormLabel>
                        <TextareaAutosize 
                            id="technical_review"
                            name="technical_review"
                            style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }} 
                            value={rowSelected.technical_review}
                            readOnly={true}
                        />
                    </Grid> */}
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Radio checked={statusValidate === 1} onClick={(e) => setStatusValidate(1)} />} label="Aprobar" />
                            <FormControlLabel control={<Radio checked={statusValidate === 2} onClick={(e) => setStatusValidate(2)} />} label="Rechazar" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button
                            onClick={() => handleSubmit()}
                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                        >
                            {
                                status === ApiStatus.FETCHING
                                    ?
                                    <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                    :
                                    'Confirmar'
                            }
                        </Button>
                        <Button
                            disabled={status === ApiStatus.FETCHING}
                            onClick={() => closeModal()}
                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}