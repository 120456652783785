import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Activities, ActivitiesType } from "../../types/slices/activitiesType";

const initialState: ActivitiesType = {
    status: ApiStatus.FETCHED,
    activities: [],
    errorMessage: undefined,
    selectedActivities: {} as Activities,
};

const ActivitiesSlice = createSlice({
    name: "activities",
    initialState,
    reducers: {
        onFetchActivities(state, { payload }: { payload: Activities[] }) {
            state.status = ApiStatus.FETCHED;
            state.activities = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedActivities(state, { payload }: { payload: Activities }) {
            state.selectedActivities = payload;
        },
    }
})

export const selectActivitiesState = (state: RootState) => state.ActivitiesSlice;

export default ActivitiesSlice.reducer;

export const {
    onFetchActivities,
    changeStatus,
    onSetSelectedActivities,
} = ActivitiesSlice.actions;