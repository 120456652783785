import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import CloseIcon from '@mui/icons-material/Close'
import { withStyles } from "@mui/styles";
import { FC, useEffect, useState } from "react";
import { AxleDetailType } from "../../types/slices/typeVehicleType";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

type rowData = {
    axle: string,
    tires: number,
    positions: string,
    type: string
}

export const TypeVehicleModal: FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, rowSelected } = props
    const [rows, setRows] = useState<rowData[]>([])

    useEffect(() => {
        const singleAxle = {};
        rowSelected.axle_detail?.forEach((element: AxleDetailType) => {
            const x = element.tire_order.charAt(0);
            const position = element.tire_order;
            if (singleAxle[x]) {
                singleAxle[x].tires += 1;
                singleAxle[x].positions.add(position);
            } else {
                singleAxle[x] = { axle: x, tires: 1, positions: new Set([position]), type: element.axle_type };
            }
        });
        const rowArray: rowData[] = Object.values(singleAxle).map((eje: any) => ({
            ...eje,
            positions: Array.from(eje.positions).join(', '),
        }));
        setRows(rowArray);
    }, [])

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="lg">
            <ModalBody>
                <Grid item container sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography variant="h6"
                        component="h1"
                        gutterBottom
                        color="#808080"
                        className="tittle"
                        style={{ textTransform: 'uppercase' }}
                        sx={{ mt: 1, ml: 1, fontWeight: 600 }}>Detalle: {rowSelected.name}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "right" }} md={6}>
                    <Tooltip title="Cerrar">
                        <IconButton onClick={() => closeModal()}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ background: "#D8E8F7" }}>
                            <TableRow>
                                <StyledTableCell className="headTable" align="center">EJE</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">N° NEUMÁTICOS</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">POSICIONES</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">TIPO</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.axle}>
                                    <TableCell align="center">Eje {row.axle}</TableCell>
                                    <TableCell align="center">{row.tires}</TableCell>
                                    <TableCell align="center">{row.positions}</TableCell>
                                    <TableCell align="center">{row.type}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ModalBody>
        </Modal>
    )
}
