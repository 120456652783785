import { useDispatch, useSelector } from "react-redux";
import { structureD2API } from "../apis/structureD2API";
import { selectStructureD2State, onFetchStructureD2, onSetEditStructureD2, onSetSelectedStructureD2, changeStatus, onFetchGroupsStructureD2 } from "../redux/slices/structureD2Slice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { StructureD2 } from "../types/slices/structureD2Type";

export const useStructureD2Store = () => {
    const dispatch = useDispatch();
    const { structureD2, status, editStructureD2, selectedStructureD2, groupsStructureD2 } = useSelector(selectStructureD2State);

    const required = 0;

    const getStructureD2Store = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD2API.getStructureD2(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchStructureD2(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createStructureD2Store = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD2API.createStructureD2(data, required);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editStructureD2Store = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD2API.editStructureD2(id, data, required);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteStructureD2Store = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD2API.deleteStructureD2(id);
            if (!response.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedStructureD2 = (structureD2: StructureD2) => {
        try {
            dispatch(onSetSelectedStructureD2(structureD2));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditStructureD2 = (structureD2: StructureD2) => {
        try {
            dispatch(onSetEditStructureD2(structureD2))
        } catch (error) {
            console.log(error);
        }
    };

    const setStructureD2 = (structure: StructureD2[]) => {
        try {
            dispatch(onFetchStructureD2(structure))
        } catch (error) {
            console.log(error);
        }
    }

    const clearStructureD2 = () => {
        try {
            dispatch(onFetchStructureD2([]))
        } catch (error) {
            console.log(error);
        }
    }

    const getGroupsStructureD2 = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD2API.getGroup(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchGroupsStructureD2(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    return {
        //states
        structureD2,
        status,
        editStructureD2,
        selectedStructureD2,
        groupsStructureD2,
        //actions
        getStructureD2Store,
        createStructureD2Store,
        editStructureD2Store,
        deleteStructureD2Store,
        setSelectedStructureD2,
        setEditStructureD2,
        clearStructureD2,
        setStructureD2,
        getGroupsStructureD2
    }
}