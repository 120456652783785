import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchImages, onFetchTechnicalReports, onSetEditTechnicalReport, selectTechnicalReportState } from "../redux/slices/technicalReportSlice"
import { ApiStatus } from "../types/api/status"
import { TechnicalReportAPI } from "../apis/TechnicalReportAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { ImageFile, TechnicalReport } from "../types/slices/technicalreportType"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useTechnicalReportStore = () => {
    const dispatch = useDispatch()
    const { technicalReports, status, editTechnicalReport, images } = useSelector(selectTechnicalReportState)

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany
    
    const getTechnicalReports = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.getWorkOrders({...data, idcompany})
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchTechnicalReports(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getOneReport = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.getOne(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchTechnicalReports(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createTechnicalReport = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.create(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return response.data.detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateTechnicalReport = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.update(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return response.data.detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteTechnicalReport = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.delete(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditTechnicalReport = (report:TechnicalReport) => {
        try {
            dispatch(onSetEditTechnicalReport(report))
        } catch (error) {
            console.log(error)
        }
    }

    const getTechnicalReportPDF = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.getPDF(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message)
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    const signature = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.signature(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setFetchImages = (image:ImageFile[]) => {
        try {
            dispatch(onFetchImages(image))
        } catch (error) {
            console.log(error)
        }
    }

    const finalized = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await TechnicalReportAPI.finalized(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    return {
        technicalReports,
        status,
        editTechnicalReport,
        images,
        getTechnicalReports,
        createTechnicalReport,
        updateTechnicalReport,
        deleteTechnicalReport,
        setEditTechnicalReport,
        getTechnicalReportPDF,
        signature,
        setFetchImages,
        getOneReport,
        finalized
    }
}