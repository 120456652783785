import { useDispatch, useSelector } from "react-redux";
import { MileageMaintenanceAPI } from "../apis/MileageMaintenanceAPI";
import { selectMileageMaintenance, onFetchMileage, changeStatus } from "../redux/slices/mileageMaintenanceSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useMileageMaintenance = () => {
    const dispatch = useDispatch();
    const { status, mileages } = useSelector(selectMileageMaintenance);

    const getMileageMaintenanceStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MileageMaintenanceAPI.getMileage(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchMileage(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    return {
        mileages,
        status,
        getMileageMaintenanceStore,
    }
}