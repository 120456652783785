import { toast } from "react-hot-toast";
import WarningIcon from "@mui/icons-material/Warning";
import BuildIcon from '@mui/icons-material/Build';
import ArticleIcon from '@mui/icons-material/Article';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export const CustomSnackbar = (alert_type: string, message: string) => {
    switch (alert_type) {
        case "warning":
            return toast(message, {
                icon: <WarningIcon style={{ color: "orange" }} />,
            });
        case "success":
            return toast.success(message);
        case "error":
            return toast.error(message);
        case "earring":
            return toast(message, {
                icon: <BuildIcon style={{ color: "blue" }} />,
            });
        case "document":
            return toast(message, {
                icon: <ArticleIcon style={{ color: "blue" }} />,
            });
        case "signed":
            return toast(message, {
                icon: <DriveFileRenameOutlineIcon style={{ color: "brown" }} />,
            });
    }
};
