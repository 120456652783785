import { api } from "./configs/axiosConfigs"

export const NameFailsAPI = {
    getNameFails: async (data?: any) => {
        const response = await api.get(
            '/nameFails',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    createNameFails: async (data: any) => {
        const response = await api.post(
            '/nameFails/register',
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    editNameFails: async (id: number, data: any) => {
        const response = await api.patch(
            `/nameFails/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    deleteNameFails: async (id: number) => {
        const response = await api.delete(
            `/nameFails/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data.message
            })
        return response
    }
}