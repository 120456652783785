import { FC, useEffect, useState } from "react";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useEmployeeIncompleteStore } from "../../hooks/useEmployeeIncompleteStore";
import { EmployeeIncompleteModalEdit } from "./EmployeeIncompleteModalEdit";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name', label: 'NOMBRES', align: 'center' },
    { type: 'text', field: 'surname', label: 'APELLIDOS', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'type', label: 'ROL', align: 'center' },
    { type: 'text', field: 'email', label: 'EMAIL', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELEFONO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]


export const EmployeeIncompleteList:FC = () => {
    const { search } = useSelector(selectHeaderState)
    const { employeesIncompletes, getEmployeesIncompletes, setEditEmployeeIncomplete } = useEmployeeIncompleteStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<any>({})
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false)

    useEffect(() => {
        getEmployeesApi()
    }, [search])

    const getEmployeesApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            await getEmployeesIncompletes(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onEdit = (rowSelected) => {
        setRowSelected(rowSelected)
        setEditEmployeeIncomplete(rowSelected)
        setShowModalEdit(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    
    return (<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Usuarios en espera'}
                columns={columns || []}
                loading={loading}
                rows={ employeesIncompletes || []}
                onRowClick={() => {}}
                onEdit={onEdit}
                hasOptions
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalEdit && (
                <EmployeeIncompleteModalEdit
                    open={showModalEdit}
                    closeModal={() => { setShowModalEdit(false) }}
                    onList={()=> getEmployeesApi()}
                    rowSelected={rowSelected}
                />
            )
        }
    </>)
}