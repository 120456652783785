import { Grid, Typography, Card, Button, Avatar, TextField, Divider, Accordion, AccordionDetails, AccordionSummary, InputAdornment, IconButton } from '@mui/material';
import { showBackdrop, showSnackBar } from '../../toolbox/helpers/custom-action';
import { Formik } from 'formik';
import { CustomFormFooter } from '../../components/common/CustomForm/CustomFormFooter';
import { useDispatch } from '../../redux/store';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { useAuthStore } from '../../hooks';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useUserStore } from '../../hooks/useUserStore';
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';
import { LocalStorageKey } from '../../types';
import { IdentificationModal } from './components/IdentificationModal';
import { Role } from '../../types/roles/roleTypes';

type Values = {
    id: number
    name: string,
    surname: string,
    email: string,
    phone: string,
    pin: string
    password: string
}

export const MyProfileView = () => {
    const dispatch = useDispatch()
    const { user, setProfileData } = useAuthStore()
    const { singingPin } = useUserStore()
    
    const [showModalFirm, setShowModalFirm] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [edit2, setEdit2] = useState<boolean>(false);
    const [data, setData] = useState<Values>({
        id: 0,
        name: '',
        surname: '',
        email: '',
        phone: '',
        pin: '',
        password: ''
    })

    const token = readLocalStorage(LocalStorageKey.TOKEN_KYTE)
    const userRole: Role | any = user.userType

    useEffect(()=>{
        setLocalData()
    },[user])

    const setLocalData = () => {
        setData({
            id: user.id,
            name: user.data?.name || '',
            surname: user.data?.surname || '',
            email: user.data?.email || '',
            phone: user.data?.phone || '',
            pin: user.pin || 'NO SE HA REGISTRADO',
            password: ''
        })
    }

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.surname) errors.surname = "apellido requerido";
        if(!values.email) errors.email = "correo requerido";
        if(!values.phone) errors.phone = "telefono requerido";
        return errors;
    }

    const validatePinForm = (values) => {
        let errors:any = {};
        if(!values.pin) errors.pin = "PIN requerido";
        if(!values.password) errors.password = "Contraseña requerido";
        return errors;
    }

    const handleClickShowPassword = () => {
        if(showPassword) setShowPassword(false)
        if(!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const onSubmit = async (values)  => {
    
    }

    const onSubmitPIN = async (values) => {
        const response = await singingPin({ pin: values.pin, password: values.password, password_confirmation: values.password })
        if (response === true) {
            await setProfileData(token)
        }
    }

    return (
        <>
        <Grid container  sx={{padding:'30px 30px 30px 30px'}}>
            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Card sx={{ width: '700px', display:'flex', justifyContent:'start', alignItems:'center', border:'solid 1px #808080', padding:'10px' }}>
                    <Grid sx={{mr:3}}>
                        <Avatar
                            alt="Remy Sharp"
                            src=""
                            sx={{ width: 56, height: 56 }}
                        />
                    </Grid>
                    <Grid>
                        <div><Typography color='#212D39' sx={{ fontSize:24, fontWeight:600}}>{data.name + ' ' + data.surname}</Typography></div>
                        <div><Typography color='#212D39' sx={{ fontSize:15, fontWeight:550}}>{user.userType || ''}</Typography></div>
                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Modifica los datos relacionados con tu perfil'}</Typography></div>
                    </Grid>
                </Card>
            </Grid>
            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Accordion  sx={{width:'700px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Mis Datos</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                            {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container sx={{padding:'20px'}}>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{"Nombre"}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="name"
                                                    variant="standard"
                                                    type="text" 
                                                    name="name"
                                                    multiline
                                                    fullWidth
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    placeholder={"name"}
                                                    error={errors.name && touched.name ? true : false}
                                                    helperText={errors.name && touched.name ? errors.name: ''}
                                                    InputProps={{
                                                        readOnly: !edit,
                                                    }}
                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{"Apellido"}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="surname"
                                                    variant="standard"
                                                    type="text" 
                                                    name="surname"
                                                    multiline
                                                    fullWidth
                                                    value={values.surname}
                                                    onChange={handleChange}
                                                    placeholder={"surname"}
                                                    error={errors.surname && touched.surname ? true : false}
                                                    helperText={errors.surname && touched.surname ? errors.surname: ''}
                                                    InputProps={{
                                                        readOnly: !edit,
                                                    }}
                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{"Correo"}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="email"
                                                    variant="standard"
                                                    type="email" 
                                                    name="email"
                                                    multiline
                                                    fullWidth
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder={"email"}
                                                    error={errors.email && touched.email ? true : false}
                                                    helperText={errors.email && touched.email ? errors.email: ''}
                                                    InputProps={{
                                                        readOnly: !edit,
                                                    }}
                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{"Teléfono"}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="phone"
                                                    variant="standard"
                                                    type="number" 
                                                    name="phone"
                                                    multiline
                                                    fullWidth
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    placeholder={"phone"}
                                                    error={errors.phone && touched.phone ? true : false}
                                                    helperText={errors.phone && touched.phone ? errors.phone: ''}
                                                    InputProps={{
                                                        readOnly: !edit,
                                                    }}
                                                />
                                                </Grid>
                                            </Grid>
                                        
                                        </Grid>

                                        <Divider />
                                        {
                                            edit && (
                                                <CustomFormFooter 
                                                    buttonType="submit"
                                                    confirmText={'Guardar'}
                                                    onConfirm={handleSubmit}
                                                    cancelText={"Cancelar"}
                                                    onCancel={()=>{
                                                        setFieldValue('name', data.name)
                                                        setFieldValue('surname', data.surname)
                                                        setFieldValue('email', data.email)
                                                        setFieldValue('phone', data.phone)
                                                        setEdit(false)
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            // !edit && (
                                            //     <CustomFormFooter 
                                            //         buttonType="submit"
                                            //         cancelText={"Editar"}
                                            //         onCancel={()=>{setEdit(true)}}
                                            //     />
                                            // )
                                        }
                                        
                                    </form>
                                )
                            }} 
                        </Formik>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {
                userRole !== Role.ADMIN && (<>
                    <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                        <Accordion  sx={{width:'700px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>PIN</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Formik initialValues={data} enableReinitialize validate={ (values) => validatePinForm(values) } onSubmit={onSubmitPIN}>
                                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container sx={{padding:'20px'}}>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{"PIN"}:</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <TextField
                                                            id="pin"
                                                            name="pin"
                                                            variant="standard"
                                                            fullWidth
                                                            type={values.pin === 'NO SE HA REGISTRADO' ? 'text' : (showPassword ? 'text' : 'password')}
                                                            value={values.pin}
                                                            onChange={(e) => {
                                                                const regex = /^[0-9\b]+$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                                    setFieldValue('pin', e.target.value)
                                                                }
                                                            }}
                                                            size='small'
                                                            InputProps={{
                                                                readOnly: !edit2,
                                                                startAdornment: (
                                                                <InputAdornment position='start'  >
                                                                    <LockIcon sx={{ color: '#29333B' }} />
                                                                </InputAdornment>),
                                                                endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                    sx={{ color: '#29333B' }}
                                                                    >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                                ),
                                                                inputProps: {
                                                                    maxLength: 6
                                                                }
                                                            }}
                                                            error={errors.pin && touched.pin ? true : false}
                                                            helperText={errors.pin && touched.pin ? errors.pin : ''}
                                                            />
                                                        </Grid>
                                                        {
                                                            edit2 && (
                                                                <Grid item container mt={2}>
                                                                <Grid item xs={4}>
                                                                    <Typography variant="subtitle1" className="custom-input"><b>{"Contraseña"}:</b></Typography>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                    id="password"
                                                                    name="password"
                                                                    variant="standard"
                                                                    fullWidth
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    size='small'
                                                                    InputProps={{
                                                                        readOnly: !edit2,
                                                                        startAdornment: (
                                                                        <InputAdornment position='start'  >
                                                                            <LockIcon sx={{ color: '#29333B' }} />
                                                                        </InputAdornment>),
                                                                        endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                            sx={{ color: '#29333B' }}
                                                                            >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                        )
                                                                    }}
                                                                    error={errors.password && touched.password ? true : false}
                                                                    helperText={errors.password && touched.password ? errors.password : ''}
                                                                    />
                                                                </Grid>
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Divider />
                                                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                                                    {
                                                        edit2 && (
                                                            <CustomFormFooter 
                                                                buttonType="submit"
                                                                confirmText={'Guardar'}
                                                                onConfirm={handleSubmit}
                                                                cancelText={"Cancelar"}
                                                                onCancel={()=>{
                                                                    setFieldValue('pin', data.pin)
                                                                    setEdit2(false)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        !edit2 && (
                                                            <CustomFormFooter 
                                                                buttonType="submit"
                                                                cancelText={"Editar"}
                                                                onCancel={()=>{
                                                                    if (values.pin === 'NO SE HA REGISTRADO') {
                                                                        setFieldValue('pin', '')
                                                                    }
                                                                    setEdit2(true)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }} 
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                        <Accordion  sx={{width:'700px'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Identificación</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    <CustomFormFooter 
                                    buttonType="submit"
                                    cancelText={"Ver"}
                                    onCancel={()=>setShowModalFirm(true)}
                                    />
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </>)
            }

        </Grid>
        {
            showModalFirm && (
                <IdentificationModal
                open={showModalFirm}
                closeModal={()=>setShowModalFirm(false)}
                />
            )
        }
        </>
    )
}