import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTypeVehicleStore } from "../../hooks/useTypeVehicleStore";
import { AxleDetailType, TypeVehicle } from "../../types/slices/typeVehicleType";
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { withStyles } from "@mui/styles";
import { useAxleStore } from "../../hooks/useAxleStore";

type TypeVehicleDataForm = {
    id: number;
    name: string;
    description?: string;
}

type axleType = {
    axle: number
    tires: number
    positions: {}
    typeAxles: number
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

export const TypeVehiclesForm: FC<any> = () => {
    const navigate = useNavigate();
    const { typeVehicleStatus, selectedTypeVehicle, setSelectedTypeVehicle, createTypeVehicleStore, editTypeVehicleStore } = useTypeVehicleStore();
    const { axles, getAxleStore } = useAxleStore();

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<TypeVehicleDataForm>({
        id: 0,
        name: '',
        description: '',
    });

    const [axleNumber, setAxleNumber] = useState<number>(0)
    const [states, setStates] = useState<axleType[]>([])

    useEffect(() => {
        axleAPI();
        if (Object.entries(selectedTypeVehicle).length > 0) {
            setIsEdit(true);
            const axleDetail = {}
            selectedTypeVehicle.axle_detail?.forEach((axleObject: AxleDetailType) => {
                const [eje, tiresNumber] = axleObject.tire_order.split('.').map(Number);
                const axle_type = axles.find((element) => element.name === axleObject.axle_type)
                if (!axleDetail[eje]) {
                    axleDetail[eje] = { tiresNumber, axle_type: axle_type?.id }
                } else {
                    const existingAxle = axleDetail[eje];
                    existingAxle.tiresNumber = Math.max(existingAxle.tiresNumber, tiresNumber);
                    existingAxle.axle_type = axle_type?.id;
                }
            });
            const numberAxles = Object.keys(axleDetail).length
            setAxleNumber(numberAxles);

            setData({
                ...data,
                id: selectedTypeVehicle.id,
                name: selectedTypeVehicle.name,
                description: selectedTypeVehicle.description,
            })
            const initialState = Array.from({ length: numberAxles }, (_, i) => ({
                axle: i + 1,
                tires: axleDetail[i + 1].tiresNumber,
                positions: {},
                typeAxles: axleDetail[i + 1].axle_type,
            }));
            setStates(initialState);
            setSelectedTypeVehicle({} as TypeVehicle)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Object.entries(selectedTypeVehicle).length <= 0) {
            setStates(prevStates => {
                if (prevStates.length === 0) {
                    const newState = Array.from({ length: axleNumber }, (_, i) => ({
                        axle: i + 1,
                        tires: 0,
                        positions: {},
                        typeAxles: 1,
                    }));
                    return newState;
                } else {
                    if (prevStates.length < axleNumber) {
                        const additionalStates = Array.from({ length: axleNumber - prevStates.length }, (_, i) => ({
                            axle: prevStates.length + i + 1,
                            tires: 0,
                            positions: {},
                            typeAxles: 1,
                        }));
                        return [...prevStates, ...additionalStates];
                    } else if (prevStates.length > axleNumber) {
                        return prevStates.slice(0, axleNumber);
                    } else {
                        return prevStates;
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axleNumber]);


    useEffect(() => {
        if (states.length > 0) {
            const updatedStates = states.map((element) => {
                const positions = {};
                for (let i = 0; i < element.tires; i++) {
                    positions[`${element.axle}.${i + 1}`] = element.typeAxles;
                }
                return { ...element, positions };
            });

            const hasChanged = !states.every((element, index) => {
                return JSON.stringify(element.positions) === JSON.stringify(updatedStates[index].positions);
            });

            if (hasChanged) {
                setStates(updatedStates);
            }
        }
    }, [states]);

    const axleAPI = async () => {
        try {
            await getAxleStore()
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async (values: any) => {
        if (values.name === null || values.name === '') return CustomSnackbar('warning', 'Ingrese un nombre')
        if (states.length === 0) return CustomSnackbar('warning', 'Ingrese el número de ejes del tipo de vehículo.');
        if (states.find(element => element.tires === 0)) return CustomSnackbar('warning', 'Ingrese correctamente la cantidad de neumáticos de cada eje')

        const mergedStates = {};
        states.forEach((axle) => {
            for (let i = 1; i <= axle.tires; i++) {
                const key = `${axle.axle}.${i}`;
                mergedStates[key] = axle.typeAxles;
            }
        });
        const payload = {
            name: values.name,
            description: values.description || '',
            axle_detail: mergedStates
        }
        const method = !isEdit ? createTypeVehicleStore(payload) : editTypeVehicleStore(data.id, payload);
        const response = await method;
        response === true && navigate({ pathname: RoutesMap.TYPE_VEHICLES })
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.TYPE_VEHICLES });
    }

    const onChangeAxle = (axle: number, value: string) => {
        const updated = states.map((item) => {
            if (item.axle === axle) {
                return { ...item, tires: parseInt(value) }
            }
            return item
        })
        setStates(updated)
    }

    const onChangeTypeAxle = (axle: number, value: any) => {
        const updated = states.map((item) => {
            if (item.axle === axle) {
                return { ...item, typeAxles: parseInt(value) }
            }
            return item
        })
        setStates(updated)
    }

    return (
        <CustomForm>
            {typeVehicleStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? "Nuevo tipo de vehículo" : "Editar tipo de vehículo"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo tipo de vehículo"
                        : "Ingrese los datos del tipo de vehículo"
                }
                goBack={RoutesMap.TYPE_VEHICLES}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange }) => {
                        return (<form onSubmit={handleSubmit}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Nombre:</FormLabel>
                                    <TextField
                                        id="name"
                                        type="text"
                                        name="name"
                                        fullWidth
                                        size="small"
                                        value={values.name}
                                        onChange={handleChange}
                                        error={
                                            errors.name && touched.name
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.name && touched.name
                                                ? errors.name
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Descripción:</FormLabel>
                                    <TextField
                                        id="description"
                                        type="text"
                                        name="description"
                                        fullWidth
                                        size="small"
                                        value={values.description}
                                        onChange={handleChange}
                                        error={
                                            errors.description && touched.description
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.description && touched.description
                                                ? errors.description
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Número de ejes:</FormLabel>
                                    <TextField
                                        id="axle_quantity"
                                        type="number"
                                        name="axle_quantity"
                                        fullWidth
                                        inputProps={{ min: 0 }}
                                        size="small"
                                        value={axleNumber}
                                        onChange={(e) => setAxleNumber(parseInt(e.currentTarget.value))}
                                    />
                                </Grid>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} sx={{ textAlign: "left", marginTop: "2%", marginBottom: "1%" }} md={6}>
                                        <Typography>Detalles de ejes:</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ background: "#D8E8F7" }}>
                                                <TableRow>
                                                    <StyledTableCell className="headTable" align="center">EJE</StyledTableCell>
                                                    <StyledTableCell className="headTable" align="center">CANTIDAD DE NEUMÁTICOS</StyledTableCell>
                                                    <StyledTableCell className="headTable" align="center">TIPO DE EJE</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {axleNumber > 0 ? states?.map((axle: axleType) => (
                                                    <TableRow key={axle.axle}>
                                                        <TableCell align="center">Eje {axle.axle}</TableCell>
                                                        <TableCell align="center">
                                                            <TextField
                                                                id={`axle${axle.axle}`}
                                                                type="number"
                                                                name={`axle${axle.axle}`}
                                                                inputProps={{ min: 0 }}
                                                                size="small"
                                                                value={states[axle.axle - 1].tires}
                                                                onChange={(e) => { onChangeAxle(axle.axle, e.target.value) }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Select
                                                                id={`typeAxle${axle.axle}`}
                                                                name={`typeAxle${axle.axle}`}
                                                                inputProps={{ min: 0 }}
                                                                value={axle.typeAxles}
                                                                onChange={(e) => onChangeTypeAxle(axle.axle, e.target.value)}
                                                                style={{ height: '52%' }}
                                                            >
                                                                {axles?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                                            </Select>
                                                        </TableCell>
                                                    </TableRow>)) : (<TableRow>
                                                        <TableCell
                                                            colSpan={5}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <div>{"Indique la cantidad de ejes del tipo de vehículo"}</div>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Divider />
                            <CustomFormFooter
                                buttonType="submit"
                                confirmText={
                                    !isEdit ? "Guardar" : "Actualizar"
                                }
                                cancelText={"Cancelar"}
                                onConfirm={handleSubmit}
                                onCancel={onCancel}
                            />
                        </form>)
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}
