import { useEffect, useState } from 'react';
import { Box, Divider, Typography, IconButton, Popover, Badge, Grid, CircularProgress, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNotificationStore } from '../../../hooks/useNotificationStore';
import { Notification } from '../../../types/slices/notificationType';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { RoutesMap } from '../../../types';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const themeAvenir = createTheme({
    typography: {
        fontFamily: 'Avenir',
        fontSize: 15
    },
})

const Notifications = () => {
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const { notifications, counter, changeSeenMassiveNotification, changeSeenNotification, getNotifications } = useNotificationStore()
    moment.locale('es')

    useEffect(() => {
        notificationsApi()
    }, []);

    const notificationsApi = async () => {
        try {
            setLoading(true);
            await getNotifications({ perPage: 5, page: 1 });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        pathname !== RoutesMap.NOTIFICATIONS && setOpen(event.currentTarget);
    }

    const handleClose = (option: Notification) => {
        setOpen(null)
    }

    const markAsReadMasive = async () => {
        const response = await changeSeenMassiveNotification();
        if (response) {
            getNotifications({ perPage: 5, page: 1 })
        } else {
            return false;
        }
    }

    const markAsRead = async (option: Notification) => {
        const id = option.id
        if (option.status_noti_name !== "Visto") {
            const response = await changeSeenNotification(id)
            if (response === false) {
                handleClose(option)
            } else {
                getNotifications({ perPage: 5, page: 1 })
                handleClose(option)
            }
        }
    }

    const allNotificationRedirect = () => {
        navigate({ pathname: RoutesMap.NOTIFICATIONS })
    }

    return (
        <>
            <IconButton
                color='inherit'
                onClick={handleOpen}
                disabled={pathname === RoutesMap.NOTIFICATIONS}
            >
                <Badge badgeContent={counter > 5 ? '5+' : counter} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <ThemeProvider theme={themeAvenir}>
                <Popover
                    open={open !== null ? true : false}
                    anchorEl={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        sx: {
                            width: 380,
                            borderRadius: '8px',
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 20,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                >
                    <Box sx={{ my: 1, px: 2 }}>
                        <Typography variant="subtitle2" noWrap fontWeight="bold" fontSize="1.1rem">
                            Notificaciones
                        </Typography>
                        <Grid sx={{ cursor: 'pointer' }} onClick={markAsReadMasive}>
                            <Typography variant='caption' fontSize=".8 rem" sx={{ cursor: 'pointer', fontWeight: '600 !important', color: '#121c4c' }}>Marcar todas como leídas</Typography>
                        </Grid>
                    </Box>
                    <div>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        {
                            loading ? (
                                <Grid sx={{ textAlign: 'center', paddingTop: '10px' }}>
                                    <CircularProgress size={28} color="inherit" />
                                </Grid>
                            ) : notifications.length > 0 ? (
                                notifications.map((option) => (
                                    <Link
                                        key={option.id}
                                        to={option.url}
                                        style={{ textDecoration: 'none' }}>
                                        <div
                                            onClick={() => markAsRead(option)}
                                            style={{
                                                cursor: 'pointer',
                                                background: option?.date_seen !== null ? '#fff' : '#efefef',
                                                padding: '5px',
                                                paddingLeft: '15px',
                                                border: '1px solid #ccc',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography variant='body2' sx={{ color: '#000 !important' }} fontWeight="bold" fontSize="1rem">{option.title}</Typography>
                                            <Typography variant='caption' fontSize=".9rem">{option.details}</Typography>
                                            <Typography variant='caption' sx={{ color: '#000 !important' }}>
                                                {moment(option.date_sent).startOf('minute').fromNow()}
                                            </Typography>

                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '15px'
                                }}>
                                    <Typography variant='body2' sx={{ color: '#000 !important' }} fontSize=".8 rem" >
                                        No hay notificaciones
                                    </Typography>
                                </div>)
                        }
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '5px'
                    }}>
                        <Grid sx={{ cursor: 'pointer' }} onClick={allNotificationRedirect}>
                            <Typography variant='caption' fontSize=".8 rem" sx={{ cursor: 'pointer', fontWeight: '600 !important', color: '#121c4c' }}>Ver todas las notificaciones</Typography>
                        </Grid>
                    </div>
                </Popover>
            </ThemeProvider>
        </>
    )
}

export default Notifications