import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Fragment, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../../hooks'
import { useDrawerStore } from '../../../hooks/useDrawerStore'
import { ListItemButtonStyle, ListItemIconStyle, ListMainItemButtonStyle } from '../../../styles/components/NavSection.styles'
import { LocalStorageKey } from '../../../types'
import { readLocalStorage, saveLocalStorage } from '../../../toolbox/helpers/local-storage-helpers'
import { icons, navSections } from '../../../toolbox/constants/NavSection'
import ScrollBar from '../Scrollbar/Scrollbar';
import { Role } from '../../../types/roles/roleTypes'

export const NavSection = () => {
    //Hooks
    const { user } = useAuthStore()
    const { openDrawer } = useDrawerStore()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const initialRuta = readLocalStorage(LocalStorageKey.RUTA)
    const [ruta, setRuta] = useState(initialRuta || '')

    //   const routes = user?.modules || []
    const userRole: Role | any = user.userType;
    // const routes = userRole === Role.SUPER_ADMIN ? navSections.Superadmin : navSections.Admin;
    let routes: any = []
    switch (userRole) {
        case Role.SUPER_ADMIN:
            routes = navSections.Superadmin
            break
        case Role.ADMIN:
            routes = navSections.Admin
            break
        // case Role.SUPERVISOR:
        //     routes = navSections.Supervisor
        //     break
        // case Role.MAINTENANCE:
        //     routes = navSections.JefeManenimiento
        //     break
        // case Role.MECHANIC:
        //     routes = navSections.Mecanico
        //     break
        // case Role.DRIVER:
        //     routes = navSections.Conductor
        //     break
        case Role.TECHNICAL:
            routes = navSections.Tecnico
            break

        case Role.CUSTOMER:
            routes = navSections.Cliente
            break;

        default:
            break
    }

    const isModule = (value: any): value is any => {
        return (value as any).name !== undefined
    }
    const handleOnClick = (value: any) => {
        if (isModule(value)) {
            if (ruta === value.name) {
                setRuta('')
            } else {
                saveLocalStorage(LocalStorageKey.RUTA, value.name)
                setRuta(value.name)
            }
        } else {
            openDrawer(false)
            //   navigate({ pathname: value.location })
        }
    }

    const handleItemSelected = (pathname: any) => {
        openDrawer(false)
        navigate({ pathname: pathname })
    }

    return (
        <ScrollBar sx={{ height: 1, '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' } }}>
            <List disablePadding sx={{ p: 1 }}>
                {
                    !!(routes.length > 0) && routes.map((route, index) => {
                        // if (route?.type_module == 1) { return }
                        const isItemSelected = ruta === route.name
                        const IconModule = route.icon
                        return (
                            <Fragment key={index}>
                                <ListItemButton
                                    sx={{
                                        fontSize: 14,
                                        ...(isItemSelected ? {
                                            color: 'rgb(18, 28, 76, 0.9)',
                                            textTransform: 'capitalize',
                                            bgColor: 'rgb(230, 247, 255)',
                                            fontWeight: 'fontWeightBold',
                                        } : null),
                                        ...ListMainItemButtonStyle,
                                    }}
                                    onClick={() => handleOnClick(route)}
                                >
                                    <ListItemIcon sx={ListItemIconStyle}>
                                        {IconModule != null ? IconModule : null}
                                    </ListItemIcon>
                                    <ListItemText disableTypography primary={route.name} />
                                    {
                                        isItemSelected ? (
                                            <KeyboardArrowUpIcon />
                                        ) : (
                                            <KeyboardArrowDownIcon />
                                        )
                                    }
                                </ListItemButton>
                                {(
                                    <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
                                        {route.rutas.map((view, index) => {
                                            // if (view?.type == 1) {return}
                                            const isSubRouteSelected = pathname === view.location
                                            const Icon = view.icon
                                            return (
                                                <ListItemButton
                                                    onClick={() => handleItemSelected(view.location)}
                                                    key={index}
                                                    sx={{
                                                        ...ListItemButtonStyle,
                                                        fontSize: 13,
                                                        ...(isSubRouteSelected ? {
                                                            color: 'rgb(241, 90, 41)',
                                                            bgcolor: 'rgb(230, 247, 255)',
                                                        } : null),
                                                    }}
                                                >
                                                    <ListItemIcon sx={ListItemIconStyle}>
                                                        {Icon != null ? Icon : null}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        disableTypography
                                                        primary={view.name_ruta}
                                                    />
                                                </ListItemButton>
                                            )
                                        })}
                                    </Collapse>
                                )}
                            </Fragment>
                        )
                    })
                }
            </List>
        </ScrollBar>
    )
}
