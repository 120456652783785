import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { ExternalMaintenance, ExternalMaintenanceType } from "../../types/slices/externalMaintenanceType";
import { RootState } from "../store";

const initialState: ExternalMaintenanceType = {
    status: ApiStatus.FETCHED,
    externalMaintenanceS: [],
    errorMessage: undefined,
    selectedExternalMaintenance: {} as ExternalMaintenance
}

const externalMaintenanceSlice = createSlice({
    name: 'externalMaintenance',
    initialState,
    reducers: {
        onFetchExternalMaintenance(state, { payload }: { payload: ExternalMaintenance[] }) {
            state.status = ApiStatus.FETCHED
            state.externalMaintenanceS = payload
            state.errorMessage = undefined
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedExternalMaintenance(state, { payload }: { payload: ExternalMaintenance }) {
            state.selectedExternalMaintenance = payload
        }
    }
})

export const selectExternalMaintenanceState = (state: RootState) => state.externalMaintenanceSlice
export default externalMaintenanceSlice.reducer

export const {
    onFetchExternalMaintenance,
    changeStatus,
    onSetSelectedExternalMaintenance
} = externalMaintenanceSlice.actions