import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { ModelMaintenance, ModelMaintenanceType } from "../../types/slices/modelMaintenanceType";

const initialState: ModelMaintenanceType = {
    status: ApiStatus.FETCHED,
    modelMaintenances: [],
    errorMessage: undefined,
    editModelMaintenance: {} as ModelMaintenance,
    selectedModelMaintenance: {} as ModelMaintenance,
};

const modelMaintenanceSlice = createSlice({
    name: "modelVehicle",
    initialState,
    reducers: {
        onFetchModelMaintenance(state, { payload }: { payload: ModelMaintenance[] }) {
            state.status = ApiStatus.FETCHED;
            state.modelMaintenances = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditModelMaintenance(state, { payload }: { payload: ModelMaintenance }) {
            state.editModelMaintenance = payload;
        },
        onSetSelectedModelMaintenance(state, { payload }: { payload: ModelMaintenance }) {
            state.selectedModelMaintenance = payload;
        },
        restartModelMaintenance(state) {
            state.modelMaintenances = []
        }
    }
});

export const selectModelMaintenanceState = (state: RootState) => state.modelMaintenanceSlice;
export default modelMaintenanceSlice.reducer;

export const {
    onFetchModelMaintenance,
    changeStatus,
    onSetEditModelMaintenance,
    onSetSelectedModelMaintenance,
    restartModelMaintenance
} = modelMaintenanceSlice.actions