import { createSlice } from "@reduxjs/toolkit";
import { StructureD1, StructureD1Type } from "../../types/slices/structureD1Type";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: StructureD1Type = {
    status: ApiStatus.FETCHED,
    structureD1: [],
    errorMessage: undefined,
    editStructureD1: {} as StructureD1,
    selectedStructureD1: {} as StructureD1,
}

const structureD1Slice = createSlice({
    name: "structureD1",
    initialState,
    reducers: {
        onFetchStructureD1(state, { payload }: { payload: StructureD1[] }) {
            state.status = ApiStatus.FETCHED;
            state.structureD1 = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditStructureD1(state, { payload }: { payload: StructureD1 }) {
            state.editStructureD1 = payload;
        },
        onSetSelectedStructureD1(state, { payload }: { payload: StructureD1 }) {
            state.selectedStructureD1 = payload;
        }
    },
});

export const selectStructureD1State = (state: RootState) => state.structureD1Slice;

export default structureD1Slice.reducer;

export const {
    onFetchStructureD1,
    changeStatus,
    onSetEditStructureD1,
    onSetSelectedStructureD1,
} = structureD1Slice.actions;