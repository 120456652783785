import { FC } from "react";
import { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { useASTStore } from "../../hooks/useAST";
import { AST } from "../../types/slices/ASTType";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";
import { useVerificationsStore } from "../../hooks/useVerifications";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useWorkOrderStore } from "../../hooks/useWorkOrderStore";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'request_correlative', label: 'CORRELATIVO SOLICITUD', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    {
        type: 'text', field: 'signature', label: 'FIRMA SUPERVISOR', align: 'center', format: (row) => {
            return row.status_supervisor === "1" ? "Firmado" : "No firmado"
        }
    },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsAdmin = [
    { type: 'text', field: 'request_correlative', label: 'CORRELATIVO SOLICITUD', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    {
        type: 'text', field: 'signature', label: 'FIRMA SUPERVISOR', align: 'center', format: (row) => {
            return row.status_supervisor === "1" ? "Firmado" : "No firmado"
        }
    },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const ASTList: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status, ASTs, getAST, deleteASTStore, setSelectedAST } = useASTStore()
    const { getWorkDones } = useWorkDoneStore()
    const { getVerificationsStore } = useVerificationsStore()
    const { getWorkOrders } = useWorkOrderStore()
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<AST>({} as AST)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [isSnackbarShown, setIsSnackbarShown] = useState<boolean>(false);
    const userRole: Role | any = user.userType

    useEffect(() => {
        setSelectedAST({} as AST)
        getASTApi()
    }, [search])

    useEffect(() => {
        if (!isSnackbarShown && userRole === Role.MECHANIC && (ASTs.find(ast => ast.signing_ast.length === 0) || !ASTs.find(ast => ast.signing_ast.find(sign => sign.iduser === user?.id)))) {
            CustomSnackbar('signed', "Tienes documentos AST que faltan firmar");
            setIsSnackbarShown(true)
        }
        if (!isSnackbarShown && userRole === Role.SUPERVISOR && ASTs.find(det => det.status_supervisor === "0")) {
            CustomSnackbar('signed', "Tienes documentos AST que faltan firmar");
            setIsSnackbarShown(true)
        }
    }, [ASTs, isSnackbarShown])

    // console.log(ASTs)

    const getASTApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            await getAST(payload)
            await getWorkDones()
            await getVerificationsStore()
            await getWorkOrders()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = async () => {
        setSelectedAST({} as AST)
        navigate({ pathname: RoutesMap.AST_FORM })
    }

    const onEdit = async (rowSelected) => {
        setSelectedAST(rowSelected)
        navigate({ pathname: RoutesMap.AST_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteASTStore(rowSelected.id)
        if (response === true) {
            setSelectedAST({} as AST)
            setShowModalConfirm(false)
            getASTApi()
        }
    }

    const onDetails = (rowSelected) => {
        navigate(`/ast-signature/${rowSelected.id}`)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <>
            {
                userRole === Role.MECHANIC && (
                    <CustomTable
                        title={'ANÁLISIS DE SEGURIDAD EN EL TRABAJO'}
                        columns={columns || []}
                        loading={loading}
                        rows={ASTs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDeleteAST={onDelete}
                        onEditAST={onEdit}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                (userRole === Role.SUPERVISOR || userRole === Role.MAINTENANCE) && (
                    <CustomTable
                        title={'ANÁLISIS DE SEGURIDAD EN EL TRABAJO'}
                        columns={columns || []}
                        loading={loading}
                        rows={ASTs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onDelete={onDelete}
                        onEdit={onEdit} //si el supervisor si pudiera después de firmado solo cambiar a onEdit                        
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole === Role.ADMIN && (
                    <CustomTable
                        title={'ANÁLISIS DE SEGURIDAD EN EL TRABAJO'}
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={ASTs || []}
                        onRowClick={() => { }}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        status2={status === ApiStatus.FETCHING ? true : false}
                    />
                )
            }
        </>)
}
