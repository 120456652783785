import React, { FC, useEffect, useState } from 'react';
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useBossStore } from '../../hooks/useBossStore';
import { BossMaintenance } from '../../types/slices/bossMaintenanceType';
import { RoutesMap } from '../../types';
import { Divider, FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import { CustomFormFooter } from '../../components/common/CustomForm/CustomFormFooter';
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomPhoneInput } from '../../components/common/CustomPhoneInput/CustomPhoneInput';
import { useContrataStore } from '../../hooks/useContrataStore';

type BossDataForm = {
    id: number;
    rut: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    idcontrata: number
};

type errorsForm = {
    name?: string;
    surname?: string;
    rut?: string;
    email?: string;
    phone?: string;
    status_turn?: string;
};

const BossMaintenancesForm: FC = () => {
    const navigate = useNavigate();
    const {
        status: bossStatus,
        selectedBoss,
        setSelectedBoss,
        createBossStore,
        editBossStore,
    } = useBossStore();
    const { contratas, getContratas } = useContrataStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<BossDataForm>({
        id: 0,
        rut: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        idcontrata: 0
    });

    useEffect(() => {
        getCombosBoxes()
        if (Object.entries(selectedBoss).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedBoss.id,
                rut: selectedBoss.rut,
                name: selectedBoss.name,
                surname: selectedBoss.surname,
                email: selectedBoss.email,
                phone: selectedBoss.phone,
                idcontrata: selectedBoss.idcontrata
            });
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            ...values,
        };
        const method = !isEdit
            ? createBossStore(payload)
            : editBossStore(data.id, payload);
        const response = await method;
        if (response === true) {
            navigate({ pathname: RoutesMap.BOSS_MAINTENANCE });
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.BOSS_MAINTENANCE });
    };

    const validateForm = (values: any) => {
        let errors: errorsForm = {};
        if (!values.name) errors.name = "Nombre requerido";
        if (!values.surname) errors.surname = "Apellido requerido";
        if (!values.rut) errors.rut = "RUT es requerido";
        if (!values.email) errors.email = "Email es requerido";
        if (!values.phone) errors.phone = "Celular es requerido";
        return errors;
    };

    const getCombosBoxes = async () => {
        setLoading(true)
        await getContratas()
        setLoading(false)
    }

    return (
        <CustomForm>
            {loading  && <CustomBackdrop open={true} />}
            {bossStatus === ApiStatus.FETCHING && (
                <CustomBackdrop open={true} />
            )}
            <CustomFormHeader
                title={!isEdit ? "Nuevo Jefe de Mantenimiento" : "Editar Jefe de Mantenimiento"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo mecánico"
                        : "Ingrese los datos del mecánico"
                }
                goBack={RoutesMap.BOSS_MAINTENANCE}
            ></CustomFormHeader>

            <CustomFormBody>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validate={(values) => validateForm(values)}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                    }) => {
                        return (
                            <form>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text"
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={
                                                errors.name && touched.name
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.name && touched.name
                                                    ? errors.name
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Apellido:</FormLabel>
                                        <TextField
                                            id="surname"
                                            type="text"
                                            name="surname"
                                            fullWidth
                                            size="small"
                                            value={values.surname}
                                            onChange={handleChange}
                                            error={
                                                errors.surname &&
                                                    touched.surname
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.surname &&
                                                    touched.surname
                                                    ? errors.surname
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>RUT:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text"
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={
                                                errors.rut && touched.rut
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.rut && touched.rut
                                                    ? errors.rut
                                                    : ""
                                            }
                                            InputProps={{
                                                inputProps: { maxLength: 10 },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={2}
                                    sx={{ mt: 2, mb: 3 }}
                                >
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Email:</FormLabel>
                                        <TextField
                                            id="email"
                                            type="email"
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={
                                                errors.email && touched.email
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.email && touched.email
                                                    ? errors.email
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Celular:</FormLabel>
                                        <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Contrata:</FormLabel>
                                        <Select
                                            id="idcontrata"
                                            name="idcontrata"
                                            fullWidth
                                            value={values.idcontrata}
                                            onChange={handleChange}
                                            error={
                                                errors.idcontrata &&
                                                    touched.idcontrata
                                                    ? true
                                                    : false
                                            }
                                            style={{ height: '52%' }}
                                        >
                                            {contratas?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={
                                        !isEdit ? "Guardar" : "Actualizar"
                                    }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default BossMaintenancesForm;