import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Formik } from "formik"
import { useState } from "react"
import LockIcon from '@mui/icons-material/Lock'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useASTStore } from "../../hooks/useAST"
import { useNavigate } from "react-router-dom"


export const ASTSignatureModal: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, idAst } = props

    const navigate = useNavigate()
    const { signatureAST } = useASTStore()

    const [data, setData] = useState({
        pin: ''
    })
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleClickShowPassword = () => {
        if (showPassword) setShowPassword(false)
        if (!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const validatePinForm = (values) => {
        let errors: any = {};
        if (!values.pin) errors.pin = "PIN requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        setLoading(true)
        const response = await signatureAST(idAst, { pin: values.pin })
        if (response === true) {
            closeModal()
            navigate(`/ast-signature/${idAst}`)
        }
        setLoading(false)
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder' }}>
                    INGRESE SU PIN
                </Typography>
                <Formik initialValues={data} enableReinitialize validate={(values) => validatePinForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (<>
                            <Grid container sx={{ margin: '15px 0px' }}>
                                <Grid item xs={8} sx={{ margin: '0px auto' }}>
                                    <TextField
                                        id="pin"
                                        name="pin"
                                        variant="standard"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.pin}
                                        onChange={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                setFieldValue('pin', e.target.value)
                                            }
                                        }}
                                        size='small'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'  >
                                                    <LockIcon sx={{ color: '#29333B' }} />
                                                </InputAdornment>),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        sx={{ color: '#29333B' }}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                maxLength: 6
                                            }
                                        }}
                                        error={errors.pin && touched.pin ? true : false}
                                        helperText={errors.pin && touched.pin ? errors.pin : ''}
                                    />
                                </Grid>
                                <Grid container sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            onClick={() => handleSubmit()}
                                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                                        >
                                            {
                                                loading
                                                    ?
                                                    <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                                    :
                                                    'Confirmar'
                                            }
                                        </Button>
                                        <Button
                                            disabled={loading}
                                            onClick={() => closeModal()}
                                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>)
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}