import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { WorkOrder, WorkOrderType } from "../../types/slices/workOrderType";

const initialState:WorkOrderType = {
    status: ApiStatus.FETCHED,
    workOrders: [],
    errorMessage: undefined,
    editWorkOrder: {} as WorkOrder
}

const WorkOrderSlice = createSlice({
    name: 'WorkOrder',
    initialState,
    reducers: {
        onFetchWorkOrders (state, { payload }: { payload: WorkOrder[] }) {
            state.status            = ApiStatus.FETCHED
            state.workOrders        = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditWorkOrder (state, { payload }: { payload: WorkOrder }) {
            state.editWorkOrder = payload
        },
        onSetIdRequestWorkOrder (state, { payload }: { payload: number }) {
            state.editWorkOrder.idrepair_request = payload
        }
    }
})

export const selectWorkOrderState = (state: RootState) => state.WorkOrderSlice
export default WorkOrderSlice.reducer

export const {
    onFetchWorkOrders,
    changeStatus,
    onSetEditWorkOrder,
    onSetIdRequestWorkOrder
} = WorkOrderSlice.actions