import { api } from "./configs/axiosConfigs"

export const InformMechanicalAPI = {
    getInformsMechanical: async (data?:any) => {
        const response = await api.get(
            '/informMechanical',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/informMechanical/register', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    update: async (id: number, data: any) => {
        const response = await api.patch(
            `/informMechanical/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
            `/informMechanical/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    }
}