import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Divider, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useControlCriticalRiskStore } from "../../hooks/useControlCriticalRisk";
import { useCriticalRiskStore } from "../../hooks/useCriticalRisk";

type FormData = {
    name: string,
    idcritical_risk: number
}

const ControlCriticalRiskForm: FC = () => {
    const navigate = useNavigate()
    const { status, selectedControlCriticalRisk, createControlCriticalRiskStore, editControlCriticalRiskStore } = useControlCriticalRiskStore()
    const { criticalRisks, getCriticalRisk } = useCriticalRiskStore()


    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<FormData>({
        name: '',
        idcritical_risk: 0,
    });

    useEffect(() => {
        getControlCriticalRiskApi()
        if (Object.entries(selectedControlCriticalRisk).length > 0) {
            setIsEdit(true)
            setData({
                name: selectedControlCriticalRisk.name,
                idcritical_risk: selectedControlCriticalRisk.idcritical_risk,
            })
        }
    }, []);

    const getControlCriticalRiskApi = async () => {
        try {
            await getCriticalRisk()
        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.name) errors.name = "El nombre es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const payload = {
            name: values.name,
            idcritical_risk: values.idcritical_risk
        }
        const method = !isEdit ? createControlCriticalRiskStore(payload) : editControlCriticalRiskStore(selectedControlCriticalRisk.id, payload)
        const response = await method
        if (response === true) {
            navigate({ pathname: RoutesMap.CONTROL_CRITICAL_RISK })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.CONTROL_CRITICAL_RISK })
    }
    return (
        <CustomForm>
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo control de riesgo crítico' : 'Editar control riesgo crítico'}
                text={!isEdit ? 'Ingrese los datos del nuevo control de riesgo crítico' : 'Ingrese los datos del control de riesgo crítico'}
                goBack={RoutesMap.CONTROL_CRITICAL_RISK}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text"
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name && touched.name ? true : false}
                                                helperText={errors.name && touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Riesgo crítico asociado:</FormLabel>
                                            <Select
                                                id="idcritical_risk"
                                                name="idcritical_risk"
                                                fullWidth
                                                value={values.idcritical_risk}
                                                onChange={(e) => {
                                                    setFieldValue('idcritical_risk', e.target.value)
                                                }}
                                                error={
                                                    errors.idcritical_risk &&
                                                        touched.idcritical_risk
                                                        ? true
                                                        : false
                                                }
                                                style={{ height: '52%' }}
                                            >
                                                {criticalRisks?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default ControlCriticalRiskForm