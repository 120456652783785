export enum RoutesMap {
    HOME = "/home",
    LOGIN = "/login",
    MAIN = "/main",
    MY_PROFILE = "/my-profile",
    NOTIFICATIONS = "/notifications",
    AUTOLOGIN = "/autologin",
    PUBLIC_REPAIR_REQUEST = "solicitudes",

    /* ------------------------- VIEWS SUPERADMIN ------------------------------- */
    ADMINISTRATOR = "/administrator",
    ADMINISTRATOR_FORM = "/administrator-form",
    COMPANY = "/company",
    COMPANY_FORM = "/company-form",
    CHANGE_PASSWORD = "/change-password",

    /* ------------------------- VIEWS ADMIN ------------------------------- */
    CLIENT = "/client",
    CLIENT_FORM = '/client-form',
    TECHNICAL = "/technical",
    TECHNICAL_FORM = "/technical-form",
    TYPE_ATTENTION = "/type-attention",
    TYPE_ATTENTION_FORM = "/type-attention-form",
    BOSS_MAINTENANCE = "/boss-maintenance",
    BOSS_MAINTENANCE_FORM = "/boss-maintenance-form",
    DRIVER = "/driver",
    DRIVER_FORM = "/driver-form",
    MECHANICS = "/mechanics",
    MECHANICS_FORM = "/mechanics-form",
    SUPERVISORS = "/supervisors",
    SUPERVISORS_FORM = "/supervisors-form",
    STRUCTURE_D1 = "/structure-d1",
    STRUCTURE_D1_FORM = "/structure-d1-form",
    STRUCTURE_D2 = "/structure-d2",
    STRUCTURE_D2_FORM = "/structure-d2-form",
    VEHICLES = "/vehicles",
    VEHICLES_FORM = "/vehicles-form",
    TYPE_VEHICLES = "/type-vehicles",
    TYPE_VEHICLES_FORM = "/type-vehicles-form",
    MODEL_VEHICLES = "/model-vehicles",
    MODEL_VEHICLES_FORM = "/model-vehicles-form",
    TRADEMARK_VEHICLES = "/trademark-vehicles",
    TRADEMARK_VEHICLES_FORM = "/trademark-vehicles-form",
    REPORT_PAUTA_R = "/report-pauta-R",
    REPORT_PAUTA_T = "/report-pauta-T",
    REPORT_MANTENCIONES_EXTERNAS = "/report-mantenciones",
    REPORT_REPAIR_REQUEST = "/report-repair-request",
    REPORT_WORK_ORDER = "/report-work-order",
    REPORT_DET = "/report-det",
    REPORT_AST = "/report-ast",

    /* ------------------------- VIEWS BOSS MAINTENANCE ------------------------------- */
    WORK_ORDER_LIST = '/work-order-list',
    WORK_ORDER_FORM = '/work-order-form',
    WORK_ORDER_SIGNATURE = '/work-order-signature/:id',
    PT_R_LIST = '/pt-r-list',
    PT_R_FORM = '/pt-r-form',
    PT_R_SIGNATURE = '/pt-r-signature/:id',
    PATTERN_T_LIST = '/pt-t-list',
    PATTERN_T_FORM = '/pt-t-form',
    PATTERN_T_SIGNATURE = '/pt-t-signature/:id',
    EXTERNAL_MAINTENANCE = '/external-maintenance',
    EXTERNAL_MAINTENANCE_FORM = '/external-maintenance-form',
    AST_LIST = '/ast-list',
    AST_FORM = '/ast-form',
    AST_SIGNATURE = '/ast-signature/:id',
    DET_LIST = '/det-list',
    DET_FORM = '/det-form',
    DET_SIGNATURE = '/det-signature/:id',
    FAIL_LIST = '/fail-list',
    FAIL_FORM = '/fail-form',
    INFORM_WORK_ORDER_LIST = '/inform-work-order-list',
    INFORM_WORK_ORDER_FORM = '/inform-work-order-form',
    WORK_DONE_LIST = '/work-done-list',
    WORK_DONE_FORM = '/work-done-form',
    REPLACEMENT_PART_LIST = '/replacement-part-list',
    REPLACEMENT_PART_FORM = '/replacement-part-form',
    CONTROL_VERIFICATION_LIST = '/control-verification-list',
    CONTROL_VERIFICATION_FORM = '/control-verification-form',
    SETTING_IMPORT_ERP = '/setting-import-erp',
    CUSTOMER_IMPORT_ERP = 'customer-import-erp',
    EMPLOYEE_INCOMPLETE_LIST = '/employee-incomplete-list',
    CUSTOMER_INCOMPLETE_LIST = '/customer-incomplete-list',

    /* ------------------------- VIEWS DRIVER ------------------------------- */
    REPAIR_REQUEST = '/repair-request',
    REPAIR_REQUEST_FORM = '/repair-request-form',
    REPAIR_REQUEST_PDF = '/repair-request-pdf/:id',

    /* ------------------------- MAINTENANCES CONFIG ------------------------------- */

    MAINTENANCE_MODEL_FORM = '/maintenance-model-form',
    MAINTENANCE_MODEL_INPUT = '/maintenance-model-input',

    MAINTENANCE_TRADEMARK_FORM = '/maintenance-trademark-form',
    MAINTENANCE_TRADEMARK_INPUT = '/maintenance-trademark-input',


    /*-------------------------STRUCTURES AST ----------------------------------------*/
    CONTROL_BARRIER = '/control-barrier',
    CONTROL_BARRIER_FORM = '/control-barrier-form',
    MAIN_STAGES_WORKS = '/main-stages-work',
    MAIN_STAGES_WORKS_FORM = '/main-stages-work-form',
    UNWANTED_EVENT = '/unwanted-event',
    UNWANTED_EVENT_FORM = '/unwanted-event-form',
    ENERGIES = '/energies',
    ENERGIES_FORM = '/energies-form',
    CRITICAL_RISK = '/critical-risk',
    CRITICAL_RISK_FORM = '/critical-risk-form',
    CONTROL_CRITICAL_RISK = '/control-critical-risk',
    CONTROL_CRITICAL_RISK_FORM = '/control-critical-risk-form',

    TECHNICAL_REPORT = '/technical-report',
    TECHNICAL_REPORT_FORM = '/technical-report-form',
    TECHNICAL_REPORT_SIGNATURE = '/technical-report-signature/:id',
}
