import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { EmployeeIncomplete, EmployeeIncompleteType } from "../../types/slices/employeeIncompleteType";
import { RootState } from "../store";

const initialState:EmployeeIncompleteType = {
    status: ApiStatus.FETCHED,
    employeesIncompletes: [],
    errorMessage: undefined,
    editEmployeeIncomplete: {} as EmployeeIncomplete
}

const EmployeeIncompleteSlice = createSlice({
    name: 'EmployeeIncomplete',
    initialState,
    reducers: {
        onFetchEmployeesIncomplete (state, { payload }: { payload: EmployeeIncomplete[] }) {
            state.status               = ApiStatus.FETCHED
            state.employeesIncompletes = payload
            state.errorMessage         = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditEmployeeIncomplete (state, { payload }: { payload: EmployeeIncomplete }) {
            state.editEmployeeIncomplete = payload
        }
    }
})

export const selectEmployeeIncompleteState = (state: RootState) => state.EmployeeIncompleteSlice
export default EmployeeIncompleteSlice.reducer

export const {
    onFetchEmployeesIncomplete,
    changeStatus,
    onSetEditEmployeeIncomplete
} = EmployeeIncompleteSlice.actions