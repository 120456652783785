import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchCompanies, onSetSelectedCompany, selectCompanyState } from "../redux/slices/companySlice"
import { ApiStatus } from "../types/api/status"
import { CompanyAPI } from "../apis/CompanyAPI"
import { toast } from "react-hot-toast"
import { Company } from "../types/slices/companyType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"

export const useCompanyStore = () => {

    const dispatch = useDispatch()
    const { companies, status, selectedCompany } = useSelector(selectCompanyState)

    const getCompanies = async (data?:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.getCompanies(data)
            if (!response?.status) {
                toast.error(response.data.message)
                throw new Error(`${response.data.message}`)
            }
            const { detail } = response.data
            dispatch(onFetchCompanies(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.error(error)
        }
    }

    const createCompany = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.createCompany(data)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message  || "")
            }
            CustomSnackbar('success', response.data.message  || "")
            return true
        } catch (error) {
            console.log(error)
        }
    }

    const editCompany = async (idcompany: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.editCompany(idcompany, data)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message  || "")
            }
            CustomSnackbar('success', response.data.message  || "")
            return true
        } catch (error) {
            console.log(error)
        }
    }

    const deleteCompany = async (idcompany: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.deleteCompany(idcompany)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message  || "")
            }
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
            console.log(error)
        }
    }

    const setSelectedCompany = (company: Company) => {
        try {
            dispatch(onSetSelectedCompany(company))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        companies,
        status,
        selectedCompany,
        //actions
        getCompanies,
        createCompany,
        editCompany,
        deleteCompany,
        setSelectedCompany
    }
}