import { api } from "../configs/axiosConfigs";

export const CriticalRiskAPI = {
    getCriticalRisk: async (data?: any) => {
        const response = await api
            .get("/criticalRisk", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createCriticalRisk: async (data: any) => {
        const response = await api
            .post("/criticalRisk/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editCriticalRisk: async (id: number, data: any) => {
        const response = await api
            .patch(`/criticalRisk/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteCriticalRisk: async (id: number) => {
        const response = await api
            .delete(`/criticalRisk/${id}`, {
                params: {
                    idcompany: 1
                }
            })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}