import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import CloseIcon from '@mui/icons-material/Close'
import { withStyles } from "@mui/styles";


const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)


const rows = [
    { id: 1, description: "Perno de fijación del eje del balancín", range1: "21", range2: "152" },
    { id: 2, description: "Perno pasador con buje de goma cónica", range1: "30-36", range2: "217-260" },
    { id: 3, description: "Perno de fijación de los tensores de la suspensión", range1: "21-25", range2: "152-181" },
    { id: 4, description: "Perno de fijación de los brazaletes de los tensores de la suspensión", range1: "15-20", range2: "109-145" },
    { id: 5, description: "Tuerca de las abrazaderas de fijación paquete de resortes de suspensión ", range1: "40-50", range2: "290-362" },
    { id: 6, description: "Tuerca de fijación del conjunto maza/tambor de freno", range1: "40", range2: "290" },
    { id: 7, description: "Tuerca de rueda (disco)", range1: "65-70", range2: "471-507" },
    { id: 8, description: "Tuerca de rueda (maza artillera)", range1: "30-40", range2: "216-290" },
    { id: 9, description: "Pernos de fijación perno rey ", range1: "18-20", range2: "131-145" }
]

export const PatternTInforModal: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal } = props

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="lg">
            <ModalBody>
                <Grid item xs={12} sx={{ textAlign: "right" }} md={6}>
                    <Tooltip title="Cerrar">
                        <IconButton onClick={() => closeModal()}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ background: "#D8E8F7" }}>
                            <TableRow>
                                <StyledTableCell className="headTable" align="center">TORQUES RECOMENDADOS</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">RANGO</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">KILOGRAMO FUERZA METRO</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">RANGO</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">PIE LIBRA FUERZA</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row =>
                                <TableRow>
                                    <TableCell align="left">{row.description}</TableCell>
                                    <TableCell align="center">{row.range1}</TableCell>
                                    <TableCell align="center">Kgf/m</TableCell>
                                    <TableCell align="center">{row.range2}</TableCell>
                                    <TableCell align="center">Ft Lb</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ModalBody>
        </Modal>
    )
}