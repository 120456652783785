import { api } from "../configs/axiosConfigs";

export const ControlCriticalRiskAPI = {
    getControlCriticalRisk: async (data?: any) => {
        const response = await api
            .get("/controlCriticalRisk", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createControlCriticalRisk: async (data: any) => {
        const response = await api
            .post("/controlCriticalRisk/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editControlCriticalRisk: async (id: number, data: any) => {
        const response = await api
            .patch(`/controlCriticalRisk/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteControlCriticalRisk: async (id: number) => {
        const response = await api
            .delete(`/controlCriticalRisk/${id}`, {
                params: {
                    idcompany: 1
                }
            })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}