import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { Company } from "../../types/slices/companyType";
import { useCompanyStore } from "../../hooks/useCompanyStore";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ApiStatus } from "../../types/api/status";
import { useAdministratorStore } from "../../hooks/useAdministratorStore";
import { Administrator } from "../../types/slices/administratorType";
import { CustomPhoneInput } from "../../components/common/CustomPhoneInput/CustomPhoneInput";

type AdminDataForm = {
    id: number
    name: string
    surname: string
    rut: string
    email: string
    phone: string
    idcompany: number
}

export const AdministratorForm: FC = () => {

    const navigate = useNavigate()
    const { status: statusCompany, companies, getCompanies } = useCompanyStore()
    const { status: statusAdmin, selectedAdministrator, setSelectedAdmin, createAdmin, editAdmin } = useAdministratorStore()

    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<AdminDataForm>({
        id: 0,
        name: '',
        surname: '',
        rut: '',
        email: '',
        phone: '',
        idcompany: 0
    });

    useEffect(() => {
        getCompanies()
        if (Object.entries(selectedAdministrator).length > 0) {
            setIsEdit(true)
            setData({
                id: selectedAdministrator.id,
                rut: selectedAdministrator.rut,
                name: selectedAdministrator.name,
                surname: selectedAdministrator.surname,
                email: selectedAdministrator.email,
                phone: selectedAdministrator.phone,
                idcompany: selectedAdministrator.idcompany
            })
            const company = companies.find(company => company.id === selectedAdministrator.idcompany)
            setSelectedCompany(company || {} as Company)
            setSelectedAdmin({} as Administrator)
        }
    }, [])

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.name) errors.name = "Nombre es requerido";
        if (!values.surname) errors.name = "Nombre es requerido";
        if (!values.rut) errors.rut = "RUT es requerido";
        if (!values.email) errors.email = "Email es requerido";
        if (!values.phone) errors.phone = "Celular es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const payload = {
            ...values,
            idcompany: selectedCompany?.id
        }
        const method = !isEdit ? createAdmin(payload) : editAdmin(data.id, payload)
        const response = await method
        if (response === true) {
            navigate({ pathname: RoutesMap.ADMINISTRATOR })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.ADMINISTRATOR })
    }

    return (
        <CustomForm >
            {statusAdmin === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {statusCompany === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo Administrador' : 'Editar Administrador'}
                text={!isEdit ? 'Ingrese los datos del nuevo administrador' : 'Ingrese los datos del administrador'}
                goBack={RoutesMap.ADMINISTRATOR}
            >
            </CustomFormHeader>

            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombres:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text"
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name && touched.name ? true : false}
                                                helperText={errors.name && touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Apellidos:</FormLabel>
                                            <TextField
                                                id="surname"
                                                type="text"
                                                name="surname"
                                                fullWidth
                                                size="small"
                                                value={values.surname}
                                                onChange={handleChange}
                                                error={errors.surname && touched.surname ? true : false}
                                                helperText={errors.surname && touched.surname ? errors.surname : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>RUT:</FormLabel>
                                            <TextField
                                                id="rut"
                                                type="text"
                                                name="rut"
                                                fullWidth
                                                size="small"
                                                value={values.rut}
                                                onChange={handleChange}
                                                error={errors.rut && touched.rut ? true : false}
                                                helperText={errors.rut && touched.rut ? errors.rut : ''}
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: 10
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Email:</FormLabel>
                                            <TextField
                                                id="email"
                                                type="email"
                                                name="email"
                                                fullWidth
                                                size="small"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={errors.email && touched.email ? true : false}
                                                helperText={errors.email && touched.email ? errors.email : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Celular:</FormLabel>
                                            <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Compañia:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-company"
                                                value={selectedCompany}
                                                options={companies || []}
                                                getOptionLabel={(option) => option.business_name || ""}
                                                isOptionEqualToValue={(option, value) => option?.business_name === value?.business_name}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedCompany(newValue);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}