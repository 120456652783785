import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { CustomerIncomplete, CustomerIncompleteType } from "../../types/slices/customerIncompleteType";

const initialState: CustomerIncompleteType = {
    status: ApiStatus.FETCHED,
    customersIncomplete: [],
    errorMessage: undefined,
    editCustomerIncomplete: {} as CustomerIncomplete,
};

const customerIncompleteSlice = createSlice({
    name: "customerIncomplete",
    initialState,
    reducers: {
        onFetchCustomerIncomplete(state, { payload }: { payload: CustomerIncomplete[] }) {
            state.status = ApiStatus.FETCHED;
            state.customersIncomplete = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditCustomerIncomplete(state, { payload }: { payload: CustomerIncomplete }) {
            state.editCustomerIncomplete = payload;
        }
    },
});

export const selectCustomerIncompleteState = (state: RootState) => state.customerIncompleteSlice;
export default customerIncompleteSlice.reducer;

export const {
    onFetchCustomerIncomplete,
    changeStatus,
    onSetEditCustomerIncomplete,
} = customerIncompleteSlice.actions;
