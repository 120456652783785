import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Checkbox, Divider, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useEnergieStore } from "../../hooks/useEnergie";
import { useMainStagesWorksStore } from "../../hooks/useMainStagesWorks";
import { MainStageWork } from "../../types/slices/mainStagesWorksType";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

type FormData = {
    name: string;
}

const EnegiesForm: FC = () => {
    const navigate = useNavigate()
    const { status, selectedEnergie, createEnergieStore, editEnergieStore } = useEnergieStore()
    const { mainStagesWorks, getMainStagesWorks } = useMainStagesWorksStore()


    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<FormData>({
        name: '',
    });
    const [selectedMainStages, setSelectedMainStages] = useState<MainStageWork[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getMainStageWorksApi()
        if (Object.entries(selectedEnergie).length > 0) {
            setIsEdit(true)
            setData({
                name: selectedEnergie.name,
            })
        }
    }, []);

    useEffect(() => {
        if (Object.entries(selectedEnergie).length > 0 && mainStagesWorks.length > 0) {
            const mains = mainStagesWorks.filter(main => selectedEnergie.idsmain_stages_work.includes(main.id))
            setSelectedMainStages(mains || [])
        }
    },[mainStagesWorks])

    const getMainStageWorksApi = async () => {
        try {
            setLoading(true)
            await getMainStagesWorks()
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.name) errors.name = "El nombre es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const a_idmain_stages_work = selectedMainStages.map(main => main.id)
        const payload = {
            name: values.name,
            a_idmain_stages_work: a_idmain_stages_work
        }
        const method = !isEdit ? createEnergieStore(payload) : editEnergieStore(selectedEnergie.id, payload)
        const response = await method
        if (response === true) {
            navigate({ pathname: RoutesMap.ENERGIES })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.ENERGIES })
    }
    return (
        <CustomForm>
            {loading && <CustomBackdrop open={true} />}
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nueva energía' : 'Editar energía'}
                text={!isEdit ? 'Ingrese los datos de la nueva energía' : 'Ingrese los datos de la energía'}
                goBack={RoutesMap.ENERGIES}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text"
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name && touched.name ? true : false}
                                                helperText={errors.name && touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Etapa principal de trabajo asociada:</FormLabel>
                                            <Autocomplete
                                                multiple
                                                disablePortal
                                                id="combo-box-work-dones"
                                                value={selectedMainStages || []}
                                                options={mainStagesWorks || []}
                                                getOptionLabel={(option) => option?.actions || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedMainStages(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.actions}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default EnegiesForm