import { useDispatch, useSelector } from "react-redux";
import { EnergieAPI } from "../apis/AST/EnergieAPI";
import { selectEnergieState, changeStatus, onFetchEnergie, onSetSelectedEnergie } from "../redux/slices/energieSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Energie } from "../types/slices/energieType";

export const useEnergieStore = () => {
    const dispatch = useDispatch();
    const { status, energies, selectedEnergie } = useSelector(selectEnergieState);


    const getEnergie = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await EnergieAPI.getEnergie(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchEnergie(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createEnergieStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await EnergieAPI.createEnergie(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editEnergieStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await EnergieAPI.editEnergie(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteEnergieStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await EnergieAPI.deleteEnergie(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedEnergie = (controlEnergie: Energie) => {
        try {
            dispatch(onSetSelectedEnergie(controlEnergie));
        } catch (error) {
            console.log(error);
        }
    };


    return {
        //states
        status, energies, selectedEnergie,
        //actions
        getEnergie,
        createEnergieStore,
        editEnergieStore,
        deleteEnergieStore,
        setSelectedEnergie,
    };
};