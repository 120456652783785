import { api } from "./configs/axiosConfigs";

export const TrademarkMaintenanceAPI = {
    getTrademarkMaintenance: async (id: number, data?: any) => {
        const response = await api
            .get(`/trademarkMaintenance?idtrademark=${id}`, { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    createTrademarkMaintenance: async (data: any) => {
        const response = await api
            .post('/trademarkMaintenance/register', { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    editTrademarkMaintenance: async (id: number, data: any) => {
        const response = await api
            .patch(`/trademarkMaintenance/${id}`, { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    deleteTrademarkMaintenance: async (id: number) => {
        const response = await api
            .delete(`/trademarkMaintenance/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    }
};