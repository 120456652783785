import { useDispatch, useSelector } from "react-redux";
import { ModelMaintenanceAPI } from "../apis/ModelMaintenanceAPI";
import { selectModelMaintenanceState, changeStatus, onFetchModelMaintenance, onSetEditModelMaintenance, onSetSelectedModelMaintenance, restartModelMaintenance } from "../redux/slices/modelMaintenanceSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { ModelMaintenance } from "../types/slices/modelMaintenanceType";

export const useModelMaintenance = () => {
    const dispatch = useDispatch();
    const {
        modelMaintenances,
        editModelMaintenance,
        selectedModelMaintenance,
        status: modelMaintenanceStatus,
    } = useSelector(selectModelMaintenanceState);

    const getModelMaintenanceStore = async (id: number, data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelMaintenanceAPI.getModelMaintenance(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchModelMaintenance(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createModelMaintenanceStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelMaintenanceAPI.createModelMaintenance(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editModelMaintenanceStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelMaintenanceAPI.editModelMaintenance(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteModelMaintenanceStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelMaintenanceAPI.deleteModelMaintenance(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedMaintenanceStore = (model: ModelMaintenance) => {
        try {
            dispatch(onSetSelectedModelMaintenance(model));
        } catch (error) {
            console.log(error);
        }
    }

    const setEditMaintenanceStore = (model: ModelMaintenance) => {
        try {
            dispatch(onSetEditModelMaintenance(model));
        } catch (error) {
            console.log(error);
        }
    }

    const deleteStateModelMaintenance = () => {
        try {
            dispatch(restartModelMaintenance());
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        modelMaintenances,
        modelMaintenanceStatus,
        editModelMaintenance,
        selectedModelMaintenance,
        //actions
        getModelMaintenanceStore,
        createModelMaintenanceStore,
        editModelMaintenanceStore,
        deleteModelMaintenanceStore,
        setSelectedMaintenanceStore,
        setEditMaintenanceStore,
        deleteStateModelMaintenance
    }

}