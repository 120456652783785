import { api } from "./configs/axiosConfigs";

export const ControlCategoryAPI = {
    getControlCategory: async (data?: any) => {
        const response = await api
            .get("/controlVerification/category", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },
}