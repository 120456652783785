import { createSlice } from "@reduxjs/toolkit";
import { ArrayDates, CalendarType } from "../../types/slices/calendarType";
import { ApiStatus } from "../../types/api/status";

const initialState: CalendarType = {
    status: ApiStatus.FETCHED,
    arrayDates: [
        {
            startDate: new Date(),
            endDate: new Date(),
            state: 'default',
            color: 'red'
        },
    ],
    pattern: undefined,
    mechanic: undefined,
    isActionBlocked: false,
}

export const calendarSlice = createSlice({
    name: 'calendarSlice',
    initialState,
    reducers: {
        updateCalendar: (state, { payload }: { payload }) => {
            state.arrayDates = state.arrayDates.filter(item => item.state !== 'default')
            payload.length > 0 && payload.map((item) => {
                const filterDate = {
                    ...item,
                    startDate: new Date(item.startDate),
                    endDate: new Date(item.endDate),
                }
                state.arrayDates.push(filterDate);
            })
        },
        addRange: (state, { payload }: { payload: ArrayDates }) => {
            const filteredDefault = (state.arrayDates).filter((item) => item.state !== 'default');
            const existCurrent = (filteredDefault).find((item) => item.state == 'current');
            if (existCurrent) {
                (state.arrayDates).map(function (dato) {
                    if (dato.state == 'current') {
                        dato.startDate = new Date(payload.startDate);
                        dato.endDate = new Date(payload.endDate);
                    }
                });
            } else {
                state.arrayDates = [
                    ...filteredDefault,
                    {
                        ...payload,
                        startDate: new Date(payload.startDate),
                        endDate: new Date(payload.endDate)
                    }
                ];
            }
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        changePeopleInvolved: (state, { payload }: { payload }) => {
            state.status = ApiStatus.FETCHED
            state.pattern = payload.pattern;
            state.mechanic = payload.mechanic;
        },
        reset: (state) => {
            state.arrayDates = initialState.arrayDates;
            state.pattern = initialState.pattern;
            state.mechanic = initialState.mechanic;
        },
        changeIsActionBlocked(state, { payload }: { payload: boolean }) {
            state.isActionBlocked = payload;
        }
    }
})

export const selectRange = (state: any) => state.calendarSlice;
export default calendarSlice.reducer;
export const {
    updateCalendar,
    addRange,
    changeStatus,
    changePeopleInvolved,
    reset,
    changeIsActionBlocked
} = calendarSlice.actions;