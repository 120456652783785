import { useEmployeeIncompleteStore } from "../../hooks/useEmployeeIncompleteStore"
import { Typography, Grid, Button, CircularProgress, FormLabel, TextField } from "@mui/material"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { ApiStatus } from "../../types/api/status"
import { useState } from "react"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"

export const EmployeeIncompleteModalEdit:React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, onList, rowSelected } = props
    
    const { status, updateEmployeeIncomplete } = useEmployeeIncompleteStore()

    const [ email, setEmail ] = useState<string>(rowSelected?.email || '')
    const [ phone, setPhone ] = useState<string>(rowSelected?.phone || '')
    
    const handleSubmit = async () => {
        if (email === '') return CustomSnackbar('warning', 'Ingrese un correo.') 
        // if (phone === '') return CustomSnackbar('warning', 'Ingrese un teléfono.')
        const response = await updateEmployeeIncomplete(rowSelected.id, { email: email, phone: phone, status_import: 1 })
        if (response === true) {
            onList()
            closeModal()
        }
    }

    return(
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalBody>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontWeight: 'bolder' }}>
                        ACTUALIZAR DATOS
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Email:</FormLabel>
                        <TextField
                            id="email"
                            type="email"
                            name="email"
                            fullWidth
                            size="small"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel>Celular:</FormLabel>
                        <TextField
                            id="phone"
                            type="number"
                            name="phone"
                            fullWidth
                            size="small"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button
                            onClick={() => handleSubmit()}
                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                        >
                            {
                                status === ApiStatus.FETCHING
                                ?
                                <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                :
                                'Importar'
                            }
                        </Button>
                        <Button
                            disabled={status === ApiStatus.FETCHING}
                            onClick={() => closeModal()}
                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}