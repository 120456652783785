import { useDispatch, useSelector } from "react-redux";
import { MechanicsAPI } from "../apis/MechanicsAPI";
import {
    selectMechanicState,
    changeStatus,
    onFetchMechanic,
    onSetEditMechanic,
    onSetSelectedMechanic,
} from "../redux/slices/mechanicSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Mechanic } from "../types/slices/mechanicType";

export const useMechanicStore = () => {
    const dispatch = useDispatch();
    const { mechanics, status, editMechanic, selectedMechanic } =
        useSelector(selectMechanicState);

    const idcompany = 1;

    const getMechanics = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MechanicsAPI.getMechanics(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchMechanic(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createMechanicStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MechanicsAPI.createMechanics(data, idcompany);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editMechanicStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MechanicsAPI.editMechanics(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteMechanics = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MechanicsAPI.deleteMechanics(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedMechanic = (mechanic: Mechanic) => {
        try {
            dispatch(onSetSelectedMechanic(mechanic));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditMechanic = (mechanic: Mechanic) => {
        try {
            dispatch(onSetEditMechanic(mechanic));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        //states
        mechanics,
        status,
        editMechanic,
        selectedMechanic,
        //actions
        getMechanics,
        createMechanicStore,
        editMechanicStore,
        deleteMechanics,
        setSelectedMechanic,
        setEditMechanic,
    };
};
