import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useTechnicalReportStore } from "../../hooks/useTechnicalReportStore";
import { Role } from "../../types/roles/roleTypes";
import { TechnicalReport } from "../../types/slices/technicalreportType";
import { RoutesMap } from "../../types";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { Grid } from "@mui/material";
import { formatDate } from "../../toolbox/helpers/dates-helpers";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const columnsAdmin = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'date_attention', label: 'FECHA', align: 'center', format: (row) => formatDate(row.date_attention)},
    { type: 'text', field: 'customer', label: 'CLIENTE', align: 'center' },
    { type: 'text', field: 'rut_customer', label: 'RUT', align: 'center' },
    { type: 'text', field: 'created_by', label: 'REGISTRADO POR', align: 'center' },
    { type: 'text', field: 'role', label: 'ROL', align: 'center' },
    { type: 'text', field: 'finalized', label: 'FINALIZADO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsTechnical = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'date_attention', label: 'FECHA', align: 'center', format: (row) => formatDate(row.date_attention)},
    { type: 'text', field: 'customer', label: 'CLIENTE', align: 'center' },
    { type: 'text', field: 'rut_customer', label: 'RUT', align: 'center' },
    { type: 'text', field: 'finalized', label: 'FINALIZADO', align: 'center' },
    { type: 'text', field: 'status_technical', label: 'FIRMADO', align: 'center', format: (row) => row.status_technical === '1' ? 'Si' : 'No' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsCustomer = [
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'date_attention', label: 'FECHA', align: 'center', format: (row) => formatDate(row.date_attention)},
    { type: 'text', field: 'customer', label: 'CLIENTE', align: 'center' },
    { type: 'text', field: 'rut_customer', label: 'RUT', align: 'center' },
    { type: 'text', field: 'finalized', label: 'FINALIZADO', align: 'center' },
    { type: 'text', field: 'status_customer', label: 'FIRMADO', align: 'center', format: (row) => row.status_customer === '1' ? 'Si' : 'No' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const TechnicalReportList:FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status, technicalReports, getTechnicalReports, deleteTechnicalReport, setEditTechnicalReport, setFetchImages, finalized } = useTechnicalReportStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<any>({} as TechnicalReport)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [showModalFinalized, setShowModalFinalized] = useState<boolean>(false)
    const userRole:Role|any = user.userType

    useEffect(() => {
        getTechnicalReportApi()
    }, [search])

    const getTechnicalReportApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.CUSTOMER) {
                payload = {
                    ...payload,
                    idcustomer: user.data?.id
                }
            }
            if (userRole === Role.TECHNICAL) {
                payload = {
                    ...payload,
                    idtechnical: user.data?.id
                }
            }
            await getTechnicalReports(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setFetchImages([])
        setEditTechnicalReport({} as TechnicalReport)
        navigate({ pathname: RoutesMap.TECHNICAL_REPORT_FORM })
    }

    const onEdit = (rowSelected) => {
        setFetchImages([])
        setEditTechnicalReport(rowSelected)
        navigate({ pathname: RoutesMap.TECHNICAL_REPORT_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteTechnicalReport(rowSelected.id)
        if (response === true) {
            setEditTechnicalReport({} as TechnicalReport)
            setShowModalConfirm(false)
            getTechnicalReportApi()
        }
    }

    const onFinalized = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalFinalized(true);
    }

    const onConfirm = async () =>{
        const response = await finalized(rowSelected.id)
        if (response === true) {
            setShowModalFinalized(false)
            getTechnicalReportApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onDetails = (rowSelected) => {
        navigate(`/technical-report-signature/${rowSelected.id}`)
    }

    return (<>
        <Grid item xs={12}>
            {
                userRole === Role.ADMIN && 
                    <CustomTable
                        title={'Informes técnicos'}
                        columns={columnsAdmin || []}
                        rows={technicalReports || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onDetails={onDetails}
                        onFinalized={onFinalized}
                        loading={loading}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
            }
            {
                userRole === Role.TECHNICAL && 
                <CustomTable
                    title={'Informes técnicos'}
                    columns={columnsTechnical || []}
                    rows={technicalReports || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onDetails={onDetails}
                    onFinalized={onFinalized}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
            {
                userRole === Role.CUSTOMER && 
                <CustomTable
                    title={'Mis informes técnicos'}
                    columns={columnsCustomer || []}
                    rows={technicalReports || []}
                    onRowClick={() => { }}
                    hasOptions  
                    onDetails={onDetails}   
                    loading={loading}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={() => { setShowModalConfirm(false) }}
                    onCancel={() => { setShowModalConfirm(false) }}
                    onConfirm={onDeleteConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                />
            )
        }
        {
            showModalFinalized && (
                <ModalConfirm
                    open={showModalFinalized}
                    closeModal={() => { setShowModalFinalized(false) }}
                    onCancel={() => { setShowModalFinalized(false) }}
                    onConfirm={onConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                    title="¿Está seguro de marcar como finalizado el reporte técnico?"
                    text="Si estas seguro, haz click en el boton 'aceptar'."
                />
            )
        }
    </>)
}