import { useDispatch, useSelector } from "react-redux";
import { CriticalRiskAPI } from "../apis/AST/CriticalRiskAPI";
import { selectCriticalRiskState, changeStatus, onFetchCriticalRisk, onSetSelectedCriticalRisk } from "../redux/slices/criticalRiskSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { CriticalRisk } from "../types/slices/criticalRiskType";

export const useCriticalRiskStore = () => {
    const dispatch = useDispatch();
    const { status, criticalRisks, selectedCriticalRisk } = useSelector(selectCriticalRiskState);


    const getCriticalRisk = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CriticalRiskAPI.getCriticalRisk(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchCriticalRisk(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createCriticalRiskStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await CriticalRiskAPI.createCriticalRisk(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editCriticalRiskStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await CriticalRiskAPI.editCriticalRisk(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteCriticalRiskStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await CriticalRiskAPI.deleteCriticalRisk(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedCriticalRisk = (controlCriticalRisk: CriticalRisk) => {
        try {
            dispatch(onSetSelectedCriticalRisk(controlCriticalRisk));
        } catch (error) {
            console.log(error);
        }
    };


    return {
        //states
        status, criticalRisks, selectedCriticalRisk,
        //actions
        getCriticalRisk,
        createCriticalRiskStore,
        editCriticalRiskStore,
        deleteCriticalRiskStore,
        setSelectedCriticalRisk,
    };
};