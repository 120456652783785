import { useDispatch, useSelector } from "react-redux";
import { TypeAttention } from "../types/slices/typeAttentionType";
import { TypeAttentionAPI } from "../apis/TypeAttentionAPI";
import { selectTypeAttention, onFetchTypeAttention, changeStatus, onSetSelectedTypeAttention } from "../redux/slices/typeAttentionSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useTypeAttention = () => {
    const dispatch = useDispatch();
    const { status, typeAttentions, selectedTypeAttention } = useSelector(selectTypeAttention);

    const getTypeAttentionStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeAttentionAPI.getTypeAttention(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchTypeAttention(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createTypeAttentionStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeAttentionAPI.createTypeAttention(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editTypeAttentionStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeAttentionAPI.editTypeAttention(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteTypeAttentionStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeAttentionAPI.deleteTypeAttention(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedTypeAttention = (typeAttention: TypeAttention) => {
        try {
            dispatch(onSetSelectedTypeAttention(typeAttention));
        } catch (error) {
            console.log(error);
        }
    }

    return {
        typeAttentions,
        status,
        selectedTypeAttention,
        //actions
        getTypeAttentionStore,
        createTypeAttentionStore,
        editTypeAttentionStore,
        deleteTypeAttentionStore,
        setSelectedTypeAttention
    }
}