import { Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { usePatternRStore } from "../../hooks/usePatternRStore";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { PatternR } from "../../types/slices/patternRType";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import CustomPatternFilter from "../../components/common/CustomPatternFilter/CustomPatternFilter";
// import CustomFullCalendar from "../../components/common/Calendar/CustomFullCalendar";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'name_trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'name_type_vehicle', label: 'TIPO DE VEHÍCULO', align: 'center' },
    { type: 'text', field: 'mileage', label: "ODÓMETRO", format: (row) => `${row.mileage} Km`, align: 'center' },
    { type: 'text', field: 'execution_date', label: 'FECHA DE EJECUCION', align: 'center' },
    {
        type: 'text', field: 'status', label: 'ESTADO', align: 'center', format: (row) => {
            if (row.status_mechanic === "0" && row.status_boss === "0") return "No firmado";
            if (row.status_mechanic === "1" && row.status_boss === "0") return "Firmado por mecánico";
            if (row.status_mechanic === "0" && row.status_boss === "1") return "Firmado por jefe de mantenimiento";
            if (row.status_mechanic === "1" && row.status_boss === "1") return "Firmado";
        }
    },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsAdmin = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'name_trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'name_type_vehicle', label: 'TIPO DE VEHÍCULO', align: 'center' },
    { type: 'text', field: 'mileage', label: "ODÓMETRO", format: (row) => `${row.mileage} Km`, align: 'center' },
    { type: 'text', field: 'execution_date', label: 'FECHA DE EJECUCION', align: 'center' },
    { type: 'text', field: 'mechanic', label: 'MECÁNICO', format: (row: any) => `${row.name_mechanic} ${row.surname_mechanic}` },
    { type: 'text', field: '', label: "RUT MECÁNICO", format: (row) => row.rut_mechanic, align: 'center' },
    {
        type: 'text', field: 'status', label: 'ESTADO', align: 'center', format: (row) => {
            if (row.status_mechanic === "0" && row.status_boss === "0") return "No firmado";
            if (row.status_mechanic === "1" && row.status_boss === "0") return "Firmado por mecánico";
            if (row.status_mechanic === "0" && row.status_boss === "1") return "Firmado por jefe de mantenimiento";
            if (row.status_mechanic === "1" && row.status_boss === "1") return "Firmado";
        }
    },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const PTRList: FC = () => {

    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status: patternRStatus, patternRs, getPatternRs, deletePatternR, setSelectedPatternR } = usePatternRStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<any>({} as PatternR)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const userRole: Role | any = user.userType

    useEffect(() => {
        getPatternRApi()
    }, [search])

    const getPatternRApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.MECHANIC) {
                payload = {
                    ...payload,
                    idmechanic: user?.data?.id
                }
            }
            if (userRole === Role.ADMIN) {
                payload = {
                    ...payload,
                    status_mechanic: 1,
                    status_boss: 1
                }
            }
            await getPatternRs(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedPatternR({} as PatternR)
        navigate({ pathname: RoutesMap.PT_R_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedPatternR(rowSelected)
        navigate({ pathname: RoutesMap.PT_R_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deletePatternR(rowSelected.id)
        if (response === true) {
            setSelectedPatternR({} as PatternR)
            setShowModalConfirm(false)
            getPatternRApi()
        }
    }

    const onDetails = (rowSelected) => {
        navigate(`/pt-r-signature/${rowSelected.id}`)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (<>

        <Grid item xs={12}>
            {
                userRole !== Role.ADMIN && (
                    <>
                        <Grid item container sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "-5px",
                        }}>
                            <Typography variant="h6"
                                component="h1"
                                gutterBottom
                                color="#808080"
                                className="tittle"
                                sx={{ mt: 1, ml: 1, fontWeight: 600 }}>PAUTA R</Typography>
                        </Grid>
                        <Grid item container xs={12} sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "10px",
                        }}>
                            <CustomPatternFilter loading={setLoading} getData={getPatternRs} />
                        </Grid>
                    </>

                )
            }
            {
                userRole === Role.ADMIN && (
                    <CustomTable
                        title={'Pauta R'}
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={patternRs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole === Role.MAINTENANCE && (
                    <CustomTable
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={patternRs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onEdit={onEdit}
                        onDeletePattern={onDelete}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole === Role.MECHANIC && (
                    <CustomTable
                        columns={columns || []}
                        loading={loading}
                        rows={patternRs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDeletePattern={onDelete}
                        // onEdit={onEdit}
                        onEditPattern={onEdit}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole !== Role.MECHANIC && userRole !== Role.ADMIN && userRole !== Role.MAINTENANCE && (
                    <CustomTable
                        columns={columns || []}
                        loading={loading}
                        rows={patternRs || []}
                        onRowClick={() => { }}
                        hasOptions
                        // onAddFn={onAdd}
                        onDeletePattern={onDelete}
                        // onEdit={onEdit}
                        // onEditPattern={onEdit}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }

        </Grid>
        {/*Calendario de pautas */}
        {/* <CustomFullCalendar /> */}
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={() => { setShowModalConfirm(false) }}
                    onCancel={() => { setShowModalConfirm(false) }}
                    onConfirm={onDeleteConfirm}
                    status2={patternRStatus === ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}