import { api } from "./configs/axiosConfigs";

export const TypeVehicleAPI = {
    getTypeVehicle: async (data?: any) => {
        const response = await api
            .get('/typeVehicle', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    createTypeVehicle: async (data: any) => {
        const response = await api
            .post('/typeVehicle/register', { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    editTypeVehicle: async (id: number, data: any) => {
        const response = await api
            .patch(`/typeVehicle/${id}`, { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    deleteTypeVehicle: async (id: number) => {
        const response = await api
            .delete(`/typeVehicle/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    }
};