import { useDispatch, useSelector } from "react-redux";
import { IdentifyImageAPI } from "../apis/IdentifyImageAPI"
import { selectIdentifyState, changeStatus, onFetchIdentify, onSetSelectedIdentify, onSetIdentifyFront, onSetIdentifyReverse, onSetIdentifySignature, onSetIdentifyPerson, onSetIdentifyFiles, onSetImage } from "../redux/slices/identifySlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { IdentifiesFiles, Identify } from "../types/slices/identifyType"

export const useIdentifyImage = () => {
    const dispatch = useDispatch();
    const { identifications, status, selectedIdentify, IdentifiesFiles, image } = useSelector(selectIdentifyState);

    const getIdentifications = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await IdentifyImageAPI.getIdentifyImage(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchIdentify(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    }

    const sendIdentify = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await IdentifyImageAPI.sendImage(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", "Error al enviar la imagen")
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar("success", response.data.message || "")
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const updateIdentify = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await IdentifyImageAPI.editIdentifyImage(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteIdentify = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await IdentifyImageAPI.deleteIdentifyImage(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedIdentify = (identify: Identify) => {
        try {
            dispatch(onSetSelectedIdentify(identify));
        } catch (error) {
            console.log(error);
        }
    };

    const setIdentifyFront = (identify:any) => {
        try {
            dispatch(onSetIdentifyFront(identify))
        } catch (error) {
            console.log(error)
        }
    }

    const setIdentifyReverse = (identify:any) => {
        try {
            dispatch(onSetIdentifyReverse(identify))
        } catch (error) {
            console.log(error)
        }
    }

    const setIdentifySignature = (identify:any) => {
        try {
            dispatch(onSetIdentifySignature(identify))
        } catch (error) {
            console.log(error)
        }
    }

    const setIdentifyPerson = (identify:any) => {
        try {
            dispatch(onSetIdentifyPerson(identify))
        } catch (error) {
            console.log(error)
        }
    }

    const setIdentifyFile = (identify:IdentifiesFiles) => {
        try {
            dispatch(onSetIdentifyFiles(identify))
        } catch (error) {
            console.log(error)
        }
    }

    const changeStatusIdentify = async (id:number, data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await IdentifyImageAPI.changeStatus(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar("error", response.data.message || "")
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar("success", response.data.message || "")
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateOrCreateIdentify = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await IdentifyImageAPI.updateOrCreate(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", "Error al guardar la imagen")
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar("success", response.data.message || "")
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setImage = (image:any) => {
        try {
            onSetImage(image)
        } catch (error) {
            console.log(error)
        }
    }

    const getCertificateIdentifyTPDF = async (ididentify:number) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await IdentifyImageAPI.getIdentifyPDF(ididentify)
          if (!response?.status) {                
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message)
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          return response.data
        } catch (error) {
          console.log(error)
        }
      }

    return {
        //states
        image,
        identifications,
        status,
        selectedIdentify,
        IdentifiesFiles,
        //actions
        getIdentifications,
        sendIdentify,
        updateIdentify,
        deleteIdentify,
        setSelectedIdentify,
        setIdentifyFront,
        setIdentifyReverse,
        setIdentifySignature,
        setIdentifyPerson,
        setIdentifyFile,
        changeStatusIdentify,
        updateOrCreateIdentify,
        setImage,
        getCertificateIdentifyTPDF
    };
};