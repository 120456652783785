import { createSlice } from "@reduxjs/toolkit";
import { Supervisor, SupervisorType } from "../../types/slices/supervisorsType";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: SupervisorType = {
    status: ApiStatus.FETCHED,
    supervisors: [],
    errorMessage: undefined,
    editSupervisor: {} as Supervisor,
    selectedSupervisor: {} as Supervisor,
};

const supervisorSlice = createSlice({
    name: "supervisor",
    initialState,
    reducers: {
        onFetchSupervisor(state, { payload }: { payload: Supervisor[] }) {
            state.status = ApiStatus.FETCHED;
            state.supervisors = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditSupervisor(state, { payload }: { payload: Supervisor }) {
            state.editSupervisor = payload;
        },
        onSetSelectedSupervisor(state, { payload }: { payload: Supervisor }) {
            state.selectedSupervisor = payload;
        },
    },
});

export const selectSupervisorState = (state: RootState) =>
    state.supervisorSlice;
export default supervisorSlice.reducer;

export const {
    onFetchSupervisor,
    changeStatus,
    onSetEditSupervisor,
    onSetSelectedSupervisor,
} = supervisorSlice.actions;
