import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Contrata, ContrataType } from "../../types/slices/contrataType";
import { RootState } from "../store";

const initialState: ContrataType = {
    status: ApiStatus.FETCHED,
    contratas: [],
    errorMessage: undefined,
    editContrata: {} as Contrata,
    selectedContrata: {} as Contrata,
};

const contrataSlice = createSlice({
    name: 'contrata',
    initialState,
    reducers: {
        onFetchContrata(state, { payload }: { payload: Contrata[] }) {
            state.status = ApiStatus.FETCHED;
            state.contratas = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditContrata(state, { payload }: { payload: Contrata }) {
            state.editContrata = payload;
        },
        onSetSelectedContrata(state, { payload }: { payload: Contrata }) {
            state.selectedContrata = payload;
        }
    },
});

export const selectContrataState = (state: RootState) => state.contrataSlice;

export default contrataSlice.reducer;

export const {
    onFetchContrata,
    changeStatus,
    onSetEditContrata,
    onSetSelectedContrata
} = contrataSlice.actions