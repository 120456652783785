import { FC, useEffect, useState } from "react"
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Divider, FormLabel, Grid, TextField, InputAdornment, Button, Tooltip, IconButton } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useNavigate } from "react-router-dom";
import { useVehicleStore } from "../../hooks/useVehicleStore";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ApiStatus } from "../../types/api/status";
import { Vehicles } from "../../types/slices/vehiclesType";
import { useExternalMaintenanceStore } from "../../hooks/useExternalMaintenanceStore"
import { ExternalMaintenance } from "../../types/slices/externalMaintenanceType";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { CloudUpload } from "@mui/icons-material";
import { useMaintenanceCompanyStore } from "../../hooks/useMaintenanceCompanyStore";
import { useAuthStore } from "../../hooks";
import { useBossStore } from "../../hooks/useBossStore";
import { Role } from "../../types/roles/roleTypes";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { createNotification } from "../../types/slices/notificationType";

const date = new Date()
const año = date.getFullYear()
const mes = String(date.getMonth() + 1).padStart(2, '0')
const día = String(date.getDate()).padStart(2, '0')
const today = `${año}-${mes}-${día}`;

export const ExternalMaintenanceForm: FC = () => {

    const { user } = useAuthStore()
    const navigate = useNavigate()
    const { vehicleStatus, vehicles, getVehicles } = useVehicleStore()
    const { status: statusEM, selectedExternalMaintenance, createExternalMaintenance, setSelectedExternalMaintenance, editExternalMaintenance, getExternalMaintenance, externalMaintenanceS } = useExternalMaintenanceStore();
    const { maintenanceCompanies, getMaintenanceCompany } = useMaintenanceCompanyStore()
    const [disabled, setDisabled] = useState<boolean>(false)

    //notifications
    const userRole: Role | any = user.userType;
    const { getBoss, boss } = useBossStore()
    const { createNotifications } = useNotificationStore()

    const [selectedVehicle, setSelectedVehicle] = useState<Vehicles | null>(null)
    const [data, setData] = useState<any>({
        id: 0,
        patent: '',
        idtrademark: 0,
        trademark: '',
        idmodel: 0,
        model: '',
        n_chassis: '',
        lastOdometer: 0,
        correspondingMileage: 0,
        odometer: '',
        n_order: '',
        execution_date: today,
        last_maintenance_date: '',
        rut_maintenance_company: '',
        pdfFile: null,
        pdfName: '',
    });

    const [selectedEmpresa, setSelectedEmpresa] = useState<string>('');
    const [isEdit, setIsEdit] = useState<boolean>(false)

    useEffect(() => {
        apiGet();
        if (Object.entries(selectedExternalMaintenance).length > 0) {
            setIsEdit(true)
            setData({
                id: selectedExternalMaintenance.id,
                patent: selectedExternalMaintenance.patent,
                odometer: selectedExternalMaintenance.odometer,
                n_order: '',
                execution_date: selectedExternalMaintenance.execution_date,
                last_maintenance_date: selectedExternalMaintenance.last_maintenance_date,
                maintenanceCompany: selectedExternalMaintenance.main_company_rut,
            })
            const vehicle = vehicles.find(vehicle => vehicle.id === selectedExternalMaintenance.idvehicle)
            setSelectedVehicle(vehicle || {} as Vehicles)
            setSelectedExternalMaintenance({} as ExternalMaintenance)
        }
    }, [])

    const apiGet = async () => {
        await getVehicles({ status: 1 });
        await getMaintenanceCompany();
        await getExternalMaintenance();
        await getBoss()
    }

    useEffect(() => {
        if (selectedVehicle !== null) {
            if (selectedVehicle.detail_maintenance === null) {
                setDisabled(true)
                CustomSnackbar('warning', 'La patente seleccionada no cuenta con una configuración para ejecutar una mantención externa.')
            } else {
                setDisabled(false)
                const lastOdometerRegister = externalMaintenanceS.find(maintenance => maintenance.idvehicle === selectedVehicle.id)?.odometer || 0;
                const correspondingMileage = selectedVehicle?.detail_maintenance?.maintenance_km?.find((detail) => detail.km > lastOdometerRegister)?.km
                // const lastDate = externalMaintenanceS.find(maintenance => maintenance.idvehicle === selectedVehicle.id)?.execution_date || "";
                setData({
                    ...data,
                    idtrademark: selectedVehicle?.idtrademark,
                    trademark: selectedVehicle?.trademark,
                    lastOdometer: selectedVehicle?.last_mileage_externalMaintenance || 0,
                    correspondingMileage: correspondingMileage,
                    last_maintenance_date: selectedVehicle?.last_externalMaintenance || '',
                    idmodel: selectedVehicle?.idmodel,
                    model: selectedVehicle?.model,
                    n_chassis: selectedVehicle?.n_chassis,
                    odometer: selectedVehicle?.mileage || 0
                })
            }
        }
    }, [selectedVehicle])


    const onCancel = async () => {
        navigate({ pathname: RoutesMap.EXTERNAL_MAINTENANCE })
    }

    const onSubmit = async (values) => {
        if (selectedVehicle === null) return CustomSnackbar('warning', 'Seleccione una patente')
        if (values.odometer === null || values.odometer === '') return CustomSnackbar('warning', 'Ingrese odometro')
        if (values.execution_date === null || values.execution_date === '') return CustomSnackbar('warning', 'Ingrese fecha de ejecucion')
        if (!values.pdfFile) {
            return CustomSnackbar('warning', 'Seleccione un archivo PDF');
        }
        const idmechanic: any = user?.data?.id
        const payload = new FormData();
        payload.append('rut_maintenance_company', selectedEmpresa);
        payload.append('idvehicle', selectedVehicle?.id.toString())
        payload.append('odometer_real', values.odometer)
        payload.append('execution_date', values.execution_date)
        payload.append('last_maintenance_date', values.last_maintenance_date)
        payload.append('document_pdf', values.pdfFile)
        payload.append('odometer_corresponding', values.correspondingMileage)
        payload.append('idmechanic', idmechanic)
        const method = !isEdit ? createExternalMaintenance(payload) : editExternalMaintenance(data.id, payload)
        const response = await method;
        if (response === true) {
            if (userRole === Role.MECHANIC) {
                boss?.map(async (element) => {
                    const notify: createNotification = {
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        iduser_type: 4,
                        title: "Nueva Mantención Preventiva Externa",
                        details: `El ${user.userType} ${user.data?.name} ${user.data?.surname} ha creado una nueva mantención preventiva externa.`,
                        idview: 3,
                        idcompany: 1,
                        idregister: null,
                    }
                    await createNotifications(notify)
                })
            }
            navigate({ pathname: RoutesMap.EXTERNAL_MAINTENANCE })
        }
    };

    return (
        <CustomForm>
            {vehicleStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {statusEM === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nueva Mantención Preventiva Externa' : 'Editar Mantención Preventiva Externa'}
                text={!isEdit ? 'Ingrese los datos de la Mantención Preventiva Externa' : 'Modifique los datos de la Mantención Preventiva Externa'}
                goBack={RoutesMap.EXTERNAL_MAINTENANCE}
            >
            </CustomFormHeader>
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Patente:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-company"
                                                value={selectedVehicle}
                                                options={vehicles || []}
                                                getOptionLabel={(option) => option.patent || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedVehicle(newValue);
                                                }}
                                                readOnly={isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Marca:</FormLabel>
                                            <TextField
                                                id="trademark"
                                                type="text"
                                                name="trademark"
                                                fullWidth
                                                size="small"
                                                value={values.trademark}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Modelo:</FormLabel>
                                            <TextField
                                                id="model"
                                                type="text"
                                                name="model"
                                                fullWidth
                                                size="small"
                                                value={values.model}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>N° Chasis:</FormLabel>
                                            <TextField
                                                id="n_chassis"
                                                type="text"
                                                name="n_chassis"
                                                fullWidth
                                                size="small"
                                                value={values.n_chassis}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        {
                                            !isEdit && (
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Última Mantención Preventiva:</FormLabel>
                                                        <TextField
                                                            id="last_maintenance_date"
                                                            type="date"
                                                            name="last_maintenance_date"
                                                            fullWidth
                                                            size="small"
                                                            value={values.last_maintenance_date}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Último odometro - Mantención Preventiva:</FormLabel>
                                                        <TextField
                                                            id="last_odometer"
                                                            type="text"
                                                            name="last_odometer"
                                                            fullWidth
                                                            size="small"
                                                            value={values.lastOdometer}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                readOnly: true,
                                                                endAdornment: <InputAdornment position="start">Km</InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Kilometraje correspondiente a la mantención:</FormLabel>
                                            <TextField
                                                id="corresponding_mileage"
                                                type="text"
                                                name="corresponding_mileage"
                                                fullWidth
                                                size="small"
                                                value={values.correspondingMileage}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: <InputAdornment position="start">Km</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Odómetro:</FormLabel>
                                            <TextField
                                                id="odometer"
                                                type="text"
                                                name="odometer"
                                                fullWidth
                                                size="small"
                                                value={values.odometer}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">Km</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Fecha de ejecución:</FormLabel>
                                            <TextField
                                                id="execution_date"
                                                type="date"
                                                name="execution_date"
                                                fullWidth
                                                size="small"
                                                value={values.execution_date}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Empresa ejecutora:</FormLabel>
                                            {/* <Tooltip title="Selecciona alguna de las empresas o ingresa el RUT de la empresa ejecutora de la mantención.">
                                                <IconButton>
                                                    <InfoIcon fontSize="small" sx={{ height: '10px'}}/>
                                                </IconButton>
                                            </Tooltip> */}
                                            <Autocomplete
                                                disablePortal
                                                id="companies"
                                                value={data.rut_maintenance_company}
                                                options={maintenanceCompanies?.map((element) => ({
                                                    label: element.business_name,
                                                    value: element.rut,
                                                }))}
                                                getOptionLabel={(option) => option ? option.label : ""}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                freeSolo
                                                onChange={(e, newValue) => {
                                                    setSelectedEmpresa(newValue?.value);
                                                }}
                                                onInputChange={(e, newInputValue) => {
                                                    setSelectedEmpresa(newInputValue);
                                                }}
                                                readOnly={isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                                            <input
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => {
                                                    setFieldValue('pdfFile', e?.currentTarget?.files?.[0])
                                                    setFieldValue('pdfName', e?.currentTarget?.files?.[0].name)
                                                }}
                                                style={{ display: 'none' }}
                                                id="pdf-file-input"
                                            />
                                            <label htmlFor="pdf-file-input">
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    startIcon={<CloudUpload />}
                                                >
                                                    {values.pdfName === '' ? 'Subir PDF' : values.pdfName}
                                                </Button>
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={'Guardar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                    disabled={disabled}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}