import React, { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMechanicStore } from "../../hooks/useMechanicStore";
import { Mechanic } from "../../types/slices/mechanicType";
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useContrataStore } from "../../hooks/useContrataStore";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { CustomPhoneInput } from "../../components/common/CustomPhoneInput/CustomPhoneInput";

type MechanicDataForm = {
    id: number;
    rut: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    status_turn: number;
    idcontrata: number
};

type errorsForm = {
    name?: string;
    surname?: string;
    rut?: string;
    email?: string;
    phone?: string;
    status_turn?: string;
    idcontrata?: string;
};

const MechanicForm: FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthStore()
    const {
        status: mechanicStatus,
        selectedMechanic,
        setSelectedMechanic,
        createMechanicStore,
        editMechanicStore,
    } = useMechanicStore();
    const { contratas, getContratas } = useContrataStore()

    const userRole: Role | any = user.userType;
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<MechanicDataForm>({
        id: 0,
        rut: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        status_turn: 0,
        idcontrata: 0,
    });

    useEffect(() => {
        getContratas();
        if (Object.entries(selectedMechanic).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedMechanic.id,
                rut: selectedMechanic.rut,
                name: selectedMechanic.name,
                surname: selectedMechanic.surname,
                email: selectedMechanic.email,
                phone: selectedMechanic.phone,
                status_turn: selectedMechanic.status_turn,
                idcontrata: selectedMechanic.idcontrata,
            });
            setSelectedMechanic({} as Mechanic);
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            rut: values.rut,
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
            status_turn: 1,
            idcontrata: userRole === Role.MAINTENANCE ? user.data?.idcontrata : values.idcontrata,
        };
        const method = !isEdit
            ? createMechanicStore(payload)
            : editMechanicStore(data.id, payload);
        const response = await method;
        if (response === true) {
            navigate({ pathname: RoutesMap.MECHANICS });
        }
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.MECHANICS });
    };

    const validateForm = (values: any) => {
        let errors: errorsForm = {};
        if (!values.name) errors.name = "Nombre requerido";
        if (!values.surname) errors.surname = "Apellido requerido";
        if (!values.rut) errors.rut = "RUT es requerido";
        if (!values.email) errors.email = "Email es requerido";
        if (!values.phone) errors.phone = "Celular es requerido";
        if (!values.idcontrata && userRole !== Role.MAINTENANCE) errors.idcontrata = "Contrata es requerido"
        return errors;
    };

    return (
        <CustomForm>
            {mechanicStatus === ApiStatus.FETCHING && (
                <CustomBackdrop open={true} />
            )}
            <CustomFormHeader
                title={!isEdit ? "Nuevo Mecánico" : "Editar Mecánico"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo mecánico"
                        : "Ingrese los datos del mecánico"
                }
                goBack={RoutesMap.MECHANICS}
            ></CustomFormHeader>

            <CustomFormBody>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validate={(values) => validateForm(values)}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                    }) => {
                        return (
                            <form>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text"
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={
                                                errors.name && touched.name
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.name && touched.name
                                                    ? errors.name
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Apellido:</FormLabel>
                                        <TextField
                                            id="surname"
                                            type="text"
                                            name="surname"
                                            fullWidth
                                            size="small"
                                            value={values.surname}
                                            onChange={handleChange}
                                            error={
                                                errors.surname &&
                                                    touched.surname
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.surname &&
                                                    touched.surname
                                                    ? errors.surname
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>RUT:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text"
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={
                                                errors.rut && touched.rut
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.rut && touched.rut
                                                    ? errors.rut
                                                    : ""
                                            }
                                            InputProps={{
                                                inputProps: { maxLength: 10 },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={2}
                                    sx={{ mt: 2, mb: 3 }}
                                >
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Email:</FormLabel>
                                        <TextField
                                            id="email"
                                            type="email"
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={
                                                errors.email && touched.email
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.email && touched.email
                                                    ? errors.email
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Celular:</FormLabel>
                                        <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                    </Grid>
                                    {
                                        userRole !== Role.MAINTENANCE && (
                                            <Grid item xs={12} md={4}>
                                                <FormLabel>Contrata:</FormLabel>
                                                <Select
                                                    id="idcontrata"
                                                    name="idcontrata"
                                                    fullWidth
                                                    value={values.idcontrata}
                                                    onChange={handleChange}
                                                    error={
                                                        errors.idcontrata &&
                                                            touched.idcontrata
                                                            ? true
                                                            : false
                                                    }
                                                    style={{ height: '52%' }}
                                                >
                                                    {contratas?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                                </Select>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={
                                        !isEdit ? "Guardar" : "Actualizar"
                                    }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    );
};

export default MechanicForm;
