import { useDispatch, useSelector } from "react-redux";
import { AxleAPI } from "../apis/AxleAPI";
import { selectAxle, onFetchAxle, changeStatus } from "../redux/slices/axleSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useAxleStore = () => {
    const dispatch = useDispatch();
    const { status, axles } = useSelector(selectAxle);

    const getAxleStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await AxleAPI.getAxle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchAxle(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    return {
        axles,
        status,
        getAxleStore,
    }
}