
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from '../redux/store';
import { AuthStatus, LocalStorageKey, LoginType, LoginTypeERP } from '../types';
import { readLocalStorage, saveLocalStorage } from '../toolbox/helpers/local-storage-helpers';
import { onChangeAuthStatus, onLogin, onLogout, onRefreshToken, onVerifying } from '../redux/slices/authSlice';
import { AuthAPI } from '../apis/AuthAPI';
import { ProfileAPI } from '../apis/ProfileAPI';
import { CustomSnackbar } from '../components/common/CustomSnackbar/CustomSnackbar';


export const useAuthStore = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { status, user, errorMessage } = useSelector(state => state.authSlice)

    const setProfileData = async (access_token: string) => {
        try {
            const perfil = await ProfileAPI.get(access_token);
            const { detail } = perfil;
            saveLocalStorage(LocalStorageKey.USER_DATA, detail);
            dispatch(onLogin(detail));
            return detail;

        } catch (error) {
            console.error(error);
        }
    }

    const loginERP = async ({ rut, password, idcharge_erp, idcompany }: LoginTypeERP) => {
        try {
            const login = await AuthAPI.loginERP(rut, password, idcharge_erp, idcompany);
            if (!login?.detail?.access_token) {
                return CustomSnackbar('error', login.data.message || "")
            }
            const { access_token } = login.detail
            saveLocalStorage(LocalStorageKey.TOKEN_KYTE, access_token)
            const detail = await setProfileData(access_token)

            CustomSnackbar('success', "Bienvenido")

            return {
                status: true,
                detail
            }
        } catch (error) {
            dispatch(onLogout('Credenciales Incorrectas'))
            CustomSnackbar('error', 'Credenciales Incorrectas');
            return {
                status: false,
                detail: {}
            }
        }
    }

    const login = async ({ iduser_type, password, rut, idcompany }: LoginType) => {
        try {
            const login = await AuthAPI.login(iduser_type, password, rut, idcompany);
            if (!login?.detail?.access_token) {
                return CustomSnackbar('error', login.data.message || "")
            }
            const { access_token } = login.detail
            saveLocalStorage(LocalStorageKey.TOKEN_KYTE, access_token)
            const detail = await setProfileData(access_token)

            CustomSnackbar('success', "Bienvenido")

            return {
                status: true,
                detail
            }

        } catch (error) {
            dispatch(onLogout('Credenciales Incorrectas'))
            CustomSnackbar('error', 'Credenciales Incorrectas');
            return {
                status: false,
                detail: {}
            }
        }
    }

    const verifySession = async () => {
        dispatch(onVerifying());
        const token = readLocalStorage(LocalStorageKey.TOKEN_KYTE);
        if (!token) return dispatch(onLogout());
        try {
            if (token) {
                const detail = await setProfileData(token)
                dispatch(onChangeAuthStatus(AuthStatus.AUTHENTICATED));
                navigate({ pathname: location.pathname })
                return
            } else {
                await refreshToken(token)
            }
            localStorage.clear();
            dispatch(onLogout());
            CustomSnackbar('error', 'Su sesión expiró');
        } catch (error) {
            CustomSnackbar('error', 'Su sesión expiró');
            localStorage.clear();
            dispatch(onLogout());
        }
    }

    const refreshToken = async (token: string) => {
        try {
            const session = await AuthAPI.refresh(token);
            if (!session?.detail?.token) {
                localStorage.clear();
                dispatch(onLogout());
                CustomSnackbar('error', 'Su sesión expiró');
            } else {
                const { token, user } = session.detail
                await setProfileData(token.access_token)
                saveLocalStorage(LocalStorageKey.TOKEN_KYTE, token.access_token)
                dispatch(onRefreshToken(user))

                navigate({ pathname: location.pathname })
            }
        } catch (error) {
            console.error('[Error whilte refreshing token]', error)
        }
    }

    const logout = async () => {
        const logout = await AuthAPI.logout();
        const { detail } = logout;
        CustomSnackbar('success', detail || 'Sesión cerrada con éxito');
        localStorage.clear();
        dispatch(onLogout());
    }

    return {
        /* states */
        status,
        user,
        errorMessage,
        /* actions */
        login,
        loginERP,
        logout,
        verifySession,
        setProfileData
    }
}