import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { useTypeVehicleStore } from '../../hooks/useTypeVehicleStore';
import { TypeVehicle } from '../../types/slices/typeVehicleType';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { selectHeaderState } from '../../redux/slices/headerSlice';
import { useSelector } from 'react-redux';
import { TypeVehicleModal } from './TypeVehicleModal';
import { ApiStatus } from '../../types/api/status';

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "name", label: "NOMBRE", align: 'center' },
    { type: "text", field: "description", label: "DESCRIPCIÓN", align: "center", format: (element: TypeVehicle) => element.description === null ? "Sin descripción" : element.description },
    { type: 'detail', field: 'detail', label: 'DETALLE DE EJES', align: 'center' },
    { type: "text", field: "", label: "", align: 'center' },
]

export const TypeVehicles: FC<any> = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState)
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<TypeVehicle>(
        {} as TypeVehicle
    );
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);

    const { typeVehicles, typeVehicleStatus, getTypeVehicles, setSelectedTypeVehicle, deleteTypeVehicleStore } = useTypeVehicleStore()
    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        getTypeVehiclesApi();
    }, [search])

    const getTypeVehiclesApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getTypeVehicles(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const onAdd = () => {
        navigate({ pathname: RoutesMap.TYPE_VEHICLES_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedTypeVehicle(rowSelected);
        navigate({ pathname: RoutesMap.TYPE_VEHICLES_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteTypeVehicleStore(rowSelected.id);
        if (response === true) {
            setSelectedTypeVehicle({} as TypeVehicle);
            setModalDeleteConfirm(false);
            getTypeVehiclesApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onDetail = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModal(true);
    }

    return (
        <>
            <Grid container xs={12}>
                <CustomTable
                    title={"Tipo de vehículos"}
                    columns={columns}
                    loading={loading}
                    rows={typeVehicles || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    onDetails={onDetail}
                />
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={typeVehicleStatus === ApiStatus.FETCHING ? true : false}
                />
            )}
            {showModal && (
                <TypeVehicleModal
                    open={showModal}
                    closeModal={() => { setShowModal(false) }}
                    rowSelected={rowSelected}
                />)}
        </>
    )
}