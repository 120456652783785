import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { ControlBarriers, controlBarriersType } from "../../types/slices/controlBarriersType";

const initialState: controlBarriersType = {
    status: ApiStatus.FETCHED,
    controlBarriers: [],
    errorMessage: undefined,
    selectedControlBarrier: {} as ControlBarriers,
};

const ControlBarrierSlice = createSlice({
    name: "controlBarriers",
    initialState,
    reducers: {
        onFetchControlBarrier(state, { payload }: { payload: ControlBarriers[] }) {
            state.status = ApiStatus.FETCHED;
            state.controlBarriers = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedControlBarrier(state, { payload }: { payload: ControlBarriers }) {
            state.selectedControlBarrier = payload;
        },
    }
})

export const selectControlBarriersState = (state: RootState) => state.ControlBarrierSlice;

export default ControlBarrierSlice.reducer;

export const {
    onFetchControlBarrier,
    changeStatus,
    onSetSelectedControlBarrier,
} = ControlBarrierSlice.actions;