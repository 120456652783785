import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Administrator, administratorType } from "../../types/slices/administratorType";
import { RootState } from "../store";

const initialState: administratorType = {
    status: ApiStatus.FETCHED,
    administrators: [],
    errorMessage: undefined,
    selectedAdministrator: {} as Administrator
}

const administratorSlice = createSlice({
    name: 'administrator',
    initialState,
    reducers: {
        onFetchAdministrators (state, { payload }: { payload: Administrator[] }) {
            state.status            = ApiStatus.FETCHED
            state.administrators    = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedAdministrator (state, { payload }: { payload: Administrator }) {
            state.selectedAdministrator = payload
        }
    }
})

export const selectAdministratorState = (state: RootState) => state.administratorSlice
export default administratorSlice.reducer

export const {
    onFetchAdministrators,
    changeStatus,
    onSetSelectedAdministrator
} = administratorSlice.actions