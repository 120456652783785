import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { EnergieType, Energie } from "../../types/slices/energieType";

const initialState: EnergieType = {
    status: ApiStatus.FETCHED,
    energies: [],
    errorMessage: undefined,
    selectedEnergie: {} as Energie,
};

const energieSlice = createSlice({
    name: "controlCriticalRisk",
    initialState,
    reducers: {
        onFetchEnergie(state, { payload }: { payload: Energie[] }) {
            state.status = ApiStatus.FETCHED;
            state.energies = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedEnergie(state, { payload }: { payload: Energie }) {
            state.selectedEnergie = payload;
        },
    }
})

export const selectEnergieState = (state: RootState) => state.energieSlice;

export default energieSlice.reducer;

export const {
    onFetchEnergie,
    changeStatus,
    onSetSelectedEnergie,
} = energieSlice.actions;