import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useInformMechanicalStore } from "../../hooks/useInformMechanicalStore";
import { InformMechanical } from "../../types/slices/informMechanicalType";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'description', label: 'DESCRIPCIÓN', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const InformMechanicalList:FC = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status, informsMechanical, getInformsMechanical, deleteInformMechanical, setEditInformMechanical } = useInformMechanicalStore()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ page, setPage ] = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ rowSelected, setRowSelected ]           = useState<InformMechanical>({} as InformMechanical)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)

    useEffect(()=>{
        getInformMechanicalApi()
    },[search])

    const getInformMechanicalApi = async () => {
        try {
          setLoading(true)
          let payload:any = {}
          if (search !== '') {
            payload = {
                ...payload,
                search
            }
          }
          await getInformsMechanical(payload)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        setEditInformMechanical({} as InformMechanical)
        navigate({ pathname: RoutesMap.INFORM_WORK_ORDER_FORM })
    }

    const onEdit = (rowSelected) => {
        setEditInformMechanical(rowSelected)
        navigate({ pathname: RoutesMap.INFORM_WORK_ORDER_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteInformMechanical(rowSelected.id)
        if (response === true) {
            setEditInformMechanical({} as InformMechanical)
            setShowModalConfirm(false)
            getInformMechanicalApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Informes mecánicos'}
                columns={columns || []}
                loading={loading}
                rows={informsMechanical || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}