import { useDispatch, useSelector } from "react-redux"
import { onFetchPatternT, onSetEditPatternT, onSetPatternTDataForm, onSetSelectedPatternT, selectPatternTState } from "../redux/slices/patternTSlice"
import { changeStatus } from "../redux/slices/patternTSlice"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { PatternT, PatternTDataForm } from "../types/slices/patternTType"
import { PatternTAPI } from "../apis/PatternTAPI"

export const usePatternTStore = () => {
    const dispatch = useDispatch()
    const { patternTs, status, selectedPatternT, editPatternT, patternTDataForm } = useSelector(selectPatternTState)

    
const getPatternTs = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternTAPI.get(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchPatternT(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getReportPatternT = async (params) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await PatternTAPI.getReport(params)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          const { detail } = response.data
          dispatch(changeStatus(ApiStatus.FETCHED))
          return detail
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }
  
  
  const getReportPatternTDetail = async (params) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await PatternTAPI.getReportDetail(params)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        const { detail } = response.data
        dispatch(changeStatus(ApiStatus.FETCHED))
        return detail
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

    const createPatternT = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternTAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return response.data.detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updatePatternT = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternTAPI.edit(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deletePatternT = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternTAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedPatternT = (patternT:PatternT) => {
        try {
            dispatch(onSetSelectedPatternT(patternT))
        } catch (error) {
            console.log(error)
       }
    }

    const setEditPatternT = (patternT:PatternT) => {
        try {
            dispatch(onSetEditPatternT(patternT))
        } catch (error) {
            console.log(error)
      }
    }

    const setPatternTDataForm = (patternT:PatternTDataForm) => {
      try {
          dispatch(onSetPatternTDataForm(patternT))
      } catch (error) {
          console.log(error)
     }
  }

    const getOnePatternT = async (id:number|string) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await PatternTAPI.getOne(id)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          const { detail } = response.data
          dispatch(changeStatus(ApiStatus.FETCHED))
          return detail[0]
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const getPatternTPDF = async (id:number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await PatternTAPI.getPatternTPDF(id)
        if (!response?.status) {                
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message)
          return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        return response.data
      } catch (error) {
        console.log(error)
      }
    }

    const signature = async (id:number, data:any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await PatternTAPI.signature(id, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const completePatternT = async (id: number, status: number) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await PatternTAPI.complete(id, status)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "")
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

    return {
        //states
        patternTs,
        status,
        selectedPatternT,
        editPatternT,
        patternTDataForm,
        //actions
        getPatternTs,
        getReportPatternT,
        getReportPatternTDetail,
        createPatternT,
        updatePatternT,
        deletePatternT,
        setSelectedPatternT,
        getOnePatternT,
        getPatternTPDF,
        signature,
        setEditPatternT,
        setPatternTDataForm,
        completePatternT
    }
}
