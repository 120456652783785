import React, { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { useStructureD2Store } from '../../hooks/useStructureD2Store';
import { StructureD2 } from '../../types/slices/structureD2Type';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { selectHeaderState } from '../../redux/slices/headerSlice';
import { useSelector } from 'react-redux';
import { ApiStatus } from '../../types/api/status';

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: "center" },
    { type: "text", field: "item", label: "ITEMS", align: "center" },
    { type: "text", field: "group_activity_d2", label: "GRUPO DE ACTIVIDAD", format: (row: any) => row?.group_activity_d2.name_group, align: "center" },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

const StructureD2List: FC = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<StructureD2>(
        {} as StructureD2
    );
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);

    const { structureD2, status: structureD2Status, getStructureD2Store, setSelectedStructureD2, deleteStructureD2Store } = useStructureD2Store();

    useEffect(() => {
        getStructureD2Api();
    }, [search]);

    const getStructureD2Api = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            await getStructureD2Store(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const onAdd = () => {
        navigate({ pathname: RoutesMap.STRUCTURE_D2_FORM });
    };

    const onEdit = (rowSelected: any) => {
        setSelectedStructureD2(rowSelected)
        navigate({ pathname: RoutesMap.STRUCTURE_D2_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteStructureD2Store(rowSelected.id);
        if (response === true) {
            setSelectedStructureD2({} as StructureD2);
            setModalDeleteConfirm(false);
            getStructureD2Api();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Grid>
                <CustomTable
                    title={"ÍTEMS - PAUTA T"}
                    columns={columns}
                    loading={loading}
                    rows={structureD2 || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={structureD2Status === ApiStatus.FETCHING ? true : false}
                />
            )}
        </>
    )
}

export default StructureD2List