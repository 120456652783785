import { FC, useEffect, useState } from "react";
import { useTechnicalReportStore } from "../../hooks/useTechnicalReportStore";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesMap } from "../../types";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { TechnicalReportSignatureModal } from "./TechnicalReportSignatureModal";

export const TechnicalReportSignature:FC = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { user } = useAuthStore()
    const { status, technicalReports, getTechnicalReportPDF, getOneReport } = useTechnicalReportStore()

    
    const [pdf, setPDF] = useState<any>(null)
    const [signed, setSigned] = useState<boolean>(false)
    const [showModalFirm, setShowModalFirm] = useState<boolean>(false)

    const { id } = params
    const idrepair_request:any = id
    const userRole: Role|any = user.userType;

    useEffect(()=>{
        setLocalData()
    },[])

    useEffect(() => {
        if (technicalReports[0] && Object.entries(technicalReports[0]).length > 0) {
            if (userRole === Role.CUSTOMER && technicalReports[0].status_customer === '1') {
                setSigned(true)
            }
            if (userRole === Role.TECHNICAL && technicalReports[0].status_technical === '1') {
                setSigned(true)
            }
        }
    }, [technicalReports])

    const setLocalData = async () => {
        await getOneReport(idrepair_request)
        showPDF(idrepair_request)
    }

    const handleBack = () => {
        navigate({ pathname: RoutesMap.TECHNICAL_REPORT })
    }

    const showPDF = async (id) => {
        const pdf = await getTechnicalReportPDF(id)
        const blob = new Blob([pdf], { type: 'application/pdf' })
        const bloblURL = URL.createObjectURL(blob)
        setPDF(bloblURL)
    }

    const modalSignature = () => {
        setShowModalFirm(true)
    }

    return (
        <Grid container>
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft: '-25px' }}>
                <IconButton size="small" color="primary" aria-label="view" onClick={() => handleBack()}>
                    <ArrowBackIcon fontSize='small' />
                </IconButton>
                <Typography>Volver</Typography>
            </Grid>
            <Grid item container sx={{ alignItems: "center", display: "flex", justifyContent: "center", flexWrap: "wrap", marginBottom: "10px" }}>
                <Typography variant="h6" component="h1" gutterBottom color="#808080" className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                    INFORME TÉCNICO
                </Typography>
            </Grid> 
            {
                !signed && ((userRole === Role.TECHNICAL && technicalReports[0]?.idtechnical === user.data?.id) || (userRole === Role.CUSTOMER && technicalReports[0]?.idcustomer === user.data?.id)) && (
                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                        <Button
                            onClick={() => modalSignature()}
                            sx={{ border: "solid 1px #73B2FF", m: "10px", color: "#73B2FF", width: '150px', "&:hover": { bgcolor: "#73B2FF", color: "#fff" } }}
                        >
                            FIRMAR
                        </Button>
                    </Grid>
                )
            }
            <Grid container style={{ overflow: 'hidden !important', height: '100%', alignItems: 'center', textAlign: 'center' }}>
                <iframe src={pdf} id="pdfVer" height="1000px" width="100%" title=''></iframe>
            </Grid>
            {
                showModalFirm && (
                    <TechnicalReportSignatureModal
                        open={showModalFirm}
                        closeModal={() => setShowModalFirm(false)}
                        idreport={idrepair_request}
                    />
                )
            }
        </Grid>
    )
}