import { api } from "./configs/axiosConfigs"

export const EmployeeIncompleteAPI = {
    getEmployeeIncomplete: async (data?:any) => {
        const response = await api.get(
            '/employeeIncomplete',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    update: async (id: number, data: any) => {
        const response = await api.patch(
            `/employeeIncomplete/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
}