import { useEffect, useState } from "react"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Typography, Grid, Button, CircularProgress, FormLabel, TextareaAutosize } from "@mui/material"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ApiStatus } from "../../types/api/status"
import { useRepairRequest } from "../../hooks/useRepairRequest"
import { useAuthStore } from "../../hooks"
import Radio from '@mui/material/Radio';
import { useMechanicStore } from "../../hooks/useMechanicStore";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { createNotification } from "../../types/slices/notificationType";

export const RepairRequestModalValidate: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, onList, rowSelected } = props

    const { user } = useAuthStore()
    const { status, validateRepairRequest } = useRepairRequest()
    const { mechanics, getMechanics } = useMechanicStore()
    const { createNotifications } = useNotificationStore()

    const [statusValidate, setStatusValidate] = useState<number>(1)

    useEffect(() => {
        dataApi()
    }, [])

    const dataApi = async () => {
        await getMechanics()
    }

    const handleSubmit = async () => {
        const response = await validateRepairRequest(rowSelected.id, { status_request: statusValidate })
        if (response === true) {
            mechanics?.map(async (element) => {
                const notify: createNotification = {
                    iduser_type: 6,
                    idreceiver: element.id_user,
                    rut_receiver: element.rut,
                    title: "Nueva Solicitud de Reparación disponible",
                    details: 'Se ha aceptado una nueva solicitud de reparación.',
                    idview: 5,
                    idcompany: 1,
                    idregister: null,
                }
                return await createNotifications(notify)
            })
            onList()
            closeModal()
        }
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalBody>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontWeight: 'bolder' }}>
                        VALIDAR SOLICITUD DE REPARACIÓN
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <FormLabel>{'Motivo(s):'}</FormLabel>
                        <TextareaAutosize
                            id="razon"
                            name="razon"
                            style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }}
                            value={
                                rowSelected.name_fails_resource?.map(element => element.name).join('\n')
                            }
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '10px' }}>
                        <FormLabel>Descripción:</FormLabel>
                        <TextareaAutosize
                            id="description"
                            name="description"
                            style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }}
                            value={rowSelected.description}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Radio checked={statusValidate === 1} onClick={(e) => setStatusValidate(1)} />} label="Aprobar" />
                            <FormControlLabel control={<Radio checked={statusValidate === 2} onClick={(e) => setStatusValidate(2)} />} label="Rechazar" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button
                            onClick={() => handleSubmit()}
                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                        >
                            {
                                status === ApiStatus.FETCHING
                                    ?
                                    <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                    :
                                    'Confirmar'
                            }
                        </Button>
                        <Button
                            disabled={status === ApiStatus.FETCHING}
                            onClick={() => closeModal()}
                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}