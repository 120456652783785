import { useDispatch, useSelector } from "react-redux"
import { selectControlVerificationState, changeStatus, onFetchControlVerifications, onSetEditControlVerification} from "../redux/slices/controlVerificationSlice"
import { ApiStatus } from "../types/api/status"
import { ControlVerificationAPI } from "../apis/ControlVerificationAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { ControlVerification } from "../types/slices/controlVerificationType"

export const useControlVerificationStore = () => {
    const dispatch = useDispatch()
    const { controlVerifications, status, editControlVerification } = useSelector(selectControlVerificationState)

    const getControlVerifications = async (data?:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlVerificationAPI.getControlVerifications(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchControlVerifications(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createControlVerification = async (data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlVerificationAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateControlVerification = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlVerificationAPI.update(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteControlVerification = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlVerificationAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditControlVerification = (control:ControlVerification) => {
        try {
            dispatch(onSetEditControlVerification(control))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        controlVerifications,
        status,
        editControlVerification,
        getControlVerifications,
        createControlVerification,
        updateControlVerification,
        deleteControlVerification,
        setEditControlVerification
    }
}