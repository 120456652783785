import { api } from "./configs/axiosConfigs"

export const UserAPI = {
    singingPin: async (data: any) => {
        const response = await api.patch(
            '/user/singingPin',
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    changePassword: async (data: any) => {
        const response = await api.post(
            '/user/changePassword', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getUserTypes: async (data?: any) => {
        const response = await api.get(
            '/user/type',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getUserTypesERP: async (data?: any) => {
        const response = await api.get(
            '/importERP/chargeERP',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    importUsersERP: async (data: any, idcompany: any) => {
        const response = await api.post(
            '/importERP',
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getUsersERP: async (data: any) => {
        const response = await api.get(
            '/importERP/employee',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    forgotPassword: async (rut: string, iduser_type: number) => {
        const response = await api.post(
            '/user/forgotPassword',
            {
                rut,
                iduser_type
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getCustomersERP: async (data?: any) => {
        const response = await api.get(
            '/importERP/customer',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    importCustomersERP: async (data: any, idcompany: any) => {
        const response = await api.post(
            '/importERP/customer',
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
}