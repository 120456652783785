import { onChangeToSeenStatus, onCounter, onFetchNotifications, selectNotificationState, changeStatus } from "../redux/slices/notificationSlice"
import { ApiStatus } from "../types/api/status"
import { NotificationAPI } from "../apis/NotificationAPI"
import { useDispatch, useSelector } from "../redux/store"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useNotificationStore = () => {
    const dispatch = useDispatch()
    const { notifications, status: notificationStatus, counter } = useSelector(selectNotificationState)

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    const createNotifications = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await NotificationAPI.createNotification(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                // return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            // CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    }

    const getNotifications = async ({ perPage, page }: { perPage: number, page: number }) => {
        try {
            const response = await NotificationAPI.getNotifications({ perPage, page, idcompany: idcompany })
            if (response?.data?.detail?.data) {
                dispatch(onFetchNotifications(response.data.detail.data))
                dispatch(onCounter(response?.data?.detail?.count))
            }
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }
    const getViewNotifications = async ({ perPage, page }: { perPage: number, page: number }) => {
        try {
            const response = await NotificationAPI.getViewNotifications({ perPage, page, idcompany: idcompany })
            if (response?.data?.detail?.data) {
                dispatch(onFetchNotifications(response.data.detail.data))
            }
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }
    const getNotViewNotifications = async ({ perPage, page }: { perPage: number, page: number }) => {
        try {
            const response = await NotificationAPI.getNotViewNotifications({ perPage, page, idcompany: idcompany })
            if (response?.data?.detail?.data) {
                dispatch(onFetchNotifications(response.data.detail.data))
            }
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const changeSeenNotification = async (idNotification: number) => {
        try {

            const response = await NotificationAPI.changeSeenNotification(idNotification)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                // CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(onChangeToSeenStatus(response.data.detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || 'Se cambió el estado de la notificación')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }
    const changeSeenMassiveNotification = async () => {
        try {

            const response = await NotificationAPI.changeSeenMassiveNotification(idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            // dispatch(onChangeToSeenStatus(response.data.detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || 'Se cambió el estado de todas las notificaciones')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const saveCounter = async (count: number) => {
        try {
            dispatch(onCounter(count))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    return {
        notificationStatus,
        notifications,
        counter,
        createNotifications,
        getNotifications,
        getViewNotifications,
        getNotViewNotifications,
        saveCounter,
        changeSeenNotification,
        changeSeenMassiveNotification,
    }
}