import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Formik } from "formik";

import { Visibility, VisibilityOff } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import { useDETStore } from "../../hooks/useDETStore";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { useMechanicStore } from "../../hooks/useMechanicStore";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { createNotification } from "../../types/slices/notificationType";
import { useSupervisorStore } from "../../hooks/useSupervisorStore";

export const DETSignatureModal: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, idDET } = props

    const navigate = useNavigate()
    const { signature } = useDETStore()
    const { mechanics, getMechanics } = useMechanicStore()
    const { getSupervisors, supervisors } = useSupervisorStore()
    const { createNotifications } = useNotificationStore()
    const { user } = useAuthStore()
    const userRole: Role | any = user.userType

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState({
        pin: ''
    })

    useEffect(() => {
        dataApi()
    }, [])

    const dataApi = async () => {
        await getMechanics()
        await getSupervisors()
    }

    const handleClickShowPassword = () => {
        if (showPassword) setShowPassword(false)
        if (!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const validatePinForm = (values) => {
        let errors: any = {};
        if (!values.pin) errors.pin = "PIN requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        setLoading(true)
        const response = await signature(idDET, { pin: values.pin })
        setLoading(false)
        if (response === true) {
            if (userRole === Role.MECHANIC) {
                supervisors?.map(async (element) => {
                    const notify: createNotification = {
                        iduser_type: 6,
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        title: "Documento DET firmado",
                        details: 'Un documento DET ha sido firmado por un mecánico.',
                        idview: 7,
                        idcompany: 1,
                        idregister: idDET,
                    }
                    return await createNotifications(notify)
                })
            } else if (userRole === Role.SUPERVISOR) {
                mechanics?.map(async (element) => {
                    const notify: createNotification = {
                        iduser_type: 6,
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        title: "Documento DET firmado",
                        details: 'Un documento DET ha sido firmado por un supervisor.',
                        idview: 7,
                        idcompany: 1,
                        idregister: idDET,
                    }
                    return await createNotifications(notify)
                })
            }
            navigate(`/det-signature/${idDET}`)
        }
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder' }}>
                    INGRESE SU PIN
                </Typography>
                <Formik initialValues={data} enableReinitialize validate={(values) => validatePinForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (<>
                            <Grid container sx={{ margin: '15px 0px' }}>
                                <Grid item xs={8} sx={{ margin: '0px auto' }}>
                                    <TextField
                                        id="pin"
                                        name="pin"
                                        variant="standard"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.pin}
                                        onChange={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                setFieldValue('pin', e.target.value)
                                            }
                                        }}
                                        size='small'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'  >
                                                    <LockIcon sx={{ color: '#29333B' }} />
                                                </InputAdornment>),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        sx={{ color: '#29333B' }}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                maxLength: 6
                                            }
                                        }}
                                        error={errors.pin && touched.pin ? true : false}
                                        helperText={errors.pin && touched.pin ? errors.pin : ''}
                                    />
                                </Grid>
                                <Grid container sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            onClick={() => handleSubmit()}
                                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                                        >
                                            {
                                                loading
                                                    ?
                                                    <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                                    :
                                                    'Confirmar'
                                            }
                                        </Button>
                                        <Button
                                            disabled={loading}
                                            onClick={() => closeModal()}
                                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>)
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}