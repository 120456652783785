import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { MileageMaintenance, MileageMaintenanceType } from "../../types/slices/MileageMaintenanceType.ts";

const initialState: MileageMaintenanceType = {
    status: ApiStatus.FETCHED,
    mileages: [],
    errorMessage: undefined,
    selectedMileage: {} as MileageMaintenance
};

const mileageMaintenanceSlice = createSlice({
    name: "mileageMaintenance",
    initialState,
    reducers: {
        onFetchMileage(state, { payload }: { payload: MileageMaintenance[] }) {
            state.status = ApiStatus.FETCHED;
            state.mileages = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedMileage(state, { payload }: { payload: MileageMaintenance }) {
            state.selectedMileage = payload;
        },
    }
});

export const selectMileageMaintenance = (state: RootState) => state.mileageMaintenanceSlice;
export default mileageMaintenanceSlice.reducer;

export const {
    onFetchMileage,
    changeStatus,
    onSetSelectedMileage,
} = mileageMaintenanceSlice.actions;