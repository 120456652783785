import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Verification, VerificationType } from "../../types/slices/verificationsType";

const initialState: VerificationType = {
    status: ApiStatus.FETCHED,
    verifications: [],
    errorMessage: undefined,
    selectedVerification: {} as Verification,
};

const verificationSlice = createSlice({
    name: "controlCriticalRisk",
    initialState,
    reducers: {
        onFetchVerification(state, { payload }: { payload: Verification[] }) {
            state.status = ApiStatus.FETCHED;
            state.verifications = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedVerification(state, { payload }: { payload: Verification }) {
            state.selectedVerification = payload;
        },
    }
})

export const selectVerificationState = (state: RootState) => state.verificationSlice;

export default verificationSlice.reducer;

export const {
    onFetchVerification,
    changeStatus,
    onSetSelectedVerification,
} = verificationSlice.actions;