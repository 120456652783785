import { api } from "./configs/axiosConfigs"

const idcompany = 1;

export const customerAPI = {
    getCustomer: async (data?: any) => {
        const response = await api.get(
            '/customer',
            {
                params: {
                    ...data,
                    idcompany
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    createCustomer: async (data: any) => {
        const response = await api.post(
            '/customer/register', { ...data, idcompany }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    editCustomer: async (id: number, data: any) => {
        const response = await api.patch(
            `/customer/${id}`,
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    deleteCustomer: async (id: number) => {
        const response = await api.delete(
            `/customer/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    }
}