import { api } from "./configs/axiosConfigs";

export const BossMaintenanceAPI = {
    getBoss: async (data?: any) => {
        const response = await api
            .get("/boss", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createBoss: async (data: any, idcompany: number) => {
        const response = await api
            .post("boss/register", { ...data, idcompany })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editBoss: async (id: number, data: any, idcompany: number) => {
        const response = await api
            .patch(`/boss/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteBoss: async (id: number, idcompany: number) => {
        const response = await api
            .delete(`/boss/${id}`, {
                params: {
                    idcompany
                }
            })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}