import { api } from "./configs/axiosConfigs";

export const TrademarkVehicleAPI = {
    getTrademarkVehicle: async (data?: any) => {
        const response = await api
            .get('/trademarkVehicle', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    createTrademarkVehicle: async (data: any) => {
        const response = await api
            .post('/trademarkVehicle/register', { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    editTrademarkVehicle: async (id: number, data: any) => {
        const response = await api
            .patch(`/trademarkVehicle/${id}`, { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    deleteTrademarkVehicle: async (id: number) => {
        const response = await api
            .delete(`/trademarkVehicle/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    }
};