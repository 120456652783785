import React from 'react'
import { MuiTelInput } from 'mui-tel-input'
import { countrySelect } from "../../../toolbox/constants/country-select";

export const CustomPhoneInput = ({ value, setting, errors, touched }) => {
    const handleChange = (newValue: any) => {
        setting("phone", newValue)
    }
    return (
        <MuiTelInput fullWidth size="small" onlyCountries={countrySelect} value={value} disableFormatting defaultCountry="PE" forceCallingCode focusOnSelectCountry onChange={handleChange} InputProps={{
            inputProps: { maxLength: 10 },
        }} error={
            errors.phone && touched.phone
                ? true
                : false
        } helperText={
            errors.phone && touched.phone
                ? errors.phone
                : ""
        } />
    )
};
