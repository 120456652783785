import { Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import FilterReport from "./FilterReport";
import { useRepairRequest } from "../../hooks/useRepairRequest";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { RepairRequest } from "../../types/slices/repairRequestType";

const columnsGroupVehicle = [
    { type: "text", field: "patent", label: "VEHÍCULO", align: 'center' },
    { type: "text", field: "aceptado", label: "ACEPTADAS", align: 'center', color: (row) => '#2FB449' },
    { type: "text", field: "pendiente", label: "PENDIENTES", align: 'center', color: (row) => '#E98116'  },
    { type: "text", field: "eliminado", label: "ELIMINADAS", align: 'center',  color: (row) => 'red' },
    { type: "text", field: "", label: "", align: 'center' }
]

const columnsGroupByDriver = [
    { type: "text", field: "name_driver", label: "CONDUCTOR", align: 'center' },
    { type: "text", field: "aceptado", label: "ACEPTADAS", align: 'center', color: (row) => '#2FB449' },
    { type: "text", field: "pendiente", label: "PENDIENTES", align: 'center', color: (row) => '#E98116'  },
    { type: "text", field: "eliminado", label: "ELIMINADAS", align: 'center',  color: (row) => 'red' },
    { type: "text", field: "", label: "", align: 'center' }
]

const columsDetail = [
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'model_vehicle', label: 'MODELO', align: 'center' },
    { type: 'text', field: 'trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'rut_driver', label: 'RUT DE CONDUCTOR', align: 'center' },
    // { type: 'text', field: 'driver', label: 'CONDUCTOR', align: 'center', format: (row: RepairRequest) => `${row.name_driver} ${row.surname_driver}` },
    { type: 'text', field: 'fail_name', label: 'MOTIVO', align: 'center', format: (row: RepairRequest) => row.name_fails_resource?.map(element => element.name).join(' - ') },
    { type: 'text', field: 'name_status_request', label: 'ESTADO', align: 'center' },
    { type: "text", field: "", label: "", align: 'center' }
]


export const ReportRepairRequest:FC = () => {
    const { getReportRepairRequest } = useRepairRequest()

    const [loading, setLoading] = useState<boolean>(false)
    const [isResponsive, setIsResponsive] = useState<boolean>(window.innerWidth <= 760)
    const [rowSelected, setRowSelected] = useState<any>(null)
    const [paramsFilter, setParamsFilter ] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [dataDetail, setDataDetail] = useState<any>([])

    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page2, setPage2] = useState<number>(0)
    const [rowsPerPage2, setRowsPerPage2] = useState<number>(10)

    const getReport = async (params) => {
        setParamsFilter(params)
        const response = await getReportRepairRequest(params)
        setData(response)
        if(response.length == 1) onRowClick(response[0])
    }

    const onRowClick = async(row) => {
        setRowSelected(row)
        setDataDetail(row.detail)
    }

    const clearTables = () => {
        setData([])
        setDataDetail([])
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
 
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage)
    }
 
    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(+event.target.value)
        setPage2(0)
    }

    return (
        <Grid item xs={12}>
            <Grid item container 
                sx={{
                   alignItems: "center",
                   display: "flex",
                   justifyContent: "center",
                   flexWrap: "wrap",
                   marginBottom: "-5px",
                }}
            >
                <Typography variant="h6"
                    component="h1"
                    gutterBottom
                    color="#808080"
                    className="tittle"
                    sx={{ mt: 1, ml: 1, fontWeight: 600 }}>REPORTE DE SOLICITUDES DE REPARACIÓN</Typography>
            </Grid>
            <Grid item container xs={12} 
                sx={{
                   alignItems: "center",
                   display: "flex",
                   justifyContent: "center",
                   flexWrap: "wrap",
                   marginBottom: !isResponsive ? "10px" : "30px",
                }}>
                    <FilterReport loading={setLoading} getReport={getReport} setRowSelected={setRowSelected} typeGroups={[1,3]} clearTables={() => clearTables()}/>
            </Grid>
            {
                !!paramsFilter &&
                <CustomTable
                   columns={paramsFilter?.groupBy == 1 ? columnsGroupVehicle : columnsGroupByDriver}
                   loading={loading}
                   rows={data || []}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   handleChangePage={handleChangePage}
                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                   onRowClick={() => {}}
               />
            }
            {
                data.length > 0 && 
                <Grid container mt={5}>
                    <CustomTable
                        title={!!rowSelected?.patent ? 'Detalle de ' + rowSelected?.patent : 'Detalle de ' + rowSelected?.name_driver}
                        columns={columsDetail}
                        loading={loading}
                        rows={dataDetail || []}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage2}
                        handleChangeRowsPerPage={handleChangeRowsPerPage2}
                        onRowClick={() => { }}
                    />
                </Grid>
            }
        </Grid>
    )
} 