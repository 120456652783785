import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { ControlVerificationCategory, ControlVerificationCategoryType } from "../../types/slices/controlVerificationCategoryType";
import { RootState } from "../store";

const initialState: ControlVerificationCategoryType = {
    status: ApiStatus.FETCHED,
    controlVerificationsCategory: [],
    errorMessage: undefined,
    editControlVerificationCategory: {} as ControlVerificationCategory,
}

const ControlVerificationCategorySlice = createSlice({
    name: 'ControlVerificationCategory',
    initialState,
    reducers: {
        onFetchControlVerificationsCategory (state, { payload }: { payload: ControlVerificationCategory[] }) {
            state.status            = ApiStatus.FETCHED
            state.controlVerificationsCategory = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditControlVerificationCategory (state, { payload }: { payload: ControlVerificationCategory }) {
            state.editControlVerificationCategory = payload
        }
    }
})

export const selectControlVerificationCategoryState = (state:RootState) => state.ControlVerificationCategorySlice
export default ControlVerificationCategorySlice.reducer

export const {
    onFetchControlVerificationsCategory,
    changeStatus,
    onSetEditControlVerificationCategory
} = ControlVerificationCategorySlice.actions