import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Modal } from "../Modal/Modal";
import { ModalBody } from "../Modal/ModalBody";
import { ModalHeader } from "../Modal/ModalHeader";
import { withStyles } from "@mui/styles";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ApiStatus } from "../../../types/api/status";
import { useUserStore } from "../../../hooks/useUserStore";

const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            // borderBottomColor: pColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e3e1e1',
            },
            '&:hover fieldset': {
                borderColor: '#e3e1e1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fafafa',
            },
        },
    },
})(TextField);

export const ModalUpdatePassword: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, logout } = props;

    const { status: statusUser, changePasswordUser } = useUserStore()

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [errors, setErrors] = useState({});

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);

    const onChangeOldPassword = (e) => {
        const oldPassword = e.target.value;
        setOldPassword(oldPassword);
    };

    const onChangeNewPassword = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);
    };

    const onChangeVerifyPassword = (e) => {
        const verifyPassword = e.target.value;
        setVerifyPassword(verifyPassword);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const data = {
            password: oldPassword,
            new_password: newPassword,
            status_erp: 0
        }

        if (handleValidation()) {
            const response = await changePasswordUser(data)
            if (response === true) {
                closeModal && closeModal();
                logout && logout();
            } else {
                return false;
            }
        }
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        //Password
        if (!oldPassword) {
            formIsValid = false;
            errors["oldPassword"] = 'contraseña requerida';
        }

        //new Password
        if (!newPassword) {
            formIsValid = false;
            errors["newPassword"] = 'contraseña requerida';
        }

        if (!verifyPassword) {
            formIsValid = false;
            errors["verifyPassword"] = 'contraseña requerida';
        }
        if (newPassword !== verifyPassword) {
            formIsValid = false;
            errors["verifyPassword"] = 'las contraseñas no coinciden';
        }
        setErrors(errors);
        return formIsValid;
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalHeader
                text={'Cambiar contraseña'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Grid container>
                    <Grid item xs={12} style={{ margin: '15px 0px' }}>
                        <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                            <CssTextField
                                style={{ width: '350px' }}
                                size='small'
                                id="oldPassword"
                                placeholder={'Ingrese contraseña actual'}
                                value={oldPassword}
                                required
                                fullWidth
                                autoComplete="new-password"
                                type={showPassword ? "text" : "password"}
                                onChange={onChangeOldPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { setShowPassword(!showPassword) }}
                                                edge="end"
                                                style={{ color: 'rgb(76, 148, 182)' }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            {errors["oldPassword"]}
                        </span>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '15px 0px' }}>
                        <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                            <CssTextField
                                style={{ width: '350px' }}
                                size='small'
                                id="newPassword"
                                placeholder={'Ingrese la nueva contraseña'}
                                value={newPassword}
                                required
                                fullWidth
                                autoComplete="new-password"
                                type={showNewPassword ? "text" : "password"}
                                onChange={onChangeNewPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { setShowNewPassword(!showNewPassword) }}
                                                edge="end"
                                                style={{ color: 'rgb(76, 148, 182)' }}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                        }}>{errors["newPassword"]}</span>
                    </Grid>

                    <Grid item xs={12} style={{ margin: '15px 0px' }}>
                        <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                            <CssTextField
                                style={{ width: '350px' }}
                                size='small'
                                id="verifyNewPassword"
                                placeholder={'vuelva a ingresar la nueva contraseña'}
                                value={verifyPassword}
                                required
                                fullWidth
                                autoComplete="new-password"
                                type={showVerifyPassword ? "text" : "password"}
                                onChange={onChangeVerifyPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { setShowVerifyPassword(!showVerifyPassword) }}
                                                edge="end"
                                                style={{ color: 'rgb(76, 148, 182)' }}
                                            >
                                                {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                        }}>{errors["verifyPassword"]}</span>
                    </Grid>
                    <div style={{ borderTop: "1px solid #e3e1e1", width: "100%", paddingTop: '15px', justifyContent: 'end', textAlign: 'end' }}>
                        {
                            statusUser === ApiStatus.FETCHING ? (
                                <Button onClick={(e) => { }} size="small" color={"primary"} variant="contained">
                                    {'Cambiar contraseña'}
                                    <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                                </Button>
                            ) : (
                                <Button onClick={(e) => { handleLogin(e) }} size="small" color={"primary"} variant="contained">
                                    {'Cambiar contraseña'}
                                </Button>
                            )
                        }
                    </div>
                </Grid>
            </ModalBody>
        </Modal>
    )
}