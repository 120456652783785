import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchAdministrators, onSetSelectedAdministrator, selectAdministratorState } from "../redux/slices/administratorSlice"
import { ApiStatus } from "../types/api/status"
import { AdministratorAPI } from "../apis/AdministratorAPI"
import { Administrator } from "../types/slices/administratorType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { toast } from "react-hot-toast"

export const useAdministratorStore = () => {
    const dispatch = useDispatch()
    const { administrators, status, selectedAdministrator } = useSelector(selectAdministratorState)

    const getAdministrators = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.getAdministrators(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchAdministrators(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createAdmin = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editAdmin = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.edit(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteAdmin = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedAdmin = (admin: Administrator) => {
        try {
            dispatch(onSetSelectedAdministrator(admin))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        administrators,
        status,
        selectedAdministrator,
        //actions
        getAdministrators,
        createAdmin,
        editAdmin,
        deleteAdmin,
        setSelectedAdmin
    }
}