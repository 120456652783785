import { FC, useEffect, useState } from "react";
import { usePatternRStore } from "../../hooks/usePatternRStore";
import { PatternR } from "../../types/slices/patternRType";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { PTRSignatureModal } from "./PTRSignatureModal";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const PTRSignature: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const params = useParams()
    const { id } = params
    const { status: patternStatus, getPatternRPDF, getOnePatternR, setSelectedPatternR } = usePatternRStore()
    const [pdf, setPDF] = useState<any>(null)
    const [idpatternR, setIdpatternR] = useState<number | undefined>(0)
    const [showModalFirm, setShowModalFirm] = useState<boolean>(false)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [signed, setSigned] = useState<boolean>(false)
    const userRole: Role | any = user.userType;

    useEffect(() => {
        setLocalData()
    }, [])

    const setLocalData = async () => {
        const idpattern: any = id
        setIdpatternR(idpattern)
        const patternR: PatternR = await getOnePatternR(idpattern)
        setSelectedPatternR(patternR)
        if (patternR && Object.entries(patternR).length > 0) {
            if (userRole === Role.MAINTENANCE && patternR.signingRepairRequestD1[0].idsignings_boss_maintenance !== null) {
                setSigned(true)
            } else if (userRole === Role.MECHANIC && patternR.signingRepairRequestD1[0].idsignings_mechanic !== null) {
                setSigned(true)
            }
            await showPDF(idpattern)
        }
    }

    const showPDF = async (idpatternR: number) => {
        const pdf = await getPatternRPDF(idpatternR)
        const blob = new Blob([pdf], { type: 'application/pdf' })
        const bloblURL = URL.createObjectURL(blob)
        setPDF(bloblURL)
    }

    const handleBack = () => {
        if (userRole === Role.MECHANIC && !signed) {
            setShowModalConfirm(true)
        } else {
            navigate({ pathname: RoutesMap.PT_R_LIST })
        }
    }

    const onConfirmBack = async () => {
        navigate({ pathname: RoutesMap.PT_R_LIST })
    }

    const modalSignature = () => {
        setShowModalFirm(true)
    }

    return (
        <Grid container>
            {patternStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft: '-25px' }}>
                <IconButton size="small" color="primary" aria-label="view" onClick={() => handleBack()}>
                    <ArrowBackIcon fontSize='small' />
                </IconButton>
                <Typography>Volver</Typography>
            </Grid>
            <Grid item container sx={{ alignItems: "center", display: "flex", justifyContent: "center", flexWrap: "wrap", marginBottom: "10px" }}>
                <Typography variant="h6" component="h1" gutterBottom color="#808080" className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                    PAUTA DE REVISIÓN
                </Typography>
            </Grid>
            {
                !signed && (userRole === Role.MECHANIC || userRole === Role.MAINTENANCE) && (
                    <Grid item xs={12} sx={{ textAlign: "right" }}>
                        <Button
                            onClick={() => modalSignature()}
                            sx={{ border: "solid 1px #73B2FF", m: "10px", color: "#73B2FF", width: '150px', "&:hover": { bgcolor: "#73B2FF", color: "#fff" } }}
                        >
                            FIRMAR
                        </Button>
                    </Grid>
                )
            }
            <Grid container style={{ overflow: 'hidden !important', height: '100%', alignItems: 'center', textAlign: 'center' }}>
                <iframe title="pdfVer" src={pdf} id="pdfVer" height="1000px" width="100%"></iframe>
            </Grid>
            {
                showModalFirm && (
                    <PTRSignatureModal
                        open={showModalFirm}
                        closeModal={() => setShowModalFirm(false)}
                        idpatternR={idpatternR}
                    />
                )
            }
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onConfirmBack}
                        title="¿Está seguro de salir?"
                        text="La pauta de revisión aún no ha sido firmada."
                    />
                )
            }
        </Grid>
    )
}