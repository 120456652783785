import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { TrademarkVehicle, TrademarkVehicleType } from "../../types/slices/trademarkVehicleType";

const initialState: TrademarkVehicleType = {
    status: ApiStatus.FETCHED,
    trademarkVehicles: [],
    errorMessage: undefined,
    editTrademarkVehicle: {} as TrademarkVehicle,
    selectedTrademarkVehicle: {} as TrademarkVehicle,
};

const trademarkVehicleSlice = createSlice({
    name: "trademarkVehicle",
    initialState,
    reducers: {
        onFetchTrademarkVehicle(state, { payload }: { payload: TrademarkVehicle[] }) {
            state.status = ApiStatus.FETCHED;
            state.trademarkVehicles = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditTrademarkVehicle(state, { payload }: { payload: TrademarkVehicle }) {
            state.editTrademarkVehicle = payload;
        },
        onSetSelectedTrademarkVehicle(state, { payload }: { payload: TrademarkVehicle }) {
            state.selectedTrademarkVehicle = payload;
        },
    }
});

export const selectTrademarkVehicle = (state: RootState) => state.trademarkVehicleSlice;
export default trademarkVehicleSlice.reducer;

export const { onFetchTrademarkVehicle, changeStatus, onSetEditTrademarkVehicle, onSetSelectedTrademarkVehicle } = trademarkVehicleSlice.actions