import React, { useEffect } from 'react';
import { Box, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { decode } from 'base-64';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { useAuthStore } from '../../hooks';
import logo from '../../assets/image/logo_segundo.png';
import { RoutesMap } from '../../types';

const AutoLogin = () => {
    const { login, loginERP } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { rut, password, iduser_type, idcompany, rut_company, redirect, idcharge_erp } = Object.fromEntries(searchParams.entries());

    useEffect(() => {
        if (idcharge_erp) {
            loginFunctionERP()
        } else {
            loginFunctionEmail();
        }
    }, []);

    const isValidate = (str) => {
        try {
            decode(str);
            return true;
        } catch (error) {
            return false;
        }
    };

    const decodedRut = isValidate(rut) ? decode(rut) : '';
    const decodedPassword = isValidate(password) ? decode(password) : '';
    const decodedRutCompany = isValidate(rut_company) ? decode(rut_company) : '';
    const decodedRedirect = isValidate(redirect) ? decode(redirect) : '';

    const loginFunctionEmail = async () => {
        try {
            if (!rut || !password || !iduser_type || !redirect) {
                return CustomSnackbar('error', 'Parámetros incompletos') && navigate({ pathname: RoutesMap.LOGIN })
            }

            if (idcompany) {
                const success: any = await login({
                    iduser_type: Number(iduser_type),
                    password: decodedPassword,
                    rut: decodedRut,
                    idcompany,
                });
                let status = success?.detail?.status_confirm;

                if (success?.status && status === "0") {
                    navigate({ pathname: RoutesMap.CHANGE_PASSWORD })
                } else if (success?.status && status === "1") {
                    navigate(decodedRedirect)
                }
            } else if (rut_company) {
                const success: any = await login({
                    iduser_type: Number(iduser_type),
                    password: decodedPassword,
                    rut: decodedRut,
                    rut_company: decodedRutCompany,
                });
                let status = success?.detail?.status_confirm;
                if (success?.status && status === "0") {
                    navigate({ pathname: RoutesMap.CHANGE_PASSWORD })
                } else if (success?.status && status === "1") {
                    navigate(decodedRedirect)
                }
            }
        } catch (error) {
            CustomSnackbar('error', 'Parámetros incompletos');
            console.log(error);
        }
    };

    const loginFunctionERP = async () => {
        try {
            if (!rut || !password || !idcharge_erp || !redirect) {
                return CustomSnackbar('error', 'Parámetros incompletos') && navigate({ pathname: RoutesMap.LOGIN })
            }

            if (idcompany) {
                const success: any = await loginERP({
                    rut: decodedRut,
                    password: decodedPassword,
                    idcharge_erp,
                    idcompany,
                });
                let status = success?.detail?.status_confirm;

                if (success?.status && status === "0") {
                    navigate({ pathname: RoutesMap.CHANGE_PASSWORD })
                } else if (success?.status && status === "1") {
                    navigate(decodedRedirect)
                }
            } else if (rut_company) {
                const success: any = await loginERP({
                    rut: decodedRut,
                    password: decodedPassword,
                    idcharge_erp,
                    rut_company: decodedRutCompany,
                });
                let status = success?.detail?.status_confirm;
                if (success?.status && status === "0") {
                    navigate({ pathname: RoutesMap.CHANGE_PASSWORD })
                } else if (success?.status && status === "1") {
                    navigate(decodedRedirect)
                }
            }
        } catch (error) {
            CustomSnackbar('error', 'Parámetros incompletos');
            console.log(error);
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Paper elevation={5} sx={{ borderRadius: '16px', backgroundColor: '#212D39', width: '50%' }}>
                <Grid container sx={{ margin: '20px auto', minWidth: '200px', padding: '15px' }}>
                    <Grid item xs={12} display="flex" alignContent="center" justifyContent="center" textAlign="center">
                        <Stack direction="row" justifyContent="start">
                            <Typography variant='body1' color='#fff' sx={{ fontWeight: 600, margin: '0px auto' }}>¡BIENVENIDO!</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} display="flex" alignContent="center" justifyContent="center" textAlign="center" marginTop={2}>
                        <img style={{ width: '100px' }} src={logo} alt="Logo" />
                    </Grid>
                    <Grid item xs={12} display="flex" alignContent="center" justifyContent="center" textAlign="center" marginTop={3}>
                        <Stack direction="row" justifyContent="start">
                            <Typography variant='body1' color='#fff' sx={{ fontWeight: 600, margin: '0px auto' }}>Iniciando sesión...</Typography>
                        </Stack>
                    </Grid>
                    <Box sx={{ width: '100%' }} marginTop={2}>
                        <LinearProgress />
                    </Box>
                </Grid>
            </Paper>
        </Box>
    );
};

export default AutoLogin;
