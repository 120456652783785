import { useDispatch, useSelector } from "react-redux";
import { changeStatus, onFetchMaintenanceType, onSetSelectedMaintenanceType, selectMaintenanceTypesState } from "../redux/slices/maintenanceTypeSlice";
import { ApiStatus } from "../types/api/status"
import { MaintenanceTypeAPI } from "../apis/MaintenanceTypeAPI";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Maintenance } from "../types/slices/maintenanceType";

export const useMaintenanceTypeStore = () => {
    const dispatch = useDispatch()
    const { maintenanceTypes, status, selectedMaintenanceType } = useSelector(selectMaintenanceTypesState)

    const getMaintenanceTypes = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceTypeAPI.get(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { message } = response.data
            dispatch(onFetchMaintenanceType(message))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const createMaintenanceTypes = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceTypeAPI.create(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true;
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const editMaintenanceTypes = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceTypeAPI.edit(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const deleteMaintenanceTypes = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceTypeAPI.delete(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const setSelectedMaintenanceTypes = (maintenance: Maintenance) => {
        try {
            dispatch(onSetSelectedMaintenanceType(maintenance))
        } catch (error) {
            console.log(error)
        }
    };

    return {
        //states
        maintenanceTypes,
        status,
        selectedMaintenanceType,
        //actions
        getMaintenanceTypes,
        createMaintenanceTypes,
        editMaintenanceTypes,
        deleteMaintenanceTypes,
        setSelectedMaintenanceTypes,
    }
}