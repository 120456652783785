import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchPatternR, onSetSelectedPatternR, selectPatternRState } from "../redux/slices/patternRSlice"
import { ApiStatus } from "../types/api/status"
import { PatternRAPI } from "../apis/PatternRAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { PatternR } from "../types/slices/patternRType"

export const usePatternRStore = () => {
    const dispatch = useDispatch()
    const { patternRs, status, selectedPatternR } = useSelector(selectPatternRState)
    const idcompany = 1

    const getPatternRs = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternRAPI.get(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchPatternR(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getOnePatternR = async (id:number|string) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await PatternRAPI.getOne(id)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          const { detail } = response.data
          dispatch(changeStatus(ApiStatus.FETCHED))
          return detail[0]
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const getReportPatternR = async (params) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await PatternRAPI.getReport(params)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        const { detail } = response.data
        dispatch(changeStatus(ApiStatus.FETCHED))
        return detail
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
}


const getReportPatternRDetail = async (params) => {
  try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PatternRAPI.getReportDetail(params)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(changeStatus(ApiStatus.FETCHED))
      return detail
  } catch (error) {
    console.log(error)
    return dispatch(changeStatus(ApiStatus.FETCHED))
  }
}

    const createPatternR = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternRAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return response.data.detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editPatternR = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternRAPI.edit(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deletePatternR = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await PatternRAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedPatternR = (patternR: PatternR) => {
        try {
            dispatch(onSetSelectedPatternR(patternR))
        } catch (error) {
            console.log(error)
        }
    }

    const getPatternRPDF = async (id:number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await PatternRAPI.getPatternRPDF(id, idcompany)
        if (!response?.status) {           
          dispatch(changeStatus(ApiStatus.FETCHED))     
          CustomSnackbar('error', response.data.message)
          return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        return response.data
      } catch (error) {
        console.log(error)
      }
    }

    const signature = async (id:number, data:any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await PatternRAPI.signature(id, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    return {
        //states
        patternRs,
        status,
        selectedPatternR,
        //actions
        getPatternRs,
        getReportPatternR,
        getReportPatternRDetail,
        createPatternR,
        editPatternR,
        deletePatternR,
        setSelectedPatternR,
        getPatternRPDF,
        signature,
        getOnePatternR
    }
}