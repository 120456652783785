import { Box, Chip, Divider, Drawer } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { useAuthStore } from '../../../hooks';
import useResponsive from '../../../hooks/useResponsive';
import { useDrawerStore } from '../../../hooks/useDrawerStore';
import { NavSection } from './NavSection';
import { HeaderView } from '../Header/Header';
import logonav from '../../../assets/image/logo_segundo.png'
import { useNavSectionStore } from '../../../hooks/useNavSectionStore';

const themeAvenir = createTheme({
    typography: {
        fontFamily: 'Avenir',
        fontSize: 15
    },
})
// ----------------------------------------------------------------------
const NAV_WIDTH = 280
const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: 'rgb(230, 247, 255)'
}))
// ----------------------------------------------------------------------

//   Nav.propTypes = {
//     openNav: PropTypes.bool,
//     onCloseNav: PropTypes.func,
//   }

export const Nav: React.FC<any> = ({ openNav, onCloseNav }): JSX.Element | any => {
    const { user } = useAuthStore();

    const isDesktop = useResponsive('up', 'lg', '');
    const { drawerOpen, openDrawer } = useDrawerStore();
    const { navOpen } = useNavSectionStore()
    const { pathname } = useLocation();

    useEffect(() => {
        if (openNav) {
            onCloseNav()
        }
    }, [pathname])

    const withNavOpen = navOpen ? NAV_WIDTH : 0

    const renderContent = (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF', padding: '10px' }}>
                <img style={{ width: '100px' }} src={logonav} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ThemeProvider theme={themeAvenir}>
                    <Chip size="medium" label={user.userType} variant="outlined" sx={{ background: '#212D39', color: '#FFFFFF', fontSize: '16px' }} />
                </ThemeProvider>
            </Box>
            <NavSection />
            <Box sx={{ flexGrow: 1 }} />
        </>
    )

    return (
        <Box component="nav" sx={{ flexShrink: { lg: 0 }, width: { lg: withNavOpen } }}>

            <HeaderView />

            {
                isDesktop ? (
                    navOpen ?
                        <Drawer open variant="permanent"
                            PaperProps={{ sx: { width: withNavOpen, bgcolor: 'background.default', borderRightStyle: 'dashed' } }}
                        >
                            {renderContent}
                        </Drawer>
                        :
                        null
                ) : (
                    <Drawer open={drawerOpen} onClose={() => openDrawer(false)} ModalProps={{ keepMounted: true }}
                        PaperProps={{ sx: { width: NAV_WIDTH } }}
                    >
                        {renderContent}
                    </Drawer>
                )
            }
        </Box>
    )
}