import { Autocomplete, Button, Checkbox, FormLabel, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import React, { ChangeEvent, useEffect, useState } from 'react'
import { NameFail } from '../../types/slices/nameFailType';
import { useNameFails } from '../../hooks/useNameFails';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { StyledTableCell } from '../../toolbox/helpers/accordion-styled';
import { ImageFile } from '../../types/slices/technicalreportType';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RepairRequestImages } from '../RepairRequest/RepairRequestImages';
import { RoutesMap } from '../../types';
import { useRepairRequest } from '../../hooks/useRepairRequest';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../components/common/Modal/Modal';
import { ModalBody } from '../../components/common/Modal/ModalBody';
import { useCustomerStore } from '../../hooks/useCustomerStore';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import { ApiStatus } from '../../types/api/status';

type customerData = {
    id: number;
    idcompany: number;
}

const PublicRepairRequest = () => {
    const [selectedNameFails, setSelectedNameFails] = useState<NameFail[]>([]);
    const [description, setDescription] = useState<string>('')
    const [images, setImages] = useState<ImageFile[]>([]);
    const [showModalImage, setShowModalImage] = useState<boolean>(false)
    const [showModalCustomer, setShowModalCustomer] = useState<boolean>(true)
    const [customerRut, setCustomerRut] = useState<string>('')
    const [customerInfo, setCustomerInfo] = useState<customerData>({
        id: 1,
        idcompany: 1
    })

    const [loading, setLoading] = useState<boolean>(false);
    const { nameFails, getNameFailStore } = useNameFails()
    const { createRepairRequestStore, status } = useRepairRequest()
    const { getCustomerStore, customers } = useCustomerStore()
    const navigate = useNavigate()

    useEffect(() => {
        infoApi()
    }, [])

    const infoApi = async () => {
        try {
            setLoading(true)
            await getNameFailStore({ company: 1 })
            await getCustomerStore({ idcompany: 1 })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDescription(value)
    }

    const deleteImageTable = (n: number) => {
        const newImages = images.filter(image => image.n !== n)
        if (!newImages) return
        setImages(newImages)
    }

    const showImage = (n: number) => {
        const imageToShow: any = images.find(image => image.n === n)
        if (!imageToShow) return
        if (imageToShow.id == null) {
            const imageUrl = URL.createObjectURL(imageToShow.image)
            window.open(imageUrl, '_blank')
        } else {
            const url = imageToShow.image
            const win: any = window.open(`${process.env.REACT_APP_ROOT_URL}/` + url, '_blank')
            win.focus()
        }
    }

    const validateUser = () => {
        // if (customerRut.length === 0) CustomSnackbar("warning", "Ingrese un rut de cliente")
        const customer = customers?.find((custom) => custom.rut === customerRut)
        if (customer) {
            setCustomerInfo({
                id: customer?.id,
                idcompany: customer?.idcompany
            })
            setShowModalCustomer(false)
            CustomSnackbar("success", "Cliente validado correctamente")
        } else {
            CustomSnackbar("error", "El rut ingresado no es válido")
        }
    }

    const onSubmit = async () => {
        const formData = new FormData()
        selectedNameFails.forEach((fail, index) => {
            formData.append(`name_fails[${index}]`, fail.id.toString())
        })
        formData.append('description', description)
        formData.append('idcustomer', customerInfo.id.toString())
        images?.forEach((image, index) => {
            formData.append(`images[${index}][id]`, image.id?.toString() || '')
            formData.append(`images[${index}][image]`, image.image)
            formData.append(`images[${index}][file_name]`, image.file_name)
            formData.append(`images[${index}][comment]`, image.comment)
        });
        formData.append('num_images', images?.length.toString())
        formData.append('idcompany', customerInfo.idcompany.toString())
        const createRepairRequest = await createRepairRequestStore(formData)
        if (createRepairRequest === true) navigate({ pathname: RoutesMap.LOGIN })
    }

    return (
        <>
            {loading && <CustomBackdrop open={true} />}
            {status === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            <div style={{ height: '100vh', width: '100vw', overflowY: 'auto' }}>
                <div style={{ width: '90vw', margin: '20px auto 20px auto', border: "1px solid #9E9E9F", borderRadius: '25px', display: 'flex', flexDirection: 'column', }}>
                    <div style={{ marginLeft: '25px', marginBottom: '-35px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h1 style={{ fontWeight: 'bold', maxWidth: '500px' }}>Solicitud De Reparación</h1>
                        <Button size='large' style={{ width: '90px', height: '50px', marginLeft: 'auto', marginRight: '25px', fontWeight: 'bold' }} variant="text" onClick={() => onSubmit()}>GENERAR</Button>
                        <Button size='large' variant="text" style={{ width: '90px', height: '50px', marginRight: '25px', fontWeight: 'bold', color: 'red' }} onClick={() => navigate({ pathname: RoutesMap.LOGIN })}>Cancelar</Button>
                    </div>
                    <div style={{ width: '100%', padding: '25px', marginBottom: '-35px' }}>
                        <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>Motivo de la solicitud:</FormLabel>
                        <Autocomplete
                            multiple={true}
                            disablePortal
                            id="combo-box-fail"
                            value={selectedNameFails || []}
                            options={nameFails || []}
                            getOptionLabel={(option) => option?.name || ""}
                            fullWidth
                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                            renderInput={
                                (params) => <TextField {...params} size="small" />
                            }
                            onChange={(e, newValue) => {
                                setSelectedNameFails(newValue);
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li key={option.id} value={option.id} {...props} >
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            )}
                            filterSelectedOptions
                        />
                    </div>
                    <div style={{ width: '100%', padding: '25px' }}>
                        <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>Descripción de la solicitud:</FormLabel>
                        <TextField type='text' id='description' name='description' value={description} onChange={handleChangeDescription} style={{ width: '100%' }} multiline rows={4} />
                    </div>
                    <div style={{ width: '95%', padding: '15px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: '#DFF9FC', borderRadius: '15px', fontWeight: 'bold', color: '#0093F4', cursor: 'pointer', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} onClick={() => setShowModalImage(true)}>
                        <UnarchiveIcon fontSize='large' style={{ margin: 'auto', marginBottom: '10px' }} />
                        <p style={{ margin: 'auto' }}>Haga click para adjuntar imágenes</p>
                    </div>
                    <div style={{ width: '100%', padding: '25px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ background: "#D8E8F7" }}>
                                    <TableRow>
                                        <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                        <StyledTableCell className="headTable" align="center" colSpan={1}>Nombre de la imagen</StyledTableCell>
                                        <StyledTableCell className="headTable" align="center" colSpan={1}>Comentario</StyledTableCell>
                                        <StyledTableCell className="headTable" align="center" colSpan={1}>Opciones</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        images.length > 0
                                            ?
                                            images.map((row, index) =>
                                            (<TableRow key={index}>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center">{row.file_name}</TableCell>
                                                <TableCell align="center">{row.comment}</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Eliminar">
                                                        <IconButton size="small" color="inherit"
                                                            onClick={() => deleteImageTable(row.n)}
                                                        >
                                                            <DeleteIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Ver">
                                                        <IconButton size="small" color="inherit"
                                                            onClick={() => showImage(row.n)}
                                                        >
                                                            <VisibilityIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>)
                                            )
                                            :
                                            (<TableRow>
                                                <TableCell colSpan={7}>
                                                    <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                        No ha agregado alguna imagen
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {
                        showModalImage && <RepairRequestImages
                            open={showModalImage}
                            closeModal={() => setShowModalImage(false)}
                            images={images}
                            setImages={setImages}
                        />
                    }
                    {
                        !loading && showModalCustomer && (
                            <Modal open={showModalCustomer} disableEscapeKeyDown disableBackdropClick size="sm">
                                <ModalBody>
                                    <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder', marginBottom: '20px' }}>
                                        VALIDACIÓN DE CLIENTE
                                    </Typography>
                                    <Grid item xs={12}>
                                        <FormLabel>Ingrese el rut del cliente:</FormLabel>
                                        <TextField
                                            id="comment"
                                            type="text"
                                            name="comment"
                                            fullWidth
                                            size="small"
                                            value={customerRut || ''}
                                            onChange={(e) => setCustomerRut(e.target.value)}
                                            inputProps={{ maxLength: 10 }}
                                        />
                                    </Grid>
                                    <Grid container sx={{ marginTop: '20px' }}>
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                onClick={() => validateUser()}
                                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                                            >
                                                Confirmar
                                            </Button>
                                            <Button
                                                onClick={() => navigate({ pathname: RoutesMap.LOGIN })}
                                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                                            >
                                                Cancelar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ModalBody>
                            </Modal>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default PublicRepairRequest