import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Divider, FormLabel, Grid, MenuItem, Select, TextField, Autocomplete, Checkbox } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useMainStagesWorksStore } from "../../hooks/useMainStagesWorks";
import { useActivities } from "../../hooks/useActivities";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";
import { WorkDone } from "../../types/slices/workDondeType";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

type FormData = {
    actions: string;
}

const MainStagesWorksForm: FC = () => {
    const navigate = useNavigate()
    const { status, selectedMainStageWork, createMainStagesWorksStore, editMainStagesWorksStore } = useMainStagesWorksStore()
    const { getWorkDones, workDones } = useWorkDoneStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<FormData>({
        actions: ''
    })
    const [selectedWorksDone, setSelectedWorksDone] = useState<WorkDone[]>([])

    useEffect(() => {
        getWorkDonesApi()
        if (Object.entries(selectedMainStageWork).length > 0) {
            setIsEdit(true)
            setData({
                actions: selectedMainStageWork.actions
            })
        }
    }, [])

    useEffect(()=> {
        if (Object.entries(selectedMainStageWork).length > 0) {
            const works = workDones.filter(work => selectedMainStageWork.a_id_work_dones.includes(work.id))
            setSelectedWorksDone(works || [])
        }
    },[workDones])

    const getWorkDonesApi = async () => {
        try {
            setLoading(true)
            await getWorkDones()
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.actions) errors.actions = "La acción es requerida";
        return errors;
    }

    const onSubmit = async (values) => {
        const a_idworkDone:number[] = selectedWorksDone.map(work => work.id)

        const payload = {
            actions: values.actions,
            a_idwork_done: a_idworkDone
        }

        const method = !isEdit ? createMainStagesWorksStore(payload) : editMainStagesWorksStore(selectedMainStageWork.id, payload)
        const response = await method
        if (response === true) {
            navigate({ pathname: RoutesMap.MAIN_STAGES_WORKS })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.MAIN_STAGES_WORKS })
    }
    return (
        <CustomForm>
            {loading && <CustomBackdrop open={true} />}
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nueva etapa principal de trabajo' : 'Editar etapa principal de trabajo'}
                text={!isEdit ? 'Ingrese los datos de la nueva etapa principal de trabajo' : 'Ingrese los datos de la etapa principal de trabajo'}
                goBack={RoutesMap.MAIN_STAGES_WORKS}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="actions"
                                                type="text"
                                                name="actions"
                                                fullWidth
                                                size="small"
                                                value={values.actions}
                                                onChange={handleChange}
                                                error={errors.actions && touched.actions ? true : false}
                                                helperText={errors.actions && touched.actions ? errors.actions : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Trabajo asociado:</FormLabel>
                                            <Autocomplete
                                                multiple
                                                disablePortal
                                                id="combo-box-work-dones"
                                                value={selectedWorksDone || []}
                                                options={workDones || []}
                                                getOptionLabel={(option) => option?.description || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedWorksDone(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.description}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default MainStagesWorksForm