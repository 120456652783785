import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks";
import { useVehicleStore } from "../../hooks/useVehicleStore";
import { useStructureD1Store } from "../../hooks/useStructureD1Store";
import { usePatternRStore } from "../../hooks/usePatternRStore";
import { useGroupActivityD1Store } from "../../hooks/useGroupActivityD1Store";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { useBossStore } from "../../hooks/useBossStore";
import { Vehicles } from "../../types/slices/vehiclesType";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Typography, Divider, FormLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Checkbox, InputAdornment, Button, Tooltip } from "@mui/material";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { Accordion, AccordionDetails, AccordionSummary, StyledTableCell } from "../../toolbox/helpers/accordion-styled";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { createNotification } from "../../types/slices/notificationType";
import { ApiStatus } from "../../types/api/status";

type PatternRDataForm = {
    mileage:number
    execution_date:string
    last_maintenance_date:string
}

type DataGroup = {
    id:number
    name_group:string
    detail: DataRow[]
}

type DataRow = {
    idstructure:number
    work_done:string
    comment_observation: string
    done: number
    groupName: string
}

const date = new Date()
const año = date.getFullYear()
const mes = String(date.getMonth() + 1).padStart(2, '0')
const día = String(date.getDate()).padStart(2, '0')
const today = `${año}-${mes}-${día}`;

export const PTRForm:FC = () => {
    
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { vehicles, getVehicles } = useVehicleStore()
    const { structureD1, getStructureD1Store } = useStructureD1Store()
    const { status: statusPTR, selectedPatternR, createPatternR, editPatternR } = usePatternRStore()
    const { groupActivitiesD1, getGroupStructureD1Store } = useGroupActivityD1Store()
    const { createNotifications } = useNotificationStore()
    const { getBoss, boss } = useBossStore()

    const [selectedVehicle, setSelectedVehicle] = useState<Vehicles|null>(null)
    const [data, setData] = useState<PatternRDataForm>({
        mileage: 0,
        execution_date: today,
        last_maintenance_date: ''
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataGroup[]>([])

    useEffect(() => {
        getLocalData()
    },[])

    useEffect(() => {
        if(Object.entries(selectedPatternR).length === 0) {
            setAllDataCreate()
        } else {
            setAllDataEdit()
        }
    }, [groupActivitiesD1, structureD1])

    useEffect(() => {
        if (Object.entries(selectedPatternR).length > 0) {
            const vehicle = vehicles?.find(vehicle => vehicle.id === selectedPatternR.idvehicle)
            setSelectedVehicle(vehicle || null)
        }
    },[vehicles])

    const getLocalData = async () => {
        setLoading(true)
        await getVehicles({ status: 1 })
        await getGroupStructureD1Store()
        await getBoss()
        if (Object.entries(selectedPatternR).length > 0) {
            setDataEdit()
        } else {
            await getStructureD1Store()
        }
        setLoading(false)
    }

    const setDataEdit = () => {
        if (Object.entries(selectedPatternR).length > 0) {
            setIsEdit(true)
            setData({
                mileage: selectedPatternR.mileage,
                execution_date: selectedPatternR.execution_date,
                last_maintenance_date: selectedPatternR.last_maintenance_date
            })
        }
    }

    const setAllDataCreate = () => {
        if (groupActivitiesD1.length === 0 || structureD1.length === 0) return

        const data:DataGroup[] = groupActivitiesD1?.map(group => {
            const detailGroup = structureD1?.filter(structure => structure?.name_groupActivityD1 === group?.name_group)
            const details:DataRow[] = detailGroup?.map(detail => {
                return {
                    idstructure: detail?.id,
                    work_done: detail?.work_done,
                    comment_observation: '',
                    done: 0,
                    groupName: detail?.name_groupActivityD1
                }
            })
            return {
                id: group?.id,
                name_group: group?.name_group,
                detail: details
            }
        })

        setDataTable(data || [])
    }

    const setAllDataEdit = () => {
        if (groupActivitiesD1.length === 0) return

        const data:DataGroup[] = groupActivitiesD1?.map(group => {
            const detailGroup = selectedPatternR.detailRepairRequestD1?.filter(detail => detail?.name_group_activity_d1_structureD1 === group?.name_group)
            const details:DataRow[] = detailGroup?.map(row => ({
                idstructure: row?.idstructure_d1,
                work_done: row?.work_done_structureD1,
                comment_observation: row.comment_observation,
                done: parseInt(row.done),
                groupName: row?.name_group_activity_d1_structureD1
            }))
            return {
                id: group?.id,
                name_group: group?.name_group,
                detail: details
            }
        })

        setDataTable(data || [])
    }

    const validateForm = (values) => {
        let errors:any = {}
        if (!values.mileage) errors.mileage = "Odómetro es requerido"
        if (!values.execution_date) errors.execution_date = "Fecha de ejecución es requerido"
        return errors
    }

    const onSubmit = async (values) => {
        if (!selectedVehicle) return CustomSnackbar('warning', 'Seleccione una patente')

        if (!isEdit) {
            const structure_d1 = dataTable?.flatMap(group => group.detail.map(detail => detail.idstructure)) || [];
            const comment_observation = dataTable?.flatMap(group => group.detail.map(detail => detail.comment_observation)) || [];
            const done = dataTable?.flatMap(group => group.detail.map(detail => detail.done)) || [];

            const payload = {
                idvehicle: selectedVehicle?.id.toString(),
                idmechanic: user.data?.id,
                mileage: values.mileage,
                execution_date: values.execution_date,
                last_maintenance_date: values.last_maintenance_date,
                idcontrata: user?.data?.idcontrata,
                structure_d1: structure_d1,
                comment_observation: comment_observation,
                done: done
            }
            const newPattern = await createPatternR(payload)
            if (newPattern.id) {
                boss?.map(async (element) => {
                    const notify: createNotification = {
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        iduser_type: 4,
                        title: `Nueva Pauta R: ${newPattern.correlative}`,
                        details: `El ${user.userType} ${user.data?.name} ${user.data?.surname} ha creado una nueva pauta R. Patente: ${selectedVehicle.patent}`,
                        idview: 13,
                        idcompany: 1,
                        idregister: null,
                    }
                    return await createNotifications(notify)
                })
                navigate(`/pt-r-signature/${newPattern.id}`)
            }
        } else {
            const Works = dataTable?.flatMap(group => group.detail.map(detail => ({
                id: detail.idstructure,
                comment_observation: detail.comment_observation,
                done: detail.done
            }))) || []

            const payload = {
                idvehicle: selectedVehicle?.id.toString(),
                mileage: values.odometer,
                execution_date: values.execution_date,
                last_maintenance_date: values.last_maintenance_date,
                works: Works
            }

            const response = await editPatternR(selectedPatternR.id, payload)
            if (response === true) {
                boss?.map(async (element) => {
                    const notify: createNotification = {
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        iduser_type: 4,
                        title: `Pauta T editada: ${selectedPatternR.correlative}`,
                        details: `El ${user.userType} ${user.data?.name} ${user.data?.surname} ha editado una pauta R. Patente: ${selectedVehicle.patent}`,
                        idview: 13,
                        idcompany: 1,
                        idregister: null,
                    }
                    return await createNotifications(notify)
                })
                navigate(`/pt-r-signature/${selectedPatternR.id}`)
            }
        }
    }

    const onCancel = () => {
        navigate({ pathname: RoutesMap.PT_R_LIST })
    }

    const handleAllChecked = () => {
        setDataTable(prevDataTable => {
            return prevDataTable.map(group => ({
                ...group,
                detail: group.detail.map(detail => {
                    return {
                        ...detail,
                        done: Object.values(dataTable).every(group => Object.values(group.detail).every(detail => detail.done === 0)) ? 1 : 0
                    }
                })
            }))
        })
    }

    const handleChangeCheckBox = (e, idestructure) => {
        const { checked } = e.target
    
        setDataTable(prevDataTable => {
            return prevDataTable.map(group => ({
                ...group,
                detail: group.detail.map(detail => {
                    if (detail.idstructure === idestructure) {
                        detail.done = checked ? 1 : 0
                    }
                    return detail
                })
            }))
        })
    }

    const setComment = (e, idestructure) => {
        const { value } = e.target;
    
        setDataTable(prevDataTable => {
            return prevDataTable.map(group => ({
                ...group,
                detail: group.detail.map(detail => {
                    if (detail.idstructure === idestructure) {
                        detail.comment_observation = value
                    }
                    return detail
                })
            }))
        })
    }
        
    return (
        <CustomForm>
            {loading && <CustomBackdrop open={true} />}
            {statusPTR === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nueva Pauta R' : 'Editar Pauta R'}
                text={!isEdit ? 'Ingrese los datos de la Pauta R' : 'Modifique los datos de la Pauta R'}
                goBack={RoutesMap.PT_R_LIST}
            />
            <CustomFormBody>
            <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Patente:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-vehicle"
                                                value={selectedVehicle}
                                                options={vehicles || []}
                                                getOptionLabel={(option) => option.patent || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedVehicle(newValue);
                                                }}
                                                readOnly={isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Marca:</FormLabel>
                                            <TextField
                                                id="trademark"
                                                type="text"
                                                name="trademark"
                                                fullWidth
                                                size="small"
                                                value={selectedVehicle?.trademark || ''}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Modelo:</FormLabel>
                                            <TextField
                                                id="model"
                                                type="text"
                                                name="model"
                                                fullWidth
                                                size="small"
                                                value={selectedVehicle?.model || ''}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>N° chasis:</FormLabel>
                                            <TextField
                                                id="n_chassis"
                                                type="text"
                                                name="n_chassis"
                                                fullWidth
                                                size="small"
                                                value={selectedVehicle?.n_chassis || ''}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        {
                                            Object.entries(selectedPatternR).length === 0 && (
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Última Pauta R:</FormLabel>
                                                        <TextField
                                                            id="last_maintenance_date"
                                                            type="date"
                                                            name="last_maintenance_date"
                                                            fullWidth
                                                            size="small"
                                                            value={selectedVehicle?.last_patternR || ''}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Último odómetro - Pauta R:</FormLabel>
                                                        <TextField
                                                            id="odometer"
                                                            type="number"
                                                            name="odometer"
                                                            fullWidth
                                                            size="small"
                                                            value={selectedVehicle?.last_mileage_patternR || 0}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="start">Km</InputAdornment>,
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Fecha de ejecución:</FormLabel>
                                            <TextField
                                                id="execution_date"
                                                type="date"
                                                name="execution_date"
                                                fullWidth
                                                size="small"
                                                value={values.execution_date}
                                                onChange={handleChange}
                                                error={errors.execution_date && touched.execution_date ? true : false}
                                                helperText={errors.execution_date && touched.execution_date ? errors.execution_date : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Odómetro:</FormLabel>
                                            <TextField
                                                id="mileage"
                                                type="number"
                                                name="mileage"
                                                fullWidth
                                                size="small"
                                                value={values.mileage}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">Km</InputAdornment>,
                                                }}
                                                inputProps={{ min: 0 }}
                                                error={errors.mileage && touched.mileage ? true : false}
                                                helperText={errors.mileage && touched.mileage ? errors.mileage : ''}
                                            />
                                        </Grid>
                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={12} sx={{ textAlign: "left", marginTop: "2%", marginBottom: "-5%" }} md={6}>
                                                <Typography>Grupos de actividades:</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ textAlign: "right" }} md={6}>
                                                <Tooltip title={Object.values(dataTable).every(group => Object.values(group.detail).every(detail => detail.done === 0)) ? "Marcar todo como realizado" : "Marcar todo como no realizado"} >
                                                <Button
                                                    disabled={selectedVehicle === null}
                                                    onClick={() => handleAllChecked()}
                                                    sx={{
                                                        border: "solid 1px #73B2FF",
                                                        m: "10px",
                                                        color: "#73B2FF",
                                                        "&:hover": {
                                                            bgcolor: "#73B2FF",
                                                            color: "#fff",
                                                        },
                                                    }}
                                                >
                                                    {Object.values(dataTable).every(group => Object.values(group.detail).every(detail => detail.done === 0)) ? "Marcar todo" : "Desmarcar Todo"}
                                                </Button>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                dataTable?.map(group => 
                                                    group.detail.length > 0 &&
                                                    <Accordion key={group.id}>
                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                        {group.name_group}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead sx={{ background: "#D8E8F7" }}>
                                                                        <TableRow>
                                                                            <StyledTableCell className="headTable" align="center">ITEM</StyledTableCell>
                                                                            <StyledTableCell className="headTable" align="center">TRABAJO EFECTUADO</StyledTableCell>
                                                                            <StyledTableCell className="headTable" align="center">REALIZADO</StyledTableCell>
                                                                            <StyledTableCell className="headTable" align="center">COMENTARIO</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            selectedVehicle
                                                                            ?
                                                                            group?.detail.map((row,i) => 
                                                                                <TableRow key={i + 1}>
                                                                                    <TableCell align="center">{row.idstructure}</TableCell>
                                                                                    <TableCell align="left">{row.work_done}</TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Checkbox color="primary" onChange={(e) => handleChangeCheckBox(e, row.idstructure)} checked={row.done === 1} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            id={"input-" + i}
                                                                                            key={"input-" + i}
                                                                                            type="text"
                                                                                            fullWidth
                                                                                            size="small"
                                                                                            value={row.comment_observation}
                                                                                            onChange={(e) => setComment(e, row.idstructure)}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>    
                                                                            )
                                                                            :
                                                                            <TableRow>
                                                                                <TableCell
                                                                                    colSpan={5}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            display: "flex",
                                                                                        }}
                                                                                    >
                                                                                        <div>{"Seleccione una patente"}</div>
                                                                                    </Grid>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={'Guardar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}