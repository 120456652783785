import { api } from "./configs/axiosConfigs";

export const TypeAttentionAPI = {
    getTypeAttention: async (data?: any) => {
        const response = await api
            .get('/typeAttention', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },

    createTypeAttention: async (data: any) => {
        const response = await api
            .post('/typeAttention/register', { ...data, idcompany: 1 })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },

    editTypeAttention: async (id: number, data: any) => {
        const response = await api
            .patch(`/typeAttention/${id}`, { ...data, idcompany: 1 })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },

    deleteTypeAttention: async (id: number) => {
        const response = await api
            .delete(`/typeAttention/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    }
};