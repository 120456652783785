import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useUserStore } from '../../hooks/useUserStore';
import { ApiStatus } from '../../types/api/status';
import { useAuthStore } from '../../hooks';
import { Role } from '../../types/roles/roleTypes';
import { RoutesMap } from '../../types';
import { useNavigate } from 'react-router-dom';

const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            // borderBottomColor: pColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e3e1e1',
            },
            '&:hover fieldset': {
                borderColor: '#e3e1e1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fafafa',
            },
        },
    },
})(TextField);

type payloadChange = {
    password: string;
    new_password: string;
    username_erp?: string;
    status_erp: number;
}

interface ChangePasswordProps {
    statusPage: Dispatch<SetStateAction<string>>;
}

const ChangePassword: FC<ChangePasswordProps> = (props) => {

    const setStatusPage = props.statusPage;
    const { status: statusUser, changePasswordUser } = useUserStore()
    const { user } = useAuthStore()
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [ERPStatus, setERPstatus] = useState<number>(0)

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [verifyNewPassword, setVerifyNewPassword] = useState<string>("");


    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showVerifyNewPassword, setShowVerifyNewPassword] = useState<boolean>(false);


    const [userERP, setUserERP] = useState<string>("");
    const [ERPPassword, setERPPassword] = useState<string>("");

    const [showERPPassword, setShowERPPassword] = useState<boolean>(false);


    const onChangeOldPassword = (e) => {
        const oldPassword = e.target.value;
        setOldPassword(oldPassword);
    };

    const onChangeNewPassword = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword)
    };

    const onChangeVerifyPassword = (e) => {
        const verification = e.target.value;
        setVerifyNewPassword(verification)
    }

    const onChangeERPPassword = (e) => {
        const ERPpassword = e.target.value;
        setERPPassword(ERPpassword);
    }

    const onChangeERP = (e) => {
        const newUserERP = e.target.value;
        setUserERP(newUserERP)
    }

    const handleChecked = () => {
        ERPStatus === 0 ? setERPstatus(1) : setERPstatus(0)
    }

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const payload: payloadChange = ERPStatus === 0 ? {
            password: oldPassword,
            new_password: newPassword,
            status_erp: 0,
        } : {
            password: oldPassword,
            new_password: ERPPassword,
            username_erp: userERP,
            status_erp: 1,
        }

        if (handleValidation()) {
            const response = await changePasswordUser(payload)
            if (response === true) {
                setStatusPage("1")
                const userType = user.userType
                switch (userType) {
                    case Role.SUPER_ADMIN:
                        navigate({ pathname: RoutesMap.ADMINISTRATOR }, { replace: true })
                        break
                    case Role.ADMIN:
                        navigate({ pathname: RoutesMap.SUPERVISORS }, { replace: true })
                        break
                    // case Role.SUPERVISOR:
                    //     navigate({ pathname: RoutesMap.REPAIR_REQUEST }, { replace: true })
                    //     break
                    // case Role.MAINTENANCE:
                    //     navigate({ pathname: RoutesMap.MECHANICS }, { replace: true })
                    //     break
                    // case Role.MECHANIC:
                    //     navigate({ pathname: RoutesMap.PT_R_LIST }, { replace: true })
                    //     break
                    // case Role.DRIVER:
                    //     navigate({ pathname: RoutesMap.REPAIR_REQUEST }, { replace: true })
                    //     break
                    case Role.TECHNICAL:
                        navigate({ pathname: RoutesMap.PT_R_LIST }, { replace: true })
                        break
                    case Role.CUSTOMER:
                        navigate({ pathname: RoutesMap.PT_R_LIST }, { replace: true })
                        break
                    default:
                        break
                }
            } else {
                return false;
            }
        }
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (ERPStatus === 0) {
            //Password
            if (!oldPassword) {
                formIsValid = false;
                errors["oldPassword"] = 'contraseña requerida';
            }

            //new Password
            if (!newPassword) {
                formIsValid = false;
                errors["newPassword"] = 'contraseña requerida';
            }

            //verify password
            if (!verifyNewPassword) {
                formIsValid = false;
                errors["verifyNewPassword"] = 'contraseña requerida';
            }

            //The passwords do not match
            if (newPassword !== verifyNewPassword) {
                formIsValid = false;
                errors["verifyNewPassword"] = 'las contraseñas no coinciden';
            }
        } else {
            //Password
            if (!oldPassword) {
                formIsValid = false;
                errors["oldPassword"] = 'contraseña requerida';
            }

            //User ERP
            if (!userERP) {
                formIsValid = false;
                errors["userERP"] = 'usuario de ERP requerido';
            }

            //Password ERP
            if (!ERPPassword) {
                formIsValid = false;
                errors["ERPPassword"] = 'usuario de ERP requerido';
            }
        }
        setErrors(errors);
        return formIsValid;
    }

    return (
        <Grid container sx={{ padding: '60px', marginTop: '40px' }}>
            <Grid container xs={12}>
                <Grid item container sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography variant="h4"
                        component="h1"
                        gutterBottom
                        color="#808080"
                        className="tittle"
                        sx={{ mt: 1, ml: 1, fontWeight: 600 }}>¡BIENVENIDO!</Typography>
                </Grid>
                <Grid item container sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography
                        variant='h6'
                        component="h3"
                        gutterBottom
                        color="#00000"
                        className="paragraph"
                        sx={{ mt: 1, ml: 1, fontWeight: 400 }}>Para continuar debe cambiar su contraseña. Si tiene una cuenta en el ERP ingrese su usuario y contraseña. Automáticamente esa contraseña pasará a ser la de esta aplicación. Si no tiene cuenta en el ERP ingrese una nueva contraseña.</Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: '15px 0px' }}>
                    <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-password-content'}>
                        <CssTextField
                            style={{ width: '450px' }}
                            size='small'
                            id="oldPassword"
                            placeholder={'Ingrese contraseña actual'}
                            value={oldPassword}
                            required
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            onChange={onChangeOldPassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => { setShowPassword(!showPassword) }}
                                            edge="end"
                                            style={{ color: 'rgb(76, 148, 182)' }}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <span style={{
                        color: "red", display: 'flex', flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {errors["oldPassword"]}
                    </span>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '3px', marginRight: '180px', display: 'flex', justifyContent: 'center' }}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onClick={() => handleChecked()} checked={ERPStatus === 1} />} label='Tengo una cuenta en el ERP.' />
                    </FormGroup>
                </Grid>
                {
                    ERPStatus === 0 ? (
                        <>
                            <Grid item xs={12} style={{ margin: '15px 0px' }}>
                                <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-newPassword-content'}>
                                    <CssTextField
                                        style={{ width: '450px' }}
                                        size='small'
                                        id="newPassword"
                                        placeholder={'Ingrese la nueva contraseña'}
                                        value={newPassword}
                                        required
                                        fullWidth
                                        type={showNewPassword ? "text" : "password"}
                                        onChange={onChangeNewPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => { setShowNewPassword(!showNewPassword) }}
                                                        edge="end"
                                                        style={{ color: 'rgb(76, 148, 182)' }}
                                                    >
                                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <span style={{
                                    color: "red", display: 'flex', flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    {errors["newPassword"]}
                                </span>
                            </Grid>
                            <Grid item xs={12} style={{ margin: '15px 0px' }}>
                                <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-verify-newPassword-content'}>
                                    <CssTextField
                                        style={{ width: '450px' }}
                                        size='small'
                                        id="verify-newPassword"
                                        placeholder={'Vuelva a ingresar la nueva contraseña'}
                                        value={verifyNewPassword}
                                        required
                                        fullWidth
                                        type={showVerifyNewPassword ? "text" : "password"}
                                        onChange={onChangeVerifyPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => { setShowVerifyNewPassword(!showVerifyNewPassword) }}
                                                        edge="end"
                                                        style={{ color: 'rgb(76, 148, 182)' }}
                                                    >
                                                        {showVerifyNewPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <span style={{
                                    color: "red", display: 'flex', flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    {errors["verifyNewPassword"]}
                                </span>
                            </Grid>
                        </>
                    ) :
                        (
                            <>
                                <Grid item xs={12} style={{ margin: '15px 0px' }}>
                                    <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-user-erp-content'}>
                                        <CssTextField
                                            style={{ width: '450px' }}
                                            size='small'
                                            id="user-erp"
                                            placeholder={'Ingrese su usuario del ERP'}
                                            value={userERP}
                                            required
                                            fullWidth
                                            type={"text"}
                                            onChange={onChangeERP}
                                        />
                                    </Box>
                                    <span style={{
                                        color: "red", display: 'flex', flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        {errors["userERP"]}
                                    </span>
                                </Grid>
                                <Grid item xs={12} style={{ margin: '15px 0px' }}>
                                    <Box textAlign="center" style={{ marginTop: '5px' }} className={'input-erp-Password-content'}>
                                        <CssTextField
                                            style={{ width: '450px' }}
                                            size='small'
                                            id="erp-password"
                                            placeholder={'Ingrese la contraseña de su cuenta en el ERP'}
                                            value={ERPPassword}
                                            required
                                            fullWidth
                                            type={showERPPassword ? "text" : "password"}
                                            onChange={onChangeERPPassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => { setShowERPPassword(!showERPPassword) }}
                                                            edge="end"
                                                            style={{ color: 'rgb(76, 148, 182)' }}
                                                        >
                                                            {showERPPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <span style={{
                                        color: "red", display: 'flex', flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        {errors["ERPPassword"]}
                                    </span>
                                </Grid>
                            </>
                        )
                }
                <div style={{ borderTop: "1px solid #e3e1e1", width: "100%", paddingTop: '15px', justifyContent: 'end', textAlign: 'end' }}>
                    {
                        statusUser === ApiStatus.FETCHING ? (
                            <Button onClick={(e) => { }} size="small" color={"primary"} variant="contained">
                                {'Cambiar contraseña'}
                                <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                            </Button>
                        ) : (
                            <Button onClick={(e) => { handleChangePassword(e) }} size="small" color={"primary"} variant="contained">
                                {'Cambiar contraseña'}
                            </Button>
                        )
                    }
                </div>
            </Grid>
        </Grid>

    )
}

export default ChangePassword