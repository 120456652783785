import { api } from "./configs/axiosConfigs";

export const ModelVehicleAPI = {
    getModelVehicle: async (data?: any) => {
        const response = await api
            .get('/modelVehicle', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    createModelVehicle: async (data: any) => {
        const response = await api
            .post('/modelVehicle/register', { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    editModelVehicle: async (id: number, data: any) => {
        const response = await api
            .patch(`/modelVehicle/${id}`, { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    deleteModelVehicle: async (id: number) => {
        const response = await api
            .delete(`/modelVehicle/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    }
}