import { useDispatch, useSelector } from "react-redux"
import { selectEmployeeIncompleteState, changeStatus, onFetchEmployeesIncomplete, onSetEditEmployeeIncomplete } from "../redux/slices/employeeIncompleteSlice"
import { ApiStatus } from "../types/api/status"
import { EmployeeIncompleteAPI } from "../apis/EmployeeIncompleteAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { EmployeeIncomplete } from "../types/slices/employeeIncompleteType"

export const useEmployeeIncompleteStore = () => {
    const dispatch = useDispatch()
    const { employeesIncompletes, status, editEmployeeIncomplete } = useSelector(selectEmployeeIncompleteState)
    
    const getEmployeesIncompletes = async (data?:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await EmployeeIncompleteAPI.getEmployeeIncomplete(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchEmployeesIncomplete(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateEmployeeIncomplete = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await EmployeeIncompleteAPI.update(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditEmployeeIncomplete = (employee:EmployeeIncomplete) => {
      try {
          dispatch(onSetEditEmployeeIncomplete(employee))
      } catch (error) {
          console.log(error)
      }
  }

    return {
        employeesIncompletes,
        status,
        editEmployeeIncomplete,
        getEmployeesIncompletes,
        updateEmployeeIncomplete,
        setEditEmployeeIncomplete
    }
}