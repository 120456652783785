import { useDispatch, useSelector } from "react-redux";
import { ContrataAPI } from "../apis/ContrataAPI";
import { selectContrataState, changeStatus, onFetchContrata, onSetEditContrata, onSetSelectedContrata } from "../redux/slices/contrataSlice"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Contrata } from "../types/slices/contrataType";

export const useContrataStore = () => {
    const dispatch = useDispatch();
    const {
        contratas,
        status: contrataStatus,
        editContrata,
        selectedContrata
    } = useSelector(selectContrataState);

    const getContratas = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ContrataAPI.getContrata(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchContrata(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createContrataStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ContrataAPI.createContrata(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editContrataStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ContrataAPI.editContrata(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteContrataStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ContrataAPI.deleteContrata(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedContrata = (contrata: Contrata) => {
        try {
            dispatch(onSetSelectedContrata(contrata));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditContrata = (contrata: Contrata) => {
        try {
            dispatch(onSetEditContrata(contrata));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        //states
        contratas,
        contrataStatus,
        editContrata,
        selectedContrata,
        //actions
        getContratas,
        createContrataStore,
        editContrataStore,
        deleteContrataStore,
        setSelectedContrata,
        setEditContrata,
    }
}