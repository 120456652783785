import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { NameFail, NameFailType } from "../../types/slices/nameFailType";

const initialState: NameFailType = {
    status: ApiStatus.FETCHED,
    nameFails: [],
    errorMessage: undefined,
    selectedNameFail: {} as NameFail,
}

const nameFailSlice = createSlice({
    name: "maintenanceCompany",
    initialState,
    reducers: {
        onFetchNameFail(state, { payload }: { payload: NameFail[] }) {
            state.status = ApiStatus.FETCHED;
            state.nameFails = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedNameFail(state, { payload }: { payload: NameFail }) {
            state.selectedNameFail = payload;
        },
    }
});

export const selectNameFailState = (state: RootState) => state.nameFailSlice;
export default nameFailSlice.reducer;

export const {
    onFetchNameFail,
    changeStatus,
    onSetSelectedNameFail,
} = nameFailSlice.actions