import { FC, useEffect, useState } from "react";
import { DET } from "../../types/slices/DETType";
import { useDETStore } from "../../hooks/useDETStore";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { DETModalValidate } from "./DETModalValidate";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'request_correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsAdmin = [
    { type: 'text', field: 'request_correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const DETList: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status: DETstatus, DETs, getDETs, setEditDET, deleteDET } = useDETStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<DET>({} as DET)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [showModalValidate, setShowModalValidate] = useState<boolean>(false)
    const [isSnackbarShown, setIsSnackbarShown] = useState<boolean>(false);

    const userRole: Role | any = user.userType

    useEffect(() => {
        getDETApi()
    }, [search])

    useEffect(() => {
        if (!isSnackbarShown && userRole === Role.MECHANIC && (DETs.find(det => det.signing_det.length === 0) || !DETs.find(det => det.signing_det.find(sign => sign.iduser === user?.id)))) {
            CustomSnackbar('signed', "Tienes documentos DET que faltan firmar");
            setIsSnackbarShown(true)
        }
        if (!isSnackbarShown && userRole === Role.SUPERVISOR && DETs.find(det => det.status_supervisor === "0")) {
            CustomSnackbar('signed', "Tienes documentos DET que faltan firmar");
            setIsSnackbarShown(true)
        }
    }, [DETs, isSnackbarShown])

    const getDETApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.MECHANIC || userRole === Role.SUPERVISOR || userRole === Role.MAINTENANCE) {
                payload = {
                    ...payload,
                    idcontrata: user?.data?.idcontrata
                }
            }
            await getDETs(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setEditDET({} as DET)
        navigate({ pathname: RoutesMap.DET_FORM })
    }

    const onEdit = (rowSelected) => {
        if (userRole === Role.MECHANIC && rowSelected.status_supervisor == 1) return CustomSnackbar('error', 'El documento DET ya ha sido firmado por el supervisor por lo tanto no puede ser modificado.')
        setEditDET(rowSelected)
        navigate({ pathname: RoutesMap.DET_FORM })
    }

    const onDelete = (rowSelected) => {
        if (userRole === Role.MECHANIC) {
            if (rowSelected.status_supervisor == '1') return CustomSnackbar('error', 'El documento DET ya ha sido firmado por el supervisor por lo tanto no puede ser eliminado.')
            if (rowSelected?.signing_det.length > 0) return CustomSnackbar('error', 'El documento DET ya ha sido firmado por lo tanto no puede ser eliminado.')
        }
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteDET(rowSelected.id)
        if (response === true) {
            getDETApi()
            setShowModalConfirm(false)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onDetails = (rowSelected) => {
        navigate(`/det-signature/${rowSelected.id}`)
    }

    const onValidateDET = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalValidate(true)
    }

    return (<>
        <Grid item xs={12}>
            {
                userRole === Role.MECHANIC && (
                    <CustomTable
                        title={'DET'}
                        columns={columns || []}
                        loading={loading}
                        rows={DETs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                (userRole === Role.SUPERVISOR) && (
                    <CustomTable
                        title={'DET'}
                        columns={columns || []}
                        loading={loading}
                        rows={DETs || []}
                        onRowClick={() => { }}
                        hasOptions
                        // onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        // onValidateDET={onValidateDET}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                (userRole === Role.MAINTENANCE) && (
                    <CustomTable
                        title={'DET'}
                        columns={columns || []}
                        loading={loading}
                        rows={DETs || []}
                        onRowClick={() => { }}
                        hasOptions
                        // onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole === Role.ADMIN && (
                    <CustomTable
                        title={'DET'}
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={DETs || []}
                        onRowClick={() => { }}
                        // onValidateDET={onValidateDET}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={() => { setShowModalConfirm(false) }}
                    onCancel={() => { setShowModalConfirm(false) }}
                    onConfirm={onDeleteConfirm}
                    status2={DETstatus === ApiStatus.FETCHING ? true : false}
                />
            )
        }
        {
            showModalValidate && (
                <DETModalValidate
                    open={showModalValidate}
                    closeModal={() => { setShowModalValidate(false) }}
                    onList={() => getDETApi()}
                    rowSelected={rowSelected}
                />
            )
        }
    </>)
}
