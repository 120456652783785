import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useMechanicStore } from "../../hooks/useMechanicStore";
import { Mechanic } from "../../types/slices/mechanicType";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { IdentificationModalAdmin } from "../MyProfile/components/IdentificationModalAdmin";
import { ApiStatus } from "../../types/api/status";
import { useUserStore } from "../../hooks/useUserStore";
import ContactMailIcon from '@mui/icons-material/ContactMail';

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "rut", label: "RUT", align: 'center' },
    { type: "text", field: "name", label: "NOMBRES", align: 'center' },
    { type: "text", field: "surname", label: "APELLIDOS", align: 'center' },
    { type: "text", field: "email", label: "EMAIL", align: 'center' },
    { type: "text", field: "phone", label: "TELÉFONO", align: 'center' },
    // { type: "text", field: "status_turn", label: "ESTADO DE TURNO", align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
];

const Mechanics: FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<Mechanic>({} as Mechanic);
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);
    const [showModalIdentification, setShowModalIdentification] = useState<boolean>(false)
    const [userRut, setUserRut] = useState<string>('')
    const userRole: Role | any = user.userType;
    const {
        mechanics,
        status: mechanicStatus,
        getMechanics,
        setSelectedMechanic,
        deleteMechanics,
    } = useMechanicStore();
    const { forgotPasswordUser } = useUserStore()

    useEffect(() => {
        getMechanicApi();
    }, [search]);


    const getMechanicApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            if (userRole === Role.MAINTENANCE) {
                payload = {
                    ...payload,
                    idcontrata: user.data?.idcontrata
                }
            }
            await getMechanics(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        navigate({ pathname: RoutesMap.MECHANICS_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedMechanic(rowSelected);
        navigate({ pathname: RoutesMap.MECHANICS_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteMechanics(rowSelected.id);
        if (response === true) {
            setSelectedMechanic({} as Mechanic);
            setModalDeleteConfirm(false);
            getMechanicApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onPersonIcon = (row) => {
        setUserRut(row.rut)
        setShowModalIdentification(true)
    }

    const sendCredentials = async (rowSelected) => {
        setLoading(true)
        await forgotPasswordUser(rowSelected.rut, rowSelected.iduser_type)
        setLoading(false)
    }

    return (
        <>
            <Grid item xs={12}>
                {
                    userRole === Role.ADMIN
                    ?
                    <CustomTable
                        title={"Mecánicos"}
                        columns={columns}
                        loading={loading}
                        rows={mechanics || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onPersonIcon={onPersonIcon}
                        onHelper={sendCredentials}
                        tooltip_helper={'Enviar credenciales'}
                        icon_helper={<ContactMailIcon fontSize="small"/>}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    :
                    <CustomTable
                        title={"Mecánicos"}
                        columns={columns}
                        loading={loading}
                        rows={mechanics || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
                
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={mechanicStatus === ApiStatus.FETCHING ? true : false}
                />
            )}
            {
                showModalIdentification && (
                    <IdentificationModalAdmin
                        open={showModalIdentification}
                        closeModal={()=>setShowModalIdentification(false)}
                        rut={userRut}
                    />
                )
            }
        </>
    );
};

export default Mechanics;
