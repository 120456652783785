import { api } from "../configs/axiosConfigs";

export const MainStagesWorksAPI = {
    getMainStagesWorks: async (data?: any) => {
        const response = await api
            .get("/mainStagesWork", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createMainStagesWorks: async (data: any) => {
        const response = await api
            .post("/mainStagesWork/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editMainStagesWorks: async (id: number, data: any) => {
        const response = await api
            .patch(`/mainStagesWork/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteMainStagesWorks: async (id: number) => {
        const response = await api
            .delete(`/mainStagesWork/${id}`, {
                params: {
                    idcompany: 1
                }
            })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}