import { api } from "./configs/axiosConfigs"

export const WorkOrderAPI = {
    getWorkOrders: async (data?:any) => {
        const response = await api.get(
            '/workOrder',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getOne: async (id:number) => {
        const response = await api.get(
            `/workOrder/${id}`,
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/workOrder/register', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    update: async (id: number, data: any) => {
        const response = await api.patch(
            `/workOrder/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
            `/workOrder/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    validatePreOT: async (id:number, status:number) => {
        const response = await api.patch(
            `/workOrder/validate/${id}`,
            {
                status_ot: status
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    addWorkDone: async (id:number, data:any) => {
        const response = await api.post(
            `/workDoneWorkOrder/massive/${id}`, data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getPDF: async (id:number) => {
        const response = await api.get(
        `/workOrder/pdf/${id}`,
        {
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
    signature: async (id: number, data:any) => {
        const response = await api.post(
        `/signingWorkOrder/${id}`, data
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    getReport: async (data?:any) => {
        const response = await api.get(
            '/workOrder/report',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
}