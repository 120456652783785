import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Driver, DriverType } from "../../types/slices/driverType";
import { RootState } from "../store";

const initialState: DriverType = {
    status: ApiStatus.FETCHED,
    drivers: [],
    errorMessage: undefined,
    selectedDriver: {} as Driver
}

const driverSlice = createSlice({
    name: 'driver',
    initialState,
    reducers: {
        onFetchDrivers (state, { payload }: { payload: Driver[] }) {
            state.status       = ApiStatus.FETCHED
            state.drivers      = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedDriver (state, { payload }: { payload: Driver }) {
            state.selectedDriver = payload
        }
    }
})

export const selectDriverState = (state: RootState) => state.driverSlice
export default driverSlice.reducer

export const {
    onFetchDrivers,
    changeStatus,
    onSetSelectedDriver
} = driverSlice.actions