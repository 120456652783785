import { FC, useEffect, useState } from 'react'
import { useNotificationStore } from '../../hooks/useNotificationStore'
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop'
import { ApiStatus } from '../../types/api/status'
import CustomTable from '../../components/common/CustomTable/CustomTable'
import { Notification } from '../../types/slices/notificationType'
import { Button, Grid, Typography } from '@mui/material'
import moment from 'moment'
import 'moment/locale/es'
import { useNavigate } from 'react-router-dom'
moment.locale('es')

const columns = [
    {
        type: 'text', field: 'notifications', label: 'NOTIFICACIÓN', format: (row: Notification) => (
            <Grid>
                <div
                    style={{
                        cursor: 'pointer',
                        background: row?.date_seen !== null ? '#fff' : '#efefef',
                        padding: '5px',
                        paddingLeft: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant='body2' sx={{ color: '#000 !important' }} fontWeight="bold" fontSize="1rem">{row.title}</Typography>
                    <Typography variant='caption' fontSize=".9rem">{row.details}</Typography>
                    <Typography variant='caption' sx={{ color: '#000 !important' }}>
                        {moment(row.date_sent).startOf('minute').fromNow()}
                    </Typography>

                </div>
            </Grid>
        )
    },
    {},
]

const AllNotifications: FC = () => {
    const { changeSeenNotification, changeSeenMassiveNotification, getNotifications, getNotViewNotifications, getViewNotifications, notificationStatus, notifications } = useNotificationStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [buttons, setButtons] = useState({
        all: 1,
        view: 0,
        notView: 0,
    })

    useEffect(() => {
        apiDataInitial()
    }, [])

    const apiDataInitial = async () => {
        try {
            setLoading(true)
            await getNotifications({ perPage: rowsPerPage, page: page })
            setLoading(false)
        } catch (error) {
            setLoading(true)
        }
    }

    const markAsReadColumn = async (rowSelected) => {
        const id = rowSelected.id
        if (rowSelected.status_noti_name !== "Visto") {
            await changeSeenNotification(id)
        }
    }

    const buttonReact = async (status: string) => {
        if (status === "all") {
            setButtons({ all: 1, view: 0, notView: 0 })
            setLoading(true)
            await getNotifications({ perPage: rowsPerPage, page: page })
            setLoading(false)
        } else if (status === "view") {
            setButtons({ all: 0, view: 1, notView: 0 })
            setLoading(true)
            await getViewNotifications({ perPage: rowsPerPage, page: page })
            setLoading(false)
        } else if (status === "notView") {
            setButtons({ all: 0, view: 0, notView: 1 })
            setLoading(true)
            await getNotViewNotifications({ perPage: rowsPerPage, page: page })
            setLoading(false)
        }
    }

    const onClick = (rowSelected) => {
        markAsReadColumn(rowSelected)
        navigate({ pathname: rowSelected.url })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const markAsReadMasive = async () => {
        const response = await changeSeenMassiveNotification();
        if (response) {
            setButtons({ all: 1, view: 0, notView: 0 })
            buttonReact("all")
        } else {
            return false;
        }
    }

    return (
        <>
            {notificationStatus === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            <Grid container xs={12} spacing={2}>
                <Grid item container xs={12} sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography variant="h6"
                        component="h1"
                        gutterBottom
                        color="#808080"
                        className="tittle"
                        sx={{ mt: 1, ml: 1, fontWeight: 600 }}>NOTIFICACIONES</Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{ textAlign: "left" }}>
                    <Button variant="contained"
                        onClick={() => buttonReact("all")}
                        sx={{
                            margin: "5px",
                            bgcolor: buttons.all !== 1 ? "#FFF" : "#73B2FF",
                            color: buttons.all !== 1 ? "#73B2FF" : "#fff"
                        }}>TODOS</Button>
                    <Button variant="contained"
                        onClick={() => buttonReact("view")}
                        sx={{
                            margin: "5px",
                            bgcolor: buttons.view !== 1 ? "#FFF" : "#73B2FF",
                            color: buttons.view !== 1 ? "#73B2FF" : "#fff"
                        }}>vistos</Button>
                    <Button variant="contained"
                        onClick={() => buttonReact("notView")}
                        sx={{
                            margin: "5px",
                            bgcolor: buttons.notView !== 1 ? "#FFF" : "#73B2FF",
                            color: buttons.notView !== 1 ? "#73B2FF" : "#fff"
                        }}>no vistos</Button>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
                    <Button
                        onClick={markAsReadMasive}
                        sx={{
                            border: "solid 1px #73B2FF",
                            mt: "10px",
                            color: "#73B2FF",
                            margin: "5px",
                            "&:hover": {
                                bgcolor: "#73B2FF",
                                color: "#fff",
                            },
                        }}
                        disabled={notifications.every(row => row.status_noti_name === "Visto")}
                    >
                        Marcar todos como visto
                    </Button>
                </Grid>
            </Grid >
            <CustomTable
                columns={columns || []}
                rows={notifications || []}
                loading={loading}
                onRowClick={onClick}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    )
}

export default AllNotifications