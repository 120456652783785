import { useDispatch, useSelector } from "react-redux";
import { ControlBarriersAPI } from "../apis/AST/ControlBarriers";
import { selectControlBarriersState, changeStatus, onFetchControlBarrier, onSetSelectedControlBarrier } from "../redux/slices/ControlBarrierSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { ControlBarriers } from "../types/slices/controlBarriersType";

export const useControlBarrier = () => {
    const dispatch = useDispatch();
    const { controlBarriers, selectedControlBarrier, status } = useSelector(selectControlBarriersState);

    const getControlBarrier = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlBarriersAPI.getControlBarriers(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchControlBarrier(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createControlBarrierStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ControlBarriersAPI.createControlBarriers(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editControlBarrierStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ControlBarriersAPI.editControlBarriers(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteControlBarrierStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ControlBarriersAPI.deleteControlBarriers(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedControlBarrier = (controlBarrier: ControlBarriers) => {
        try {
            dispatch(onSetSelectedControlBarrier(controlBarrier));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        controlBarriers,
        selectedControlBarrier,
        status,
        getControlBarrier,
        createControlBarrierStore,
        editControlBarrierStore,
        deleteControlBarrierStore,
        setSelectedControlBarrier
    }

}