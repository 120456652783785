
import { useDispatch, useSelector } from '../redux/store';
import { addRange, selectRange, updateCalendar, changePeopleInvolved, reset, changeStatus, changeIsActionBlocked } from '../redux/slices/calendarSlice';
import { daysBetweenDates } from '../toolbox/helpers/dates-helpers';
import moment from 'moment';
import { CustomSnackbar } from '../components/common/CustomSnackbar/CustomSnackbar';
import { ApiStatus } from '../types/api/status';

export const useCalendar = () => {

    const dispatch = useDispatch();

    const { arrayDates, departament, owner } = useSelector(selectRange);

    const refreshCalendar = async (datesRegister: any) => {
        try {
            const dataDefault: any = [{ startDate: new Date(), endDate: new Date(), state: 'default' }]
            const arrayFilter: any = [];
            if (datesRegister.length > 0) {
                datesRegister.map((item) => {
                    let newDates = {
                        startDate: (new Date(item.startDate)).toString(),
                        endDate: (new Date(item.endDate)).toString(),
                        state: item.state && item.state == 'current' ? 'current' : 'data',
                        color: item.state && item.state == 'current' ? '#73B2FF' : 'rgba(250, 35, 5, 0.4)',
                    }
                    arrayFilter.push(newDates);
                })
                dispatch(updateCalendar(arrayFilter));
            } else {
                dispatch(updateCalendar(dataDefault));
            }

        } catch (error) {
            console.error(error);
        }
    }

    const addCurrentRange = async (value: any) => {
        try {
            let verify: boolean = false;

            const currentRange: any = {
                startDate: moment(value.startDate).format('YYYY/MM/DD'),
                endDate: moment(value.endDate).format('YYYY/MM/DD'),
            }

            const arrayCurrentDates = daysBetweenDates(moment(value.startDate), moment(value.endDate));

            const filtered = (arrayDates).filter((item) => item.state != 'current');

            for (let i = 0; i < filtered.length; i++) {
                const start = moment(filtered[i].startDate).format('YYYY/MM/DD');
                const end = moment(filtered[i].endDate).format('YYYY/MM/DD');

                if (currentRange.startDate <= end && currentRange.startDate >= start) verify = true;
                if (currentRange.endDate <= end && currentRange.endDate >= start) verify = true;
                if (currentRange.startDate == currentRange.endDate) verify = true;
                if (arrayCurrentDates.includes(start) || arrayCurrentDates.includes(end)) verify = true;
            }

            if (verify) return alert('El rango de fechas seleccionado no esta permitido.');

            const data = {
                ...value,
                startDate: (new Date(value.startDate)).toString(),
                endDate: (new Date(value.endDate)).toString(),
            }

            dispatch(addRange({ ...data, state: 'current', color: '#73B2FF' }));
        } catch (error) {
            console.error(error);
        }
    }

    const getCurrentRange = () => {
        const currentRange = (arrayDates).find((item) => item.state == 'current');
        return currentRange;
    }

    const addPeopleInvolved = (data) => {
        try {
            dispatch(changePeopleInvolved(data));
        } catch (error) {
            console.error(error);
        }
    }

    const resetCalendar = () => {
        try {
            dispatch(reset());
        } catch (error) {
            console.error(error);
        }
    }

    return {
        /* states */
        arrayDates,
        departament,
        owner,

        /* actions */
        refreshCalendar,
        addCurrentRange,
        getCurrentRange,
        addPeopleInvolved,
        resetCalendar,
    }
}