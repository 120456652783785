import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { DetailPatternT, PatternT, PatternTDataForm, PatternTType } from "../../types/slices/patternTType";
import { RootState } from "../store";

const initialState: PatternTType = {
    status: ApiStatus.FETCHED,
    patternTs: [],
    errorMessage: undefined,
    selectedPatternT: {} as PatternT,
    editPatternT: {} as PatternT,
    patternTDataForm: {
        id: 0,
        idvehicle: 0,
        iddriver: 0,
        correlative: '',
        mileage: 0,
        entry_date: '',
        exit_date: '',
        data_structure: [],
        data_tires1: [],
        data_tires2: []
    }
}

const patternTSlice  = createSlice({
    name: 'patternT',
    initialState,
    reducers: {
        onFetchPatternT (state, { payload }: { payload: PatternT[] }) {
            state.status            = ApiStatus.FETCHED
            state.patternTs         = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedPatternT (state, { payload }: { payload: PatternT }) {
            state.selectedPatternT = payload
        },
        onSetEditPatternT (state, { payload }: { payload: PatternT }) {
            state.editPatternT = payload
        },
        onSetPatternTDataForm (state, { payload }: { payload: PatternTDataForm }) {
            state.patternTDataForm = payload
        }
    }
})

export const selectPatternTState = (state: RootState) => state.patternTSlice
export default patternTSlice.reducer

export const {
    onFetchPatternT,
    changeStatus,
    onSetSelectedPatternT,
    onSetEditPatternT,
    onSetPatternTDataForm
} = patternTSlice.actions
