import { api } from "./configs/axiosConfigs";

export const MechanicsAPI = {
    getMechanics: async (data?: any) => {
        const response = await api
            .get("/mechanic", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createMechanics: async (data: any, idcompany: number) => {
        const response = await api
            .post("/mechanic/register", { ...data, idcompany: idcompany })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editMechanics: async (id: number, data: any) => {
        const response = await api
            .patch(`/mechanic/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteMechanics: async (id: number) => {
        const response = await api
            .delete(`/mechanic/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
};
