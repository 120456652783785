import { FC, useState, useEffect } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useTechnicalStore } from "../../hooks/useTechnical";
import { Technical } from "../../types/slices/technicalType";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { ApiStatus } from "../../types/api/status";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useUserStore } from "../../hooks/useUserStore";
import { IdentificationModalAdmin } from "../MyProfile/components/IdentificationModalAdmin";

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "rut", label: "RUT", align: 'center' },
    { type: "text", field: "name", label: "NOMBRES", align: 'center' },
    { type: "text", field: "surname", label: "APELLIDOS", align: 'center' },
    { type: "text", field: "email", label: "EMAIL", align: 'center' },
    { type: "text", field: "phone", label: "TELÉFONO", align: 'center' },
    {
        type: "text", field: "address", label: "DIRECCIÓN", align: 'center', format: (row: Technical) => {
            const address = row.address === null ? "No registra" : row.address
            return address;
        }
    },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const TechnicalList: FC<any> = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<Technical>({} as Technical);
    const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
    const [userRut, setUserRut] = useState<string>('');
    const [showModalIdentification, setShowModalIdentification] = useState<boolean>(false);

    const { forgotPasswordUser } = useUserStore()
    const {
        technicals,
        status,
        getTechnicalStore,
        setSelectedTechnical,
        deleteTechnicalStore,
    } = useTechnicalStore();

    useEffect(() => {
        getTechnicalApi();
    }, [search]);

    const getTechnicalApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getTechnicalStore(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        navigate({ pathname: RoutesMap.TECHNICAL_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedTechnical(rowSelected);
        navigate({ pathname: RoutesMap.TECHNICAL_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteTechnicalStore(rowSelected.id);
        if (response === true) {
            setSelectedTechnical({} as Technical);
            setModalDeleteConfirm(false);
            getTechnicalApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onPersonIcon = (row) => {
        setUserRut(row.rut)
        setShowModalIdentification(true)
    }

    const sendCredentials = async (rowSelected) => {
        setLoading(true)
        await forgotPasswordUser(rowSelected.rut, 9)
        setLoading(false)
    }

    return (
        <>
            <CustomTable
                title={"Técnicos"}
                columns={columns}
                loading={loading}
                rows={technicals || []}
                onRowClick={() => { }}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                onPersonIcon={onPersonIcon}
                onHelper={sendCredentials}
                tooltip_helper={'Enviar credenciales'}
                icon_helper={<ContactMailIcon fontSize="small" />}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                />
            )}
            {
                showModalIdentification && (
                    <IdentificationModalAdmin
                        open={showModalIdentification}
                        closeModal={() => setShowModalIdentification(false)}
                        rut={userRut}
                    />
                )
            }
        </>
    )
}

export default TechnicalList