import { useDispatch, useSelector } from "react-redux"
import { selectInformMechanicalState, changeStatus, onFetchInformsMechanical, onSetEditInformMechanical } from "../redux/slices/informMechanicalSlice"
import { ApiStatus } from "../types/api/status"
import { InformMechanicalAPI } from "../apis/InformMechanicalAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { InformMechanical } from "../types/slices/informMechanicalType"

export const useInformMechanicalStore = () => {
    const dispatch = useDispatch()
    const { informsMechanical, status, editInformMechanical } = useSelector(selectInformMechanicalState)

    const getInformsMechanical = async (data?:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await InformMechanicalAPI.getInformsMechanical(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchInformsMechanical(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }
    const createInformMechanical = async (data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await InformMechanicalAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateInformMechanical = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await InformMechanicalAPI.update(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteInformMechanical = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await InformMechanicalAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditInformMechanical = (inform:InformMechanical) => {
        try {
            dispatch(onSetEditInformMechanical(inform))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        informsMechanical,
        status,
        editInformMechanical,
        getInformsMechanical,
        createInformMechanical,
        updateInformMechanical,
        deleteInformMechanical,
        setEditInformMechanical
    }
}