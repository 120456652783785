import { useDispatch, useSelector } from "react-redux";
import { TypeVehicleAPI } from "../apis/TypeVehicleAPI";
import { selectTypeVehicleState, changeStatus, onFetchTypeVehicle, onSetEditTypeVehicle, onSetSelectedTypeVehicle } from "../redux/slices/typeVehicleSlice"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { TypeVehicle } from "../types/slices/typeVehicleType";

export const useTypeVehicleStore = () => {
    const dispatch = useDispatch();
    const {
        typeVehicles,
        status: typeVehicleStatus,
        editTypeVehicle,
        selectedTypeVehicle
    } = useSelector(selectTypeVehicleState);

    const getTypeVehicles = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeVehicleAPI.getTypeVehicle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchTypeVehicle(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createTypeVehicleStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeVehicleAPI.createTypeVehicle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editTypeVehicleStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeVehicleAPI.editTypeVehicle(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteTypeVehicleStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TypeVehicleAPI.deleteTypeVehicle(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedTypeVehicle = (type: TypeVehicle) => {
        try {
            dispatch(onSetSelectedTypeVehicle(type))
        } catch (error) {
            console.log(error)
        }
    };

    const setEditTypeVehicle = (type: TypeVehicle) => {
        try {
            dispatch(onSetEditTypeVehicle(type));
        } catch (error) {
            console.log(error)
        }
    };

    return {
        //states
        typeVehicles,
        typeVehicleStatus,
        editTypeVehicle,
        selectedTypeVehicle,
        //actions
        getTypeVehicles,
        createTypeVehicleStore,
        editTypeVehicleStore,
        deleteTypeVehicleStore,
        setSelectedTypeVehicle,
        setEditTypeVehicle
    }
}