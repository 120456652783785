import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuthStore } from "../../../hooks";
import { styled } from '@mui/material/styles'
import { Nav } from "../../common/Nav/Nav";
import { Grid } from "@mui/material";
import { Role } from "../../../types/roles/roleTypes";
import { HeaderView } from "../../common/Header/Header";

export const Layout: React.FC<any> = (): JSX.Element | any => {
    const { user } = useAuthStore();
    const userRole: Role | any = user.userType;

    const [openNew, setOpenNew] = useState(false)
    const APP_BAR_MOBILE = 64
    // const APP_BAR_DESKTOP = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN || user.userType == Role.MAINTENANCE) ? 80 : 75
    const APP_BAR_DESKTOP = 80

    const StyledRoot = styled('div')({
        display: 'flex',
        minHeight: '100%',
    });

    const Main = styled('div')(({ theme }) => ({
        flexGrow: 1,
        height: `calc(100VH - ${75}px)`,
        marginTop: APP_BAR_MOBILE,
        paddingTop: 10,
        background: 'rgb(250, 250, 251)',
        overflow: 'scroll',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            height: `calc(100VH - ${75}px)`,
            marginTop: APP_BAR_DESKTOP,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: 10,
            overflow: 'scroll',
            overflowX: 'hidden',
        },
    }));

    return (
        <>
            <StyledRoot>
                {
                    [Role.SUPER_ADMIN, Role.ADMIN, Role.MAINTENANCE, Role.SUPERVISOR, Role.MECHANIC, Role.DRIVER, Role.TECHNICAL, Role.CUSTOMER].includes(userRole) ? (

                        <Nav openNav={openNew} onCloseNav={() => setOpenNew(false)} />
                    ) : (
                        <HeaderView />
                    )
                }
                <Main >
                    {
                        [Role.SUPER_ADMIN, Role.ADMIN, Role.MAINTENANCE, Role.SUPERVISOR, Role.MECHANIC, Role.DRIVER, Role.TECHNICAL, Role.CUSTOMER].includes(userRole) ? (
                            <Grid sx={{ minHeight: 600, background: '#fff', borderRadius: 2, padding: 3, /* border:'solid 1px black' */ }}>
                                <Outlet />
                            </Grid>
                        )
                            : (
                                <Grid sx={{ minHeight: 600, background: '#fff', borderRadius: 2, /* border:'solid 1px black'  */ }}>
                                    <Outlet />
                                </Grid>
                            )
                    }
                </Main>
            </StyledRoot>

        </>
    )
}