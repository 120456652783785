import { FC, useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import FilterReport from "./FilterReport";
import { PatternRAPI } from "../../apis/PatternRAPI";
import { usePatternRStore } from "../../hooks/usePatternRStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

const columsDetail = [
    // { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "correlative", label: "CORRELATIVO", align: 'center' },
    { type: "text", field: "name_mechanic", label: "MECÁNICO", align: 'center' },
    { type: "text", field: "rut_mechanic", label: "RUT MECÁNICO", align: 'center' },
    { type: "text", field: "patent", label: "VEHÍCULO", align: 'center' },
    { type: "text", field: "execution_date", label: "EJECUCIÓN", align: 'center' },
    { type: "text", field: "status_name", label: "ESTADO", align: 'center', color: (row) => row.status_report == 0 ? '#2FB449' : row.status_report == 1 ? '#E98116' : 'red' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
];

const columnsGroupVehicle = [
    { type: "text", field: "patent", label: "VEHÍCULO", align: 'center' },
    { type: "text", field: "n_realizado", label: "PAUTAS RELIZADAS A TIEMPO", align: 'center', color: (row) => '#2FB449' },
    { type: "text", field: "n_fuera_tiempo", label: "PAUTAS REALIZADAS FUERA DE TIEMPO", align: 'center', color: (row) => '#E98116' },
    { type: "text", field: "n_no_realizado", label: "PAUTAS NO REALIZADAS", align: 'center', color: (row) => 'red' },
];

const columnsGroupByMechanic = [
    { type: "text", field: "name_mechanic", label: "MECÁNICO", align: 'center' },
    { type: "text", field: "n_realizado", label: "PAUTAS RELIZADAS A TIEMPO", align: 'center', color: (row) => '#2FB449' },
    { type: "text", field: "n_fuera_tiempo", label: "PAUTAS REALIZADAS FUERA DE TIEMPO", align: 'center', color: (row) => '#E98116' },
    // { type: "text", field: "n_no_realizado", label: "PAUTAS NO REALIZADAS", align: 'center',  color: (row) => 'red' },
];

const ReportPatternR: FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [dataDetail, setDataDetail] = useState<any>([]);
    const { status: patternStatus, getReportPatternR, getReportPatternRDetail } = usePatternRStore()

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [isResponsive, setIsResponsive] = useState<boolean>(window.innerWidth <= 760);

    const [paramsFilter, setParamsFilter] = useState<any>(null);
    const [rowSelected, setRowSelected] = useState<any>(null)

    const getReport = async (params) => {
        //consumo api
        //seteo data
        setParamsFilter(params)
        const reponse = await getReportPatternR(params);
        setData(reponse)
        if (reponse.length == 1) {
            await onRowClick(reponse[0], params)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const handleResize = (): void => {
            setIsResponsive(window.innerWidth <= 760);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onRowClick = async (row, filters) => {
        console.log(row)
        setRowSelected(row);

        let params = {
            ...filters,
            idvehicle: row?.idvehicle || null,
            idmechanic: row?.idmechanic || null
        }
        const response = await getReportPatternRDetail(params);
        setDataDetail(response)
    }

    return (
        <>
            {patternStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <Grid item xs={12}>
                <Grid item container sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography variant="h6"
                        component="h1"
                        gutterBottom
                        color="#808080"
                        className="tittle"
                        sx={{ mt: 1, ml: 1, fontWeight: 600 }}>REPORTE DE PAUTA R</Typography>
                </Grid>
                <Grid item container xs={12} sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: !isResponsive ? "10px" : "30px",
                }}>
                    <FilterReport loading={setLoading} getReport={getReport} setRowSelected={setRowSelected} />
                </Grid>
                {!!paramsFilter && <CustomTable
                    columns={paramsFilter?.groupBy == 1 ? columnsGroupVehicle : columnsGroupByMechanic}
                    loading={loading}
                    rows={data || []}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={(row) => onRowClick(row, paramsFilter)}
                />}
                {!!rowSelected &&
                    <Grid container mt={5}>
                        <CustomTable
                            title={!!rowSelected?.patent ? 'Detalle de ' + rowSelected?.patent : 'Detalle de ' + rowSelected?.name_mechanic}
                            columns={columsDetail}
                            loading={loading}
                            rows={dataDetail || []}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            onRowClick={() => { }}
                        />
                    </Grid>

                }
            </Grid>
        </>
    );
};

export default ReportPatternR;
