import { api } from "../configs/axiosConfigs"

export const ASTAPI = {
    getASTs: async (data?: any) => {
        const response = await api.get(
            '/ast',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },

    getOneAST: async (id: number | string) => {
        const response = await api.get(
            `/ast/${id}`
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },

    create: async (data: any) => {
        const response = await api.post(
            '/ast/register', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },

    edit: async (id: number, data: any) => {
        const response = await api.patch(
            `/ast/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },

    deleteDocument: async (id: number) => {
        const response = await api.delete(`/ast/${id}`, {
            params: {
                idcompany: 1
            }
        })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    deleteAstOneGroup: async (iddocument: number, iddetail: number) => {
        const response = await api.delete(`/ast/group/${iddocument}`, {
            params: {
                iddetail
            }
        })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    deleteAstOneCriticalRisk: async (iddocument: number, iddetail: number) => {
        const response = await api.delete(`/ast/criticalRisk/${iddocument}`, {
            params: {
                iddetail
            }
        })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    getASTPDF: async (id: number) => {
        const response = await api.get(
            `/ast/pdf/${id}`,
            {
                params: {
                    idcompany: 1
                },
                responseType: 'blob'
            }
        ).then(response => response)
            .catch((error) => {
                console.error(error)
                return error.response
            })
        return response
    },
    signature: async (id: number, data: any) => {
        const response = await api.post(
            `/signingAST/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    getReport: async (data?: any) => {
        const response = await api.get(
            '/ast/report',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
}