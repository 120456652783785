import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CustomTable from "../../../components/common/CustomTable/CustomTable";
import { useCustomerIncompleteStore } from "../../../hooks/useCustomerIncompleteStore";
import { selectHeaderState } from "../../../redux/slices/headerSlice";
import { CustomerIncompleteModalEdit } from "./CustomerIncompleteModalEdit";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name_rz', label: 'NOMBRE', align: 'center' },
    { type: 'text', field: 'fantasy_name', label: 'NOMBRE DE FANTASÍA', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELÉFONO', align: 'center' },
    { type: 'text', field: 'email', label: 'EMAIL', align: 'center' },
]

const CustomerIncompleteList: FC = () => {
    const { search } = useSelector(selectHeaderState)
    const { customersIncomplete, getCustomersIncompletes, setEditCustomerIncomplete } = useCustomerIncompleteStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<any>({})
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false)

    useEffect(() => {
        getCustomersApi()
    }, [search])

    const getCustomersApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search,
                }
            }
            await getCustomersIncompletes(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onEdit = (rowSelected) => {
        setRowSelected(rowSelected)
        setEditCustomerIncomplete(rowSelected)
        setShowModalEdit(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <>
            <Grid item xs={12}>
                <CustomTable
                    title={'Clientes en espera'}
                    columns={columns || []}
                    loading={loading}
                    rows={customersIncomplete.filter((customer) => customer.status_import === "0") || []}
                    onRowClick={() => { }}
                    onEdit={onEdit}
                    hasOptions
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
            {
                showModalEdit && (
                    <CustomerIncompleteModalEdit
                        open={showModalEdit}
                        closeModal={() => { setShowModalEdit(false) }}
                        onList={() => getCustomersApi()}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}

export default CustomerIncompleteList