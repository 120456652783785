import { api } from "./configs/axiosConfigs"

export const PatternRAPI = {
    get: async (data?: any) => {
        const response = await api.get(
            '/patternR',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getOne: async (id:number|string) => {
        const response = await api.get(
            `/patternR/${id}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getReport: async (params) => {
        const response = await api.get(
            `/patternR/reportByVehicle`, {
                params: {
                    ...params
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getReportDetail: async (params) => {
        const response = await api.get(
            `/patternR/reportByVehicleDetail`, {
                params: {
                    ...params
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/patternR/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.patch(
        `/patternR/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
        `/patternR/${id}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    getPatternRPDF: async (id:number, idcompany:number) => {
        const response = await api.get(
        `/patternR/pdf/${id}`,
        {
            params: {
                idcompany
            },
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
    signature: async (id: number, data:any) => {
        const response = await api.patch(
        `/patternR/signature/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
}