import { FC, useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import FilterReport from "./FilterReport";
import { usePatternRStore } from "../../hooks/usePatternRStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { usePatternTStore } from "../../hooks/usePatternTStore";
import FilterReportMaintenanceExternal from "./FilterReportMaintenanceExternal";
import { useExternalMaintenanceStore } from "../../hooks/useExternalMaintenanceStore";

const columns = [
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'type_vehicle', label: 'TIPO DE VEHÍCULO', align: 'center' },
    { type: 'text', field: 'model_vehicle', label: 'MODELO', align: 'center' },
    { type: 'text', field: 'odometer', label: 'ODÓMETRO', align: 'center', format: (row: any) => `${row.odometer} Km` },
    { type: 'text', field: 'execution_date', label: 'FECHA DE EJECUCION', align: 'center' },
    { type: 'text', field: 'main_companybusiness_name', label: 'EMPRESA EJECUTORA', align: 'center' },
    { type: 'text', field: 'main_company_rut', label: 'RUT EMPRESA', align: 'center' },
    { type: 'text', field: 'status_name', label: 'ESTADO', align: 'center', color: (row) => row.status == 1 ? "#2FB449" : "red" },
];

const ReportMaintenanceExternal: FC = () => {
    
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [dataDetail, setDataDetail] = useState<any>([]);
    const { status: patternStatus, reportExternalMaintenance } = useExternalMaintenanceStore()

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [isResponsive, setIsResponsive] = useState<boolean>(window.innerWidth <= 760);
   
    const [paramsFilter, setParamsFilter ] = useState<any>(null);
    const [rowSelected, setRowSelected] = useState<any>(null)

    const getReport = async (params) => {
       setParamsFilter(params)
      const reponse = await reportExternalMaintenance(params);
      setData(reponse)
    }

    const handleChangePage = (event, newPage) => {
       setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
       setRowsPerPage(+event.target.value);
       setPage(0);
   };

   useEffect(() => {
       const handleResize = (): void => {
           setIsResponsive(window.innerWidth <= 760);
       };

       window.addEventListener('resize', handleResize);
       return () => {
           window.removeEventListener('resize', handleResize);
       };
   }, []);

   return (
       <>
           { patternStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
           <Grid item xs={12}>
               <Grid item container sx={{
                   alignItems: "center",
                   display: "flex",
                   justifyContent: "center",
                   flexWrap: "wrap",
                   marginBottom: "-5px",
               }}>
                   <Typography variant="h6"
                       component="h1"
                       gutterBottom
                       color="#808080"
                       className="tittle"
                       sx={{ mt: 1, ml: 1, fontWeight: 600 }}>REPORTE DE MANTENCIONES EXTERNAS</Typography>
               </Grid>
               <Grid item container xs={12} sx={{
                   alignItems: "center",
                   display: "flex",
                   justifyContent: "center",
                   flexWrap: "wrap",
                   marginBottom: !isResponsive ? "10px" : "30px",
               }}>
                   <FilterReportMaintenanceExternal loading={setLoading} getReport={getReport} setRowSelected={setRowSelected}/>
               </Grid>
               { !!paramsFilter &&<CustomTable
                   columns={ columns }
                   loading={loading}
                   rows={data || []}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   handleChangePage={handleChangePage}
                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                   onRowClick={()=>{}}
               />}
           </Grid>
       </>
   );
};

export default ReportMaintenanceExternal;
