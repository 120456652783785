import { Button, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export const CustomFormHeader = (props:any) => {
  const { title, text, className="", clearState, goBack } = props;
  const navigate = useNavigate()

  return (
    <DialogTitle className="modal-header">
      <div className={className}>
        <div className={className}>
            {
                goBack && (
                    <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft:'-25px'}}>
                        <IconButton size="small" color="primary" aria-label="view" onClick={() =>  navigate({ pathname: goBack}) }>
                            <ArrowBackIcon fontSize='small'/>
                        </IconButton>
                        <Typography>Volver</Typography>
                    </Grid>
                )
            }
            
            <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ fontWeight:600}}>
              {title && (title).toUpperCase()}
            </Typography>
            <Typography color='#808080' sx={{ fontSize:14, fontWeight:100}}>
                {text}
            </Typography>
        </div>    
      </div>
    </DialogTitle>
  )
}