import { api } from "./configs/axiosConfigs";

export const ContrataAPI = {
    getContrata: async (data?: any) => {
        const response = await api
            .get("/contrata", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createContrata: async (data: any) => {
        const response = await api
            .post("/contrata/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editContrata: async (id: number, data: any) => {
        const response = await api
            .patch(`/contrata/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteContrata: async (id: number) => {
        const response = await api
            .delete(`/contrata/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
};
