import { useDispatch, useSelector } from "react-redux";
import { changeStatus, onFetchDETs, onSetEditDET, onSetIdWorkOrderDET, selectDETState } from "../redux/slices/DETSlice";
import { ApiStatus } from "../types/api/status";
import { DETAPI } from "../apis/DETAPI";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { DET } from "../types/slices/DETType";

export const useDETStore = () => {
    const dispatch = useDispatch()
    const { DETs, status, selectedDET, editDET } = useSelector(selectDETState)

    const getDETs = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.getDETs(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchDETs(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getOneDET = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.getOne(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchDETs(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createDET = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.create(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return response.data.detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateDET = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.edit(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return response.data.detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteDET = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.delete(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditDET = (det: DET) => {
        try {
            dispatch(onSetEditDET(det))
        } catch (error) {
            console.log(error)
        }
    }

    const setEditIdWorkOrder = (id: number) => {
        try {
            dispatch(onSetIdWorkOrderDET(id))
        } catch (error) {
            console.log(error)
        }
    }

    const getDETPDF = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.getPDF(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message)
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    const signature = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.signature(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const validateDET = async (id: number, status_det: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.validate(id, status_det)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getDETReport = async (params) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DETAPI.getReport(params)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    return {
        DETs,
        status,
        selectedDET,
        editDET,
        getDETs,
        getOneDET,
        createDET,
        updateDET,
        setEditDET,
        setEditIdWorkOrder,
        getDETPDF,
        signature,
        deleteDET,
        validateDET,
        getDETReport
    }
}