import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { ControlCriticalRisk, controlCriticalRiskType } from "../../types/slices/controlCriticalRiskType";

const initialState: controlCriticalRiskType = {
    status: ApiStatus.FETCHED,
    controlCriticalRisks: [],
    errorMessage: undefined,
    selectedControlCriticalRisk: {} as ControlCriticalRisk,
};

const controlCriticalRiskSlice = createSlice({
    name: "controlCriticalRisk",
    initialState,
    reducers: {
        onFetchControlCriticalRisk(state, { payload }: { payload: ControlCriticalRisk[] }) {
            state.status = ApiStatus.FETCHED;
            state.controlCriticalRisks = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedControlCriticalRisk(state, { payload }: { payload: ControlCriticalRisk }) {
            state.selectedControlCriticalRisk = payload;
        },
    }
})

export const selectControlCriticalRiskState = (state: RootState) => state.controlCriticalRiskSlice;

export default controlCriticalRiskSlice.reducer;

export const {
    onFetchControlCriticalRisk,
    changeStatus,
    onSetSelectedControlCriticalRisk,
} = controlCriticalRiskSlice.actions;