import { useEffect, useState } from 'react'
import { Grid, TextField, Paper, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'
import './CustomSearchByTableStyle.css'
import { useDispatch } from 'react-redux'
import { onSetSearch } from '../../../redux/slices/headerSlice'

export const CustomSearchByTable = (props: any) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState<string | number | undefined>(undefined);

    useEffect(() => {
        clearTimeout(searchTimeout);
        setSearchTimeout(
            window.setTimeout(() => {
                dispatch(onSetSearch(search))
            }, 500)
        )
    }, [search]);

    const handleSearch = (event: any) => {
        setSearch(event.target.value);
    }

    const clearSearch = () => {
        setSearch("");
        dispatch(onSetSearch(""))
    }

    return (
        <Paper elevation={5} className='paper-search-table'>
            <Grid className='main-container'>
                <TextField
                    type='text'
                    value={search}
                    onChange={(e) => { handleSearch(e) }}
                    placeholder={'Buscador'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            search ? (
                                <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={() => clearSearch()}>
                                    <CloseIcon fontSize="small" />
                                </InputAdornment>
                            ) : null
                        )
                    }}
                    sx={{ width: 350 }}
                    size="small"
                />
            </Grid>
        </Paper>
    )
}