import { api } from "../configs/axiosConfigs";

export const EnergieAPI = {
    getEnergie: async (data?: any) => {
        const response = await api
            .get("/energie", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createEnergie: async (data: any) => {
        const response = await api
            .post("/energie/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editEnergie: async (id: number, data: any) => {
        const response = await api
            .patch(`/energie/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteEnergie: async (id: number) => {
        const response = await api
            .delete(`/energie/${id}`, {
                params: {
                    idcompany: 1
                }
            })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}