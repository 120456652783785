import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useStructureD1Store } from '../../hooks/useStructureD1Store';
import { StructureD1 } from '../../types/slices/structureD1Type';
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { ApiStatus } from '../../types/api/status';

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "work_done", label: "TRABAJO REALIZADO", align: 'left' },
    { type: "text", field: "name_groupActivityD1", label: "GRUPO DE ACTIVIDAD", align: 'center' },
    {
        type: "text", field: "vehicle_types", label: "TIPO DE VEHÍCULOS", format: (row: StructureD1) => row?.vehicle_types && row?.vehicle_types.length > 0 ? row.vehicle_types.map((vehicles: any) => vehicles.name).join(', ') : "No especificado", align: 'center'
    },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const StructureD1List: FC = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<StructureD1>(
        {} as StructureD1
    );
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);

    const {
        structureD1,
        status: structureD1Status,
        getStructureD1Store,
        setSelectedStructureD1,
        deleteStructureD1Store
    } = useStructureD1Store();


    useEffect(() => {
        getStructureD1Api();
    }, [search]);

    const getStructureD1Api = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            await getStructureD1Store(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        navigate({ pathname: RoutesMap.STRUCTURE_D1_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedStructureD1(rowSelected)
        navigate({ pathname: RoutesMap.STRUCTURE_D1_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteStructureD1Store(rowSelected.id);
        if (response === true) {
            setSelectedStructureD1({} as StructureD1);
            setModalDeleteConfirm(false);
            getStructureD1Api();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Grid>
                <CustomTable
                    title={"Trabajos efectuados - Pauta R"}
                    columns={columns}
                    loading={loading}
                    rows={structureD1 || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={structureD1Status === ApiStatus.FETCHING ? true : false}
                />
            )}
        </>
    )
}

export default StructureD1List