import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { WorkDone, WorkDoneType } from "../../types/slices/workDondeType";
import { RootState } from "../store";

const initialState:WorkDoneType = {
    status: ApiStatus.FETCHED,
    workDones: [],
    errorMessage: undefined,
    editWorkDone: {} as WorkDone
}

const WorkDoneSlice = createSlice({
    name: 'WorkDone',
    initialState,
    reducers: {
        onFetchWorkDones (state, { payload }: { payload: WorkDone[] }) {
            state.status            = ApiStatus.FETCHED
            state.workDones         = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditWorkDone (state, { payload }: { payload: WorkDone }) {
            state.editWorkDone = payload
        }
    }
})

export const selectWorkDoneState = (state: RootState) => state.WorkDoneSlice
export default WorkDoneSlice.reducer

export const {
    onFetchWorkDones,
    changeStatus,
    onSetEditWorkDone
} = WorkDoneSlice.actions