import { ChangeEvent, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, Link, Paper, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useAuthStore } from '../../hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { RoutesMap } from '../../types';
import logo from '../../assets/image/_login.png';
import { Role } from '../../types/roles/roleTypes';
import './Login.css';
import { Formik } from 'formik';
import { ModalForgotPassword } from './components/ModalForgotPassword';

const themeAvenir = createTheme({
    typography: {
        fontFamily: 'Avenir',
        fontSize: 15
    },
})

const roles = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Superadministrador' },
    // { id: 3, name: 'Supervisor' },
    // { id: 4, name: 'Jefe de Mantenimiento' },
    // { id: 5, name: 'Mecánico' },
    // { id: 6, name: 'Conductor' }
    { id: 8, name: 'Cliente' },
    { id: 9, name: 'Técnico' },
]

type Values = {
    rut: string,
    password: string,
    perfil: number | undefined | null,
    idcompany?: number | undefined | null,
}

export const LoginView: React.FC<any> = (props: any): JSX.Element | any => {

    const { login } = useAuthStore();
    const navigate = useNavigate();


    const [loading, setLoading] = useState(false);
    const [selectedRol, setSelectedRol] = useState<any>('Administrador');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [data, setData] = useState<Values>({
        rut: '',
        password: '',
        perfil: 2,
        idcompany: 1
    });
    const [showModalForgotPassword, setShowModalForgotPassword] = useState<boolean>(false)
    const [selectedRolNum, setSelectedRolNum] = useState<any>(2)

    const handleChangeTypeUser = (event: React.SyntheticEvent, role_user: string) => {
        switch (role_user) {
            case Role.SUPER_ADMIN:
                setData(prev => ({ ...prev, perfil: 1, idcompany: 1 }))
                setSelectedRolNum(1)
                break

            case Role.ADMIN:
                setData(prev => ({ ...prev, perfil: 2, idcompany: 1 }))
                setSelectedRolNum(2)
                break

            case Role.CUSTOMER:
                setData(prev => ({ ...prev, perfil: 8, idcompany: 1 }))
                setSelectedRolNum(8)
                break

            case Role.TECHNICAL:
                setData(prev => ({ ...prev, perfil: 9, idcompany: 1 }))
                setSelectedRolNum(9)
                break
            // case Role.SUPERVISOR:
            //     setData(prev => ({ ...prev, perfil: 3, idcompany: 1 }))
            //     setSelectedRolNum(3)
            //     break

            // case Role.MAINTENANCE:
            //     setData(prev => ({ ...prev, perfil: 4, idcompany: 1 }))
            //     setSelectedRolNum(4)
            //     break

            // case Role.DRIVER:
            //     setData(prev => ({ ...prev, perfil: 5, idcompany: 1 }))
            //     setSelectedRolNum(5)
            //     break

            // case Role.MECHANIC:
            //     setData(prev => ({ ...prev, perfil: 6, idcompany: 1 }))
            //     setSelectedRolNum(6)
            //     break

            default:
                break
        }
        setSelectedRol(role_user);
    };

    const validateForm = (values) => {
        let errors: any = {};
        let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
        if (!values.rut) errors.rut = "rut requerido";
        if (values.rut && !(regRut.test(values.rut))) errors.rut = "rut no válido";
        if (!values.password) errors.password = "contraseña requerida";
        return errors;
    }

    const handleClickShowPassword = () => {
        if (showPassword) setShowPassword(false)
        if (!showPassword) setShowPassword(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    // const createAccount = () => { }

    const onSubmit = async (values) => {
        const { rut, password, perfil, idcompany } = values
        onSendLogin(perfil, password, rut, idcompany)
    }

    const onSendLogin = async (perfil, password, rut, idcompany) => {
        setLoading(true)
        try {
            const success: any = await login({ iduser_type: perfil, password, rut, idcompany });
            if (!success?.status) {
                setLoading(false)
                return
            }

            let role = success?.detail?.userType;
            let status = success?.detail?.status_confirm;

            if (status === "0") {
                switch (role) {
                    case Role.SUPER_ADMIN:
                    case Role.ADMIN:
                    case Role.TECHNICAL:
                    case Role.CUSTOMER:
                        // case Role.SUPERVISOR:
                        // case Role.MAINTENANCE:
                        // case Role.MECHANIC:
                        // case Role.DRIVER:
                        navigate({ pathname: RoutesMap.CHANGE_PASSWORD }, { replace: true })
                        break
                    default:
                        break
                }
            } else {
                switch (role) {
                    case Role.SUPER_ADMIN:
                        navigate({ pathname: RoutesMap.ADMINISTRATOR }, { replace: true })
                        break
                    case Role.ADMIN:
                        navigate({ pathname: RoutesMap.FAIL_LIST }, { replace: true })
                        break
                    // case Role.SUPERVISOR:
                    //     navigate({ pathname: RoutesMap.REPAIR_REQUEST }, { replace: true })
                    //     break
                    // case Role.MAINTENANCE:
                    //     navigate({ pathname: RoutesMap.MECHANICS }, { replace: true })
                    //     break
                    // case Role.MECHANIC:
                    //     navigate({ pathname: RoutesMap.PT_R_LIST }, { replace: true })
                    //     break
                    // case Role.DRIVER:
                    //     navigate({ pathname: RoutesMap.REPAIR_REQUEST }, { replace: true })
                    //     break
                    case Role.TECHNICAL:
                        navigate({ pathname: RoutesMap.TECHNICAL_REPORT }, { replace: true })
                        break
                    case Role.CUSTOMER:
                        navigate({ pathname: RoutesMap.REPAIR_REQUEST }, { replace: true })
                        break
                    default:
                        break
                }
            }
            setLoading(false)
        } catch (error) {
            setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }))
            setLoading(false)
        }
    }

    return (
        <Box className='login-main-container'>
            <Grid container justifyContent="center">
                <Grid item xs={12} sx={{ backgroundColor: '#212D39', height: '60px', display: 'flex', alignItems: 'center' }}>
                    <Tabs
                        sx={{ flex: 1, maxWidth: { xs: 380, sm: 480, md: 900, lg: 900, xl: 900 }, color: '#FFF' }}
                        value={selectedRol}
                        onChange={handleChangeTypeUser}
                        variant="scrollable"
                        scrollButtons allowScrollButtonsMobile>
                        {
                            roles && roles.map((role) => (
                                <Tab sx={{ color: '#fff', fontSize: '11px' }} value={role.name} label={role.name} />
                            ))
                        }
                    </Tabs>
                    <Button sx={{ color: '#FFF', fontSize: '11px', marginLeft: 'auto', marginRight: '35px' }} onClick={() => { navigate({ pathname: RoutesMap.PUBLIC_REPAIR_REQUEST }) }}>
                        REGISTRAR SOLICITUD
                    </Button>
                </Grid>

                <Grid item xs={12} className='login-container' >
                    <Grid item xs={12} sm={12} md={5} lg={5}
                        sx={{ textAlign: { md: 'end', xs: 'start' }, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
                        {/* <img style={{ width: '300px' }} src={logo} alt="" /> */}
                    </Grid>

                    <Grid item xs={12} sm={10} md={6} lg={5} sx={{ margin: '0px auto', minWidth: '200px' }}>
                        <Paper elevation={5} sx={{ borderRadius: '16px', backgroundColor: 'whitesmoke' }}>
                            <Grid xs={12} sx={{ height: '40px', backgroundColor: '#212D39', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}></Grid>
                            <Grid sx={{ margin: { xs: '10px', sm: '10 px 20px', md: '10px 20px', lg: '10px 20px', xl: '10px 20px' } }}>
                                {/* Title */}
                                <Grid item xs={12} display="flex" justifyContent="center" textAlign="center">
                                    <Stack direction="row" justifyContent="start">
                                        <Typography variant='h5' color='#212D39' sx={{ fontWeight: 600, margin: '0px auto' }}>INGRESA TU USUARIO</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center" textAlign="center">
                                    <Stack direction="row" justifyContent="start">
                                        <Typography variant='body2' color='#808080' sx={{ fontWeight: 400, paddingBottom: 3 }}>Completa el formulario con tus datos:</Typography>
                                    </Stack>
                                </Grid>
                                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <ThemeProvider theme={themeAvenir}>
                                                    <Grid container spacing={2}>
                                                        <Grid item container xs={12} spacing={2}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id="rut"
                                                                    name='rut'
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    value={values.rut}
                                                                    onChange={handleChange}
                                                                    placeholder='Ingrese su RUT'
                                                                    size='small'
                                                                    type="text"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            maxLength: 10
                                                                        },
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>
                                                                                <PersonIcon sx={{ color: '#29333B' }} />
                                                                            </InputAdornment>)
                                                                    }}
                                                                    error={errors.rut && touched.rut ? true : false}
                                                                    helperText={errors.rut && touched.rut ? errors.rut : ''}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id="password"
                                                                    name="password"
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    size='small'
                                                                    placeholder='Ingresa su clave'
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'  >
                                                                                <LockIcon sx={{ color: '#29333B' }} />
                                                                            </InputAdornment>),
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleClickShowPassword}
                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                    edge="end"
                                                                                    sx={{ color: '#29333B' }}
                                                                                >
                                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    error={errors.password && touched.password ? true : false}
                                                                    helperText={errors.password && touched.password ? errors.password : ''}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} display='flex' justifyContent='center'>
                                                                <Link color="inherit" sx={{ cursor: 'pointer', textDecoration: 'underline', fontSize: '13px' }} onClick={() => { setShowModalForgotPassword(true) }}>
                                                                    Olvidé mi contraseña
                                                                </Link>
                                                            </Grid>
                                                            <Grid item xs={12} display='flex' justifyContent='center'>
                                                                <Button
                                                                    type="submit"
                                                                    disabled={loading}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ width: '150px', background: '#73B2FF', color: '#fff', '&:hover': { bgcolor: '#007ea7' } }}
                                                                    onClick={() => { handleSubmit() }}
                                                                >
                                                                    {loading ? <CircularProgress size={28} /> : 'Continuar'}
                                                                </Button>
                                                            </Grid>

                                                            <Grid item xs={12} display='flex' justifyContent='center'>
                                                                <Button
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ width: '150px', background: '#FFFFFF', color: '#73B2FF', border: 'solid 1px #73B2FF', '&:hover': { bgcolor: '#73B2FF', color: '#FFFFFF' } }}
                                                                >
                                                                    Crear cuenta
                                                                </Button>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Divider variant="middle" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </ThemeProvider>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {
                showModalForgotPassword && (
                    <ModalForgotPassword
                        open={showModalForgotPassword}
                        closeModal={() => { setShowModalForgotPassword(false) }}
                        role={selectedRolNum}
                    />
                )
            }
        </Box >
    )
}
