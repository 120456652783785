import { useDispatch, useSelector } from "react-redux"
import { selectReplacementPartState, changeStatus, onFetchReplacementParts, onSetEditReplacementPart } from "../redux/slices/replacementPartSlice"
import { ApiStatus } from "../types/api/status"
import { ReplacementPartAPI } from "../apis/ReplacementPartAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { ReplacementPart } from "../types/slices/replacementPartType"

export const useReplacementPartStore = () => {
    const dispatch = useDispatch()
    const { replacementParts, status, editReplacementPart } = useSelector(selectReplacementPartState)

    const getReplacementParts = async (data?:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReplacementPartAPI.getReplacementParts(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchReplacementParts(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createReplacementPart = async (data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReplacementPartAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateReplacementPart = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReplacementPartAPI.update(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteReplacementPart = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReplacementPartAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditReplacementPart = (part:ReplacementPart) => {
        try {
            dispatch(onSetEditReplacementPart(part))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        replacementParts,
        status,
        editReplacementPart,
        getReplacementParts,
        createReplacementPart,
        updateReplacementPart,
        deleteReplacementPart,
        setEditReplacementPart
    }
}