import { useEffect, useRef, useState } from "react"
import { Crop, PixelCrop } from "../../../types/typeComponents/CropType"
import { centerCrop, makeAspectCrop } from "../crop/utils"
import { useDebounceEffect } from "../crop/useDebounceEffect"
import { canvasPreview } from "./canvasPreview"
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { Modal } from "../../../components/common/Modal/Modal"
import { Button, CircularProgress, FormLabel, Grid, Typography } from "@mui/material"
import ReactCrop from 'react-image-crop'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useIdentifyImage } from "../../../hooks/useIdentifyImage"
import { ApiStatus } from "../../../types/api/status"
import { useSelector } from "react-redux"
import { selectIdentifyState } from "../../../redux/slices/identifySlice"

import imglyRemoveBackground from "@imgly/background-removal"

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    )
}

export const IdentificationCropModal: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, typeImage, onList } = props

    const { IdentifiesFiles } = useSelector(selectIdentifyState)
    const { status: statusIdentify, updateOrCreateIdentify } = useIdentifyImage()

    const [ nameFile, setNameFile ]       = useState<string>('')
    const [ updateFile,  setUpdateFile  ] = useState<any>(null)

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)

    const [loading, setLoading] = useState<boolean>(false)
    const [image, setImage] = useState<File|null>(null)

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
            reader.readAsDataURL(e.target.files[0])

            //codigo adicional
            const files = (e.target && e.target.files) || []
            const titleCert = files[0]
            if (titleCert) {
              setUpdateFile(titleCert)
              setNameFile(titleCert.name)
            }
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
            }
        },
        100,
        [completedCrop, scale, rotate]
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            setCrop(centerAspectCrop(width, height, 16 / 9))
        }
    }

    const onSave = async () => {
        if (completedCrop && previewCanvasRef.current) {
            previewCanvasRef.current.toBlob((blob) => {
                if (blob) {
                    const image = new File([blob], 'image.png', { type: 'image/png' })
                    if (typeImage !== 'signature') {
                        handleSubmit(image)
                    } else {
                        handleRemoveBackground(image)
                    }
                }
            }, 'image/jpeg');
        }
    }

    const handleSubmit = async (image:File|null) => {
        if (!image) return
        const payload = new FormData()
        switch (typeImage) {
            case 'front':
                payload.append('front_identification', image)
                break;

            case 'reverse':
                payload.append('reverse_identification', image)
                break;
            
            case 'person':
                payload.append('person_identification', image)
                break;
        }
        const response = await updateOrCreateIdentify(payload)
        if (response === true) {
            closeModal()
            onList()
        }
    }

    const handleRemoveBackground = async (image:File|null) => {
        if (!image) return;
        try {
            setLoading(true)
            const imageBuffer = await image.arrayBuffer()
            imglyRemoveBackground(imageBuffer).then((blob: Blob) => {
                if (blob) {
                    const file = new File([blob], 'signature.png', { type: 'image/png' });
                    const payload = new FormData()
                    payload.append('identification_signature', file)
                    setLoading(false)
                    updateOrCreateIdentify(payload)
                    closeModal()
                    onList()
                }
            });
        } catch (error) {
          console.error('Error removing background:', error);
        }
      };

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder', marginBottom: '20px' }}>
                    CONFIRMAR IMAGEN
                </Typography>
                <Grid container rowSpacing={1} spacing={1}>
                    <Grid item container sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                            <FormLabel>Seleccione una imagen:</FormLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Button 
                                variant="contained"
                                component="label"
                                style={{ maxWidth: '100%', width: '100%' }}
                                sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}        
                            >
                                <FileUploadIcon fontSize="small" />
                                {nameFile || ''}
                                <input
                                    style={{ display: 'none' }}
                                    type='file'
                                    name='file'
                                    onChange={onSelectFile}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    {!!imgSrc && (
                        <Grid item xs={12} sx={{ margin: '0px auto'}}>
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={c => setCompletedCrop(c)}
                                aspect={aspect}
                                style={{ background: '#FFFFFF', width: '100%' }}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, width: '100%' }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </Grid>
                    )}
                    {completedCrop && (
                        <Grid item xs={12}>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <FormLabel sx={{ color: '#000000', fontWeight: 'bolder' }}>Imagen a guardar:</FormLabel>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Grid item>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {
                            completedCrop &&  previewCanvasRef && (
                                <Button
                                    onClick={() => onSave()}
                                    sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                                >
                                    {
                                        statusIdentify === ApiStatus.FETCHING || loading
                                        ?
                                        <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                        :
                                        'Confirmar'
                                    }
                                </Button>
                            )
                        }
                            <Button
                                disabled={statusIdentify === ApiStatus.FETCHING || loading ? true : false}
                                onClick={() => closeModal()}
                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}