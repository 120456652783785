import React, { useEffect, useState } from 'react'
import { CustomForm } from '../../components/common/CustomForm/CustomForm'
import { CustomFormHeader } from '../../components/common/CustomForm/CustomFormHeader'
import { CustomFormBody } from '../../components/common/CustomForm/CustomFormBody'
import { Formik } from 'formik'
import { Divider, FormLabel, Grid, TextField } from '@mui/material'
import { CustomPhoneInput } from '../../components/common/CustomPhoneInput/CustomPhoneInput'
import { CustomFormFooter } from '../../components/common/CustomForm/CustomFormFooter'
import { useNavigate } from 'react-router-dom'
import { RoutesMap } from '../../types'
import { useCustomerStore } from '../../hooks/useCustomerStore'
import { Customer } from '../../types/slices/customerType'
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop'
import { ApiStatus } from '../../types/api/status'

type ClientDataForm = {
    id: number
    rut: string
    name_rz: string
    fantasy_name: string
    giro: string
    phone: string
    email: string
    address: string
    fax: string
}

const ClientForm = () => {
    const navigate = useNavigate()
    const { status, selectedCustomer, setSelectedCustomer, createCustomerStore, editCustomerStore } = useCustomerStore()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<ClientDataForm>({
        id: 0,
        rut: '',
        name_rz: '',
        fantasy_name: '',
        giro: '',
        phone: '',
        email: '',
        address: '',
        fax: '',
    })

    useEffect(() => {
        if (Object.entries(selectedCustomer).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedCustomer.id,
                rut: selectedCustomer.rut,
                name_rz: selectedCustomer.name_rz,
                fantasy_name: selectedCustomer.fantasy_name,
                giro: selectedCustomer.giro,
                phone: selectedCustomer.phone,
                email: selectedCustomer.email,
                address: selectedCustomer.address,
                fax: selectedCustomer.fax,
            });
            setSelectedCustomer({} as Customer);
        }
    }, []);

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.rut) errors.rut = "RUT es requerido";
        if (!values.name_rz) errors.name_rz = "Nombre es requerido";
        // if (!values.fantasy_name) errors.fantasy_name = "Nombre de fantasía (?) es requerido";
        if (!values.giro) errors.giro = "Giro es requerido";
        // if (!values.phone) errors.phone = "Celular es requerido";
        if (!values.address) errors.address = "Dirección es requerida";
        if (!values.email) errors.email = "Email es requerido";
        // if (!values.fax) errors.fax = "Fax es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const payload = {
            "rut": values.rut,
            "name_rz": values.name_rz,
            "fantasy_name": values.fantasy_name,
            "giro": values.giro,
            "phone": values.phone,
            "email": values.email,
            "address": values.address,
            "fax": values.fax,
        }
        const method = !isEdit ? createCustomerStore(payload) : editCustomerStore(data.id, payload)
        const response = await method
        if (response.status === true) {
            navigate({ pathname: RoutesMap.CLIENT })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.CLIENT })
    }

    return (
        <CustomForm>
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo Cliente' : 'Editar Cliente'}
                text={!isEdit ? 'Ingrese los datos del nuevo cliente' : 'Ingrese los datos del cliente'}
                goBack={RoutesMap.CLIENT}
            >
            </CustomFormHeader>
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="name_rz"
                                                type="text"
                                                name="name_rz"
                                                fullWidth
                                                size="small"
                                                value={values.name_rz}
                                                onChange={handleChange}
                                                error={errors.name_rz && touched.name_rz ? true : false}
                                                helperText={errors.name_rz && touched.name_rz ? errors.name_rz : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombre de fantasía:</FormLabel>
                                            <TextField
                                                id="fantasy_name"
                                                type="text"
                                                name="fantasy_name"
                                                fullWidth
                                                size="small"
                                                value={values.fantasy_name}
                                                onChange={handleChange}
                                                error={errors.fantasy_name && touched.fantasy_name ? true : false}
                                                helperText={errors.fantasy_name && touched.fantasy_name ? errors.fantasy_name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>RUT:</FormLabel>
                                            <TextField
                                                id="rut"
                                                type="text"
                                                name="rut"
                                                fullWidth
                                                size="small"
                                                value={values.rut}
                                                onChange={handleChange}
                                                error={errors.rut && touched.rut ? true : false}
                                                helperText={errors.rut && touched.rut ? errors.rut : ''}
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: 10
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Giro:</FormLabel>
                                            <TextField
                                                id="giro"
                                                type="text"
                                                name="giro"
                                                fullWidth
                                                size="small"
                                                value={values.giro}
                                                onChange={handleChange}
                                                error={errors.giro && touched.giro ? true : false}
                                                helperText={errors.giro && touched.giro ? errors.giro : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Celular:</FormLabel>
                                            <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Email:</FormLabel>
                                            <TextField
                                                id="email"
                                                type="email"
                                                name="email"
                                                fullWidth
                                                size="small"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={errors.email && touched.email ? true : false}
                                                helperText={errors.email && touched.email ? errors.email : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Dirección:</FormLabel>
                                            <TextField
                                                id="address"
                                                type="text"
                                                name="address"
                                                fullWidth
                                                size="small"
                                                value={values.address}
                                                onChange={handleChange}
                                                error={
                                                    errors.address && touched.address
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.address && touched.address
                                                        ? errors.address
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Fax:</FormLabel>
                                            <TextField
                                                id="fax"
                                                type="text"
                                                name="fax"
                                                fullWidth
                                                size="small"
                                                value={values.fax}
                                                onChange={handleChange}
                                                error={errors.fax && touched.fax ? true : false}
                                                helperText={errors.fax && touched.fax ? errors.fax : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default ClientForm