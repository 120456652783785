import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { WorkOrder } from "../../types/slices/workOrderType";
import { useWorkOrderStore } from "../../hooks/useWorkOrderStore";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { WorkOrderModalValidate } from "./WorkOrderModalValidate";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { WorkOrderModalInformation } from "./WorkOrderModalInformation";
import { useDETStore } from "../../hooks/useDETStore";
import { DET } from "../../types/slices/DETType";
import { useASTStore } from "../../hooks/useAST";
import { AST } from "../../types/slices/ASTType";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";
import { useVerificationsStore } from "../../hooks/useVerifications";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO SOLICITUD', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'name_trademark_vehicle', label: "MARCA", align: 'center' },
    { type: 'text', field: 'name_model_vehicle', label: "MODELO", align: 'center' },
    { type: 'text', field: 'start_date', label: 'FECHA DE CREACIÓN', align: 'center' },
    { type: 'text', field: 'status_ot_text', label: 'ESTADO REVISIÓN TÉCNICA', align: 'center' },
    { type: 'text', field: 'status_det', label: 'ESTADO DET', align: 'center', format: (row) => row.status_det === '1' ? 'Realizado' : 'No realizado' },
    { type: 'text', field: 'status_ast', label: 'ESTADO AST', align: 'center', format: (row) => row.status_ast === '1' ? 'Realizado' : 'No realizado' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsSupervisor = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO SOLICITUD', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'name_trademark_vehicle', label: "MARCA", align: 'center' },
    { type: 'text', field: 'name_model_vehicle', label: "MODELO", align: 'center' },
    { type: 'text', field: 'start_date', label: 'FECHA DE CREACIÓN', align: 'center' },
    { type: 'text', field: 'mechanic', label: 'MECÁNICO', align: 'center' },
    { type: 'text', field: 'mechanic_rut', label: 'RUT MECÁNICO', align: 'center' },
    { type: 'text', field: 'status_ot_text', label: 'ESTADO REVISIÓN TÉCNICA', align: 'center' },
    { type: 'text', field: 'status_det', label: 'ESTADO DET', align: 'center', format: (row) => row.status_det === '1' ? 'Realizado' : 'No realizado' },
    { type: 'text', field: 'status_ast', label: 'ESTADO AST', align: 'center', format: (row) => row.status_ast === '1' ? 'Realizado' : 'No realizado' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsAdmin = [
    { type: 'text', field: 'correlative', label: 'CORRELATIVO SOLICITUD', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'name_trademark_vehicle', label: "MARCA", align: 'center' },
    { type: 'text', field: 'name_model_vehicle', label: "MODELO", align: 'center' },
    { type: 'text', field: 'start_date', label: 'FECHA DE CREACIÓN', align: 'center' },
    { type: 'text', field: 'mechanic', label: 'MECÁNICO', align: 'center' },
    { type: 'text', field: 'mechanic_rut', label: 'RUT MECÁNICO', align: 'center' },
    { type: 'text', field: 'status_ot_text', label: 'ESTADO REVISIÓN TÉCNICA', align: 'center' },
    { type: 'text', field: 'status_det', label: 'ESTADO DET', align: 'center', format: (row) => row.status_det === '1' ? 'Realizado' : 'No realizado' },
    { type: 'text', field: 'status_ast', label: 'ESTADO AST', align: 'center', format: (row) => row.status_ast === '1' ? 'Realizado' : 'No realizado' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const WorkOrderList: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status: workOrderStatus, workOrders, getWorkOrders, setEditWorkOrder, deleteWorkOrder } = useWorkOrderStore()
    const { setEditIdWorkOrder, setEditDET } = useDETStore()
    const { setEditIdWorkOrderAST, setSelectedAST } = useASTStore()
    const { getWorkDones } = useWorkDoneStore()
    const { getVerificationsStore } = useVerificationsStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<WorkOrder>({} as WorkOrder)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [showModalValidate, setShowModalValidate] = useState<boolean>(false)
    const [showModalInformation, setShowModalInformation] = useState<boolean>(false)

    const [isSnackbarShown, setIsSnackbarShown] = useState<boolean>(false);

    const userRole: Role | any = user.userType

    useEffect(() => {
        getWorkOrderApi()
    }, [search])

    useEffect(() => {
        if (!isSnackbarShown && userRole === Role.SUPERVISOR && workOrders.length > 0 && workOrders?.find(doc => doc.status_ot === "0")) {
            CustomSnackbar('earring', "Tienes pre - órdenes de trabajo pendientes de validación");
            setIsSnackbarShown(true);
        }
        if (!isSnackbarShown && userRole === Role.MECHANIC && workOrders.length > 0 && workOrders?.find(doc => doc.status_ot === "1" && doc.status_det === "0")) {
            CustomSnackbar('document', "Tienes órdenes de trabajo pendientes de documento DET");
            setIsSnackbarShown(true);
        }
        if (!isSnackbarShown && userRole === Role.MECHANIC && workOrders.length > 0 && workOrders?.find(doc => doc.status_ot === "1" && doc.status_det === "1" && doc.status_ast === '0')) {
            CustomSnackbar('document', "Tienes órdenes de trabajo pendientes de documento AST");
            setIsSnackbarShown(true);
        }
    }, [workOrders, isSnackbarShown]);

    const getWorkOrderApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.MECHANIC) {
                payload = {
                    ...payload,
                    idmechanic: user?.data?.id
                }
            }
            await getWorkOrders(payload)
            await getWorkDones()
            await getVerificationsStore()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setEditWorkOrder({} as WorkOrder)
        navigate({ pathname: RoutesMap.WORK_ORDER_FORM })
    }

    const onEdit = (rowSelected) => {
        if (userRole === Role.MECHANIC && rowSelected.status_ot === '2') return CustomSnackbar('error', 'La revisión técnica de la OT ha sido rechazada.')
        if (userRole === Role.MECHANIC && rowSelected.status_ot === '1' && rowSelected.status_det === '0') return CustomSnackbar('warning', 'Realize el DET de la orden de trabajo.')
        if (userRole === Role.MECHANIC && rowSelected.status_ot === '1' && rowSelected.status_ast === '0') return CustomSnackbar('warning', 'Realize el AST de la orden de trabajo.')
        if (userRole === Role.MECHANIC && rowSelected.status_ot === '1' && rowSelected.status_boss === '1') return CustomSnackbar('error', 'La orden de trabajo está firmada por el jefe de mantenimineto por lo tanto ya no puede ser modificada.')
        setEditWorkOrder(rowSelected)
        navigate({ pathname: RoutesMap.WORK_ORDER_FORM })
    }

    const onDelete = (rowSelected) => {
        if (userRole === Role.MECHANIC && rowSelected.status_boss === '1') return CustomSnackbar('error', 'La orden de trabajo está firmada por el jefe de mantenimineto por lo tanto ya no puede ser eliminada.')
        if (userRole === Role.MECHANIC && rowSelected.status_ot === '1') return CustomSnackbar('error', 'La pre orden de trabajo está aprobada por el jefe de mantenimineto por lo tanto ya no puede ser eliminada.')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteWorkOrder(rowSelected.id)
        if (response === true) {
            setEditWorkOrder({} as WorkOrder)
            setShowModalConfirm(false)
            getWorkOrderApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onDetails = (rowSelected) => {
        navigate(`/work-order-signature/${rowSelected.id}`)
    }

    const onValidateOT = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalValidate(true)
    }

    const onCreateDET = (rowSelected) => {
        setEditDET({} as DET)
        setEditIdWorkOrder(rowSelected.id)
        navigate({ pathname: RoutesMap.DET_FORM })
    }

    const onCreateAST = async (rowSelected) => {
        setSelectedAST({} as AST)
        setEditIdWorkOrderAST(rowSelected.id)
        navigate({ pathname: RoutesMap.AST_FORM })
    }

    const onInfo = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalInformation(true)
    }

    return (<>
        <Grid item xs={12}>
            {/* {loading && <CustomBackdrop open={true} />} */}
            {
                userRole === Role.MECHANIC && (
                    <CustomTable
                        title={'ORDENES DE TRABAJO'}
                        columns={columns || []}
                        loading={loading}
                        rows={workOrders || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onInfo={onInfo}
                        onDetails={onDetails}
                        onCreateDET={onCreateDET}
                        onCreateAST={onCreateAST}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                (userRole === Role.SUPERVISOR) && (
                    <CustomTable
                        title={'ORDENES DE TRABAJO'}
                        columns={columnsSupervisor || []}
                        loading={loading}
                        rows={workOrders || []}
                        onRowClick={() => { }}
                        hasOptions
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onInfo={onInfo}
                        onDetails={onDetails}
                        onValidateOT={onValidateOT}
                        icon_onValidateOT={<PendingActionsIcon fontSize="small" />}
                        tooltip_onValidateOT={'Validar'}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                (userRole === Role.MAINTENANCE) && (
                    <CustomTable
                        title={'ORDENES DE TRABAJO'}
                        columns={columnsSupervisor || []}
                        loading={loading}
                        rows={workOrders || []}
                        onRowClick={() => { }}
                        hasOptions
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onInfo={onInfo}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole === Role.ADMIN && (
                    <CustomTable
                        title={'ORDENES DE TRABAJO'}
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={workOrders || []}
                        onRowClick={() => { }}
                        onInfo={onInfo}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={() => { setShowModalConfirm(false) }}
                    onCancel={() => { setShowModalConfirm(false) }}
                    onConfirm={onDeleteConfirm}
                    status2={workOrderStatus === ApiStatus.FETCHING ? true : false}
                />
            )
        }
        {
            showModalValidate && (
                <WorkOrderModalValidate
                    open={showModalValidate}
                    closeModal={() => { setShowModalValidate(false) }}
                    onList={() => getWorkOrderApi()}
                    rowSelected={rowSelected}
                />
            )
        }
        {
            showModalInformation && (
                <WorkOrderModalInformation
                    open={showModalInformation}
                    closeModal={() => { setShowModalInformation(false) }}
                    rowSelected={rowSelected}
                />
            )
        }
    </>)
}