import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSupervisorStore } from "../../hooks/useSupervisorStore";
import { Supervisor } from "../../types/slices/supervisorsType";
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomPhoneInput } from "../../components/common/CustomPhoneInput/CustomPhoneInput";


type SupervisorDataForm = {
    id: number;
    name: string;
    surname: string;
    rut: string;
    email: string;
    phone: string;
};

type errorsForm = {
    name?: string;
    surname?: string;
    rut?: string;
    email?: string;
    phone?: string;
};

const SupervisorForm: FC = () => {
    const navigate = useNavigate();
    const {
        status: supervisorStatus,
        selectedSupervisor,
        setSelectedSupervisor,
        createSupervisorStore,
        editSupervisorStore,
    } = useSupervisorStore();

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<SupervisorDataForm>({
        id: 0,
        rut: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
    });

    useEffect(() => {
        if (Object.entries(selectedSupervisor).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedSupervisor.id,
                rut: selectedSupervisor.rut,
                name: selectedSupervisor.name,
                surname: selectedSupervisor.surname,
                email: selectedSupervisor.email,
                phone: selectedSupervisor.phone,
            });
            setSelectedSupervisor({} as Supervisor);
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            rut: values.rut,
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
        };
        const method = !isEdit
            ? createSupervisorStore(payload)
            : editSupervisorStore(data.id, payload);
        const response = await method;
        if (response === true) {
            navigate({ pathname: RoutesMap.SUPERVISORS });
        }
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.SUPERVISORS });
    };

    const validateForm = (values: any) => {
        let errors: errorsForm = {};
        if (!values.name) errors.name = "El nombre requerido";
        if (!values.surname) errors.surname = "El apellido requerido";
        if (!values.rut) errors.rut = "El RUT es requerido";
        if (!values.email) errors.email = "El email es requerido";
        if (!values.phone) errors.phone = "El número de celular es requerido";
        return errors;
    };

    return (
        <CustomForm>
            {supervisorStatus === ApiStatus.FETCHING && (
                <CustomBackdrop open={true} />
            )}
            <CustomFormHeader
                title={!isEdit ? "Nuevo Supervisor" : "Editar Supervisor"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo supervisor"
                        : "Ingrese los datos del supervisor"
                }
                goBack={RoutesMap.SUPERVISORS}
            ></CustomFormHeader>

            <CustomFormBody>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validate={(values) => validateForm(values)}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text"
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={
                                                errors.name && touched.name
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.name && touched.name
                                                    ? errors.name
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Apellido:</FormLabel>
                                        <TextField
                                            id="surname"
                                            type="text"
                                            name="surname"
                                            fullWidth
                                            size="small"
                                            value={values.surname}
                                            onChange={handleChange}
                                            error={
                                                errors.surname &&
                                                    touched.surname
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.surname &&
                                                    touched.surname
                                                    ? errors.surname
                                                    : ""
                                            }
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>RUT:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text"
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={
                                                errors.rut && touched.rut
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.rut && touched.rut
                                                    ? errors.rut
                                                    : ""
                                            }
                                            InputProps={{
                                                inputProps: { maxLength: 10 },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={2}
                                    sx={{ mt: 2, mb: 3 }}
                                >
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Email:</FormLabel>
                                        <TextField
                                            id="email"
                                            type="email"
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={
                                                errors.email && touched.email
                                                    ? true
                                                    : false
                                            }
                                            helperText={
                                                errors.email && touched.email
                                                    ? errors.email
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Celular:</FormLabel>
                                        <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={
                                        !isEdit ? "Guardar" : "Actualizar"
                                    }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    );
};

export default SupervisorForm;
