import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Button, CircularProgress, FormLabel, Grid, TextField, Typography } from "@mui/material"
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useState } from "react"
import { useTechnicalReportStore } from "../../hooks/useTechnicalReportStore"
import { ImageFile } from "../../types/slices/technicalreportType"

export const TechnicalReportImage: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, typeImage, onList } = props

    const { images, setFetchImages } = useTechnicalReportStore()

    const [nameFile, setNameFile]= useState<string>('')
    const [updateFile, setUpdateFile] = useState<any>(null)
    const [comment, setComment] = useState<any>(null)

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        const files = (e.target && e.target.files) || []
        const titleCert = files[0]
        if (titleCert) {
          setUpdateFile(titleCert)
          setNameFile(titleCert.name)
        }
    }

    const onSave = () => {
        const n = images.length + 1
        const image:ImageFile ={
            id: null,
            n: n,
            image: updateFile,
            file_name: nameFile,
            comment: comment,
            status: '1'
        }
        setFetchImages([...images, image])
        closeModal()
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder', marginBottom: '20px' }}>
                    AGREGAR IMAGEN
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Seleccione una imagen:</FormLabel>
                        <Button 
                            variant="contained"
                            component="label"
                            style={{ maxWidth: '100%', width: '100%' }}
                            sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}        
                        >
                            <FileUploadIcon fontSize="small" />
                            {nameFile || ''}
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                name='file'
                                onChange={onSelectFile}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel>Comentario:</FormLabel>
                        <TextField
                            id="comment"
                            type="text"
                            name="comment"
                            fullWidth
                            size="small"
                            value={comment || ''}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Grid>
                </Grid>
                
                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => onSave()}
                            disabled={updateFile === null}
                            sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                        >
                            Confirmar
                        </Button>
                        <Button
                            onClick={() => closeModal()}
                            sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#212D39" } }}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}