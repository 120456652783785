import { useDispatch, useSelector } from "react-redux";
import { ModelVehicleAPI } from "../apis/ModelVehicleAPI";
import { selectModelVehicleState, changeStatus, onFetchModelVehicle, onSetEditModelVehicle, onSetSelectedModelVehicle } from "../redux/slices/modelVehicleSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { ModelVehicle } from "../types/slices/modelVehicleType";

export const useModelVehicleStore = () => {
    const dispatch = useDispatch();
    const {
        modelVehicles,
        status: modelVehicleStatus,
        editModelVehicle,
        selectedModelVehicle
    } = useSelector(selectModelVehicleState);

    const getModelVehicles = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelVehicleAPI.getModelVehicle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchModelVehicle(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createModelVehicleStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelVehicleAPI.createModelVehicle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editModelVehicleStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelVehicleAPI.editModelVehicle(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteModelVehicleStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ModelVehicleAPI.deleteModelVehicle(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedModelVehicle = (model: ModelVehicle) => {
        try {
            dispatch(onSetSelectedModelVehicle(model));
        } catch (error) {
            console.log(error);
        }
    }

    const setEditModelVehicle = (model: ModelVehicle) => {
        try {
            dispatch(onSetEditModelVehicle(model));
        } catch (error) {
            console.log(error);
        }
    }

    return {
        //states
        modelVehicles,
        modelVehicleStatus,
        editModelVehicle,
        selectedModelVehicle,
        //actions
        getModelVehicles,
        createModelVehicleStore,
        editModelVehicleStore,
        deleteModelVehicleStore,
        setSelectedModelVehicle,
        setEditModelVehicle
    }

}