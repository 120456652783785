import { useDispatch, useSelector } from "react-redux"
import { selectCustomerIncompleteState, changeStatus, onFetchCustomerIncomplete, onSetEditCustomerIncomplete } from "../redux/slices/customerIncompleteSlice"
import { ApiStatus } from "../types/api/status"
import { CustomerIncompleteAPI } from "../apis/CustomerIncompleteAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { CustomerIncomplete } from "../types/slices/customerIncompleteType"

export const useCustomerIncompleteStore = () => {
    const dispatch = useDispatch()
    const { status, customersIncomplete, editCustomerIncomplete } = useSelector(selectCustomerIncompleteState)

    const getCustomersIncompletes = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CustomerIncompleteAPI.getCustomerIncomplete(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchCustomerIncomplete(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateCustomerIncomplete = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CustomerIncompleteAPI.updateCustomerIncomplete(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditCustomerIncomplete = (customer: CustomerIncomplete) => {
        try {
            dispatch(onSetEditCustomerIncomplete(customer))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        customersIncomplete,
        status,
        editCustomerIncomplete,
        getCustomersIncompletes,
        updateCustomerIncomplete,
        setEditCustomerIncomplete
    }
}