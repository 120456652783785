import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { InformMechanical } from "../../types/slices/informMechanicalType";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { useControlVerificationStore } from "../../hooks/useControlVerificationStore";
import { ControlVerification } from "../../types/slices/controlVerificationType";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name', label: 'DESCRIPCIÓN', align: 'center' },
    { type: 'text', field: 'name_category', label: 'CATEGORÍA', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const ControlVerificationList:FC = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status, controlVerifications, getControlVerifications, deleteControlVerification, setEditControlVerification } = useControlVerificationStore()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ page, setPage ] = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ rowSelected, setRowSelected ]           = useState<ControlVerification>({} as ControlVerification)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)

    useEffect(()=>{
        getApi()
    },[search])

    const getApi = async () => {
        try {
          setLoading(true)
          let payload:any = {}
          if (search !== '') {
            payload = {
                ...payload,
                search
            }
          }
          await getControlVerifications(payload)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        setEditControlVerification({} as ControlVerification)
        navigate({ pathname: RoutesMap.CONTROL_VERIFICATION_FORM })
    }

    const onEdit = (rowSelected) => {
        setEditControlVerification(rowSelected)
        navigate({ pathname: RoutesMap.CONTROL_VERIFICATION_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteControlVerification(rowSelected.id)
        if (response === true) {
            setEditControlVerification({} as ControlVerification)
            setShowModalConfirm(false)
            getApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    return(<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Controles DET'}
                columns={columns || []}
                loading={loading}
                rows={controlVerifications || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}