import { Button, Checkbox, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { StyledTableCell } from '../../toolbox/helpers/accordion-styled'
import { useSelector } from 'react-redux'
import { selectHeaderState } from '../../redux/slices/headerSlice'
import { useUserStore } from '../../hooks/useUserStore'
import { useNavigate } from 'react-router-dom'
import { RoutesMap } from '../../types'
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar'
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop'

type dataERP = {
    order: number
    rut: string
    name_rz: string
    name_fantasy: string
    giro: string
    phone: string
    email: string
    fax: string
    selected: boolean
}

const ImportCustomerERP = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { getCustomersERP, importCustomersERP, customersERP } = useUserStore()
    const [loading, setLoading] = useState<boolean>(false)
    const [loading2, setLoading2] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [dataTable, setDataTable] = useState<dataERP[]>([])

    useEffect(() => {
        getCustomerERPApi();
    }, [search]);

    useEffect(() => {
        setAllDataTable()
    }, [customersERP])

    const getCustomerERPApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getCustomersERP(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const setAllDataTable = () => {
        const data = customersERP.map((customer, index) => {
            return {
                order: index + 1,
                rut: customer.rut,
                name_rz: customer.nombre_rz,
                name_fantasy: customer.nombre_fantasia || "No registra",
                giro: customer.giro,
                phone: customer.telefono || "No registra",
                email: customer.email || "No registra",
                fax: customer.fax || "No registra",
                selected: false
            }
        })
        setDataTable(data || [])
    }

    const onChangeCheckBox = (e: any, order: number) => {
        const { checked } = e.target;
        const data = dataTable?.map(row => {
            if (order === row.order) {
                return {
                    ...row,
                    selected: checked ? true : false
                }
            }
            return row
        })
        setDataTable(data || [])
    }

    const handleAllChecked = () => {
        const data = dataTable?.map(row => {
            return {
                ...row,
                selected: Object.values(dataTable).every(value => value.selected === false) ? true : false
            }
        })
        setDataTable(data || [])
    }

    const handleSubmit = async () => {
        const customerSelected = dataTable.filter((customer) => customer.selected === true).map((element) => element.rut)
        setLoading2(true)
        if (customerSelected.length === 0) return CustomSnackbar('warning', 'Seleccione clientes del ERP SOFTNET para importar.')
        const payload = { rut_customers: customerSelected }
        const response = await importCustomersERP(payload)
        setLoading2(false)
        if (response === true) navigate({ pathname: RoutesMap.CLIENT })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    return (
        <Grid container>
            {loading2 && <CustomBackdrop open={true} />}
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                    IMPORTAR CLIENTES DEL ERP SOFTNET
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid item xs={12} sx={{ textAlign: "left", marginBottom: '10px' }}>
                    <Tooltip title={Object.values(dataTable).every(value => value.selected === false) ? "Seleccionar todos los usuarios" : "Deseleccionar todos los usuarios"}>
                        <Button
                            onClick={handleAllChecked}
                            size="small"
                            sx={{ margin: '10px 0', background: '#FFFFFF', color: '#73B2FF', border: 'solid 1px #73B2FF', '&:hover': { bgcolor: '#73B2FF', color: '#FFFFFF' } }}>
                            {Object.values(dataTable).every(value => value.selected === false) ? "Seleccionar todo" : "Deseleccionar todo"}
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "right", marginBottom: '10px', marginTop: '-64px' }}>
                    <Tooltip title={"Importar los clientes seleccionados del ERP"}>
                        <Button
                            onClick={handleSubmit}
                            size="small"
                            sx={{ margin: '10px 0', background: '#FFFFFF', color: '#73B2FF', border: 'solid 1px #73B2FF', '&:hover': { bgcolor: '#73B2FF', color: '#FFFFFF' } }}>
                            IMPORTAR
                        </Button>
                    </Tooltip>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ background: "#D8E8F7" }}>
                            <TableRow>
                                <StyledTableCell className="headTable" align="center" colSpan={1} sx={{ width: '10px' }}></StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>RUT</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>NOMBRE</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>NOMBRE DE FANTASÍA</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>GIRO</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>TELÉFONO</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>EMAIL</StyledTableCell>
                                <StyledTableCell className="headTable" align="center" colSpan={1}>FAX</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Skeleton variant="rectangular" width="100%" height={20} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                dataTable?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                    .map((row, index) =>
                                        <TableRow key={index}>
                                            <TableCell align="center" sx={{ width: '10px' }}>
                                                <Checkbox color="primary" onChange={(e) => onChangeCheckBox(e, row.order)} checked={row.selected} />
                                            </TableCell>
                                            <TableCell align="center">{row.order}</TableCell>
                                            <TableCell align="center">{row.rut}</TableCell>
                                            <TableCell align="center">{row.name_rz}</TableCell>
                                            <TableCell align="center">{row.name_fantasy}</TableCell>
                                            <TableCell align="center">{row.giro}</TableCell>
                                            <TableCell align="center">{row.phone}</TableCell>
                                            <TableCell align="center">{row.email}</TableCell>
                                            <TableCell align="center">{row.fax}</TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    dataTable.length > 1 &&
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[10, 25, 100]}
                        count={dataTable.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Grid>
        </Grid >
    )
}

export default ImportCustomerERP