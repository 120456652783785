import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { CriticalRisk } from "../../types/slices/criticalRiskType";
import { useCriticalRiskStore } from "../../hooks/useCriticalRisk";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name', label: 'NOMBRES', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const CriticalRiskList: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status, criticalRisks, getCriticalRisk, deleteCriticalRiskStore, setSelectedCriticalRisk } = useCriticalRiskStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<CriticalRisk>({} as CriticalRisk)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const userRole: Role | any = user.userType

    useEffect(() => {
        getCriticalRiskApi()
    }, [search])

    const getCriticalRiskApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    name: search
                }
            }
            await getCriticalRisk(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedCriticalRisk({} as CriticalRisk)
        navigate({ pathname: RoutesMap.CRITICAL_RISK_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedCriticalRisk(rowSelected)
        navigate({ pathname: RoutesMap.CRITICAL_RISK_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteCriticalRiskStore(rowSelected.id)
        if (response === true) {
            setSelectedCriticalRisk({} as CriticalRisk)
            setShowModalConfirm(false)
            getCriticalRiskApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    return (
        <>
            <Grid item xs={12}>
                {userRole === Role.ADMIN && (
                    <CustomTable
                        title={'Riesgos Críticos'}
                        columns={columns || []}
                        loading={loading}
                        rows={criticalRisks || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
                {userRole === Role.MAINTENANCE && (
                    <CustomTable
                        title={'Riesgos Críticos'}
                        columns={columns || []}
                        loading={loading}
                        rows={criticalRisks || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        status2={status === ApiStatus.FETCHING ? true : false}
                    />
                )
            }
        </>
    )
}

export default CriticalRiskList

