import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuthStore } from "./hooks/useAuthStore";
import { AuthStatus } from "./types/slices/authType";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { RoutesMap } from "./types/common/routes";
import { HomeView } from "./views/Home/HomeView";
import { LoginView } from "./views/Login/LoginView";
import { RestrictedRoute } from "./routes";
import { Protected } from "./components/Layout/Protected";
import { useSelector } from "./redux/store";
import { CustomBackdrop } from "./components/common/CustomBackdrop/CustomBackdrop";
import { MyProfileView } from "./views/MyProfile/MyProfile";
import "./App.css"; //no comentar
import Supervisors from "./views/Supervisors/Supervisors";
import { AdministratorView } from "./views/Administrator/Administrator";
import SupervisorForm from "./views/Supervisors/SupervisorForm";
import { CompanyList } from "./views/Company/Company";
import { AdministratorForm } from "./views/Administrator/AdministratorForm";
import { Toaster } from "react-hot-toast";
import Mechanics from "./views/Mechanics/Mechanics";
import { CompanyForm } from "./views/Company/CompanyForm";
import { DriverList } from "./views/Driver/DriverList";
import { DriverForm } from "./views/Driver/DriverForm";
import MechanicForm from "./views/Mechanics/MechanicForm";
import { BossMaintenances } from "./views/BossMaintenance/BossMaintenances"
import BossMaintenancesForm from "./views/BossMaintenance/BossMaintenancesForm";
import { PTRForm } from "./views/PTR/PTRForm";
import StructureD1List from "./views/StructureD1/StructureD1List";
import StructureD1Form from "./views/StructureD1/StructureD1Form";
import { PTRList } from "./views/PTR/PTRList";
import VehiclesList from "./views/Vehicles/VehiclesList";
import VehiclesForm from "./views/Vehicles/VehiclesForm";
import { PTRSignature } from "./views/PTR/PTRSignature";
import StructureD2List from "./views/StructureD2/StructureD2List";
import StructureD2Form from "./views/StructureD2/StructureD2Form";
import { PatternTList } from "./views/PatternT/PatternTList";
import { PatternTForm } from "./views/PatternT/PatternTForm";
import ExternalMaintenanceList from "./views/ExternalMaintenance/ExternalMaintenance";
import { ExternalMaintenanceForm } from "./views/ExternalMaintenance/ExternalMaintenanceForm";
import { PatternTSignature } from "./views/PatternT/PatternTSignature";
import { TypeVehicles } from "./views/TypeVehicles/TypeVehicles";
import { TypeVehiclesForm } from "./views/TypeVehicles/TypeVehiclesForm";
import { ModelVehicleList } from "./views/ModelVehicle/ModelVehicleList";
import { ModelVehicleForm } from "./views/ModelVehicle/ModelVehicleForm";
import RepairRequestForm from "./views/RepairRequest/RepairRequestForm";
import FormMaintenance from "./views/MaintenanceModel/FormMaintenance";
import ReportPatternR from "./views/Report/ReportPatternR";
import ReportPatternT from "./views/Report/ReportPatternT";
import MaintenanceModelInput from "./views/MaintenanceModel/MaintenanceModelInput";
import TrademarkVehicleList from "./views/TrademarkVehicle/TrademarkVehicleList";
import FormMaintenanceTrademark from "./views/MaintenanceTrademark/FormMaintenanceTrademark";
import MaintenanceTrademarkInput from "./views/MaintenanceTrademark/MaintenanceTrademarkInput";
import ReportMaintenanceExternal from "./views/Report/ReportMaintenanceExternal";
import { TrademarkVehicleForm } from "./views/TrademarkVehicle/TrademarkVehicleForm";
import RepairRequest from "./views/RepairRequest/RepairRequestList";
import { ASTList } from "./views/AST/ASTList";
import { ASTForm } from "./views/AST/ASTForm";
import { DETList } from "./views/DET/DETList";
import { DETForm } from "./views/DET/DETForm";
import { WorkOrderList } from "./views/WorkOrder/WorkOrderList";
import { WorkOrderForm } from "./views/WorkOrder/WorkOrderForm";
import { FailList } from "./views/Fail/FailList";
import { FailForm } from "./views/Fail/FailForm";
import { InformMechanicalList } from "./views/InformMechanical/InformMechanicalList";
import { InformMechanicalForm } from "./views/InformMechanical/InformMechanicalForm";
import { WorkDoneList } from "./views/WorkDone/WorkDoneList";
import { WorkDoneForm } from "./views/WorkDone/WorkDoneForm";
import { ReplacementPartList } from "./views/ReplacementPart/ReplacementPartList";
import { ReplacementPartForm } from "./views/ReplacementPart/ReplacementPartForm";
import { ControlVerificationList } from "./views/ControlVerification/ControlVerificationList";
import { ControlVerificationForm } from "./views/ControlVerification/ControlVerificationForm";
import ControlBarriersList from "./views/ControlBarriers/ControlBarriersList";
import ControlBarrierForm from "./views/ControlBarriers/ControlBarrierForm";
import MainStagesWorksList from "./views/MainStagesWorks/MainStagesWorksList";
import MainStagesWorksForm from "./views/MainStagesWorks/MainStagesWorksForm";
import UnwantedEventList from "./views/UnwantedEvents/UnwantedEventList";
import UnwantedEventForm from "./views/UnwantedEvents/UnwantedEventForm";
import EnergiesList from "./views/Energies/EnergiesList";
import EnegiesForm from "./views/Energies/EnegiesForm";
import { WorkOrderSignature } from "./views/WorkOrder/WorkOrderSignature";
import { DETSignature } from "./views/DET/DETSignature";
import CriticalRiskList from "./views/CriticalRisk/CriticalRiskList";
import CriticalRiskForm from "./views/CriticalRisk/CriticalRiskForm";
import ControlCriticalRiskList from "./views/ControlCriticalRisk/ControlCriticalRiskList";
import ControlCriticalRiskForm from "./views/ControlCriticalRisk/ControlCriticalRiskForm";
import { SettingImportERP } from "./views/ImportERP/SettingImportERP";
import ASTSignature from "./views/AST/ASTSignature";
import { RepairRequestPDF } from "./views/RepairRequest/RepairRequestPDF";
import { EmployeeIncompleteList } from "./views/ImportERP/EmployeeIncompleteList";
import AllNotifications from "./views/AllNotifications/AllNotifications";
import AutoLogin from "./views/AutoLogin/AutoLogin";
import { ReportRepairRequest } from "./views/Report/ReportRepairRequest";
import { ReportWorkOrder } from "./views/Report/ReportWorkOrder";
import { ReportDET } from "./views/Report/ReportDET";
import { ReportAST } from "./views/Report/ReportAST";
import ChangePassword from "./views/ChangePassword/ChangePassword";
import ClientList from "./views/Client/ClientList";
import ClientForm from "./views/Client/ClientForm";
import TechnicalList from "./views/Technical/TechnicalList";
import TechnicalForm from "./views/Technical/TechnicalForm";
import TypeAttentionForm from "./views/TypeAttention/TypeAttentionForm";
import TypeAttentionList from "./views/TypeAttention/TypeAttention";
import ImportCustomerERP from "./views/ImportERP/ImportCustomerERP";
import { TechnicalReportList } from "./views/TechnicalReport/TechnicalReportList";
import { TechnicalReportForm } from "./views/TechnicalReport/TechnicalReportForm";
import { TechnicalReportSignature } from "./views/TechnicalReport/TechnicalReportSignature";
import CustomerIncompleteList from "./views/ImportERP/CustomerIncomplete/CustomerIncompleteList";
import PublicRepairRequest from "./views/PublicRepairRequest/PublicRepairRequest";

function App() {
    /* hooks */
    const { verifySession, status, user } = useAuthStore();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const rut = queryParams.get('rut');
    const password = queryParams.get('password');
    const { alertType, message, show } = useSelector(
        (state) => state.snackbarSlice
    );
    const { showBackdrop } = useSelector((state) => state.backdropSlice);

    const [statusPage, setStatusPage] = useState<string>("")

    useEffect(() => {
        verifySession();
    }, []);

    useEffect(() => {
        if (user.status_confirm === "0") setStatusPage("0")
        if (user.status_confirm === "1") setStatusPage("1")
    }, [user])

    if (status === AuthStatus.VERIFYING) {
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <>
            <Routes>
                {/* RUTAS PUBLICAS */}
                {/* <Route
                    path="/"
                    element={<Navigate to={RoutesMap.MAIN} replace />}
                /> */}

                {/* RUTAS PRIVADAS */}
                {status === AuthStatus.NOT_AUTHENTICATED ? (
                    <>
                        {
                            rut && password ?
                                (<Route
                                    path="/login" element={<AutoLogin />} />) :
                                (<Route path="/login" element={<LoginView />} />)
                        }
                        <Route
                            path={"login/solicitudes"}
                            element={<PublicRepairRequest />}
                        />
                        <Route
                            path="/*"
                            element={<Navigate to={RoutesMap.LOGIN} replace />}
                        />
                    </>
                ) : statusPage === "0" ? (<Route element={<RestrictedRoute pathRequired={RoutesMap.CHANGE_PASSWORD} />}>
                    <Route path={RoutesMap.CHANGE_PASSWORD} element={<ChangePassword statusPage={setStatusPage} />} />
                </Route>) : (
                    <>
                        <Route path="/" element={<Protected />}>
                            {/* vita principal por default */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.LOGIN} />}>
                                <Route path={RoutesMap.LOGIN} element={<></>} />
                            </Route>

                            {/* ADMINISTRATOR */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.ADMINISTRATOR} />}>
                                <Route path={RoutesMap.ADMINISTRATOR} element={<AdministratorView />} />
                            </Route>
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.ADMINISTRATOR_FORM} />}>
                                <Route path={RoutesMap.ADMINISTRATOR_FORM} element={<AdministratorForm />} />
                            </Route>
                            {/* COMPANY */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.COMPANY} />}>
                                <Route path={RoutesMap.COMPANY} element={<CompanyList />} />
                            </Route>
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.COMPANY_FORM} />}>
                                <Route path={RoutesMap.COMPANY_FORM} element={<CompanyForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.NOTIFICATIONS} />}>
                                <Route path={RoutesMap.NOTIFICATIONS} element={<AllNotifications />} />
                            </Route>

                            {/* ------------------------- VIEWS ADMIN ------------------------------- */}

                            {/* CLIENT */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CLIENT} />}>
                                <Route path={RoutesMap.CLIENT} element={<ClientList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CLIENT_FORM} />}>
                                <Route path={RoutesMap.CLIENT_FORM} element={<ClientForm />} />
                            </Route>

                            {/* TECHNICAL */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TECHNICAL} />}>
                                <Route path={RoutesMap.TECHNICAL} element={<TechnicalList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TECHNICAL_FORM} />}>
                                <Route path={RoutesMap.TECHNICAL_FORM} element={<TechnicalForm />} />
                            </Route>

                            {/* TYPE ATTENTION */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TYPE_ATTENTION} />}>
                                <Route path={RoutesMap.TYPE_ATTENTION} element={<TypeAttentionList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TYPE_ATTENTION_FORM} />}>
                                <Route path={RoutesMap.TYPE_ATTENTION_FORM} element={<TypeAttentionForm />} />
                            </Route>

                            {/*DRIVER */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.DRIVER} />}>
                                <Route path={RoutesMap.DRIVER} element={<DriverList />} />
                            </Route>
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.DRIVER_FORM} />}>
                                <Route path={RoutesMap.DRIVER_FORM} element={<DriverForm />} />
                            </Route>

                            { /*MECHANIC */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MECHANICS} />}>
                                <Route path={RoutesMap.MECHANICS} element={<Mechanics />} />
                            </Route>
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MECHANICS_FORM} />}>
                                <Route path={RoutesMap.MECHANICS_FORM} element={<MechanicForm />} />
                            </Route>

                            { /*SUPERVISOR */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.SUPERVISORS} />} >
                                <Route path={RoutesMap.SUPERVISORS} element={<Supervisors />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.SUPERVISORS_FORM} />}>
                                <Route path={RoutesMap.SUPERVISORS_FORM} element={<SupervisorForm />} />
                            </Route>

                            {/* BOSS MAINTENANCE*/}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.BOSS_MAINTENANCE} />}>
                                <Route path={RoutesMap.BOSS_MAINTENANCE} element={<BossMaintenances />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.BOSS_MAINTENANCE_FORM} />}>
                                <Route path={RoutesMap.BOSS_MAINTENANCE_FORM} element={<BossMaintenancesForm />} />
                            </Route>

                            {/* STRUCTURE D1*/}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.STRUCTURE_D1} />}>
                                <Route path={RoutesMap.STRUCTURE_D1} element={<StructureD1List />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.STRUCTURE_D1_FORM} />}>
                                <Route path={RoutesMap.STRUCTURE_D1_FORM} element={<StructureD1Form />} />
                            </Route>

                            {/* STRUCTURE D2*/}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.STRUCTURE_D2} />}>
                                <Route path={RoutesMap.STRUCTURE_D2} element={<StructureD2List />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.STRUCTURE_D2_FORM} />}>
                                <Route path={RoutesMap.STRUCTURE_D2_FORM} element={<StructureD2Form />} />
                            </Route>

                            {/*VEHICLES*/}

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.VEHICLES} />}>
                                <Route path={RoutesMap.VEHICLES} element={<VehiclesList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.VEHICLES_FORM} />}>
                                <Route path={RoutesMap.VEHICLES_FORM} element={<VehiclesForm />} />
                            </Route>

                            { /*TYPE VEHICLES */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TYPE_VEHICLES} />} >
                                <Route path={RoutesMap.TYPE_VEHICLES} element={<TypeVehicles />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TYPE_VEHICLES_FORM} />}>
                                <Route path={RoutesMap.TYPE_VEHICLES_FORM} element={<TypeVehiclesForm />} />
                            </Route>

                            { /*MODEL VEHICLES */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MODEL_VEHICLES} />} >
                                <Route path={RoutesMap.MODEL_VEHICLES} element={<ModelVehicleList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MODEL_VEHICLES_FORM} />}>
                                <Route path={RoutesMap.MODEL_VEHICLES_FORM} element={<ModelVehicleForm />} />
                            </Route>

                            { /*TRADEMARK VEHICLES */}

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TRADEMARK_VEHICLES} />} >
                                <Route path={RoutesMap.TRADEMARK_VEHICLES} element={<TrademarkVehicleList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TRADEMARK_VEHICLES_FORM} />}>
                                <Route path={RoutesMap.TRADEMARK_VEHICLES_FORM} element={<TrademarkVehicleForm />} />
                            </Route>

                            { /*REPORTS */}

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_PAUTA_T} />}>
                                <Route path={RoutesMap.REPORT_PAUTA_T} element={<ReportPatternT />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_PAUTA_R} />}>
                                <Route path={RoutesMap.REPORT_PAUTA_R} element={<ReportPatternR />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_MANTENCIONES_EXTERNAS} />}>
                                <Route path={RoutesMap.REPORT_MANTENCIONES_EXTERNAS} element={<ReportMaintenanceExternal />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_REPAIR_REQUEST} />}>
                                <Route path={RoutesMap.REPORT_REPAIR_REQUEST} element={<ReportRepairRequest />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_WORK_ORDER} />}>
                                <Route path={RoutesMap.REPORT_WORK_ORDER} element={<ReportWorkOrder />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_DET} />}>
                                <Route path={RoutesMap.REPORT_DET} element={<ReportDET />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_AST} />}>
                                <Route path={RoutesMap.REPORT_AST} element={<ReportAST />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.WORK_ORDER_LIST} />}>
                                <Route path={RoutesMap.WORK_ORDER_LIST} element={<WorkOrderList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.WORK_ORDER_FORM} />}>
                                <Route path={RoutesMap.WORK_ORDER_FORM} element={<WorkOrderForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.FAIL_LIST} />}>
                                <Route path={RoutesMap.FAIL_LIST} element={<FailList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.FAIL_FORM} />}>
                                <Route path={RoutesMap.FAIL_FORM} element={<FailForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.INFORM_WORK_ORDER_LIST} />}>
                                <Route path={RoutesMap.INFORM_WORK_ORDER_LIST} element={<InformMechanicalList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.INFORM_WORK_ORDER_FORM} />}>
                                <Route path={RoutesMap.INFORM_WORK_ORDER_FORM} element={<InformMechanicalForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.WORK_DONE_LIST} />}>
                                <Route path={RoutesMap.WORK_DONE_LIST} element={<WorkDoneList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.WORK_DONE_FORM} />}>
                                <Route path={RoutesMap.WORK_DONE_FORM} element={<WorkDoneForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPLACEMENT_PART_LIST} />}>
                                <Route path={RoutesMap.REPLACEMENT_PART_LIST} element={<ReplacementPartList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPLACEMENT_PART_FORM} />}>
                                <Route path={RoutesMap.REPLACEMENT_PART_FORM} element={<ReplacementPartForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONTROL_VERIFICATION_LIST} />}>
                                <Route path={RoutesMap.CONTROL_VERIFICATION_LIST} element={<ControlVerificationList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONTROL_VERIFICATION_FORM} />}>
                                <Route path={RoutesMap.CONTROL_VERIFICATION_FORM} element={<ControlVerificationForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.WORK_ORDER_SIGNATURE} />}>
                                <Route path={RoutesMap.WORK_ORDER_SIGNATURE} element={<WorkOrderSignature />} />
                            </Route>
                            {/* ------------------------- STRUCTURES AST ------------------------ */}

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONTROL_BARRIER} />}>
                                <Route path={RoutesMap.CONTROL_BARRIER} element={<ControlBarriersList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONTROL_BARRIER_FORM} />}>
                                <Route path={RoutesMap.CONTROL_BARRIER_FORM} element={<ControlBarrierForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MAIN_STAGES_WORKS} />}>
                                <Route path={RoutesMap.MAIN_STAGES_WORKS} element={<MainStagesWorksList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MAIN_STAGES_WORKS_FORM} />}>
                                <Route path={RoutesMap.MAIN_STAGES_WORKS_FORM} element={<MainStagesWorksForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.UNWANTED_EVENT} />}>
                                <Route path={RoutesMap.UNWANTED_EVENT} element={<UnwantedEventList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.UNWANTED_EVENT_FORM} />}>
                                <Route path={RoutesMap.UNWANTED_EVENT_FORM} element={<UnwantedEventForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.ENERGIES} />}>
                                <Route path={RoutesMap.ENERGIES} element={<EnergiesList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.ENERGIES_FORM} />}>
                                <Route path={RoutesMap.ENERGIES_FORM} element={<EnegiesForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CRITICAL_RISK} />}>
                                <Route path={RoutesMap.CRITICAL_RISK} element={<CriticalRiskList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CRITICAL_RISK_FORM} />}>
                                <Route path={RoutesMap.CRITICAL_RISK_FORM} element={<CriticalRiskForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONTROL_CRITICAL_RISK} />}>
                                <Route path={RoutesMap.CONTROL_CRITICAL_RISK} element={<ControlCriticalRiskList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONTROL_CRITICAL_RISK_FORM} />}>
                                <Route path={RoutesMap.CONTROL_CRITICAL_RISK_FORM} element={<ControlCriticalRiskForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_IMPORT_ERP} />}>
                                <Route path={RoutesMap.SETTING_IMPORT_ERP} element={<SettingImportERP />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CUSTOMER_IMPORT_ERP} />}>
                                <Route path={RoutesMap.CUSTOMER_IMPORT_ERP} element={<ImportCustomerERP />} />
                            </Route>

                            {/* ------------------------- VIEWS SUPERVISOR ------------------------------- */}
                            {/* P. R.*/}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.PT_R_LIST} />}>
                                <Route path={RoutesMap.PT_R_LIST} element={<PTRList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.PT_R_FORM} />}>
                                <Route path={RoutesMap.PT_R_FORM} element={<PTRForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.PT_R_SIGNATURE} />}>
                                <Route path={RoutesMap.PT_R_SIGNATURE} element={<PTRSignature />} />
                            </Route>

                            {/* ------------------------- VIEWS MECHANIC ------------------------------- */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.PATTERN_T_LIST} />}>
                                <Route path={RoutesMap.PATTERN_T_LIST} element={<PatternTList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.PATTERN_T_FORM} />}>
                                <Route path={RoutesMap.PATTERN_T_FORM} element={<PatternTForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.PATTERN_T_SIGNATURE} />}>
                                <Route path={RoutesMap.PATTERN_T_SIGNATURE} element={<PatternTSignature />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.AST_LIST} />}>
                                <Route path={RoutesMap.AST_LIST} element={<ASTList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.AST_FORM} />}>
                                <Route path={RoutesMap.AST_FORM} element={<ASTForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.AST_SIGNATURE} />}>
                                <Route path={RoutesMap.AST_SIGNATURE} element={<ASTSignature />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.DET_LIST} />}>
                                <Route path={RoutesMap.DET_LIST} element={<DETList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.DET_FORM} />}>
                                <Route path={RoutesMap.DET_FORM} element={<DETForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.DET_SIGNATURE} />}>
                                <Route path={RoutesMap.DET_SIGNATURE} element={<DETSignature />} />
                            </Route>

                            {/*--------EXTERNAL MAINTENANCE------------------------------ */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.EXTERNAL_MAINTENANCE} />}>
                                <Route path={RoutesMap.EXTERNAL_MAINTENANCE} element={<ExternalMaintenanceList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.EXTERNAL_MAINTENANCE_FORM} />}>
                                <Route path={RoutesMap.EXTERNAL_MAINTENANCE_FORM} element={<ExternalMaintenanceForm />} />
                            </Route>
                            {/*------------------MODEL MAINTENANCE CONFIG----------------------*/}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MAINTENANCE_MODEL_FORM} />}>
                                <Route path={RoutesMap.MAINTENANCE_MODEL_FORM} element={<FormMaintenance />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MAINTENANCE_MODEL_INPUT} />}>
                                <Route path={RoutesMap.MAINTENANCE_MODEL_INPUT} element={<MaintenanceModelInput />} />
                            </Route>

                            {/*------------------TRADEMARK MAINTENANCE CONFIG----------------------*/}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MAINTENANCE_TRADEMARK_FORM} />}>
                                <Route path={RoutesMap.MAINTENANCE_TRADEMARK_FORM} element={<FormMaintenanceTrademark />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.MAINTENANCE_TRADEMARK_INPUT} />}>
                                <Route path={RoutesMap.MAINTENANCE_TRADEMARK_INPUT} element={<MaintenanceTrademarkInput />} />
                            </Route>

                            {/*--------------------  VIEWS DRIVER ----------------------------- */}
                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPAIR_REQUEST} />}>
                                <Route path={RoutesMap.REPAIR_REQUEST} element={<RepairRequest />} />
                            </Route>
                            RepairRequestForm

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPAIR_REQUEST_FORM} />}>
                                <Route path={RoutesMap.REPAIR_REQUEST_FORM} element={<RepairRequestForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPAIR_REQUEST_PDF} />}>
                                <Route path={RoutesMap.REPAIR_REQUEST_PDF} element={<RepairRequestPDF />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.EMPLOYEE_INCOMPLETE_LIST} />}>
                                <Route path={RoutesMap.EMPLOYEE_INCOMPLETE_LIST} element={<EmployeeIncompleteList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.CUSTOMER_INCOMPLETE_LIST} />}>
                                <Route path={RoutesMap.CUSTOMER_INCOMPLETE_LIST} element={<CustomerIncompleteList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TECHNICAL_REPORT} />}>
                                <Route path={RoutesMap.TECHNICAL_REPORT} element={<TechnicalReportList />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TECHNICAL_REPORT_FORM} />}>
                                <Route path={RoutesMap.TECHNICAL_REPORT_FORM} element={<TechnicalReportForm />} />
                            </Route>

                            <Route element={<RestrictedRoute pathRequired={RoutesMap.TECHNICAL_REPORT_SIGNATURE} />}>
                                <Route path={RoutesMap.TECHNICAL_REPORT_SIGNATURE} element={<TechnicalReportSignature />} />
                            </Route>

                            {/* PROFILE */}
                            <Route
                                element={
                                    <RestrictedRoute
                                        pathRequired={RoutesMap.MY_PROFILE}
                                    />
                                }
                            >
                                <Route
                                    path={RoutesMap.MY_PROFILE}
                                    element={<MyProfileView />}
                                />
                            </Route>

                            {/* HOME */}
                            <Route
                                element={
                                    <RestrictedRoute
                                        pathRequired={RoutesMap.HOME}
                                    />
                                }
                            >
                                <Route
                                    path={RoutesMap.HOME}
                                    element={<HomeView />}
                                />
                            </Route>
                        </Route>
                    </>
                )}
            </Routes >
            <CustomBackdrop open={showBackdrop || false} />
            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        background: "#c9c7c7",
                        color: "#fff",
                    },
                }}
            />
        </>
    );
}

export default App;
