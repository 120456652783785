import { api } from "./configs/axiosConfigs";

export const ModelMaintenanceAPI = {
    getModelMaintenance: async (id: number, data?: any) => {
        const response = await api
            .get(`/modelMaintenance?idmodel=${id}`, { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    createModelMaintenance: async (data: any) => {
        const response = await api
            .post('/modelMaintenance/register', { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    editModelMaintenance: async (id: number, data: any) => {
        const response = await api
            .patch(`/modelMaintenance/${id}`, { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    deleteModelMaintenance: async (id: number) => {
        const response = await api
            .delete(`/modelMaintenance/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    }
};