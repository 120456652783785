import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCommentIcon from "@mui/icons-material/AddComment";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import ArticleIcon from "@mui/icons-material/Article";
import BadgeIcon from "@mui/icons-material/Badge";
import BlockIcon from "@mui/icons-material/Block";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ChatIcon from "@mui/icons-material/Chat";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupIcon from "@mui/icons-material/Group";
import HailIcon from "@mui/icons-material/Hail";
import HandymanIcon from "@mui/icons-material/Handyman";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import SegmentIcon from "@mui/icons-material/Segment";
import SettingsIcon from "@mui/icons-material/Settings";
import ThreePIcon from "@mui/icons-material/ThreeP";
import TimelineIcon from "@mui/icons-material/Timeline";
import TodayIcon from "@mui/icons-material/Today";
import PersonIcon from "@mui/icons-material/Person";
import MediationSharpIcon from '@mui/icons-material/MediationSharp';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import { RoutesMap } from "../../types";
import { routeAdminType } from "../../types/typeComponents/NavSection";

export const icons = {
    AddCommentIcon,
    AddToQueueIcon,
    ArticleIcon,
    BadgeIcon,
    ChatIcon,
    ContentPasteIcon,
    DashboardIcon,
    EqualizerIcon,
    FormatListBulletedIcon,
    GroupIcon,
    HailIcon,
    HandymanIcon,
    PaidIcon,
    PeopleIcon,
    PeopleAltIcon,
    PermContactCalendarIcon,
    SegmentIcon,
    SettingsIcon,
    ThreePIcon,
    TimelineIcon,
    ManageAccountsIcon,
    AccountCircleIcon,
    NotificationsIcon,
    RememberMeIcon,
    CardMembershipIcon,
    TodayIcon,
    BlockIcon,
    ReceiptLongIcon,
};

const rutasAdmin: routeAdminType[] = [
    {
        id: 1,
        name: "Maestros",
        icon: <PersonIcon />,
        rutas: [
            {
                name_ruta: "Clientes",
                location: RoutesMap.CLIENT,
                icon: null,
            },
            {
                name_ruta: "Técnicos",
                location: RoutesMap.TECHNICAL,
                icon: null,
            },
            // {
            //     name_ruta: "Supervisores",
            //     location: RoutesMap.SUPERVISORS,
            //     icon: null,
            // },
            // {
            //     name_ruta: "Mecánicos",
            //     location: RoutesMap.MECHANICS,
            //     icon: null,
            // },
            // {
            //     name_ruta: "Conductores",
            //     location: RoutesMap.DRIVER,
            //     icon: null,
            // },
            // {
            //     name_ruta: "Jefes de mantenimiento",
            //     location: RoutesMap.BOSS_MAINTENANCE,
            //     icon: null,
            // },
            // {
            //     name_ruta: "Vehículos",
            //     location: RoutesMap.VEHICLES,
            //     icon: null
            // },
            // {
            //     name_ruta: "Tipos de vehículos",
            //     location: RoutesMap.TYPE_VEHICLES,
            //     icon: null
            // },
            // {
            //     name_ruta: "Modelo de vehículos",
            //     location: RoutesMap.MODEL_VEHICLES,
            //     icon: null
            // },
            // {
            //     name_ruta: "Marca de vehículos",
            //     location: RoutesMap.TRADEMARK_VEHICLES,
            //     icon: null
            // },
            {
                name_ruta: "Fallas",
                location: RoutesMap.FAIL_LIST,
                icon: null
            },
            // {
            //     name_ruta: "Informe mecánico - Orden de trabajo",
            //     location: RoutesMap.INFORM_WORK_ORDER_LIST,
            //     icon: null
            // },
            {
                name_ruta: "Trabajos realizados",
                location: RoutesMap.WORK_DONE_LIST,
                icon: null
            },
            {
                name_ruta: "Repuestos",
                location: RoutesMap.REPLACEMENT_PART_LIST,
                icon: null
            },
            {
                name_ruta: "Tipos de atención",
                location: RoutesMap.TYPE_ATTENTION,
                icon: null
            }
        ],
    },
    // {
    //     id: 2,
    //     name: "Estructuras",
    //     icon: <MediationSharpIcon />,
    //     rutas: [
    //         {
    //             name_ruta: "Pauta R",
    //             location: RoutesMap.STRUCTURE_D1,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Pauta T",
    //             location: RoutesMap.STRUCTURE_D2,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Controles DET",
    //             location: RoutesMap.CONTROL_VERIFICATION_LIST,
    //             icon: null
    //         }
    //     ]
    // },
    // {
    //     id: 3,
    //     name: "Estructuras AST",
    //     icon: <AccountTreeIcon />,
    //     rutas: [
    //         {
    //             name_ruta: "Etapas principales de trabajo",
    //             location: RoutesMap.MAIN_STAGES_WORKS,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Controles/Barreras",
    //             location: RoutesMap.CONTROL_BARRIER,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Eventos no deseados",
    //             location: RoutesMap.UNWANTED_EVENT,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Energías",
    //             location: RoutesMap.ENERGIES,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Riesgos Críticos",
    //             location: RoutesMap.CRITICAL_RISK,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Control de riesgos críticos",
    //             location: RoutesMap.CONTROL_CRITICAL_RISK,
    //             icon: null,
    //         }
    //     ]
    // },
    {
        id: 4,
        name: "Actividades",
        icon: <ArticleIcon />,
        rutas: [
            {
                name_ruta: "Solicitudes de repación",
                location: RoutesMap.REPAIR_REQUEST,
                icon: null,
            },
            {
                name_ruta: "Informes técnicos",
                location: RoutesMap.TECHNICAL_REPORT,
                icon: null,
            },
        ],
    },
    // {
    //     id: 4,
    //     name: "Reparaciones",
    //     icon: <ArticleIcon />,
    //     rutas: [
    //         {
    //             name_ruta: "Solicitudes de Reparación",
    //             location: RoutesMap.REPAIR_REQUEST,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Ordenes de Trabajo",
    //             location: RoutesMap.WORK_ORDER_LIST,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "AST",
    //             location: RoutesMap.AST_LIST,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "DET",
    //             location: RoutesMap.DET_LIST,
    //             icon: null,
    //         }
    //     ],
    // },
    // {
    //     id: 5,
    //     name: "Reportes",
    //     icon: <DashboardIcon />,
    //     rutas: [
    //         {
    //             name_ruta: "Pauta R",
    //             location: RoutesMap.REPORT_PAUTA_R,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Pauta T",
    //             location: RoutesMap.REPORT_PAUTA_T,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Mantenciones Externas",
    //             location: RoutesMap.REPORT_MANTENCIONES_EXTERNAS,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Solcitud de reparación",
    //             location: RoutesMap.REPORT_REPAIR_REQUEST,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "Orden de trabajo",
    //             location: RoutesMap.REPORT_WORK_ORDER,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "DET",
    //             location: RoutesMap.REPORT_DET,
    //             icon: null,
    //         },
    //         {
    //             name_ruta: "AST",
    //             location: RoutesMap.REPORT_AST,
    //             icon: null,
    //         },
    //     ]
    // },
    {
        id: 6,
        name: "Importaciones",
        icon: <ImportExportIcon />,
        rutas: [
            {
                name_ruta: "Importar usuarios del ERP",
                location: RoutesMap.SETTING_IMPORT_ERP,
                icon: null,
            },
            {
                name_ruta: "Usuarios en espera",
                location: RoutesMap.EMPLOYEE_INCOMPLETE_LIST,
                icon: null,
            },
            {
                name_ruta: "Importar clientes del ERP",
                location: RoutesMap.CUSTOMER_IMPORT_ERP,
                icon: null,
            },
            {
                name_ruta: "Clientes en espera",
                location: RoutesMap.CUSTOMER_INCOMPLETE_LIST,
                icon: null
            }
        ]
    }
];

const rutasSuperAdmin: routeAdminType[] = [
    {
        id: 1,
        name: "Maestros",
        icon: <PersonIcon />,
        rutas: [
            {
                name_ruta: "Administradores",
                location: RoutesMap.ADMINISTRATOR,
                icon: null,
            },
            {
                name_ruta: "Empresas",
                location: RoutesMap.COMPANY,
                icon: null,
            },
        ],
    },
    // {
    //     id: 2,
    //     name: "Ajustes",
    //     icon: <SettingsIcon />,
    //     rutas: [
    //         {
    //             name_ruta: "Ajuste 1",
    //             location: RoutesMap.ADMINISTRATOR,
    //             icon: null,
    //         },
    //     ],
    // },
];

// const rutasJefeMantenimiento: routeAdminType[] = [
//     {
//         id: 1,
//         name: "Maestros",
//         icon: <PersonIcon />,
//         rutas: [
//             {
//                 name_ruta: "Mecánicos",
//                 location: RoutesMap.MECHANICS,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Conductores",
//                 location: RoutesMap.DRIVER,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Vehículos",
//                 location: RoutesMap.VEHICLES,
//                 icon: null
//             },
//             {
//                 name_ruta: "Tipos de vehículos",
//                 location: RoutesMap.TYPE_VEHICLES,
//                 icon: null
//             },
//             {
//                 name_ruta: "Modelo de vehículos",
//                 location: RoutesMap.MODEL_VEHICLES,
//                 icon: null
//             },
//             {
//                 name_ruta: "Marca de vehículos",
//                 location: RoutesMap.TRADEMARK_VEHICLES,
//                 icon: null
//             },
//             {
//                 name_ruta: "Fallas - Solicitud de reparación",
//                 location: RoutesMap.FAIL_LIST,
//                 icon: null
//             },
//             {
//                 name_ruta: "Informe mecánico - Orden de trabajo",
//                 location: RoutesMap.INFORM_WORK_ORDER_LIST,
//                 icon: null
//             },
//             {
//                 name_ruta: "Trabajos - Orden de Trabajo",
//                 location: RoutesMap.WORK_DONE_LIST,
//                 icon: null
//             },
//             {
//                 name_ruta: "Repuestos",
//                 location: RoutesMap.REPLACEMENT_PART_LIST,
//                 icon: null
//             }
//         ],
//     },
//     {
//         id: 2,
//         name: "Estructuras",
//         icon: <MediationSharpIcon />,
//         rutas: [
//             {
//                 name_ruta: "Pauta R",
//                 location: RoutesMap.STRUCTURE_D1,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Pauta T",
//                 location: RoutesMap.STRUCTURE_D2,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Controles DET",
//                 location: RoutesMap.CONTROL_VERIFICATION_LIST,
//                 icon: null
//             }
//         ]
//     },
//     {
//         id: 3,
//         name: "Estructuras AST",
//         icon: <AccountTreeIcon />,
//         rutas: [
//             {
//                 name_ruta: "Etapas principales de trabajo",
//                 location: RoutesMap.MAIN_STAGES_WORKS,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Controles/Barreras",
//                 location: RoutesMap.CONTROL_BARRIER,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Eventos no deseados",
//                 location: RoutesMap.UNWANTED_EVENT,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Energías",
//                 location: RoutesMap.ENERGIES,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Riesgos Críticos",
//                 location: RoutesMap.CRITICAL_RISK,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Control de riesgos críticos",
//                 location: RoutesMap.CONTROL_CRITICAL_RISK,
//                 icon: null,
//             }
//         ]
//     },
//     {
//         id: 4,
//         name: "Actividades",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Pauta R",
//                 location: RoutesMap.PT_R_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Pauta T",
//                 location: RoutesMap.PATTERN_T_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Mantenciones externas",
//                 location: RoutesMap.EXTERNAL_MAINTENANCE,
//                 icon: null,
//             }
//         ],
//     },
//     {
//         id: 5,
//         name: "Reparaciones",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Solicitudes de Reparación",
//                 location: RoutesMap.REPAIR_REQUEST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Ordenes de Trabajo",
//                 location: RoutesMap.WORK_ORDER_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "AST",
//                 location: RoutesMap.AST_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "DET",
//                 location: RoutesMap.DET_LIST,
//                 icon: null,
//             }
//         ],
//     },
//     {
//         id: 6,
//         name: "Reportes",
//         icon: <DashboardIcon />,
//         rutas: [
//             {
//                 name_ruta: "Pauta R",
//                 location: RoutesMap.REPORT_PAUTA_R,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Pauta T",
//                 location: RoutesMap.REPORT_PAUTA_T,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Mantenciones Externas",
//                 location: RoutesMap.REPORT_MANTENCIONES_EXTERNAS,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Solcitudes de reparación",
//                 location: RoutesMap.REPORT_MANTENCIONES_EXTERNAS,
//                 icon: null,
//             },
//         ]
//     },
//     {
//         id: 7,
//         name: "Importaciones",
//         icon: <ImportExportIcon />,
//         rutas: [
//             {
//                 name_ruta: "Importar usuarios del ERP",
//                 location: RoutesMap.SETTING_IMPORT_ERP,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Usuarios en espera",
//                 location: RoutesMap.EMPLOYEE_INCOMPLETE_LIST,
//                 icon: null,
//             }
//         ]
//     }
// ];

// const rutasSupervisor: routeAdminType[] = [
//     {
//         id: 1,
//         name: "Actividades",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Pauta R",
//                 location: RoutesMap.PT_R_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Pauta T",
//                 location: RoutesMap.PATTERN_T_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Mantención externas",
//                 location: RoutesMap.EXTERNAL_MAINTENANCE,
//                 icon: null,
//             }
//         ],
//     },
//     {
//         id: 2,
//         name: "Reparaciones",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Solicitudes de Reparación",
//                 location: RoutesMap.REPAIR_REQUEST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Ordenes de Trabajo",
//                 location: RoutesMap.WORK_ORDER_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "AST",
//                 location: RoutesMap.AST_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "DET",
//                 location: RoutesMap.DET_LIST,
//                 icon: null,
//             }
//         ],
//     }
// ]

// const rutasMecanico: routeAdminType[] = [
//     {
//         id: 1,
//         name: "Actividades",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Pauta R",
//                 location: RoutesMap.PT_R_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Pauta T",
//                 location: RoutesMap.PATTERN_T_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Mantenciones externas",
//                 location: RoutesMap.EXTERNAL_MAINTENANCE,
//                 icon: null,
//             }
//         ],
//     },
//     {
//         id: 2,
//         name: "Reparaciones",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Solicitudes Disponibles",
//                 location: RoutesMap.REPAIR_REQUEST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "Ordenes de Trabajo",
//                 location: RoutesMap.WORK_ORDER_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "AST",
//                 location: RoutesMap.AST_LIST,
//                 icon: null,
//             },
//             {
//                 name_ruta: "DET",
//                 location: RoutesMap.DET_LIST,
//                 icon: null,
//             }
//         ],
//     }
// ];

// const rutasConductor: routeAdminType[] = [
//     // {
//     //     id: 1,
//     //     name: "Registros",
//     //     icon: <PersonIcon />,
//     //     rutas: [
//     //         {
//     //             name_ruta: "Mis solicitudes",
//     //             location: RoutesMap.MY_REQUEST,
//     //             icon: null,
//     //         },
//     //     ],
//     // },
//     {
//         id: 1,
//         name: "Actividades",
//         icon: <ArticleIcon />,
//         rutas: [
//             {
//                 name_ruta: "Solicitud de reparación",
//                 location: RoutesMap.REPAIR_REQUEST,
//                 icon: null,
//             },
//         ],
//     }
// ];

const rutasTecnico: routeAdminType[] = [
    {
        id: 1,
        name: "Actividades",
        icon: <ArticleIcon />,
        rutas: [
            {
                name_ruta: "Reportes técnicos",
                location: RoutesMap.TECHNICAL_REPORT,
                icon: null,
            },
            // {
            //     name_ruta: "Solicitudes de reparación disponibles",
            //     location: RoutesMap.REPAIR_REQUEST,
            //     icon: null,
            // }
        ],
    },
]

const rutasCliente: routeAdminType[] = [
    {
        id: 1,
        name: "Actividades",
        icon: <ArticleIcon />,
        rutas: [
            {
                name_ruta: "Solicitudes de reparación",
                location: RoutesMap.REPAIR_REQUEST,
                icon: null,
            },
            {
                name_ruta: "Mis reportes técnicos",
                location: RoutesMap.TECHNICAL_REPORT,
                icon: null,
            }
        ],
    },
]

export const navSections = {
    Superadmin: rutasSuperAdmin,
    Admin: rutasAdmin,
    // JefeManenimiento: rutasJefeMantenimiento,
    // Supervisor: rutasSupervisor,
    // Mecanico: rutasMecanico,
    // Conductor: rutasConductor
    Tecnico: rutasTecnico,
    Cliente: rutasCliente,
};
