import { useDispatch, useSelector } from "react-redux";
import { TechnicalAPI } from "../apis/TechnicalAPI";
import { onFetchTechnical, onSetEditTechnical, onSetSelectedTechnical, selectTechnicalState, changeStatus } from "../redux/slices/technicalSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Technical } from "../types/slices/technicalType";

export const useTechnicalStore = () => {
    const dispatch = useDispatch();
    const { technicals, status, editTechnical, selectedTechnical } =
        useSelector(selectTechnicalState);

    const getTechnicalStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TechnicalAPI.getTechnicals(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchTechnical(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createTechnicalStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TechnicalAPI.createTechnicals(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editTechnicalStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TechnicalAPI.editTechnicals(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteTechnicalStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TechnicalAPI.deleteTechnicals(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedTechnical = (technical: Technical) => {
        try {
            dispatch(onSetSelectedTechnical(technical));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditTechnical = (technical: Technical) => {
        try {
            dispatch(onSetEditTechnical(technical));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        //states
        technicals,
        status,
        editTechnical,
        selectedTechnical,
        //actions
        getTechnicalStore,
        createTechnicalStore,
        editTechnicalStore,
        deleteTechnicalStore,
        setSelectedTechnical,
        setEditTechnical
    };
};