import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { BossMaintenance, BossMaintenanceType } from "../../types/slices/bossMaintenanceType";

const initialState: BossMaintenanceType = {
    status: ApiStatus.FETCHED,
    boss: [],
    errorMessage: undefined,
    editBoss: {} as BossMaintenance,
    selectedBoss: {} as BossMaintenance,
};

const bossMaintenanceSlice = createSlice({
    name: "boss-maintenance",
    initialState,
    reducers: {
        onFetchBoss(state, { payload }: { payload: BossMaintenance[] }) {
            state.status = ApiStatus.FETCHED;
            state.boss = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditBoss(state, { payload }: { payload: BossMaintenance }) {
            state.editBoss = payload;
        },
        onSetSelectedBoss(state, { payload }: { payload: BossMaintenance }) {
            state.selectedBoss = payload;
        },
    }
})

export const selectBossState = (state: RootState) => state.bossMaintenanceSlice;

export default bossMaintenanceSlice.reducer;

export const {
    onFetchBoss,
    changeStatus,
    onSetEditBoss,
    onSetSelectedBoss,
} = bossMaintenanceSlice.actions;