import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Autocomplete, TextField, Modal, FormLabel, Box, IconButton } from '@mui/material';
import { Search, FilterList } from '@mui/icons-material'
import React, { FC, useEffect, useState } from 'react';
import { useVehicleStore } from '../../hooks/useVehicleStore';
import { makeStyles } from '@mui/styles';
import { Vehicles } from '../../types/slices/vehiclesType';
import { useMechanicStore } from '../../hooks/useMechanicStore';
import { Mechanic } from '../../types/slices/mechanicType';
import VehiclesList from '../Vehicles/VehiclesList';
import { Modal as MyModal } from '../../components/common/Modal/Modal';
import { ModalHeader } from '../../components/common/Modal/ModalHeader';
import { ModalBody } from '../../components/common/Modal/ModalBody';
import SearchIcon from '@mui/icons-material/Search';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { Driver } from '../../types/slices/driverType';
import { useDriverStore } from '../../hooks/useDriverStore';
//estilos para el modal
const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        zIndex: 1300, // Asegúrate de que sea mayor que el zIndex del contenido de la aplicación
    },
    modal: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        height: '100%',
        transition: 'transform 1.5s ease-in-out',
        transform: 'translateX(100%)',
    },
    modalContent: {
        flexGrow: 1,
        backgroundColor: 'white',
        overflow: 'auto',
        padding: 16,
    },
    modalOpen: {
        transform: 'translateX(0%)', // Cambio en la transformación al abrir el modal
    },
    modalClosed: {
        transform: 'translateX(100%)', // Transición inversa al cerrar
    },
}));

type filterType = {
    vehicle: Vehicles | null,
    mechanic: Mechanic | null,
    driver: Driver|null,
    groupBy: number,
    type: number,
    dateInitial: string | null,
    dateEnd: string | null
}

type TypeGroup = {
    id:number
    name:string
}

interface VehiclesFilterProps {
    loading: (isLoading: boolean) => void;
}

interface FilterReportProps {
    loading:any
    getReport:any
    setRowSelected:any
    typeGroups?:number[]
    clearTables?:any
}

const typeDateRange = [
    { id: 1, name: "Último mes" },
    { id: 2, name: "Últimos 3 meses" },
    { id: 3, name: "Últimos 6 meses" },
    { id: 4, name: "Últimos 12 meses" },
    { id: 5, name: "Seleccionar rango" },
]

const type_group = [
    { id: 1, name: 'Patente' },
    { id: 2, name: 'Mecánico' },
    { id: 3, name: 'Conductor'}
]

const FilterReport: FC<FilterReportProps> = ({ loading, getReport, setRowSelected, typeGroups, clearTables }) => {
    const classes = useStyles();
    const [isResponsive, setIsResponsive] = useState<boolean>(window.innerWidth <= 760);
    const [filterButton, setFilterButton] = useState<boolean>(false)
    const { getVehicles, vehicles } = useVehicleStore()
    const { getMechanics, mechanics } = useMechanicStore();
    const { drivers, getDrivers} = useDriverStore()
    const [filters, setFilters] = useState<filterType>({
        groupBy: 0,
        vehicle: null,
        mechanic: null,
        driver: null,
        type: 0,
        dateInitial: null,
        dateEnd: null
    })

    const TypeGroupInitial = [1,2]

    const [buscadorModal, setBuscadorModal] = useState<boolean>(false)
    const [typeGroup, setTypeGroup] = useState<TypeGroup[]>([])

    useEffect(() => {
        const handleResize = (): void => {
            setIsResponsive(window.innerWidth <= 760);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setGroups(typeGroups||TypeGroupInitial)
        infoApi();
    }, [])

    const infoApi = async () => {
        await getVehicles({ status: 1 });
        await getMechanics();
        await getDrivers()
    }

    const setGroups = (ids: number[]) => {
        const types = type_group.filter(type => ids.includes(type.id))
        setTypeGroup(types)
    }

    const onFilters = (e) => {
        const { value, name } = e.target
        setFilters({
            ...filters,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        loading(true);
        setFilterButton(false)
        setRowSelected(null)
        // usamos los filtros(filters.idvehicles, filters.type )
        if (!!!filters.groupBy) {
            loading(false);
            return CustomSnackbar('error', 'El campo "Agrupar por" es obligatorio');
        }
        if (!!!filters.type) {
            loading(false);
            return CustomSnackbar('error', 'El campo "Margen de tiempo" es obligatorio');
        }
        if (filters.type == 5 && !!!filters.dateEnd && !!!filters.dateInitial) {
            loading(false);
            return CustomSnackbar('error', 'El campo de rangos de tiempos son obligatorios');
        }
        let params = {
            ...filters,
            idvehicle: !!filters.vehicle ? filters.vehicle.id : null,
            idmechanic: !!filters.mechanic ? filters.mechanic.id : null,
        }
        getReport(params)
        loading(false);
    }

    const handleCancel = async () => {
        loading(true);
        setRowSelected(null)
        setFilters({
            vehicle: null,
            mechanic: null,
            driver: null,
            type: 0,
            groupBy: 0,
            dateInitial: null,
            dateEnd: null
        })
        setFilterButton(false)
        await getVehicles({ status: 1 });
        await getMechanics();
        await getMechanics()
        clearTables()
        loading(false);
    }

    const filterModal = () => {
        setFilterButton(!filterButton)
    }

    const onSelectedVehicle = async (row) => {
        console.log(row)
        setFilters({ ...filters, vehicle: row });
        await getVehicles({ status: 1 });
    }

    return (
        <>
            {!isResponsive ? (<Grid item container xs={12} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: 'solid 1px #e9e2e2', borderRadius: '10px' }}>
                <Grid xs={12} sm={12} md={9} lg={9} xl={9} sx={{ padding: '10px' }} container marginBottom="-10px" justifyContent="center">
                    <Grid item xs={12}>
                        <FormControl sx={{ margin: "5px" }}>
                            <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }} >
                                AGRUPAR POR
                            </InputLabel>
                            <Select
                                id="combo-box-type"
                                name='groupBy'
                                size="small"
                                label="TIPO"
                                value={filters.groupBy}
                                onChange={onFilters}
                                sx={{ width: '250px', fontSize: "12px" }}>
                                {typeGroup?.map((type) => (<MenuItem key={type.id} sx={{ width: "250px" }} value={type.id}>{type.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                        {filters.groupBy == 1 &&
                            <>
                                <FormControl sx={{ margin: "5px" }} >
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-company"
                                        value={filters.vehicle}
                                        options={vehicles || []}
                                        getOptionLabel={(option) => option.patent || ""}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        sx={{ width: "350px" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                label="PATENTE"
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                            />
                                        )}
                                        onChange={(e, newValue) => {
                                            setFilters({ ...filters, vehicle: newValue });
                                        }}
                                    />
                                </FormControl>
                                <IconButton onClick={() => setBuscadorModal(true)}>
                                    <SearchIcon />
                                </IconButton>
                            </>
                        }
                        {filters.groupBy == 2 && <FormControl sx={{ margin: "5px" }} >
                            <Autocomplete
                                disablePortal
                                id="combo-box-company"
                                value={filters.mechanic}
                                options={mechanics || []}
                                getOptionLabel={(option) => option.name + ' ' + option.surname || ""}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                sx={{ width: "350px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        label="MECÁNICO"
                                        InputLabelProps={{ style: { fontSize: "12px" } }}
                                    />
                                )}
                                onChange={(e, newValue) => {
                                    setFilters({ ...filters, mechanic: newValue });
                                }}
                            />
                        </FormControl>}
                        {filters.groupBy == 3 && <FormControl sx={{ margin: "5px" }} >
                            <Autocomplete
                                disablePortal
                                id="combo-box-driver"
                                value={filters.driver}
                                options={drivers || []}
                                getOptionLabel={(option) => option.name + ' ' + option.surname || ""}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                sx={{ width: "350px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        label="CONDUCTOR"
                                        InputLabelProps={{ style: { fontSize: "12px" } }}
                                    />
                                )}
                                onChange={(e, newValue) => {
                                    setFilters({ ...filters, driver: newValue });
                                }}
                            />
                        </FormControl>}
                    </Grid>
                    <Grid item xs={12} container sx={{ alignItems: 'center' }}>
                        <FormControl sx={{ margin: "5px" }}>
                            <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }} >
                                MARGEN DE TIEMPO
                            </InputLabel>
                            <Select
                                id="combo-box-type"
                                name='type'
                                size="small"
                                label="TIPO"
                                value={filters.type}
                                onChange={onFilters}
                                sx={{ width: '250px', fontSize: "12px" }}>
                                {typeDateRange?.map((type) => (<MenuItem key={type.id} sx={{ width: "260px" }} value={type.id}>{type.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                        {
                            filters.type == 5 &&
                            <>
                                <span style={{ margin: 10 }}>Desde: </span>
                                <TextField
                                    id='date-initial'
                                    name="dateInitial"
                                    size="small"
                                    type="date"
                                    value={filters.dateInitial}
                                    onChange={onFilters}
                                    sx={{ width: '200px', fontSize: "12px" }}
                                />
                                <span style={{ margin: 10 }}>Hasta: </span>
                                <TextField
                                    id='date-end'
                                    name="dateEnd"
                                    size="small"
                                    type="date"
                                    value={filters.dateEnd}
                                    onChange={onFilters}
                                    sx={{ width: '200px', fontSize: "12px" }}
                                />
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid item display='flex' justifyContent='center' padding='5px' xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button
                        startIcon={<Search />}
                        size="small"
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ width: '140px', height: '40px', backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", marginRight: '3px', lineHeight: '12px' }}>
                        Filtrar búsqueda
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleCancel}
                        sx={{ width: '140px', height: '40px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#a79f9f" }, fontSize: "12px" }}>
                        Cancelar filtros
                    </Button>
                </Grid>
            </Grid >) : (
                <>
                    {/* Botón de filtro - responsive */}
                    <Button startIcon={<FilterList />}
                        size="small" variant="contained" sx={{ height: "40px", marginBottom: "-60px", backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", lineHeight: '12px', marginRight: "auto", alignItems: "center" }} onClick={filterModal}>Filtros</Button>
                </>
            )}
            {filterButton &&
                <div>
                    <Modal
                        open={filterButton}
                        onClose={filterModal}
                        className={classes.modalContainer}
                    >
                        <div className={`${classes.modal} ${filterButton ? classes.modalOpen : classes.modalClosed}`}>
                            <div className={classes.modalContent}>
                                <h2>FILTROS</h2>
                                <Grid sx={{ padding: '5px' }} justifyContent="center">
                                    <FormControl sx={{ margin: "5px", marginBottom: "15px" }} >
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={filters.vehicle}
                                            options={vehicles || []}
                                            getOptionLabel={(option) => option.patent || ""}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            sx={{ width: "350px" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    label="VEHÍCULO"
                                                    InputLabelProps={{ style: { fontSize: "12px" } }}
                                                />
                                            )}
                                            onChange={(e, newValue) => {
                                                setFilters({ ...filters, vehicle: newValue });
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ margin: "5px", marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }} >
                                            TIPO
                                        </InputLabel>
                                        <Select
                                            id="combo-box-type"
                                            name='type'
                                            size="small"
                                            label="TIPO"
                                            value={filters.type}
                                            onChange={onFilters}
                                            sx={{ width: '200px', fontSize: "12px" }}>
                                            {typeDateRange?.map((type) => (<MenuItem key={type.id} sx={{ width: "260px" }} value={type.id}>{type.name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item display='flex' justifyContent='center' padding='5px' xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Button
                                        startIcon={<Search />}
                                        size="small"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{ width: '120px', height: '40px', backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", marginRight: '3px', lineHeight: '12px' }}>
                                        Filtrar búsqueda
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={handleCancel}
                                        sx={{ width: '120px', height: '40px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#a79f9f" }, fontSize: "12px" }}>
                                        Cancelar filtros
                                    </Button>
                                </Grid>
                            </div>
                        </div>
                    </Modal>
                </div>}
            {
                buscadorModal &&
                <MyModal open={buscadorModal} onClose={() => setBuscadorModal(false)}>
                    <ModalHeader
                        className='positionElements'
                        onCancel={() => setBuscadorModal(false)}
                    >
                    </ModalHeader>

                    <ModalBody>
                        <VehiclesList onSelectedVehicle={onSelectedVehicle} setBuscadorModal={setBuscadorModal} />
                    </ModalBody>

                    {/* <ModalFooter
                            confirmText={"Reservar"}
                            onConfirm={() => { onReservation() }}
                        /> */}
                </MyModal>
            }
        </>
    )
};

export default FilterReport;