import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { ReplacementPart, ReplacementPartType } from "../../types/slices/replacementPartType";
import { RootState } from "../store";

const initialState:ReplacementPartType = {
    status: ApiStatus.FETCHED,
    replacementParts: [],
    errorMessage: undefined,
    editReplacementPart: {} as ReplacementPart
}

const ReplacementPartSlice = createSlice({
    name: 'ReplacementPart',
    initialState,
    reducers: {
        onFetchReplacementParts (state, { payload }: { payload: ReplacementPart[] }) {
            state.status            = ApiStatus.FETCHED
            state.replacementParts         = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditReplacementPart (state, { payload }: { payload: ReplacementPart }) {
            state.editReplacementPart = payload
        }
    }
})

export const selectReplacementPartState = (state: RootState) => state.ReplacementPartSlice
export default ReplacementPartSlice.reducer

export const {
    onFetchReplacementParts,
    changeStatus,
    onSetEditReplacementPart
} = ReplacementPartSlice.actions