import { api } from "./configs/axiosConfigs";

const idcompany = 1

export const TechnicalAPI = {
    getTechnicals: async (data?: any) => {
        const response = await api
            .get("/technical", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createTechnicals: async (data: any) => {
        const response = await api
            .post("technical/register", { ...data, idcompany })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editTechnicals: async (id: number, data: any) => {
        const response = await api
            .patch(`/technical/${id}`, { ...data, idcompany })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteTechnicals: async (id: number) => {
        const response = await api
            .delete(`/technical/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
};
