import { ChangeEvent, FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks";
import { useVehicleStore } from "../../hooks/useVehicleStore";
import { useStructureD2Store } from "../../hooks/useStructureD2Store";
import { usePatternTStore } from "../../hooks/usePatternTStore";
import { useBossStore } from "../../hooks/useBossStore";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { Vehicles } from "../../types/slices/vehiclesType";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Button, Divider, FormLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, TableContainer } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary, StyledTableCell } from "../../toolbox/helpers/accordion-styled";

import InfoIcon from '@mui/icons-material/Info';
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { Role } from "../../types/roles/roleTypes";
import { createNotification } from "../../types/slices/notificationType";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { PatternTInforModal } from "./PatternTInfoModal";


const statusItem = [
    { id: 1, name: 'Ok' },
    { id: 2, name: 'Revisar/Falla' },
    { id: 3, name: 'N/A' },
    { id: 4, name: 'Sin Revisar' },
]

const statusPDI = [
    { id: 1, name: 'Correcto' },
    { id: 2, name: 'Tolerable' },
    { id: 3, name: 'Cambiar' }
]

type PatternTDataForm = {
    idvehicle: number
    mileage: number
    entry_date: string
}

type DataGroup = {
    id:number
    name_group:string
    detail: DataTable1[]
}

type DataTable1 = {
    id?: number
    idstructure_d2?: number
    idgroup_activity_d2:number
    item: string
    status_item: number
}

type DataTable2 = {
    id?: number
    tire_order: string
    axle_type: string
    n_tire: number
    pressure: number
    comment_pressure: string
    marking: number
    comment_marking: string
    torque: number
    comment_torque: string
}

type DataTable3 = {
    id?: number
    tire_order: string
    axle_type: string
    n_tire: number
    idfire_status: number
    alignment: number
    swinging: number
}

const date = new Date()
const año = date.getFullYear()
const mes = String(date.getMonth() + 1).padStart(2, '0')
const día = String(date.getDate()).padStart(2, '0')
const today = `${año}-${mes}-${día}`;

export const PatternTForm:FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { vehicles, getVehicles } = useVehicleStore()
    const { structureD2, groupsStructureD2, getStructureD2Store, getGroupsStructureD2 } = useStructureD2Store()
    const { status: patternTStatus, editPatternT, createPatternT, updatePatternT } = usePatternTStore()
    const { getBoss, boss } = useBossStore()
    const { createNotifications } = useNotificationStore()

    const [data, setData] = useState<PatternTDataForm>({
        idvehicle: 0,
        mileage: 0,
        entry_date: today
    })
    const [dataTable1, setDataTable1] = useState<DataGroup[]>([])
    const [dataTable2, setDataTable2] = useState<DataTable2[]>([])
    const [dataTable3, setDataTable3] = useState<DataTable3[]>([])
    const [showModalInfo, setShowModalInfo] = useState<boolean>(false)
    const [isOkAllD1, setIsOkallD1] = useState<boolean>(false)
    const [isOkAllD2, setIsOkallD2] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<number|false>(0)
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicles|null>(null)
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const userRole: Role|any = user.userType

    useEffect(() => {
        getAllData()
    },[])

    useEffect(() => {
        if (Object.entries(editPatternT).length === 0) {
            setAllDataTable1Create()
        } else {
            setAllDataTable1Edit()
        }
    },[structureD2, groupsStructureD2])

    useEffect(() => {
        if (Object.entries(editPatternT).length > 0) {
            const vehicle = vehicles?.find(vehicle => vehicle.id === editPatternT.idvehicle)
            setSelectedVehicle(vehicle || null)
        }
    },[vehicles])

    useEffect(() => {
        setAllDataTires()
    },[selectedVehicle])

    const getAllData = async () => {
        setLoading(true)
        await getVehicles({ status: 1 })
        await getBoss()
        await getGroupsStructureD2()
        await getStructureD2Store()
        setDataEdit()
        setLoading(false)
    }

    const setDataEdit = () => {
        if (Object.entries(editPatternT).length > 0) {
            setIsEdit(true)
            setData({
                idvehicle: editPatternT.idvehicle,
                mileage: editPatternT.mileage,
                entry_date: editPatternT.entry_date
            })
            setIsOkallD1(true)
            setIsOkallD2(true)
        }
    }

    const validateForm = (values) => {
        let errors:any = {}
        if (!values.mileage) errors.mileage = "Odómetro es requerido"
        if (!values.entry_date) errors.entry_date = "Fecha de ejecución es requerido"
        return errors
    }

    const onSubmit = async (values) => {
        if (!selectedVehicle) return CustomSnackbar('warning', 'Seleccione una patente')
        if (data.entry_date === null || data.entry_date === '') return CustomSnackbar('warning', 'Ingrese fecha de ejecución')

        const data_structure = dataTable1?.flatMap(group => group.detail.map(detail => detail)) || []
        const dataTires1 = dataTable2?.map(row => {
            return {
                id: row.id,
                n_tire: row.n_tire,
                pressure: row.pressure,
                comment_pressure: row.comment_pressure,
                marking: row.marking,
                comment_marking: row.comment_marking,
                torque: row.torque,
                comment_torque: row.comment_torque
            }
        })
        const dataTires2 = dataTable3?.map(row => {
            return {
                id: row.id,
                n_tire: row.n_tire,
                idfire_status: row.idfire_status,
                alignment: row.alignment,
                swinging: row.swinging
            }
        })

        
        let payload:any = {
            idvehicle: selectedVehicle?.id,
            mileage: values.mileage,
            entry_date: values.entry_date,
            data_structure: data_structure,
            data_tires1: dataTires1,
            data_tires2: dataTires2,
            idcontrata: user?.data?.idcontrata
        }
        if (userRole === Role.MECHANIC) {
            payload = {
                ...payload,
                idmechanic: user?.data?.id,
            }
        }

        if (!isEdit) {
            const response = await createPatternT(payload)
            if (response.id) {
                boss?.map(async (element) => {
                    const notify: createNotification = {
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        iduser_type: 4,
                        title: `Nueva Pauta T: ${response.correlative}`,
                        details: `El ${user.userType} ${user.data?.name} ${user.data?.surname} ha creado una nueva pauta T. Patente: ${selectedVehicle.patent}`,
                        idview: 14,
                        idcompany: 1,
                        idregister: null,
                    }
                    return await createNotifications(notify)
                })
                navigate(`/pt-t-signature/${response.id}`)
            }
        } else {
            const response = await updatePatternT(editPatternT.id, payload)
            if (response === true) {
                boss?.map(async (element) => {
                    const notify: createNotification = {
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        iduser_type: 4,
                        title: `Pauta T editada: ${editPatternT.correlative}`,
                        details: `El ${user.userType} ${user.data?.name} ${user.data?.surname} ha editado una pauta T. Patente: ${selectedVehicle.patent}`,
                        idview: 14,
                        idcompany: 1,
                        idregister: null,
                    }
                    return await createNotifications(notify)
                })
                navigate(`/pt-t-signature/${editPatternT.id}`)
            }
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.PATTERN_T_LIST })
    }

    const handleStatusOkAll = () => {
        setDataTable1(prevDataTable => {
            return prevDataTable.map(group => ({
                ...group,
                detail: group.detail.map(detail => {
                    return {
                        ...detail,
                        status_item: Object.values(dataTable1).every(group => Object.values(group.detail).every(detail => detail.status_item === 0)) ? 1 : 0
                    }
                })
            }))
        })
    }

    const handleChangePanel = (panel: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    }

    const setAllDataTable1Create = () => {
        if (groupsStructureD2.length === 0 || structureD2.length === 0) return

        const data:DataGroup[] = groupsStructureD2?.map(group => {
            const detailGroup = structureD2?.filter(structure => structure?.idgroup_activity_d2 === group.id)
            const details:DataTable1[] = detailGroup?.map(detail => {
                return {
                    idstructure_d2: detail.id,
                    idgroup_activity_d2: group.id,
                    item: detail.item,
                    status_item: 0
                }
            })
            return {
                id: group?.id,
                name_group: group?.name_group,
                detail: details
            }
        })

        setDataTable1(data || [])
    }

    const setAllDataTable1Edit = () => {
        if (groupsStructureD2.length === 0) return

        const data:any = editPatternT.detail_patternT.map(group => {
            const details:DataTable1[] = group?.detail_group?.map(row => {
                return {
                    id: parseInt(row?.id),
                    idstructure_d2: row.idstructure_d2,
                    idgroup_activity_d2: group.id,
                    item: row.item,
                    status_item: parseInt(row.status_item)
                }
            })
            return {
                id: group.nro,
                name_group: group.group,
                detail: details
            }
        })

        setDataTable1(data || [])
    }

    const setAllDataTires = () => {
        if (!selectedVehicle) return

        let data2: DataTable2[]
        let data3: DataTable3[]

        if (Object.entries(editPatternT).length === 0) {
            data2 = selectedVehicle.axle_detail.map((element, i) => {
                return {
                    tire_order: element.tire_order,
                    axle_type: element.axle_type,
                    n_tire: i + 1,
                    pressure: 0,
                    comment_pressure: '',
                    marking: 0,
                    comment_marking: '',
                    torque: 0,
                    comment_torque: ''
                }
            })

            data3 = selectedVehicle.axle_detail.map((element, i) => {
                return {
                    tire_order: element.tire_order,
                    axle_type: element.axle_type,
                    n_tire: i + 1,
                    idfire_status: 0,
                    alignment: 0,
                    swinging: 0
                }
            })
        } else {
            data2 = editPatternT?.detail_tires_1?.map((row, i) => {
                return {
                    id: row.id,
                    tire_order: selectedVehicle?.axle_detail[i].tire_order || '',
                    axle_type: selectedVehicle?.axle_detail[i].axle_type || '',
                    n_tire: row.n_tire,
                    pressure: row.pressure,
                    comment_pressure: row.comment_pressure,
                    marking: parseInt(row.marking),
                    comment_marking: row.comment_marking,
                    torque: parseInt(row.torque),
                    comment_torque: row.comment_torque
                }
            })

            data3 = editPatternT?.detail_tires_2?.map((row, i) => {
                return {
                    id: row.id,
                    tire_order: selectedVehicle?.axle_detail[i].tire_order || '',
                    axle_type: selectedVehicle?.axle_detail[i].axle_type || '',
                    n_tire: row.n_tire,
                    idfire_status: row.idfire_status,
                    alignment: row.alignment,
                    swinging: row.swinging
                }
            })
        }

        setDataTable2(data2 || [])
        setDataTable3(data3 || [])
    }

    const changeValueDetail = (idstructureD2, value) => {
        setDataTable1(prevDataTable => {
            return prevDataTable.map(group => ({
                ...group,
                detail: group.detail.map(detail => {
                    if (detail.idstructure_d2 === idstructureD2) {
                        detail.status_item = parseInt(value)
                    }
                    return detail
                })
            }))
        })
    }

    const handleOkAllD1 = () => {
        if (isOkAllD1) {
            const detail = dataTable2.map(detail => { return { ...detail, pressure: 0, marking: 0, torque: 0 } })
            setDataTable2(detail || [])
            setIsOkallD1(false)
        } else {
            const detail = dataTable2.map(detail => { return { ...detail, pressure: 1, marking: 1, torque: 1 } })
            setDataTable2(detail || [])
            setIsOkallD1(true)
        }
    }

    const handleOkAllD2 = () => {
        if (isOkAllD2) {
            const detail = dataTable3.map(detail => { return { ...detail, alignment: 0, swinging: 0 } })
            setDataTable3(detail || [])
            setIsOkallD2(false)
        } else {
            const detail = dataTable3.map(detail => { return { ...detail, alignment: 1, swinging: 1, } })
            setDataTable3(detail || [])
            setIsOkallD2(true)
        }
    }

    const onChangeCheckBoxDataTires1 = (e: ChangeEvent<HTMLInputElement>, n_tire: number, type: string) => {
        const { checked } = e.target
        const details = dataTable2.map(detail => {
            if (detail.n_tire === n_tire) {
                if (type === 'pressure') {
                    return { ...detail, pressure: checked ? 1 : 0 }
                } else if (type === 'marking') {
                    return { ...detail, marking: checked ? 1 : 0 }
                } else if (type === 'torque') {
                    return { ...detail, torque: checked ? 1 : 0 }
                }
            }
            return detail
        })
        setDataTable2(details || [])
    }

    const returnChecked = (n_tire: number, type: string) => {
        let checked = false
        const detail = dataTable2.find(detail => detail.n_tire === n_tire)
        if (detail) {
            switch (type) {
                case 'pressure':
                    checked = detail.pressure === 1 ? true : false
                    break;

                case 'marking':
                    checked = detail.marking === 1 ? true : false
                    break;

                case 'torque':
                    checked = detail.torque === 1 ? true : false
                    break;

                default:
                    break;
            }
        }
        return checked
    }

    const setCommentPressure = (n_tire: number, comment: string) => {
        const detail = dataTable2.find(detail => detail.n_tire === n_tire)
        if (detail) {
            detail.comment_pressure = comment
        }
    }

    const setCommentMarking = (n_tire: number, comment: string) => {
        const detail = dataTable2.find(detail => detail.n_tire === n_tire)
        if (detail) {
            detail.comment_marking = comment
        }
    }

    const setCommentTorque = (n_tire: number, comment: string) => {
        const detail = dataTable2.find(detail => detail.n_tire === n_tire)
        if (detail) {
            detail.comment_torque = comment
        }
    }

    const changeFireStatus = (n_tire: number, value: number) => {
        setDataTable3(dataTable3.map(detail => {
            if (detail.n_tire === n_tire) {
              if (0 < value && value < 2) {
                detail.idfire_status = 3
              } else if (2 <= value && value < 4) {
                detail.idfire_status = 2
              } else if (4 <= value) {
                detail.idfire_status = 1
              }
            }
            return detail
        }))
    }

    const findValueFire = (n_tire: number) => {
        const detail = dataTable3.find(detail => detail.n_tire === n_tire)
        const name = statusPDI.find(status => status.id === detail?.idfire_status)
        return name?.name || ''
    }

    const onChangeCheckBoxD2 = (e: ChangeEvent<HTMLInputElement>, n_tire: number, type: string) => {
        const { checked } = e.target;
        const updatedDataTable = dataTable3.slice()
        const detail = updatedDataTable.find(detail => detail.n_tire === n_tire);
      
        if (detail) {
          if (type === 'alignment') {
            detail.alignment = checked ? 1 : 0
          } else if (type === 'swinging') {
            detail.swinging = checked ? 1 : 0
          }
          setDataTable3(updatedDataTable)
        }
    }

    const returnCheckedD2 = (n_tire: number, type: string) => {
        let checked = false
        const detail = dataTable3.find(detail => detail.n_tire === n_tire)
        if (detail) {
            switch (type) {
                case 'alignment':
                    checked = detail.alignment === 1 ? true : false
                    break;

                case 'swinging':
                    checked = detail.swinging === 1 ? true : false
                    break;

                default:
                    break;
            }
        }
        return checked;
    }

    return (
        <CustomForm>
            {loading && <CustomBackdrop open={true} />}
            {patternTStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nueva Pauta T' : 'Editar Pauta T'}
                text={!isEdit ? 'Ingrese los datos de la nueva Pauta T' : 'Ingrese los datos de la pauta T'}
                goBack={RoutesMap.PATTERN_T_LIST}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Patente:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-vehicle"
                                                value={selectedVehicle}
                                                options={vehicles || []}
                                                getOptionLabel={(option) => option.patent || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedVehicle(newValue)
                                                    setFieldValue('idvehicle', newValue?.id || 0)
                                                }}
                                                readOnly={isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Marca:</FormLabel>
                                            <TextField
                                                id="trademark"
                                                type="text"
                                                name="trademark"
                                                fullWidth
                                                size="small"
                                                value={selectedVehicle?.trademark || ''}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Modelo:</FormLabel>
                                            <TextField
                                                id="model"
                                                type="text"
                                                name="model"
                                                fullWidth
                                                size="small"
                                                value={selectedVehicle?.model || ''}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>N° Chasis:</FormLabel>
                                            <TextField
                                                id="n_chassis"
                                                type="text"
                                                name="n_chassis"
                                                fullWidth
                                                size="small"
                                                value={selectedVehicle?.n_chassis || ''}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Grid>
                                        {
                                            !isEdit && (
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Última Pauta T:</FormLabel>
                                                        <TextField
                                                            id="exit_date"
                                                            type="date"
                                                            name="exit_date"
                                                            fullWidth
                                                            size="small"
                                                            value={selectedVehicle?.last_patternT || ''}
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Último odómetro:</FormLabel>
                                                        <TextField
                                                            id="last_mileage"
                                                            type="number"
                                                            name="last_mileage"
                                                            fullWidth
                                                            size="small"
                                                            value={selectedVehicle?.last_mileage_patternT || 0}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="start">Km</InputAdornment>
                                                            }}
                                                            inputProps={{ min: 0, readOnly: true }}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Fecha de Ejecución:</FormLabel>
                                            <TextField
                                                id="entry_date"
                                                type="date"
                                                name="entry_date"
                                                fullWidth
                                                size="small"
                                                value={values.entry_date}
                                                onChange={handleChange}
                                                error={errors.entry_date && touched.entry_date ? true : false}
                                                helperText={errors.entry_date && touched.entry_date ? errors.entry_date : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Odómetro:</FormLabel>
                                            <TextField
                                                id="mileage"
                                                type="number"
                                                name="mileage"
                                                fullWidth
                                                size="small"
                                                value={values.mileage}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">Km</InputAdornment>
                                                }}
                                                inputProps={{ min: 0 }}
                                                onChange={handleChange}
                                                error={errors.mileage && touched.mileage ? true : false}
                                                helperText={errors.mileage && touched.mileage ? errors.mileage : ''}
                                            />
                                        </Grid>
                                        <Divider variant="middle" sx={{ margin: '15px 0px' }} />
                                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                                            <Tooltip title='Marcar todos los items con estado "Ok"' >
                                                <Button
                                                    disabled={selectedVehicle === null}
                                                    onClick={handleStatusOkAll}
                                                    sx={{
                                                        border: "solid 1px #73B2FF",
                                                        m: "10px",
                                                        color: "#73B2FF",
                                                        "&:hover": {
                                                            bgcolor: "#73B2FF",
                                                            color: "#fff",
                                                        },
                                                    }}
                                                >
                                                    {Object.values(dataTable1).every(group => Object.values(group.detail).every(detail => detail.status_item === 0)) ? "Marcar todo" : "Desmarcar todo"}
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                dataTable1?.map(group => 
                                                    <Accordion key={group.id} expanded={expanded === group.id} onChange={handleChangePanel(group.id)}>
                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                            <Typography>{group.name_group}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Table>
                                                                <TableHead sx={{ background: "#D8E8F7" }}>
                                                                    <TableRow>
                                                                        <StyledTableCell className="headTable" align="center">ITEM</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center">DESCRIPCIÓN</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center">ESTADO</StyledTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        selectedVehicle
                                                                        ?
                                                                        group?.detail.map((row,i) => 
                                                                            <TableRow key={i + 1}>
                                                                                <TableCell align="center">{row.idstructure_d2}</TableCell>
                                                                                <TableCell align="left">{row.item}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <Select
                                                                                        labelId="demo-simple-select"
                                                                                        id="demo-simple"
                                                                                        value={row.status_item}
                                                                                        onChange={(e) => changeValueDetail(row.idstructure_d2, e.target.value)}
                                                                                        sx={{ width: '150px' }}
                                                                                        size="small"
                                                                                    >
                                                                                        {
                                                                                            statusItem.map(row =>
                                                                                                <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                                                                            )
                                                                                        }
                                                                                    </Select>
                                                                                </TableCell>
                                                                            </TableRow>    
                                                                        )
                                                                        :
                                                                        <TableRow>
                                                                            <TableCell
                                                                                colSpan={5}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        display: "flex",
                                                                                    }}
                                                                                >
                                                                                    <div>{"Seleccione una patente"}</div>
                                                                                </Grid>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            }
                                            <Divider variant="middle" sx={{ margin: '40px 0px' }} />
                                            <Grid item xs={12}>
                                                <Accordion key={groupsStructureD2.length + 1} expanded={expanded === groupsStructureD2.length + 1} onChange={handleChangePanel(groupsStructureD2.length + 1)}>
                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                        <Typography>Neumáticos</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                                                            <Tooltip title='Marcar todos los items con estado "Ok"' >
                                                                <Button
                                                                    disabled={selectedVehicle === null}
                                                                    onClick={handleOkAllD1}
                                                                    sx={{
                                                                        border: "solid 1px #73B2FF",
                                                                        m: "10px",
                                                                        color: "#73B2FF",
                                                                        "&:hover": {
                                                                            bgcolor: "#73B2FF",
                                                                            color: "#fff",
                                                                        },
                                                                    }}
                                                                >
                                                                    {isOkAllD1 ? "Desmarcar todo" : "Marcar todo"}
                                                                </Button>
                                                            </Tooltip>
                                                            <Tooltip title="Ver Información">
                                                                <IconButton onClick={() => setShowModalInfo(true)}>
                                                                    <InfoIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead sx={{ background: "#D8E8F7" }}>
                                                                    <TableRow>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>NEUMATICOS</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>TIPO DE EJE</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={2}>{"PRESIÓN(psi)"}</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={2}>MARCAJE</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={2}>TORQUE</StyledTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        selectedVehicle && dataTable2.length > 0
                                                                        ?
                                                                        dataTable2.map(row => 
                                                                            <TableRow key={row.n_tire}>
                                                                                <TableCell align="center">{row.n_tire}</TableCell>
                                                                                <TableCell align="center">{row.tire_order}</TableCell>
                                                                                <TableCell align="center">{row.axle_type}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox color="primary"
                                                                                        onChange={(e) => onChangeCheckBoxDataTires1(e, row.n_tire, 'pressure')}
                                                                                        checked={returnChecked(row.n_tire, 'pressure')}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <TextField
                                                                                        id={"input-" + row.n_tire}
                                                                                        type="text"
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        label="Comentario"
                                                                                        onChange={(e) => setCommentPressure(row.n_tire, e.target.value)}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox color="primary"
                                                                                        onChange={(e) => onChangeCheckBoxDataTires1(e, row.n_tire, 'marking')}
                                                                                        checked={returnChecked(row.n_tire, 'marking')}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <TextField
                                                                                        id={"input-" + row.n_tire}
                                                                                        type="text"
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        label="Comentario"
                                                                                        onChange={(e) => setCommentMarking(row.n_tire, e.target.value)}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox color="primary"
                                                                                        onChange={(e) => onChangeCheckBoxDataTires1(e, row.n_tire, 'torque')}
                                                                                        checked={returnChecked(row.n_tire, 'torque')}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <TextField
                                                                                        id={"input-" + row.n_tire}
                                                                                        type="text"
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        label="Comentario"
                                                                                        onChange={(e) => setCommentTorque(row.n_tire, e.target.value)}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>    
                                                                        )
                                                                        :
                                                                        <TableRow>
                                                                            <TableCell colSpan={7}>
                                                                                <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                                    Seleccione una patente
                                                                                </Grid>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion key={groupsStructureD2.length + 2} expanded={expanded === groupsStructureD2.length + 2} onChange={handleChangePanel(groupsStructureD2.length + 2)}>
                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                        <Typography>Profundidad, Dibujo e Inspección</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                                                            <Tooltip title='Marcar todos los items con estado "Ok"' >
                                                                <Button
                                                                    disabled={selectedVehicle === null}
                                                                    onClick={handleOkAllD2}
                                                                    sx={{
                                                                        border: "solid 1px #73B2FF",
                                                                        m: "10px",
                                                                        color: "#73B2FF",
                                                                        "&:hover": {
                                                                            bgcolor: "#73B2FF",
                                                                            color: "#fff",
                                                                        },
                                                                    }}
                                                                >
                                                                    {isOkAllD2 ? "Desmarcar todo" : "Marcar todo"}
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead sx={{ background: "#D8E8F7" }}>
                                                                    <TableRow>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>NEUMATICOS</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>TIPO DE EJE</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" colSpan={2}>ESTADO</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center">ALINEACIÓN</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center">BALANCEO</StyledTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        selectedVehicle && dataTable3.length > 0
                                                                        ?
                                                                        dataTable3.map(row =>
                                                                            <TableRow key={row.n_tire}>
                                                                                <TableCell align="center">{row.n_tire}</TableCell>
                                                                                <TableCell align="center">{row.tire_order}</TableCell>
                                                                                <TableCell align="center">{row.axle_type}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <TextField
                                                                                        id={"input-" + row.n_tire}
                                                                                        type="number"
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="start">mm</InputAdornment>
                                                                                        }}
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => changeFireStatus(row.n_tire, parseInt(e.target.value))}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <TextField
                                                                                        id={"input-" + row.n_tire}
                                                                                        type="text"
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        InputProps={{
                                                                                            readOnly: true
                                                                                        }}
                                                                                        value={findValueFire(row.n_tire)}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox color="primary"
                                                                                        onChange={(e) => onChangeCheckBoxD2(e, row.n_tire, 'alignment')}
                                                                                        checked={returnCheckedD2(row.n_tire, 'alignment')}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox color="primary"
                                                                                        onChange={(e) => onChangeCheckBoxD2(e, row.n_tire, 'swinging')}
                                                                                        checked={returnCheckedD2(row.n_tire, 'swinging')}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                        :
                                                                        <TableRow>
                                                                            <TableCell colSpan={7}>
                                                                                <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                                    Seleccione una patente
                                                                                </Grid>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
            {
                showModalInfo && (
                    <PatternTInforModal
                        open={showModalInfo}
                        closeModal={() => setShowModalInfo(false)}
                    />
                )
            }  
        </CustomForm>
    )
}