import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { PatternR, PatternRType } from "../../types/slices/patternRType";
import { RootState } from "../store";

const initialState: PatternRType = {
    status: ApiStatus.FETCHED,
    patternRs: [],
    errorMessage: undefined,
    selectedPatternR: {} as PatternR
}

const patternRSlice  = createSlice({
    name: 'patternR',
    initialState,
    reducers: {
        onFetchPatternR (state, { payload }: { payload: PatternR[] }) {
            state.status            = ApiStatus.FETCHED
            state.patternRs         = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedPatternR (state, { payload }: { payload: PatternR }) {
            state.selectedPatternR = payload
        }
    }
})

export const selectPatternRState = (state: RootState) => state.patternRSlice
export default patternRSlice.reducer

export const {
    onFetchPatternR,
    changeStatus,
    onSetSelectedPatternR
} = patternRSlice.actions