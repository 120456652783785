import { FC, useEffect, useState } from "react";
import { useControlBarrier } from "../../hooks/useControlBarrier";
import { ControlBarriers } from "../../types/slices/controlBarriersType";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";

const ControlBarriersList: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status, controlBarriers, getControlBarrier, deleteControlBarrierStore, setSelectedControlBarrier } = useControlBarrier();

    const columns = [
        { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
        { type: 'text', field: 'control', label: 'CONTROL', align: 'center' },
        { type: 'text', field: 'main_stages', label: 'ETAPAS PRINCIPALES DE TRABAJO', align: 'center', format: (row) => row.main_stages?.map((element) => element).join(' - ') },
        { type: 'detail', field: 'detail', label: '', align: 'center' },
    ]

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<ControlBarriers>({} as ControlBarriers)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const userRole: Role | any = user.userType

    useEffect(() => {
        getControlBarrierApi()
    }, [search])

    const getControlBarrierApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    control: search
                }
            }
            await getControlBarrier(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedControlBarrier({} as ControlBarriers)
        navigate({ pathname: RoutesMap.CONTROL_BARRIER_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedControlBarrier(rowSelected)
        navigate({ pathname: RoutesMap.CONTROL_BARRIER_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteControlBarrierStore(rowSelected.id)
        if (response === true) {
            setSelectedControlBarrier({} as ControlBarriers)
            setShowModalConfirm(false)
            getControlBarrierApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    return (
        <>
            <Grid item xs={12}>
                {userRole === Role.ADMIN && (
                    <CustomTable
                        title={'Controles / Barreras'}
                        columns={columns || []}
                        loading={loading}
                        rows={controlBarriers || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
                {userRole === Role.MAINTENANCE && (
                    <CustomTable
                        title={'Controles / Barreras'}
                        columns={columns || []}
                        loading={loading}
                        rows={controlBarriers || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        status2={status === ApiStatus.FETCHING ? true : false}
                    />
                )
            }
        </>
    )
}

export default ControlBarriersList

