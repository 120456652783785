import { api } from "./configs/axiosConfigs"

export const DETAPI = {
    getDETs: async (data?:any) => {
        const response = await api.get(
            '/det',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getOne: async (id:number) => {
        const response = await api.get(
            `/det/${id}`
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/det/register', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.patch(
            `/det/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
            `/det/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    getPDF: async (id:number) => {
        const response = await api.get(
        `/det/pdf/${id}`,
        {
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
    signature: async (id: number, data:any) => {
        const response = await api.post(
        `/signingDET/${id}`, data
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    validate: async (id: number, status_det:number) => {
        const response = await api.patch(
            `/det/validate/${id}`,
            {
                status_det: status_det
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    getReport: async (data?:any) => {
        const response = await api.get(
            '/det/report',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
}