import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRepairRequest } from "../../hooks/useRepairRequest";
import { RoutesMap } from "../../types";
import { Grid, IconButton, Typography } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ApiStatus } from "../../types/api/status";

export const RepairRequestPDF:FC = () => {

    const navigate = useNavigate()
    const params = useParams()
    const { status, getRepairRequestPDF } = useRepairRequest()

    const [pdf, setPDF] = useState<any>(null)

    const { id } = params
    const idrepair_request:any = id

    useEffect(()=>{
        showPDF(idrepair_request)
    },[])

    const handleBack = () => {
        navigate({ pathname: RoutesMap.REPAIR_REQUEST })
    }

    const showPDF = async (id) => {
        const pdf = await getRepairRequestPDF(id)
        const blob = new Blob([pdf], { type: 'application/pdf' })
        const bloblURL = URL.createObjectURL(blob)
        setPDF(bloblURL)
    }

    return(
        <Grid container>
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft: '-25px' }}>
                <IconButton size="small" color="primary" aria-label="view" onClick={() => handleBack()}>
                    <ArrowBackIcon fontSize='small' />
                </IconButton>
                <Typography>Volver</Typography>
            </Grid>
            <Grid item container sx={{ alignItems: "center", display: "flex", justifyContent: "center", flexWrap: "wrap", marginBottom: "10px" }}>
                <Typography variant="h6" component="h1" gutterBottom color="#808080" className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                    SOLICITUD DE REPARACIÓN
                </Typography>
            </Grid>
            <Grid container style={{ overflow: 'hidden !important', height: '100%', alignItems: 'center', textAlign: 'center' }}>
                <iframe src={pdf} id="pdfVer" height="1000px" width="100%"></iframe>
            </Grid>
        </Grid>
    )
}