import { useDispatch, useSelector } from "react-redux";
import { ASTAPI } from "../apis/AST/ASTAPI";
import { selectASTState, changeStatus, onFetchAST, onSetEditAST, onSetSelectedAST, onSetIdWorkOrderAST } from "../redux/slices/ASTSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { AST } from "../types/slices/ASTType";

export const useASTStore = () => {
    const dispatch = useDispatch();
    const { ASTs, status, editAST, selectedAST } = useSelector(selectASTState);

    const getAST = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ASTAPI.getASTs(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchAST(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const getOneASTstore = async (id: number | string) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ASTAPI.getOneAST(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail[0]
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const getASTPDFstore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ASTAPI.getASTPDF(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message)
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            return response.data
        } catch (error) {
            console.log(error)
        }
    };

    const createASTStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ASTAPI.create(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response.data.detail;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editASTStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ASTAPI.edit(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response.data.detail;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteASTStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ASTAPI.deleteDocument(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteASTOneGroupStore = async (iddocument: number, iddetail: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ASTAPI.deleteAstOneGroup(iddocument, iddetail);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteASTOneCriticalRiskStore = async (iddocument: number, iddetail: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ASTAPI.deleteAstOneCriticalRisk(iddocument, iddetail);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedAST = (AST: AST) => {
        try {
            dispatch(onSetSelectedAST(AST));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditAST = (AST: AST) => {
        try {
            dispatch(onSetEditAST(AST));
        } catch (error) {
            console.log(error)
        }
    }

    const setEditIdWorkOrderAST = (id: number) => {
        try {
            dispatch(onSetIdWorkOrderAST(id))
        } catch (error) {
            console.log(error)
        }
    }

    const signatureAST = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ASTAPI.signature(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getASTReport = async (params) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ASTAPI.getReport(params)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }


    return {
        //states
        ASTs,
        status,
        editAST,
        selectedAST,
        //actions
        getAST,
        getOneASTstore,
        getASTPDFstore,
        createASTStore,
        editASTStore,
        deleteASTStore,
        deleteASTOneGroupStore,
        deleteASTOneCriticalRiskStore,
        setSelectedAST,
        setEditAST,
        setEditIdWorkOrderAST,
        signatureAST,
        getASTReport
    };
};