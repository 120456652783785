import { ChangeEvent, FC, useEffect, useState } from "react"
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Divider, FormLabel, Grid, TextField, Table, TableRow, TableCell, TableHead, TableBody, FormControlLabel, Radio, Checkbox, Typography, FormGroup, Tooltip, Button } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary, StyledTableCell } from "../../toolbox/helpers/accordion-styled";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useNavigate } from "react-router-dom";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useVerificationsStore } from "../../hooks/useVerifications";
import { useASTStore } from "../../hooks/useAST";
import { useMainStagesWorksStore } from "../../hooks/useMainStagesWorks";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { BlockType, CriticalType, GroupType, VerificationType } from "../../types/typeComponents/ASTtypes";
import { useEnergieStore } from "../../hooks/useEnergie";
import { useUnwantedEventStore } from "../../hooks/useUnwantedEvent";
import { useControlBarrier } from "../../hooks/useControlBarrier";
import { useCriticalRiskStore } from "../../hooks/useCriticalRisk";
import { useWorkOrderStore } from "../../hooks/useWorkOrderStore";
import { WorkOrder } from "../../types/slices/workOrderType";
import { Energie } from "../../types/slices/energieType";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";
import { WorkDone } from "../../types/slices/workDondeType";
import { Verification } from "../../types/slices/verificationsType";
import { detail, critical } from "../../types/slices/ASTType";
import { CriticalRisk } from "../../types/slices/criticalRiskType";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ApiStatus } from "../../types/api/status";
import { useAuthStore } from "../../hooks";
import { createNotification } from "../../types/slices/notificationType";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { useSupervisorStore } from "../../hooks/useSupervisorStore";
import { Role } from "../../types/roles/roleTypes";
import { useMechanicStore } from "../../hooks/useMechanicStore";

export const ASTForm: FC = () => {
    const { createASTStore, editASTStore } = useASTStore()
    const { workOrders } = useWorkOrderStore();
    const { workDones } = useWorkDoneStore()
    const { verifications } = useVerificationsStore()
    const { getMainStagesWorks, mainStagesWorks } = useMainStagesWorksStore()
    const { getUnwantedEvent, unwantedEvents } = useUnwantedEventStore()
    const { getControlBarrier, controlBarriers } = useControlBarrier()
    const { getEnergie, energies } = useEnergieStore()
    const { criticalRisks, getCriticalRisk } = useCriticalRiskStore()
    const { selectedAST, status: ASTstatus } = useASTStore()
    const { getSupervisors, supervisors } = useSupervisorStore()
    const { getMechanics, mechanics } = useMechanicStore()
    const { createNotifications } = useNotificationStore()
    const { user } = useAuthStore()
    const [data, setData] = useState<any>({
        idwork_order: 0,
        date: '',
    })
    const navigate = useNavigate()
    const [newWorkOrders, setNewWorkOrders] = useState<WorkOrder[]>([])
    const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<WorkDone[]>([]);
    const [selectRisks, setSelectRisks] = useState<CriticalRisk[]>([])
    const [groupRisks, setGroupRisks] = useState<CriticalType[]>([])
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isPreOT, setIsPreOT] = useState<boolean>(false)
    const [verification, setVerification] = useState<VerificationType[]>([])
    const [groups, setGroups] = useState<BlockType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const userRole: Role | any = user.userType

    useEffect(() => {
        apiDataInitial();
        const validateWorkOrders = workOrders?.filter(workOrderValiate => workOrderValiate.status_det === "1") || [];
        setNewWorkOrders(validateWorkOrders)
        if (Object.entries(selectedAST).length === 1) {
            setIsPreOT(true)
            const newWorkOrder = validateWorkOrders?.find(workOrder => workOrder.id === selectedAST.idwork_order) || null;
            const newVerifications: VerificationType[] = verifications?.map((element: Verification, index) => ({ idverification: index + 1, check: 2 }))
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const textDate = `${year}-${month}-${day}`
            setSelectedWorkOrder(newWorkOrder)
            setData({
                idwork_order: newWorkOrder?.id,
                date: textDate,
            })
            setVerification(newVerifications)
        } else if (Object.entries(selectedAST).length > 1) {
            setIsEdit(true)
            const newWorkOrder = validateWorkOrders?.find((order) => order.id === selectedAST.idwork_order) || null;
            const newDate = selectedAST.date
            const verificationsEdit: VerificationType[] = selectedAST?.verifications_detail.map((verificationEd: VerificationType) => {
                return {
                    idverification: verificationEd.idverification,
                    check: verificationEd.check
                }
            });
            // const newCriticalRisks = selectedAST.control_critical_risk?.map((element) => element.idcritical_risk);
            // const selectedNewCriticalRisks = criticalRisks?.filter(criticals => newCriticalRisks.includes(criticals.id))
            setSelectedWorkOrder(newWorkOrder);
            setData({ ...data, date: newDate })
            setVerification(verificationsEdit);
            // setSelectRisks(selectedNewCriticalRisks);
        } else {
            //obtener la fecha actual y setearlo en date
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const textDate = `${year}-${month}-${day}`
            setData({
                ...data,
                date: textDate,
            })
            const newVerifications: VerificationType[] = verifications?.map((element: Verification, index) => ({ idverification: index + 1, check: 2 }))
            setVerification(newVerifications)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedWorkOrder !== null) {
            const numberWork = selectedWorkOrder?.workDone?.map(works => works.idwork_done);
            const selectedWorks = workDones?.filter(works => numberWork.includes(works.id))
            setSelectedActivity(selectedWorks);
        } else {
            setSelectedActivity([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWorkOrder])

    useEffect(() => {
        if (Object.entries(selectedAST).length > 1 && criticalRisks.length > 0) {
            const newCriticalRisks = selectedAST?.control_critical_risk?.map((element) => element?.idcritical_risk)
            const selectedNewCriticalRisks = criticalRisks?.filter(criticals => newCriticalRisks.includes(criticals?.id))
            setSelectRisks(selectedNewCriticalRisks || [])
        }
    }, [criticalRisks])

    useEffect(() => {
        if (selectedActivity && selectedActivity.length > 0 && mainStagesWorks.length > 0 && energies.length > 0 && unwantedEvents.length > 0 && controlBarriers.length > 0) {
            const idActivities = selectedActivity?.map(element => element.id);
            const filterMainStage = idActivities?.map(id => {
                return mainStagesWorks.filter(main => main.a_id_work_dones.includes(id));
            });
            let groupCounter = 1;

            const newGroups = filterMainStage.map((element, index) => {
                const groups = element.map((group, position) => {
                    if (isEdit) {
                        const groupEdit: detail = selectedAST?.detailAST?.find((element) => element.control_detail.group === groupCounter) || {} as detail
                        const groupObj: GroupType = {
                            generalPosition: groupCounter++,
                            group: position + 1,
                            name_main_stages_works: group.actions,
                            main_stages_work: group.id,
                            energie: energies?.find((energy) => energy.idsmain_stages_work.includes(group.id)) || {} as Energie,
                            unwanted_event: unwantedEvents?.filter((unwanted) => unwanted.idsmain_stages_work.includes(group.id)),
                            control_barriers: controlBarriers?.filter((control) => control.idsmain_stages_work.includes(group.id)),
                            checked: parseInt(groupEdit?.check),
                        };
                        return groupObj;
                    } else {
                        const groupObj: GroupType = {
                            generalPosition: groupCounter++,
                            group: position + 1,
                            name_main_stages_works: group.actions,
                            main_stages_work: group.id,
                            energie: energies?.find((energy) => energy.idsmain_stages_work.includes(group.id)) || {} as Energie,
                            unwanted_event: unwantedEvents?.filter((unwanted) => unwanted.idsmain_stages_work.includes(group.id)),
                            control_barriers: controlBarriers?.filter((control) => control.idsmain_stages_work.includes(group.id)),
                            checked: 0,
                        };
                        return groupObj;
                    }
                });

                return {
                    block: index + 1,
                    nameBlock: selectedActivity[index]?.description,
                    groups: groups
                };
            });
            setGroups(newGroups);
        } else {
            setGroups([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedActivity, mainStagesWorks, energies, unwantedEvents, controlBarriers]);

    useEffect(() => {
        if (selectRisks && selectRisks.length > 0) {
            if (isEdit) {
                const newCritical: CriticalType[] = selectRisks.map(critical => {
                    return {
                        name: critical.name,
                        controls: critical.controls.map(control => {
                            const selectedCritical = selectedAST.control_critical_risk.find(contr => contr.idcontrols_cr === control.id) || {} as critical;
                            return {
                                id: selectedCritical.id,
                                idcontrol: control.id,
                                nameControl: control.name,
                                check: parseInt(selectedCritical.check),
                            }
                        }),
                    }
                });
                setGroupRisks(newCritical)
            } else {
                const newCritical: CriticalType[] = selectRisks.map(critical => {
                    return {
                        name: critical.name,
                        controls: critical.controls.map(control => {
                            return {
                                idcontrol: control.id,
                                nameControl: control.name,
                                check: 0,
                            }
                        }),
                    }
                });
                setGroupRisks(newCritical)
            }
        } else {
            setGroupRisks([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectRisks]);

    const apiDataInitial = async () => {
        try {
            setLoading(true)
            await getMainStagesWorks()
            await getEnergie()
            await getUnwantedEvent()
            await getControlBarrier()
            await getCriticalRisk()
            await getSupervisors()
            await getMechanics()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error al obtener los datos: ', error);
        }
    }

    const handleMeets = (index: number, value: number) => {
        const newData = verification?.map(ver => {
            if (ver.idverification === index) {
                ver.check = value
            }
            return ver
        })
        setVerification(newData || [])
    };

    const handleAllMeets = () => {
        setLoading(true)
        const newData = verification?.map((ver) => {
            return {
                ...ver,
                check: Object.values(verification).every(value => value.check === 1) ? 0 : 1
            }
        })
        setVerification(newData)
        setLoading(false)
    }

    const onChecked = (event: ChangeEvent<HTMLInputElement>, index: number, block: number) => {
        const value = event.target.checked
        if (value) {
            setGroups(prevGroups => {
                const newGroups = [...prevGroups]
                newGroups[block - 1].groups[index].checked = 1;
                return newGroups
            })
        } else {
            setGroups(prevGroups => {
                const newGroups = [...prevGroups]
                newGroups[block - 1].groups[index].checked = 0;
                return newGroups
            })
        }
    };

    const onRiskChecked = (event: ChangeEvent<HTMLInputElement>, index: number, group: number) => {
        const value = event.target.checked
        if (value) {
            setGroupRisks(prevRisks => {
                const newRisks = [...prevRisks]
                newRisks[group].controls[index].check = 1;
                return newRisks
            })
        } else {
            setGroupRisks(prevRisks => {
                const newRisks = [...prevRisks]
                newRisks[group].controls[index].check = 0;
                return newRisks
            })
        }
    };

    const onCancel = async () => {
        setNewWorkOrders([])
        navigate({ pathname: RoutesMap.AST_LIST })
    };

    const onSubmit = async (values) => {
        if (selectedWorkOrder === null) return CustomSnackbar('warning', 'No hay ninguna orden de trabajo seleccionada. Seleccione una antes de generar AST.')
        if (selectedActivity.length === 0) return CustomSnackbar('warning', 'No hay ninguna actividad seleccionada. Seleccione una antes de generar AST.')

        const mainStagesWorksDetail = groups?.map((element) => {
            const returns = element.groups.map((value) => {
                if (isEdit) {
                    const detailId = selectedAST.detailAST?.find((detail) => detail.control_detail.group === value.generalPosition)
                    return {
                        id: detailId?.id,
                        group: value.generalPosition,
                        idmain_stage: [value.main_stages_work]
                    }
                } else {
                    return {
                        group: value.generalPosition,
                        idmain_stage: [value.main_stages_work]
                    }
                }
            })
            return returns
        }).reduce((acc, arr) => { return acc.concat(arr) }, [])

        const energieDetail = groups?.map((element) => {
            const returns = element.groups.map((value) => {
                if (isEdit) {
                    const detailId = selectedAST.detailAST?.find((detail) => detail.control_detail.group === value.generalPosition)
                    return {
                        id: detailId?.id,
                        group: value.generalPosition,
                        idenergy: value.energie.id === undefined ? [] : [value.energie.id]
                    }
                } else {
                    return {
                        group: value.generalPosition,
                        idenergy: value.energie.id === undefined ? [] : [value.energie.id]
                    }
                }
            })
            return returns
        }).reduce((acc, arr) => { return acc.concat(arr) }, [])

        const unwantedEventDetail = groups?.map((element) => {
            const returns = element.groups.map((value) => {
                if (isEdit) {
                    const detailId = selectedAST.detailAST?.find((detail) => detail.control_detail.group === value.generalPosition)
                    return {
                        id: detailId?.id,
                        group: value.generalPosition,
                        idunwanted_event: value.unwanted_event.map(event => event.id)
                    }
                } else {
                    return {
                        group: value.generalPosition,
                        idunwanted_event: value.unwanted_event.map(event => event.id)
                    }
                }
            })
            return returns
        }).reduce((acc, arr) => { return acc.concat(arr) }, [])

        const controlDetail = groups?.map((element) => {
            const returns = element.groups.map((value) => {
                if (isEdit) {
                    const detailId = selectedAST.detailAST?.find((detail) => detail.control_detail.group === value.generalPosition)
                    return {
                        id: detailId?.id,
                        group: value.generalPosition,
                        idcontrol: value.control_barriers.map(control => control.id)
                    }
                } else {
                    return {
                        group: value.generalPosition,
                        idcontrol: value.control_barriers.map(control => control.id)
                    }
                }
            })
            return returns
        }).reduce((acc, arr) => { return acc.concat(arr) }, [])

        const detailCheck = groups?.map((element) => {
            const returns = element.groups?.map((value) => {
                if (isEdit) {
                    const detailId = selectedAST.detailAST.find((detail) => detail.control_detail.group === value.generalPosition)
                    return {
                        id: detailId?.id,
                        group: value.generalPosition,
                        check: value.checked
                    }
                } else {
                    return {
                        group: value.generalPosition,
                        check: value.checked
                    }
                }

            })
            return returns
        }).reduce((acc, arr) => { return acc.concat(arr) }, [])

        const risksDetail = groupRisks?.map((element) => {
            if (isEdit) {
                const returns = element.controls?.map((value) => {
                    return {
                        id: value.id,
                        idcontrols_cr: value.idcontrol,
                        check: value.check
                    }
                })
                return returns
            } else {
                const returns = element.controls?.map((value) => {
                    return {
                        idcontrols_cr: value.idcontrol,
                        check: value.check
                    }
                })
                return returns
            }
        }).reduce((acc, arr) => { return acc.concat(arr) }, [])

        if (mainStagesWorksDetail.length === 0) return CustomSnackbar('warning', 'No hay etapas principales de trabajo para las actividades seleccionadas.')
        if (energieDetail.length === 0) return CustomSnackbar('warning', 'No hay energías para las etapas principales de trabajo.')
        if (unwantedEventDetail.length === 0) return CustomSnackbar('warning', 'No hay eventos no deseados para las etapas principales de trabajo.')
        if (controlDetail.length === 0) return CustomSnackbar('warning', 'No hay controles/barreras para las etapas principales de trabajo.')
        if (risksDetail.length === 0) return CustomSnackbar('warning', 'No hay controles de riesgos críticos.')

        const payload = {
            idwork_order: isEdit ? selectedAST.idwork_order : values.idwork_order,
            idmechanic: user.data?.id,
            work_done: selectedActivity.map((element: WorkDone) => element.id),
            idcompany: 1,
            date: values.date,
            verifications_detail: verification,
            main_stages_work_detail: mainStagesWorksDetail,
            energy_detail: energieDetail,
            unwanted_event_detail: unwantedEventDetail,
            control_detail: controlDetail,
            detail_check: detailCheck,
            control_critical_risk: risksDetail
        }

        if (!isEdit) {
            const ASTresponse = await createASTStore(payload);
            if (ASTresponse.id) {
                CustomSnackbar("succes", "Se creó correctamente un nuevo documento AST.")
                setNewWorkOrders([])
                navigate(`/ast-signature/${ASTresponse.id}`);
            } else {
                CustomSnackbar("warning", "No se pudo crear correctamente el documento AST.")
            }
        } else {
            const ASTresponse = await editASTStore(selectedAST.id, payload);
            if (ASTresponse.id) {
                CustomSnackbar("succes", "Se actualizó correctamente el documento AST.")
                setNewWorkOrders([])
                if (userRole === Role.MECHANIC) {
                    supervisors?.map(async (element) => {
                        const notify: createNotification = {
                            iduser_type: 3,
                            idreceiver: element.id_user,
                            rut_receiver: element.rut,
                            title: `Documento AST (${selectedAST.request_correlative}) editado`,
                            details: `Editado por el mecánico ${user.data?.name} ${user.data?.surname}. Patente del vehículo: ${selectedAST.patent}.`,
                            idview: 6,
                            idcompany: 1,
                            idregister: ASTresponse.id,
                        }
                        return await createNotifications(notify)
                    })
                } else if (userRole === Role.SUPERVISOR) {
                    mechanics?.map(async (element) => {
                        const notify: createNotification = {
                            iduser_type: 6,
                            idreceiver: element.id_user,
                            rut_receiver: element.rut,
                            title: `Documento AST (${selectedAST.request_correlative}) editado`,
                            details: `Editado por el supervisor ${user.data?.name} ${user.data?.surname}. Patente del vehículo: ${selectedAST.patent}.`,
                            idview: 6,
                            idcompany: 1,
                            idregister: ASTresponse.id,
                        }
                        return await createNotifications(notify)
                    })
                }
                navigate(`/ast-signature/${ASTresponse.id}`);
            } else {
                CustomSnackbar("warning", "No se pudo actualizar correctamente el documento AST.")
            }
        }
    };

    return (
        <CustomForm>
            {ASTstatus === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            {loading && (<CustomBackdrop open={true} />)}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo ANÁLISIS DE SEGURIDAD EN EL TRABAJO (AST)' : 'Editar ANÁLISIS DE SEGURIDAD EN EL TRABAJO (AST)'}
                text={!isEdit ? 'Ingrese los datos del nuevo documento AST.' : 'Modifique los datos del documento AST.'}
                goBack={RoutesMap.AST_LIST} />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Orden de trabajo:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-request"
                                                value={selectedWorkOrder}
                                                options={newWorkOrders || []}
                                                getOptionLabel={(option) => option.correlative || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedWorkOrder(newValue);
                                                    setFieldValue('idwork_order', newValue?.id || 0)
                                                }}
                                                readOnly={isEdit || isPreOT}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Fecha:</FormLabel>
                                            <TextField
                                                id="date"
                                                type="date"
                                                name="date"
                                                fullWidth
                                                size="small"
                                                value={values.date}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormLabel>Actividad:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-work-dones"
                                                value={selectedActivity || []}
                                                options={workDones || []}
                                                getOptionLabel={(option) => option?.description || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedActivity(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.description}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Accordion sx={{ width: '100%' }}>
                                            <AccordionSummary>
                                                <Grid item xs={12} sx={{ textAlign: "left", marginTop: "2%", marginBottom: "-5%" }} md={6}>
                                                    <Typography>Verificaciones:</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ textAlign: "right" }} md={6}>
                                                    <Tooltip title="Marcar todas las tareas como realizadas" >
                                                        <Button
                                                            onClick={() => handleAllMeets()}
                                                            sx={{
                                                                border: "solid 1px #73B2FF",
                                                                m: "10px",
                                                                color: "#73B2FF",
                                                                "&:hover": {
                                                                    bgcolor: "#73B2FF",
                                                                    color: "#fff",
                                                                },
                                                            }}
                                                        >
                                                            {Object.values(verification).every(value => value.check === 1) ? "Desmarcar todo" : "Marcar todo"}
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{ overflowX: 'auto' }}>
                                                    <Table>
                                                        <TableHead sx={{ background: "#D8E8F7" }}>
                                                            <TableRow >
                                                                <StyledTableCell className="headTable" align="center">VERIFICACIONES</StyledTableCell>
                                                                <StyledTableCell className="headTable" align="center">SÍ/NO</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {verifications.map((verify, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{verify.name}</TableCell>
                                                                    <TableCell>
                                                                        <FormGroup row={true}>
                                                                            <FormControlLabel control={<Radio
                                                                                onClick={() => handleMeets(index + 1, 1)}
                                                                                size="small"
                                                                                checked={verification[index]?.check === 1} />} label="Sí"
                                                                            />
                                                                            <FormControlLabel control={<Radio
                                                                                onClick={() => handleMeets(index + 1, 0)}
                                                                                size="small"
                                                                                checked={verification[index]?.check === 0} />} label="No"
                                                                            />
                                                                        </FormGroup>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Accordion sx={{ width: '100%' }}>
                                            <AccordionSummary >
                                                <Typography>Revisión de riesgos críticos</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <>
                                                    {groups.length === 0 ? (<Grid
                                                        sx={{
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div>{"Seleccione una orden de trabajo"}</div>
                                                    </Grid>) : groups?.map((block, index) => (
                                                        <div style={{ overflowX: 'auto', marginBottom: '10px' }} key={index}>
                                                            <Typography sx={{ mb: 2 }}>Actividad: {block.nameBlock}</Typography>
                                                            <Table sx={{ mb: 2 }}>
                                                                <TableHead sx={{ background: "#D8E8F7" }}>
                                                                    <TableRow >
                                                                        <StyledTableCell className="headTable" align="center" >ÍTEM</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" >ETAPAS PRINCIPALES DEL TRABAJO <br />¿Qué tengo que hacer?</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" >ENERGÍAS <br />Involucradas/Peligros</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" >EVENTOS NO DESEADOS<br />¿Cómo podría accidentarme?</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" >CONTROLES / BARRERAS <br />¿Qué haré para evitar el accidente</StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center" >VERIFICACIÓN</StyledTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {block?.groups?.length === 0 ? (
                                                                        <TableRow>
                                                                            <TableCell
                                                                                colSpan={6}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        display: "flex",
                                                                                    }}
                                                                                >
                                                                                    <div>{"Seleccione actividades"}</div>
                                                                                </Grid>
                                                                            </TableCell>
                                                                        </TableRow>) : block?.groups?.map((element, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell align="center">{`${element.group}`}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <Typography>{element.name_main_stages_works}</Typography>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Typography>{element.energie?.name}</Typography>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {element.unwanted_event?.map((unwanted) => (
                                                                                        <Typography key={unwanted.id}>- {unwanted.case}</Typography>
                                                                                    ))}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {element.control_barriers?.map((control) => (
                                                                                        <Typography key={control.id}>- {control.control}</Typography>
                                                                                    ))}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <Checkbox checked={element.checked === 1} onChange={(event) => onChecked(event, element.group - 1, block.block)} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    ))}
                                                </>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Accordion sx={{ width: '100%' }}>
                                            <AccordionSummary >
                                                <Typography>Control de riesgos críticos</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {selectedWorkOrder ? <Grid item xs={12} sx={{ mb: 2 }}>
                                                    <FormLabel>Riesgos críticos:</FormLabel>
                                                    <Autocomplete
                                                        multiple={true}
                                                        disablePortal
                                                        id="combo-box-critical-risks"
                                                        value={selectRisks || []}
                                                        options={criticalRisks || []}
                                                        getOptionLabel={(option) => option?.name || ""}
                                                        fullWidth
                                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                        renderInput={
                                                            (params) => <TextField {...params} size="small" />
                                                        }
                                                        onChange={(e, newValue) => {
                                                            setSelectRisks(newValue);
                                                        }}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li key={option.id} value={option.id} {...props} >
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                    checked={selected}
                                                                />
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        filterSelectedOptions
                                                    />
                                                </Grid> : <Grid
                                                    sx={{
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div>{"Seleccione una orden de trabajo"}</div>
                                                </Grid>}
                                                {groupRisks.length > 0 ? groupRisks.map((group, positionGroup) => (
                                                    <Grid item xs={12} key={positionGroup}>
                                                        <div style={{ overflowX: 'auto' }}>
                                                            <Table sx={{ mb: 2 }}>
                                                                <TableHead sx={{ background: "#D8E8F7" }}>
                                                                    <TableRow>
                                                                        <TableCell colSpan={3}>
                                                                            <Grid
                                                                                sx={{
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    display: "flex",
                                                                                }}
                                                                            >
                                                                                <div>RIESGO CRÍTICO: {group.name.toUpperCase()}</div>
                                                                            </Grid>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow >
                                                                        <StyledTableCell className="headTable" align="center">
                                                                            ÍTEM
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center">
                                                                            CONTROL DE RIESGO CRÍTICO
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className="headTable" align="center">
                                                                            VERIFICACIÓN
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {group.controls?.map((control, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell align="center">{index + 1}</TableCell>
                                                                            <TableCell align="center">{control.nameControl}</TableCell>
                                                                            <TableCell align="center">
                                                                                <Checkbox checked={control.check === 1} onChange={(event) => onRiskChecked(event, index, positionGroup)} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </Grid>
                                                )) : null}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={isEdit ? 'Actualizar' : 'Guardar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>)
}