import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { ASTType, AST } from "../../types/slices/ASTType";

const initialState: ASTType = {
    status: ApiStatus.FETCHED,
    ASTs: [],
    errorMessage: undefined,
    selectedAST: {} as AST,
    editAST: {} as AST,
};

const ASTSlice = createSlice({
    name: "AST-state",
    initialState,
    reducers: {
        onFetchAST(state, { payload }: { payload: AST[] }) {
            state.status = ApiStatus.FETCHED;
            state.ASTs = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditAST(state, { payload }: { payload: AST }) {
            state.editAST = payload;
        },
        onSetSelectedAST(state, { payload }: { payload: AST }) {
            state.selectedAST = payload;
        },
        onSetIdWorkOrderAST(state, { payload }: { payload: number }) {
            state.selectedAST.idwork_order = payload
        }
    }
})

export const selectASTState = (state: RootState) => state.ASTSlice;

export default ASTSlice.reducer;

export const {
    onFetchAST,
    changeStatus,
    onSetEditAST,
    onSetSelectedAST,
    onSetIdWorkOrderAST
} = ASTSlice.actions;