import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { IdentifiesFiles, Identify, IdentifyType } from "../../types/slices/identifyType";

const initialState: IdentifyType = {
    status: ApiStatus.FETCHED,
    identifications: [],
    errorMessage: undefined,
    selectedIdentify: {} as Identify,
    IdentifiesFiles: {
        front_identification: undefined,
        reverse_identification: undefined,
        identification_signature: undefined,
        person_identification: undefined
    },
    image: undefined
}

const identifySlice = createSlice({
    name: "identify",
    initialState,
    reducers: {
        onFetchIdentify(state, { payload }: { payload: Identify[] }) {
            state.status = ApiStatus.FETCHED;
            state.identifications = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedIdentify(state, { payload }: { payload: Identify }) {
            state.selectedIdentify = payload;
        },
        onSetIdentifyFiles(state, { payload }: { payload: IdentifiesFiles }) {
            state.IdentifiesFiles = payload;
        },
        onSetIdentifyFront(state, { payload }: { payload: any }) {
            state.IdentifiesFiles.front_identification = payload;
        },
        onSetIdentifyReverse(state, { payload }: { payload: any }) {
            state.IdentifiesFiles.reverse_identification = payload;
        },
        onSetIdentifySignature(state, { payload }: { payload: any }) {
            state.IdentifiesFiles.identification_signature = payload;
        },
        onSetIdentifyPerson(state, { payload }: { payload: any }) {
            state.IdentifiesFiles.person_identification = payload;
        },
        onSetImage (state, { payload }: { payload: any }) {
            state.image = payload
        }
    }
})

export const selectIdentifyState = (state: RootState) => state.identifySlice;
export default identifySlice.reducer;

export const {
    onFetchIdentify,
    changeStatus,
    onSetSelectedIdentify,
    onSetIdentifyFront,
    onSetIdentifyReverse,
    onSetIdentifySignature,
    onSetIdentifyPerson,
    onSetIdentifyFiles,
    onSetImage
} = identifySlice.actions