import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { MaintenanceCompany, MaintenanceCompanyType } from "../../types/slices/maintenanceCompanyType";

const initialState: MaintenanceCompanyType = {
    status: ApiStatus.FETCHED,
    maintenanceCompanies: [],
    errorMessage: undefined,
    selectedMaintenanceCompany: {} as MaintenanceCompany,
}

const maintenanceCompanySlice = createSlice({
    name: "maintenanceCompany",
    initialState,
    reducers: {
        onFetchMaintenanceCompany(state, { payload }: { payload: MaintenanceCompany[] }) {
            state.status = ApiStatus.FETCHED;
            state.maintenanceCompanies = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedMaintenanceCompany(state, { payload }: { payload: MaintenanceCompany }) {
            state.selectedMaintenanceCompany = payload;
        },
    }
});

export const selectMaintenanceCompanyState = (state: RootState) => state.maintenanceCompanySlice;
export default maintenanceCompanySlice.reducer;

export const {
    onFetchMaintenanceCompany,
    changeStatus,
    onSetSelectedMaintenanceCompany,
} = maintenanceCompanySlice.actions