import React, { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTechnicalStore } from "../../hooks/useTechnical";
import { Technical } from "../../types/slices/technicalType";
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomPhoneInput } from "../../components/common/CustomPhoneInput/CustomPhoneInput";

type TechnicalDataForm = {
    id: number;
    rut: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    address: string;
    birth_date: string;
}

type errorsForm = {
    rut?: string;
    name?: string;
    surname?: string;
    email?: string;
    phone?: string;
    address?: string;
    birth_date?: string;
};

const TechnicalForm: FC = () => {
    const navigate = useNavigate();
    const { status, selectedTechnical, setSelectedTechnical, createTechnicalStore, editTechnicalStore } = useTechnicalStore()

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<TechnicalDataForm>({
        id: 0,
        rut: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        address: "",
        birth_date: ""
    });

    useEffect(() => {
        if (Object.entries(selectedTechnical).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedTechnical.id,
                rut: selectedTechnical.rut,
                name: selectedTechnical.name,
                surname: selectedTechnical.surname,
                email: selectedTechnical.email,
                phone: selectedTechnical.phone,
                address: selectedTechnical.address,
                birth_date: selectedTechnical.birth_date,
            });
            setSelectedTechnical({} as Technical);
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            rut: values.rut,
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
            address: values.address,
            birth_date: values.birth_date
        };
        const method = !isEdit
            ? createTechnicalStore(payload)
            : editTechnicalStore(data.id, payload);
        const response = await method;
        if (response.status === true) {
            navigate({ pathname: RoutesMap.TECHNICAL });
        }
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.TECHNICAL });
    };

    const validateForm = (values: any) => {
        let errors: errorsForm = {};

        if (!values.rut) errors.rut = "RUT es requerido";
        if (!values.name) errors.name = "Nombre requerido";
        if (!values.surname) errors.surname = "Apellido requerido";
        if (!values.email) errors.email = "Email es requerido";
        if (!values.phone) errors.phone = "Celular es requerido";
        if (!values.address) errors.address = "Dirección es requerido";
        if (!values.birth_date) errors.birth_date = "Fecha de nacimiento es requerido";

        return errors;
    };

    return (
        <CustomForm>
            {status === ApiStatus.FETCHING && (
                <CustomBackdrop open={true} />
            )}
            <CustomFormHeader
                title={!isEdit ? "Nuevo Técnico" : "Editar Técnico"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo técnico"
                        : "Ingrese los datos del técnico"
                }
                goBack={RoutesMap.TECHNICAL}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit} validate={(values) => validateForm(values)}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text"
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={
                                                    errors.name && touched.name
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.name && touched.name
                                                        ? errors.name
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Apellido:</FormLabel>
                                            <TextField
                                                id="surname"
                                                type="text"
                                                name="surname"
                                                fullWidth
                                                size="small"
                                                value={values.surname}
                                                onChange={handleChange}
                                                error={
                                                    errors.surname &&
                                                        touched.surname
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.surname &&
                                                        touched.surname
                                                        ? errors.surname
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>RUT:</FormLabel>
                                            <TextField
                                                id="rut"
                                                type="text"
                                                name="rut"
                                                fullWidth
                                                size="small"
                                                value={values.rut}
                                                onChange={handleChange}
                                                error={
                                                    errors.rut && touched.rut
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.rut && touched.rut
                                                        ? errors.rut
                                                        : ""
                                                }
                                                InputProps={{
                                                    inputProps: { maxLength: 10 },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Email:</FormLabel>
                                            <TextField
                                                id="email"
                                                type="email"
                                                name="email"
                                                fullWidth
                                                size="small"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={
                                                    errors.email && touched.email
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.email && touched.email
                                                        ? errors.email
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Celular:</FormLabel>
                                            <CustomPhoneInput value={values.phone} setting={setFieldValue} errors={errors} touched={touched} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Dirección:</FormLabel>
                                            <TextField
                                                id="address"
                                                type="text"
                                                name="address"
                                                fullWidth
                                                size="small"
                                                value={values.address}
                                                onChange={handleChange}
                                                error={
                                                    errors.address && touched.address
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.address && touched.address
                                                        ? errors.address
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Fecha de nacimiento:</FormLabel>
                                            <TextField
                                                id="birth_date"
                                                type="date"
                                                name="birth_date"
                                                fullWidth
                                                size="small"
                                                value={values.birth_date}
                                                onChange={handleChange}
                                                error={
                                                    errors.birth_date && touched.birth_date
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.birth_date && touched.birth_date
                                                        ? errors.birth_date
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default TechnicalForm