import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { ImageFile, TechnicalReport, technicalReportType } from "../../types/slices/technicalreportType";
import { RootState } from "../store";

const initialState:technicalReportType = {
    status: ApiStatus.FETCHED,
    technicalReports: [],
    errorMessage: undefined,
    editTechnicalReport: {} as TechnicalReport,
    images: []
}

const technicalReportSlice = createSlice({
    name: "technicalReport",
    initialState,
    reducers: {
        onFetchTechnicalReports (state, { payload }: { payload: TechnicalReport[] }) {
            state.status = ApiStatus.FETCHED;
            state.technicalReports = payload;
            state.errorMessage = undefined;
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditTechnicalReport (state, { payload }: { payload: TechnicalReport }) {
            state.editTechnicalReport = payload;
        },
        onFetchImages (state, { payload }: { payload: ImageFile[] }) {
            state.images = payload;
        },
    }
})

export const selectTechnicalReportState = (state: RootState) => state.technicalReportSlice;
export default technicalReportSlice.reducer;

export const {
    onFetchTechnicalReports,
    changeStatus,
    onSetEditTechnicalReport,
    onFetchImages
} = technicalReportSlice.actions