import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { MainStageWorkType, MainStageWork } from "../../types/slices/mainStagesWorksType";

const initialState: MainStageWorkType = {
    status: ApiStatus.FETCHED,
    mainStagesWorks: [],
    errorMessage: undefined,
    selectedMainStageWork: {} as MainStageWork,
};

const mainStagesWorkSlice = createSlice({
    name: "controlCriticalRisk",
    initialState,
    reducers: {
        onFetchMainStageWork(state, { payload }: { payload: MainStageWork[] }) {
            state.status = ApiStatus.FETCHED;
            state.mainStagesWorks = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedMainStageWork(state, { payload }: { payload: MainStageWork }) {
            state.selectedMainStageWork = payload;
        },
    }
})

export const selectMainStageWorkState = (state: RootState) => state.mainStagesWorkSlice;

export default mainStagesWorkSlice.reducer;

export const {
    onFetchMainStageWork,
    changeStatus,
    onSetSelectedMainStageWork,
} = mainStagesWorkSlice.actions;