import { combineReducers } from "redux";
import counterSlice from "./slices/counterSlice";
import authSlice from "./slices/authSlice";
import snackbarSlice from "./slices/snackbarSlice";
import backdropSlice from "./slices/backdropSlice";
import drawerSlice from "./slices/drawerSlice";
import calendarSlice from "./slices/calendarSlice";
import navSectionSlice from "./slices/navSectionSlice";
import companySlice from "./slices/companySlice";
import supervisorSlice from "./slices/supervisorSlice";
import administratorSlice from "./slices/administratorSlice";
import headerSlice from "./slices/headerSlice";
import mechanicSlice from "./slices/mechanicSlice";
import driverSlice from "./slices/driverSlice";
import bossMaintenanceSlice from "./slices/bossMaintenanceSlice";
import structureD1Slice from "./slices/structureD1Slice";
import vehiclesSlice from "./slices/vehiclesSlice";
import patternRSlice from "./slices/patternRSlice";
import userSlice from "./slices/userSlice";
import modelVehicleSlice from "./slices/modelVehicleSlice";
import typeVehicleSlice from "./slices/typeVehicleSlice";
import trademarkVehicleSlice from "./slices/trademarkVehicleSlice";
import contrataSlice from "./slices/contrataSlice";
import groupActivityD1Slice from "./slices/groupActivityD1Slice";
import structureD2Slice from "./slices/structureD2Slice";
import identifySlice from "./slices/identifySlice";
import groupActivityD2Slice from "./slices/groupActivityD2Slice";
import patternTSlice from "./slices/patternTSlice";
import externalMaintenanceSlice from "./slices/externalMaintenanceSlice";
import maintenanceCompanySlice from "./slices/maintenanceCompanySlice";
import axleSlice from "./slices/axleSlice";
import maintenanceTypeSlice from "./slices/maintenanceTypeSlice";
import modelMaintenanceSlice from "./slices/modelMaintenanceSlice";
import mileageMaintenanceSlice from "./slices/mileageMaintenanceSlice";
import trademarkMaintenanceSlice from "./slices/trademarkMaintenanceSlice";
import notificationSlice from "./slices/notificationSlice";
import repairRequestSlice from "./slices/repairRequestSlice";
import nameFailSlice from "./slices/nameFailSlice";
import DETSlice from "./slices/DETSlice";
import workOrderSlice from "./slices/workOrderSlice";
import workDoneSlice from "./slices/workDoneSlice";
import replacementPartSlice from "./slices/replacementPartSlice";
import ASTSlice from "./slices/ASTSlice";
import ControlBarrierSlice from "./slices/ControlBarrierSlice";
import controlCriticalRiskSlice from "./slices/controlCriticalRiskSlice";
import criticalRiskSlice from "./slices/criticalRiskSlice";
import energieSlice from "./slices/energieSlice";
import mainStagesWorkSlice from "./slices/mainStagesWorkSlice";
import unwantedEventSlice from "./slices/unwantedEventSlice";
import verificationSlice from "./slices/verificationSlice";
import informMechanicalSlice from "./slices/informMechanicalSlice";
import controlVerificationSlice from "./slices/controlVerificationSlice";
import ActivitiesSlice from "./slices/ActivitiesSlice";
import employeeIncompleteSlice from "./slices/employeeIncompleteSlice";
import controlVerificationCategorySlice from "./slices/controlVerificationCategorySlice";
import technicalSlice from "./slices/technicalSlice";
import customerSlice from "./slices/customerSlice";
import typeAttentionSlice from "./slices/typeAttentionSlice";
import technicalReportSlice from "./slices/technicalReportSlice";
import customerIncompleteSlice from "./slices/customerIncompleteSlice";

const reducers = combineReducers({
    counterSlice: counterSlice,
    authSlice: authSlice,
    snackbarSlice: snackbarSlice,
    backdropSlice: backdropSlice,
    drawerSlice: drawerSlice,
    calendarSlice: calendarSlice,
    navSectionSlice: navSectionSlice,
    companySlice: companySlice,
    supervisorSlice: supervisorSlice,
    mechanicSlice: mechanicSlice,
    administratorSlice: administratorSlice,
    headerSlice: headerSlice,
    driverSlice: driverSlice,
    bossMaintenanceSlice: bossMaintenanceSlice,
    structureD1Slice: structureD1Slice,
    vehiclesSlice: vehiclesSlice,
    patternRSlice: patternRSlice,
    patternTSlice: patternTSlice,
    externalMaintenanceSlice: externalMaintenanceSlice,
    maintenanceCompanySlice: maintenanceCompanySlice,
    userSlice: userSlice,
    modelVehicleSlice: modelVehicleSlice,
    typeVehicleSlice: typeVehicleSlice,
    trademarkVehicleSlice: trademarkVehicleSlice,
    contrataSlice: contrataSlice,
    groupActivityD1Slice: groupActivityD1Slice,
    structureD2Slice: structureD2Slice,
    groupActivityD2Slice: groupActivityD2Slice,
    identifySlice: identifySlice,
    axleSlice: axleSlice,
    maintenanceTypeSlice: maintenanceTypeSlice,
    modelMaintenanceSlice: modelMaintenanceSlice,
    trademarkMaintenanceSlice: trademarkMaintenanceSlice,
    mileageMaintenanceSlice: mileageMaintenanceSlice,
    notificationSlice: notificationSlice,
    repairRequestSlice: repairRequestSlice,
    nameFailSlice: nameFailSlice,
    DETSlice: DETSlice,
    WorkOrderSlice: workOrderSlice,
    WorkDoneSlice: workDoneSlice,
    ReplacementPartSlice: replacementPartSlice,
    InformMechanicalSlice: informMechanicalSlice,
    ControlVerificationSlice: controlVerificationSlice,
    ASTSlice: ASTSlice,
    ControlBarrierSlice: ControlBarrierSlice,
    controlCriticalRiskSlice: controlCriticalRiskSlice,
    criticalRiskSlice: criticalRiskSlice,
    energieSlice: energieSlice,
    mainStagesWorkSlice: mainStagesWorkSlice,
    unwantedEventSlice: unwantedEventSlice,
    verificationSlice: verificationSlice,
    ActivitiesSlice: ActivitiesSlice,
    EmployeeIncompleteSlice: employeeIncompleteSlice,
    ControlVerificationCategorySlice: controlVerificationCategorySlice,
    technicalSlice: technicalSlice,
    customerSlice: customerSlice,
    typeAttentionSlice: typeAttentionSlice,
    technicalReportSlice: technicalReportSlice,
    customerIncompleteSlice: customerIncompleteSlice
});

export default reducers;
