import { useDispatch, useSelector } from "react-redux";
import { BossMaintenanceAPI } from "../apis/BossMaintenanceAPI";
import { selectBossState, changeStatus, onFetchBoss, onSetEditBoss, onSetSelectedBoss } from "../redux/slices/bossMaintenanceSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { BossMaintenance } from "../types/slices/bossMaintenanceType";

export const useBossStore = () => {
    const dispatch = useDispatch();
    const { boss, status, editBoss, selectedBoss } = useSelector(selectBossState);

    const idcompany = 1;

    const getBoss = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await BossMaintenanceAPI.getBoss(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchBoss(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createBossStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await BossMaintenanceAPI.createBoss(data, idcompany);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editBossStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await BossMaintenanceAPI.editBoss(id, data, idcompany);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteBossStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await BossMaintenanceAPI.deleteBoss(id, idcompany);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedBoss = (boss: BossMaintenance) => {
        try {
            dispatch(onSetSelectedBoss(boss));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditBoss = (boss: BossMaintenance) => {
        try {
            dispatch(onSetEditBoss(boss));
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        boss,
        status,
        editBoss,
        selectedBoss,
        //actions
        getBoss,
        createBossStore,
        editBossStore,
        deleteBossStore,
        setSelectedBoss,
        setEditBoss,
    };
};