import { api } from "./configs/axiosConfigs";

export const VehiclesAPI = {
    getVehicles: async (data?: any) => {
        const response = await api
            .get('/vehicle', { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createVehicles: async (data: any) => {
        const response = await api
            .post('/vehicle/register', { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editVehicles: async (id: number, data: any) => {
        const response = await api
            .patch(`/vehicle/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteVehicles: async (id: number) => {
        const response = await api
            .delete(`/vehicle/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    filterVehicles: async (idtrademark: number, idmodel: number, idtype_vehicle: number, idcontrata: number) => {
        const response = await api
            .get(`/vehicle?idtrademark=${idtrademark}&idmodel=${idmodel}&idtype_vehicle=${idtype_vehicle}&idcontrata=${idcontrata}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    }
};