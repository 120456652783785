import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchExternalMaintenance, onSetSelectedExternalMaintenance, selectExternalMaintenanceState } from "../redux/slices/externalMaintenanceSlice"
import { ApiStatus } from "../types/api/status"
import { ExternalMaintenanceAPI } from "../apis/ExternalMaintenanceAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { ExternalMaintenance } from "../types/slices/externalMaintenanceType"

export const useExternalMaintenanceStore = () => {
    const dispatch = useDispatch()
    const { externalMaintenanceS, status, selectedExternalMaintenance } = useSelector(selectExternalMaintenanceState)
    // const idcompany = 1

    const getExternalMaintenance = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ExternalMaintenanceAPI.get(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchExternalMaintenance(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const reportExternalMaintenance = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ExternalMaintenanceAPI.report(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createExternalMaintenance = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ExternalMaintenanceAPI.create(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true;
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editExternalMaintenance = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ExternalMaintenanceAPI.edit(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteExternalMaintenance = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ExternalMaintenanceAPI.delete(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedExternalMaintenance = (externalMaintenance: ExternalMaintenance) => {
        try {
            dispatch(onSetSelectedExternalMaintenance(externalMaintenance))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        externalMaintenanceS,
        status,
        selectedExternalMaintenance,
        //actions
        getExternalMaintenance,
        reportExternalMaintenance,
        createExternalMaintenance,
        editExternalMaintenance,
        deleteExternalMaintenance,
        setSelectedExternalMaintenance,
    }
}