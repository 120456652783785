import { api } from "./configs/axiosConfigs";

export const MaintenanceCompanyAPI = {
    get: async (data?: any) => {
        const response = await api
            .get('/maintenanceCompany', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    },
    create: async (data: any) => {
        const response = await api
            .post("/contrata/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    edit: async (id: number, data: any) => {
        const response = await api
            .patch(`/maintenanceCompany/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    delete: async (id: number) => {
        const response = await api
            .delete(`/maintenanceCompany/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}