import { useDispatch, useSelector } from "react-redux";
import { VerificationsAPI } from "../apis/AST/VerificationsAPI";
import { changeStatus, onFetchVerification, selectVerificationState } from "../redux/slices/verificationSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useVerificationsStore = () => {
    const dispatch = useDispatch();
    const { verifications, status } = useSelector(selectVerificationState);

    const getVerificationsStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await VerificationsAPI.getVerification(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchVerification(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    return {
        verifications,
        status,
        getVerificationsStore,
    }
}