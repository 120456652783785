import moment from 'moment';

export const daysBetweenDates = (dateStart, dateEnd) => {
    let current_day = moment(dateStart);
    let arrayDates:any = [];

  	while (current_day.isSameOrBefore(moment(dateEnd))) {
    	arrayDates.push(current_day.format('YYYY/MM/DD'));
        current_day.add(1, 'days');
  	}

  	return arrayDates;
}

export const formatDate = (date:string) => {
    const dateSplit = date.split('-')
    const final_date = [dateSplit[2], dateSplit[1], dateSplit[0]].join('-')
    return final_date
}