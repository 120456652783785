import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Typography, ListItemButton, Divider, Grid, TextareaAutosize } from "@mui/material"
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useEffect, useState } from "react";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const WorkOrderModalInformation : React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, rowSelected } = props

    const [expanded, setExpanded] = useState<number|false>(0)

    const handleChangePanel = (panel: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return(
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalHeader
                text={'Detalle de Orden de Trabajo'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Accordion expanded={expanded === 1} onChange={handleChangePanel(1)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Información general</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItemButton>
                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Solicitud</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.correlative || ''}</Typography>
                        </ListItemButton>
                        <ListItemButton>
                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Patente</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.patent || ''}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Marca</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.name_trademark_vehicle || ''}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Modelo</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.name_model_vehicle || ''} </Typography>
                        </ListItemButton>
                        <Divider />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 2} onChange={handleChangePanel(2)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{'Motivo(s)'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected?.name_fails_resource?.map((fail, index) => 
                                <>
                                <ListItemButton>
                                    <Typography sx={{ color: 'text.secondary' }}>{(index+1) + '. ' +fail?.name || ''}</Typography>
                                </ListItemButton>
                                <Divider />
                                </>
                            )
                        }
                        {
                            rowSelected?.description_request && (<>
                                <Grid item container xs={12} md={12} sx={{ marginTop: '15px' }} >
                                    <Grid item xs={12}> 
                                        <Typography sx={{ width: '40%', marginLeft: '10px', color: 'text.secondary' }}>Descripción:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextareaAutosize
                                            id="description_request"
                                            name="description_request"
                                            style={{ width: '100%', border: '0px', minHeight: '30px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px', color: 'rgba(0, 0, 0, 0.6)' }} 
                                            value={rowSelected?.description_request || ''}
                                            readOnly={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 3} onChange={handleChangePanel(3)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>{'Informe técnico'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected?.informMechanical?.map((inform, index) => 
                                <>
                                <ListItemButton>
                                    <Typography sx={{ color: 'text.secondary' }}>{(index+1) + '. ' +inform?.description || ''}</Typography>
                                </ListItemButton>
                                <Divider />
                                </>
                            )
                        }
                        {
                            rowSelected?.towable && (
                                <ListItemButton>
                                    <Typography sx={{ width: '40%', flexShrink: 0 }}>Remolcable</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{rowSelected?.towable == 1 ? 'Si' : 'No' } </Typography>
                                </ListItemButton>
                            )
                        }
                        {
                            rowSelected?.technical_review && (<>
                                <Grid item container xs={12} md={12} sx={{ marginTop: '15px' }} >
                                    <Grid item xs={12}> 
                                        <Typography sx={{ width: '40%', marginLeft: '10px', color: 'text.secondary' }}>Descripción:</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextareaAutosize
                                        id="technical_review"
                                        name="technical_review"
                                        style={{ width: '100%', border: '0px', minHeight: '30px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px', color: 'rgba(0, 0, 0, 0.6)' }} 
                                        value={rowSelected?.technical_review || ''}
                                        readOnly={true}
                                    />
                                </Grid>
                                <Divider />
                            </>)
                        }
                    </AccordionDetails>
                </Accordion>
                {
                    rowSelected?.workDone?.length > 0 && (
                        <Accordion expanded={expanded === 4} onChange={handleChangePanel(4)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                            >
                                <Typography>Trabajos a realizar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    rowSelected?.workDone?.map((work, index) => <>
                                    <ListItemButton>
                                        <Typography sx={{ color: 'text.secondary' }}>{(index+1) + '. ' +work.description || ''}</Typography>
                                    </ListItemButton>
                                    <Divider />
                                    </>)
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                {
                    rowSelected?.replacement_parts?.length > 0 && (
                        <Accordion expanded={expanded === 5} onChange={handleChangePanel(5)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5a-content"
                                id="panel5a-header"
                            >
                                <Typography>Repuestos Utilizados</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    rowSelected?.replacement_parts?.map(part => <>
                                        <ListItemButton>
                                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Repuesto</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>{part?.replacement_parts || ''}</Typography>
                                        </ListItemButton>
                                        <ListItemButton>
                                            <Typography sx={{ width: '40%', flexShrink: 0 }}>Cantidad</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>{part?.quantity || ''}</Typography>
                                        </ListItemButton>
                                        <Divider />
                                    </>)
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                }
            </ModalBody>
        </Modal>
    )
}