import { useDispatch, useSelector } from "react-redux";
import { ControlCriticalRiskAPI } from "../apis/AST/ControlCriticalRisk";
import { selectControlCriticalRiskState, changeStatus, onFetchControlCriticalRisk, onSetSelectedControlCriticalRisk } from "../redux/slices/controlCriticalRiskSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { ControlCriticalRisk } from "../types/slices/controlCriticalRiskType";

export const useControlCriticalRiskStore = () => {
    const dispatch = useDispatch();
    const { status, controlCriticalRisks, selectedControlCriticalRisk } = useSelector(selectControlCriticalRiskState);


    const getControlCriticalRisk = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlCriticalRiskAPI.getControlCriticalRisk(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchControlCriticalRisk(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createControlCriticalRiskStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ControlCriticalRiskAPI.createControlCriticalRisk(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editControlCriticalRiskStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ControlCriticalRiskAPI.editControlCriticalRisk(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteControlCriticalRiskStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await ControlCriticalRiskAPI.deleteControlCriticalRisk(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedControlCriticalRisk = (controlCriticalRisk: ControlCriticalRisk) => {
        try {
            dispatch(onSetSelectedControlCriticalRisk(controlCriticalRisk));
        } catch (error) {
            console.log(error);
        }
    };


    return {
        //states
        status, controlCriticalRisks, selectedControlCriticalRisk,
        //actions
        getControlCriticalRisk,
        createControlCriticalRiskStore,
        editControlCriticalRiskStore,
        deleteControlCriticalRiskStore,
        setSelectedControlCriticalRisk,
    };
};