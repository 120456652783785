import { FC, useEffect, useState } from "react";
import { useNameFails } from "../../hooks/useNameFails";
import { NameFail } from "../../types/slices/nameFailType";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name', label: 'DESCRIPCIÓN', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const FailList:FC = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status: statusFail, nameFails, getNameFailStore, deleteNameFailStore, setSelectedNameFail } = useNameFails()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ page, setPage ] = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ rowSelected, setRowSelected ]           = useState<NameFail>({} as NameFail)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)

    useEffect(()=>{
        getFailsApi()
    },[search])

    const getFailsApi = async () => {
        try {
          setLoading(true)
          let payload:any = {
            company : 1
          }
          if (search !== '') {
            payload = {
                ...payload,
                name: search
            }
          }
          await getNameFailStore(payload)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedNameFail({} as NameFail)
        navigate({ pathname: RoutesMap.FAIL_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedNameFail(rowSelected)
        navigate({ pathname: RoutesMap.FAIL_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteNameFailStore(rowSelected.id)
        if (response === true) {
            setSelectedNameFail({} as NameFail)
            setShowModalConfirm(false)
            getFailsApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }


    return (<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Motivos de solicitud de reparación'}
                columns={columns || []}
                loading={loading}
                rows={nameFails || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                    status2={statusFail === ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}