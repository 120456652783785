import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchDrivers, onSetSelectedDriver, selectDriverState } from "../redux/slices/driverSlice"
import { ApiStatus } from "../types/api/status"
import { driverAPI } from "../apis/driverAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Driver } from "../types/slices/driverType"

export const useDriverStore = () => {
    const dispatch = useDispatch()
    const { drivers, status, selectedDriver } = useSelector(selectDriverState)

    const getDrivers = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await driverAPI.getDrivers(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchDrivers(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createDriver = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await driverAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editDriver = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await driverAPI.edit(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteDriver = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await driverAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedDriver = (driver: Driver) => {
        try {
            dispatch(onSetSelectedDriver(driver))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        drivers,
        status,
        selectedDriver,
        //actions
        getDrivers,
        createDriver,
        editDriver,
        deleteDriver,
        setSelectedDriver
    }
}