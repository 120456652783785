import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { ControlVerification, ControlVerificationType } from "../../types/slices/controlVerificationType";
import { RootState } from "../store";

const initialState:ControlVerificationType = {
    status: ApiStatus.FETCHED,
    controlVerifications: [],
    errorMessage: undefined,
    editControlVerification: {} as ControlVerification
}

const ControlVerificationSlice = createSlice({
    name: 'ControlVerification',
    initialState,
    reducers: {
        onFetchControlVerifications (state, { payload }: { payload: ControlVerification[] }) {
            state.status            = ApiStatus.FETCHED
            state.controlVerifications        = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditControlVerification (state, { payload }: { payload: ControlVerification }) {
            state.editControlVerification = payload
        }
    }
})

export const selectControlVerificationState = (state: RootState) => state.ControlVerificationSlice
export default ControlVerificationSlice.reducer

export const {
    onFetchControlVerifications,
    changeStatus,
    onSetEditControlVerification
} = ControlVerificationSlice.actions