import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { CustomerERP, UserERP, UserSlice, UserType, UserTypeERP, UserTypeSlice } from "../../types/slices/userType";
import { RootState } from "../store";

const initialState: UserTypeSlice = {
    status: ApiStatus.FETCHED,
    users: [],
    errorMessage: undefined,
    selectedUser: {} as UserSlice,
    userTypes: [],
    userTypesERP: [],
    usersERP: [],
    customersERP: []
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        onFetchUsers(state, { payload }: { payload: UserSlice[] }) {
            state.status = ApiStatus.FETCHED
            state.users = payload
            state.errorMessage = undefined
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedUser(state, { payload }: { payload: UserSlice }) {
            state.selectedUser = payload
        },
        onFetchUserTypes(state, { payload }: { payload: UserType[] }) {
            state.userTypes = payload
        },
        onFetchUserTypesERP(state, { payload }: { payload: UserTypeERP[] }) {
            state.userTypesERP = payload
        },
        onFetchUsersERP(state, { payload }: { payload: UserERP[] }) {
            state.usersERP = payload
        },
        onFetchCustomersERP(state, { payload }: { payload: CustomerERP[] }) {
            state.customersERP = payload
        },
    }
})

export const selectUserState = (state: RootState) => state.userSlice
export default userSlice.reducer

export const {
    onFetchUsers,
    changeStatus,
    onSetSelectedUser,
    onFetchUserTypes,
    onFetchUserTypesERP,
    onFetchUsersERP,
    onFetchCustomersERP
} = userSlice.actions