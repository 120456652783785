import { FC, useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { useVehicleStore } from '../../hooks/useVehicleStore';
import { Vehicles } from '../../types/slices/vehiclesType';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { selectHeaderState } from '../../redux/slices/headerSlice';
import { useSelector } from 'react-redux';
import VehiclesFilter from './VehiclesFilter';
import { ApiStatus } from '../../types/api/status';

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "patent", label: "PPU", align: 'center' },
    { type: "text", field: "trademark", label: "MARCA", align: 'center' },
    { type: "text", field: "type_vehicle", label: "TIPO DE VEHÍCULO", align: 'center' },
    { type: "text", field: "model", label: "MODELO", align: 'center' },
    { type: "text", field: "year", label: "AÑO", align: 'center' },
    { type: "text", field: "n_chassis", label: "N° CHASIS", align: 'center' },
    { type: "text", field: "idcontrata", label: "CONTRATO", format: (row: any) => row?.idcontrata === 1 ? "El soldado" : "Las tortolas", align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsNotOptions = [
    { type: "text", field: "patent", label: "PPU", align: 'center' },
    { type: "text", field: "trademark", label: "MARCA", align: 'center' },
    { type: "text", field: "type_vehicle", label: "TIPO DE VEHÍCULO", align: 'center' },
    { type: "text", field: "model", label: "MODELO", align: 'center' },
    { type: "text", field: "year", label: "AÑO", align: 'center' },
    { type: "text", field: "n_chassis", label: "N° CHASIS", align: 'center' },
    { type: "text", field: "idcontrata", label: "CONTRATO", format: (row: any) => row?.idcontrata === 1 ? "El soldado" : "Las tortolas", align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const VehiclesList: FC<any> = ({onSelectedVehicle, setBuscadorModal}) => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState)
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<Vehicles>(
        {} as Vehicles
    );
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);

    const {
        vehicles,
        vehicleStatus,
        getVehicles,
        setSelectedVehicle,
        deleteVehicleStore,
        editVehicleStore
    } = useVehicleStore()

    useEffect(() => {
        getVehiclesApi();
    }, [search]);

    const getVehiclesApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getVehicles(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        navigate({ pathname: RoutesMap.VEHICLES_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedVehicle(rowSelected);
        navigate({ pathname: RoutesMap.VEHICLES_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteVehicleStore(rowSelected.id);
        if (response === true) {
            setSelectedVehicle({} as Vehicles);
            setModalDeleteConfirm(false);
            getVehiclesApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onRowClick = (row) => {
        if(!!onSelectedVehicle) {
            onSelectedVehicle(row); setBuscadorModal(false);
        }
    }

    const onSwitch = async (row) => {
        const response = await editVehicleStore(row.id, { status: row.status == 1 ? 0 : 1 })
        if (response) getVehiclesApi()
    }

    return (
        <>
            <Grid container xs={12}>
                <Grid item container sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography variant="h6"
                        component="h1"
                        gutterBottom
                        color="#808080"
                        className="tittle"
                        sx={{ mt: 1, ml: 1, fontWeight: 600 }}>VEHÍCULOS</Typography>
                </Grid>
                <Grid item container xs={12} sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                }}>
                    <VehiclesFilter loading={setLoading} />
                </Grid>
                <CustomTable
                    columns={!!!onSelectedVehicle ?columns: columnsNotOptions}
                    loading={loading}
                    rows={vehicles || []}
                    onRowClick={onRowClick}
                    hasOptions={!!!onSelectedVehicle}
                    onAddFn={!!!onSelectedVehicle && onAdd}
                    onDelete={!!!onSelectedVehicle && onDelete}
                    onEdit={!!!onSelectedVehicle && onEdit}
                    onSwitch={!!!onSelectedVehicle && onSwitch}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={vehicleStatus === ApiStatus.FETCHING ? true : false}
                />
            )}
        </>
    )
}

export default VehiclesList
