import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useReplacementPartStore } from "../../hooks/useReplacementPartStore";

type ReplacementPartDataForm = {
    code: string
    name: string
    stock: number
}

export const ReplacementPartForm: FC = () => {
    const navigate = useNavigate()
    const { status, editReplacementPart, createReplacementPart, updateReplacementPart } = useReplacementPartStore()

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<ReplacementPartDataForm>({
        code: '',
        name: '',
        stock: 0
    })

    useEffect(() => {
        if (Object.entries(editReplacementPart).length > 0) {
            setIsEdit(true)
            setData({
                code: editReplacementPart.code,
                name: editReplacementPart.name,
                stock: editReplacementPart.stock
            })
        }
    }, [])

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.code) errors.code = "Código es requerido";
        if (!values.name) errors.name = "Nombre es requerido";
        if (!values.stock) errors.stock = "Stock es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const payload = {
            code: values.code,
            name: values.name,
            stock: values.stock
        }
        const method = !isEdit ? createReplacementPart(payload) : updateReplacementPart(editReplacementPart.id, payload)
        const response = await method
        if (response === true) navigate({ pathname: RoutesMap.REPLACEMENT_PART_LIST })
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.REPLACEMENT_PART_LIST })
    }

    return (
        <CustomForm>
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo trabajo realizado' : 'Editar trabajo realizado'}
                text={!isEdit ? 'Ingrese los datos del nuevo trabajo realizado' : 'Ingrese los datos del trabajo realizado'}
                goBack={RoutesMap.REPLACEMENT_PART_LIST}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Código:</FormLabel>
                                            <TextField
                                                id="code"
                                                type="text"
                                                name="code"
                                                fullWidth
                                                size="small"
                                                value={values.code}
                                                onChange={handleChange}
                                                error={errors.code && touched.code ? true : false}
                                                helperText={errors.code && touched.code ? errors.code : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Nombre:</FormLabel>
                                            <TextField
                                                id="name"
                                                type="text"
                                                name="name"
                                                fullWidth
                                                size="small"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name && touched.name ? true : false}
                                                helperText={errors.name && touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Stock:</FormLabel>
                                            <TextField
                                                id="stock"
                                                type="number"
                                                name="stock"
                                                fullWidth
                                                size="small"
                                                value={values.stock}
                                                onChange={handleChange}
                                                error={errors.stock && touched.stock ? true : false}
                                                helperText={errors.stock && touched.stock ? errors.stock : ''}
                                                inputProps={{ min: 0 }}
                                                InputProps={{ readOnly: isEdit }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}