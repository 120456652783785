import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Vehicles, VehiclesType } from "../../types/slices/vehiclesType";
import { RootState } from "../store";

const initialState: VehiclesType = {
    status: ApiStatus.FETCHED,
    vehicles: [],
    errorMessage: undefined,
    editVehicles: {} as Vehicles,
    selectedVehicles: {} as Vehicles,
};

const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        onFetchVehicles(state, { payload }: { payload: Vehicles[] }) {
            state.status = ApiStatus.FETCHED;
            state.vehicles = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditVehicles(state, { payload }: { payload: Vehicles }) {
            state.editVehicles = payload;
        },
        onSetSelectedVehicles(state, { payload }: { payload: Vehicles }) {
            state.selectedVehicles = payload;
        },
        onFilterVehicles(state, { payload }: { payload: Vehicles[] }) {
            state.status = ApiStatus.FETCHED;
            state.vehicles = payload;
            state.errorMessage = undefined;
        }
    }
});

export const selectVehiclesState = (state: RootState) => state.vehiclesSlice;

export default vehiclesSlice.reducer;

export const {
    onFetchVehicles,
    changeStatus,
    onSetEditVehicles,
    onSetSelectedVehicles,
    onFilterVehicles,
} = vehiclesSlice.actions;