import { api } from "./configs/axiosConfigs";

export const SupervisorAPI = {
    getSupervisors: async (data?: any) => {
        const response = await api
            .get("/supervisor", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createSupervisors: async (data: any, idcompany: number) => {
        const response = await api
            .post("supervisor/register", { ...data, idcompany: idcompany })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editSupervisors: async (id: number, data: any, idcompany: number) => {
        const response = await api
            .patch(`/supervisor/${id}`, { ...data, idcompany: idcompany })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteSupervisors: async (id: number) => {
        const response = await api
            .delete(`/supervisor/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
};
