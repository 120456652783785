import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTrademarkVehicleStore } from "../../hooks/useTrademarkVehicleStore";
import { TrademarkVehicle } from "../../types/slices/trademarkVehicleType";
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

type TrademarkVehiclesDataForm = {
    id: number
    name: string
}

export const TrademarkVehicleForm: FC = () => {
    const navigate = useNavigate();
    const { trademarkStatus, selectedTrademarkVehicle, setSelectedTrademark, createTrademarkStore, editTrademarkStore } = useTrademarkVehicleStore();

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<TrademarkVehiclesDataForm>({
        id: 0,
        name: '',
    });

    useEffect(() => {
        if (Object.entries(selectedTrademarkVehicle).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedTrademarkVehicle.id,
                name: selectedTrademarkVehicle.name,
            });
            setSelectedTrademark({} as TrademarkVehicle);
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            name: values.name,
            number_axles: values.number_axles,
            axle_detail: values.axle_detail
        }
        const method = !isEdit ? createTrademarkStore(payload) : editTrademarkStore(data.id, payload);
        const response = await method;
        if (response === true) {
            navigate({ pathname: RoutesMap.TRADEMARK_VEHICLES })
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.TRADEMARK_VEHICLES });
    };

    const validateForm = (values: any) => {
        let errors: any = {};
        if (!values.name) errors.name = "El nombre de la marca de vehículo es obligatorio"
        return errors;
    }

    return (
        <CustomForm>
            {trademarkStatus === ApiStatus.FETCHING && (
                <CustomBackdrop open={true} />
            )}
            <CustomFormHeader
                title={!isEdit ? "Nueva marca de vehículo" : "Editar marca de vehículo"}
                text={
                    !isEdit
                        ? "Ingrese los datos de la nueva marca de vehículo"
                        : "Ingrese los datos de la marca de vehículo"
                }
                goBack={RoutesMap.TRADEMARK_VEHICLES}
            />
            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (<form onSubmit={handleSubmit}>
                            <Grid item container xs={12} spacing={2} marginBottom={2}>
                                <Grid item xs={12} md={4} marginLeft={2}>
                                    <FormLabel>Nombre:</FormLabel>
                                    <TextField
                                        id="name"
                                        type="text"
                                        name="name"
                                        inputProps={{
                                            maxLength: 45,
                                        }}
                                        fullWidth
                                        size="small"
                                        value={values.name}
                                        onChange={handleChange}
                                        error={
                                            errors.name && touched.name
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.name && touched.name
                                                ? errors.name
                                                : ""
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <CustomFormFooter
                                buttonType="submit"
                                confirmText={
                                    !isEdit ? "Guardar" : "Actualizar"
                                }
                                cancelText={"Cancelar"}
                                onConfirm={handleSubmit}
                                onCancel={onCancel}
                            />
                        </form>)
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}
