import React, { FC, useEffect, useState } from 'react'
import { Autocomplete, FormLabel, Grid, InputAdornment, TextField, Divider, CircularProgress, Checkbox, Tooltip, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, IconButton } from "@mui/material";
import { CustomFormHeader } from '../../components/common/CustomForm/CustomFormHeader'
import { CustomForm } from '../../components/common/CustomForm/CustomForm'
import { CustomFormBody } from '../../components/common/CustomForm/CustomFormBody'
import { Formik } from 'formik'
import { useAuthStore } from '../../hooks';
import { useVehicleStore } from '../../hooks/useVehicleStore';
import { CustomFormFooter } from '../../components/common/CustomForm/CustomFormFooter';
import { Vehicles } from '../../types/slices/vehiclesType';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, RoutesMap } from '../../types';
import { useNameFails } from '../../hooks/useNameFails';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { useRepairRequest } from '../../hooks/useRepairRequest';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import { ApiStatus } from '../../types/api/status';
import { useSupervisorStore } from '../../hooks/useSupervisorStore';
import { NameFail } from '../../types/slices/nameFailType';
import { Customer } from '../../types/slices/customerType';
import { useCustomerStore } from '../../hooks/useCustomerStore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Role } from '../../types/roles/roleTypes';
import { StyledTableCell } from '../../toolbox/helpers/accordion-styled';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ImageFile } from '../../types/slices/technicalreportType';
import { RepairRequestImages } from './RepairRequestImages';
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';

type RepairRequestDataForm = {
    description: string
}

const RepairRequestForm: FC = () => {    
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { customers, getCustomerStore } = useCustomerStore()
    const { nameFails, getNameFailStore } = useNameFails()
    const { status, selectedRepairRequest, createRepairRequestStore, editRepairRequestStore } = useRepairRequest()

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [selectedCustomer, setSelectedCustomer] = useState<Customer|null>(null)
    const [selectedNameFails, setSelectedNameFails] = useState<NameFail[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<RepairRequestDataForm>({
        description: ''
    })
    const [images, setImages] = useState<ImageFile[]>([])
    const [showModalImage, setShowModalImage] = useState<boolean>(false)

    const userRole:Role|any = user.userType
    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    useEffect(() => {
        infoApi()
        setDataEdit()
    }, [])

    useEffect(()=>{
        if (Object.entries(selectedRepairRequest).length > 0) {
            const customer = customers.find(customer => customer.id === selectedRepairRequest.idcustomer)
            setSelectedCustomer(customer || null)
        }
    },[customers])

    useEffect(()=>{
        if (Object.entries(selectedRepairRequest).length > 0) {
            const fails = nameFails.filter(fail => selectedRepairRequest.name_fails.includes(fail.id))
            setSelectedNameFails(fails || [])
        }
    },[nameFails])

    const infoApi = async () => {
        try {
            setLoading(true)
            await getCustomerStore()
            await getNameFailStore()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const setDataEdit = () => {
        if (Object.entries(selectedRepairRequest).length > 0) {
            setIsEdit(true)
            setData({
                description: selectedRepairRequest.description
            })
            const Imagenes:ImageFile[] = selectedRepairRequest.images?.map((image, index) => ({
                id: image.id,
                n: index+1,
                image: image.image,
                file_name: image.file_name,
                comment: image.comment,
                status: image.status
            }))
            setImages(Imagenes)
        }
    }

    const onCancel = () => {
        navigate({ pathname: RoutesMap.REPAIR_REQUEST })
    }

    const onSubmit = async (values: any) => {
        if (userRole === Role.ADMIN && !selectedCustomer) return CustomSnackbar("warning", "Seleccione un cliente para generar la solicitud.");
        if (selectedNameFails.length === 0) CustomSnackbar("warning", "Seleccione o ingrese alguna falla.")
        const idcustomer:any = userRole === Role.CUSTOMER ? user.data?.id : selectedCustomer?.id

        const formData = new FormData()
        selectedNameFails.forEach((fail, index) => {
            formData.append(`name_fails[${index}]`, fail.id.toString())
        })
        formData.append('description', values.description)
        formData.append('idcustomer', idcustomer)
        images?.forEach((image, index) => {
            formData.append(`images[${index}][id]`, image.id?.toString() || '')
            formData.append(`images[${index}][image]`, image.image)
            formData.append(`images[${index}][file_name]`, image.file_name)
            formData.append(`images[${index}][comment]`, image.comment)
        });
        formData.append('num_images', images?.length.toString())
        formData.append('idcompany', idcompany)
        
        const createRepairRequest = await ( !isEdit ? createRepairRequestStore(formData) : editRepairRequestStore(selectedRepairRequest.id, formData))
        if (createRepairRequest === true) navigate({ pathname: RoutesMap.REPAIR_REQUEST })
    }

    const deleteImageTable = (n:number) => {
        const newImages = images.filter(image => image.n !== n)
        if (!newImages) return
        setImages(newImages)
    }

    const showImage = (n:number) => {
        const imageToShow:any = images.find(image => image.n === n)
        if (!imageToShow) return
        if (imageToShow.id == null) {
            const imageUrl = URL.createObjectURL(imageToShow.image)
            window.open(imageUrl, '_blank')
        } else {
            const url = imageToShow.image
            const win:any = window.open(`${process.env.REACT_APP_ROOT_URL}/` + url, '_blank')
            win.focus()
        }
    }

    return (
        <CustomForm>
            {loading && <CustomBackdrop open={true}/>}
            { status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader goBack={RoutesMap.REPAIR_REQUEST} title={'Solicitud de reparación'} text={ !isEdit ? 'Ingrese los datos para generar una nueva solicitud de reparación.' : 'Ingrese los datos para actualizad la solicitud de reparación.'} />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2}>
                                        {
                                            userRole !== Role.CUSTOMER && (
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>Cliente:</FormLabel>
                                                        <Autocomplete
                                                            loading={loading}
                                                            disablePortal
                                                            id="combo-box-customer"
                                                            value={selectedCustomer}
                                                            options={customers || []}
                                                            getOptionLabel={(option) => option.name_rz || ""}
                                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                            fullWidth
                                                            renderInput={(params) => <TextField {...params} size="small"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }} />}
                                                            onChange={(e, newValue) => {
                                                                setSelectedCustomer(newValue);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel>RUT:</FormLabel>
                                                        <TextField
                                                            id="rut"
                                                            type="text"
                                                            name="rut"
                                                            fullWidth
                                                            size="small"
                                                            value={selectedCustomer?.rut || ''}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Motivo:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-fail"
                                                value={selectedNameFails || []}
                                                options={nameFails || []}
                                                getOptionLabel={(option) => option?.name || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedNameFails(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Descripción:</FormLabel>
                                            <TextField type='text' id='description' name='description' value={values.description} onChange={handleChange} multiline rows={4} fullWidth />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                                            <Tooltip title={'Agregar imágenes'} >
                                                <Button
                                                    onClick={() => setShowModalImage(true)}
                                                    sx={{
                                                        border: "solid 1px #73B2FF",
                                                        m: "10px",
                                                        color: "#73B2FF",
                                                        "&:hover": {
                                                            bgcolor: "#73B2FF",
                                                            color: "#fff",
                                                        },
                                                    }}
                                                >
                                                    {"Agregar imágenes"}
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <TableContainer>
                                            <Table>
                                                <TableHead sx={{ background: "#D8E8F7" }}>
                                                    <TableRow>
                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>Nombre de la imagen</StyledTableCell>
                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>Comentario</StyledTableCell>
                                                        <StyledTableCell className="headTable" align="center" colSpan={1}>Opciones</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        images.length > 0
                                                        ?
                                                        images.map((row, index) =>
                                                            (<TableRow key={index}>
                                                                <TableCell align="center">{index + 1}</TableCell>
                                                                <TableCell align="center">{row.file_name}</TableCell>
                                                                <TableCell align="center">{row.comment}</TableCell>
                                                                <TableCell align="center">
                                                                    <Tooltip title="Eliminar">
                                                                        <IconButton size="small" color="inherit"
                                                                            onClick={() => deleteImageTable(row.n)}
                                                                        >
                                                                            <DeleteIcon fontSize='small'/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Ver">
                                                                        <IconButton size="small" color="inherit"
                                                                            onClick={() => showImage(row.n)}
                                                                        >
                                                                            <VisibilityIcon fontSize='small'/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>)
                                                        )
                                                        :
                                                        (<TableRow>
                                                            <TableCell colSpan={7}>
                                                                <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                    No ha agregado alguna imagen
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={'Guardar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
            {
                showModalImage && <RepairRequestImages
                    open={showModalImage}
                    closeModal={()=> setShowModalImage(false)}
                    images={images}
                    setImages={setImages}
                />
            }
        </CustomForm>
    )
}

export default RepairRequestForm