import { Carrousel } from "./Carrousel/Carrousel";
import { Card, Grid, Slider, Typography } from "@mui/material";
  
const marks = [
    {
      value: 1,
      label: 'Apertura (Día 1)',
    },
    {
      value: 10,
      label: 'Emisión (Día 10)',
    },
    {
        value: 20,
        label: 'Pago anterior (Día 20)',
    },
    {
      value: 30,
      label: 'Cierre (Día 30)',
    },
    //suspension(dia 20)
];

export const HomeView = () => {

    return (
        <>
        <Grid container >
            <Carrousel/>

            <Grid item container xs={12} sx={{display: 'flex', justifyContent:'center'}}>

                <Card sx={{textAlign:'center', padding:'5px', marginX:'10px', border:'solid 1px #ebebeb'}}>
                    <Typography gutterBottom color='#808080' sx={{ fontWeight:400, fontSize:'16px' }}>
                        Días Disponibles
                    </Typography>
                    <Grid sx={{display:'flex'}}>
                        <Grid sx={{borderRight:'solid 1px #101010',textAlign:'center', padding:'20px 60px 20px 60px'}}>
                            <div><Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>{'Temporada alta:'}</Typography></div>
                            <div><Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>{'6 días'}</Typography></div>
                            <div><Typography color='#808080' sx={{ fontWeight:400, fontSize:'15px'}}>{'disponibles'}</Typography></div>
                        </Grid>
                        <Grid sx={{borderLeft:'solid 1px #101010',textAlign:'center', padding:'20px 60px 20px 60px'}}>
                            <div><Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>{'Temporada baja:'}</Typography></div>
                            <div><Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>{'30 días'}</Typography></div>
                            <div><Typography color='#808080' sx={{ fontWeight:400, fontSize:'15px'}}>{'disponibles'}</Typography></div>
                        </Grid>
                    </Grid>
                </Card>

                <Card sx={{padding:'5px', marginX:'10px', border:'solid 1px #ebebeb'}}>
                    <Typography gutterBottom color='#212D39' sx={{ fontWeight:600, paddingLeft:'10px', fontSize: '21px'}}>
                        {'Ciclo de Rendición'}
                    </Typography>
                    <div><Typography color='#808080' sx={{ fontWeight:300, paddingLeft:'10px', fontSize: '14px'}}>
                        {'Se presenta el siguiente cronograma'}
                    </Typography></div>
                    <Grid sx={{padding:'10px 75px 10px 75px'}}>
                    <Grid container sx={{ width: 400}}>
                        <Slider
                            defaultValue={1}
                            aria-labelledby="discrete-slider-custom"
                            valueLabelDisplay="auto"
                            marks={marks}
                            max={30}
                            min={1}
                        />
                    </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
        </>
    )
}