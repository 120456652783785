import { useDispatch, useSelector } from "react-redux"
import { onFetchControlVerificationsCategory, selectControlVerificationCategoryState, changeStatus } from "../redux/slices/controlVerificationCategorySlice"
import { ControlCategoryAPI } from "../apis/ControlCategoryAPI"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"

export const useControlCategoryStore = () => {
    const dispatch = useDispatch()
    const { controlVerificationsCategory, status, editControlVerificationCategory } = useSelector(selectControlVerificationCategoryState)
    
    const getControlsCategories = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ControlCategoryAPI.getControlCategory(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchControlVerificationsCategory(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    return {
        controlVerificationsCategory,
        status,
        editControlVerificationCategory,
        getControlsCategories
    }
}