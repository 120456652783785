import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { TrademarkMaintenance, TrademarkMaintenanceType } from "../../types/slices/trademarkMaintenanceType";

const initialState: TrademarkMaintenanceType = {
    status: ApiStatus.FETCHED,
    trademarkMaintenances: [],
    errorMessage: undefined,
    editTrademarkMaintenance: {} as TrademarkMaintenance,
    selectedTrademarkMaintenance: {} as TrademarkMaintenance,
};

const trademarkMaintenanceSlice = createSlice({
    name: "trademarkVehicle",
    initialState,
    reducers: {
        onFetchTrademarkMaintenance(state, { payload }: { payload: TrademarkMaintenance[] }) {
            state.status = ApiStatus.FETCHED;
            state.trademarkMaintenances = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditTrademarkMaintenance(state, { payload }: { payload: TrademarkMaintenance }) {
            state.editTrademarkMaintenance = payload;
        },
        onSetSelectedTrademarkMaintenance(state, { payload }: { payload: TrademarkMaintenance }) {
            state.selectedTrademarkMaintenance = payload;
        },
        restartTrademarkMaintenance(state) {
            state.trademarkMaintenances = []
        }
    }
});

export const selectTrademarkMaintenanceState = (state: RootState) => state.trademarkMaintenanceSlice;
export default trademarkMaintenanceSlice.reducer;

export const {
    onFetchTrademarkMaintenance,
    changeStatus,
    onSetEditTrademarkMaintenance,
    onSetSelectedTrademarkMaintenance,
    restartTrademarkMaintenance
} = trademarkMaintenanceSlice.actions