import { useDispatch, useSelector } from "react-redux";
import { structureD1API } from "../apis/structureD1API";
import { selectStructureD1State, onFetchStructureD1, onSetEditStructureD1, onSetSelectedStructureD1, changeStatus } from "../redux/slices/structureD1Slice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { StructureD1 } from "../types/slices/structureD1Type";

export const useStructureD1Store = () => {
    const dispatch = useDispatch();
    const { structureD1, status, editStructureD1, selectedStructureD1 } = useSelector(selectStructureD1State);

    const required = 0;

    const getStructureD1Store = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD1API.getStructureD1(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchStructureD1(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createStructureD1Store = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD1API.createStructureD1(data, required);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editStructureD1Store = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD1API.editStructureD1(id, data, required);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteStructureD1Store = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await structureD1API.deleteStructureD1(id);
            if (!response.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedStructureD1 = (structureD1: StructureD1) => {
        try {
            dispatch(onSetSelectedStructureD1(structureD1));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditStructureD1 = (structureD1: StructureD1) => {
        try {
            dispatch(onSetEditStructureD1(structureD1))
        } catch (error) {
            console.log(error);
        }
    };

    const setStructureD1 = (structure: StructureD1[]) => {
        try {
            dispatch(onFetchStructureD1(structure))
        } catch (error) {
            console.log(error);
        }
    }

    const clearStructureD1 = () => {
        try {
            dispatch(onFetchStructureD1([]))
        } catch (error) {
            console.log(error);
        }
    }

    return {
        //states
        structureD1,
        status,
        editStructureD1,
        selectedStructureD1,
        //actions
        getStructureD1Store,
        createStructureD1Store,
        editStructureD1Store,
        deleteStructureD1Store,
        setSelectedStructureD1,
        setEditStructureD1,
        clearStructureD1,
        setStructureD1
    }
}