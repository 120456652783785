import { api } from "./configs/axiosConfigs";

export const GroupActivityD2API = {
    getGroupActivityD2: async (data?: any) => {
        const response = await api
            .get('/structureD2/group', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    }
}