import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Tooltip, Button, Autocomplete, Divider, FormLabel, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useNavigate } from "react-router-dom";
import { useWorkOrderStore } from "../../hooks/useWorkOrderStore";
import { WorkOrder } from "../../types/slices/workOrderType";
import { withStyles } from "@mui/styles";
import { useControlVerificationStore } from "../../hooks/useControlVerificationStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import Radio from '@mui/material/Radio';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useDETStore } from "../../hooks/useDETStore";
import { useSupervisorStore } from "../../hooks/useSupervisorStore";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { createNotification } from "../../types/slices/notificationType";

type DETDataForm = {
    id: number
    idwork_order: number
    turn: number
    date: string
    comment: string
}

const turnos = [
    { id: 1, name: 'Mañana', code: 'M' },
    { id: 2, name: 'Tarde', code: 'T' },
    { id: 3, name: 'Noche', code: 'N' },
]

const date = new Date()
const año = date.getFullYear()
const mes = String(date.getMonth() + 1).padStart(2, '0')
const día = String(date.getDate()).padStart(2, '0')
const today = `${año}-${mes}-${día}`;

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

type ControlVerify = {
    idcontrol_verification: number
    name: string
    meets: number
}

export const DETForm: FC = () => {
    const navigate = useNavigate()
    const { workOrders, getWorkOrders, getOneWorkOrder } = useWorkOrderStore()
    const { controlVerifications, getControlVerifications } = useControlVerificationStore()
    const { status: detStatus, editDET, createDET, updateDET } = useDETStore()
    const { createNotifications } = useNotificationStore()
    const { getSupervisors, supervisors } = useSupervisorStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<ControlVerify[]>([])
    const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(null)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<DETDataForm>({
        id: 0,
        idwork_order: 0,
        turn: 1,
        date: today,
        comment: ''
    })

    useEffect(() => {
        getAllInputData()
    }, [])

    useEffect(() => {
        if ((Object.entries(editDET).length === 0 || Object.entries(editDET).length === 1) && controlVerifications.length > 0) {
            setAllDataTableCreate()
        }
    }, [controlVerifications])

    useEffect(() => {
        if (Object.entries(editDET).length > 0 && workOrders.length > 0) {
            const workOrder = workOrders.find(workOrder => workOrder.id === editDET.idwork_order)
            setSelectedWorkOrder(workOrder || null)
        }
    }, [workOrders])

    const getAllInputData = async () => {
        setLoading(true)
        await getSupervisors()
        if (Object.entries(editDET).length > 1) {
            await getOneWorkOrder(editDET.idwork_order)
            setDataEdit()
        } else if (Object.entries(editDET).length === 1) {
            await getOneWorkOrder(editDET.idwork_order)
            await getControlVerifications()
            setData({
                id: editDET.id,
                idwork_order: editDET.idwork_order,
                turn: 1,
                date: today,
                comment: ''
            })
        } else {
            await getWorkOrders({ withoutDET: true, status: 1, status_ot: 1 })
            await getControlVerifications()
        }
        setLoading(false)
    }

    const setDataEdit = () => {
        if (Object.entries(editDET).length > 0) {
            setIsEdit(true)
            const Turn = turnos.find(turno => turno.code === editDET.turn)
            setData({
                id: editDET.id,
                idwork_order: editDET.idwork_order,
                turn: Turn?.id || 1,
                date: editDET.date || today,
                comment: editDET.comment || ''
            })
            setAllDataTableEdit()
        }
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.date) errors.date = "Fecha de ejecución es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        if (!selectedWorkOrder) return CustomSnackbar('warning', 'Seleccione una orden de trabajo.')

        const Turn = turnos.find(turn => turn.id === values.turn)
        const dataControl = dataTable?.map(row => {
            return {
                idcontrol_verification: row.idcontrol_verification,
                meets: row.meets
            }
        })

        const payload = {
            idwork_order: values.idwork_order,
            turn: Turn?.code,
            date: values.date,
            comment: values.comment,
            data_control: dataControl
        }

        const response = !isEdit ? await createDET(payload) : await updateDET(editDET.id, payload)
        if (response.id) {
            if (isEdit) {
                supervisors?.map(async (element) => {
                    const notify: createNotification = {
                        iduser_type: 3,
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        title: "Documento DET editado",
                        details: "Se ha editado un documento DET:",
                        idview: 7,
                        idcompany: 1,
                        idregister: response.id,
                    }
                    return await createNotifications(notify)
                })
            }
            navigate(`/det-signature/${response.id}`)
        }
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.DET_LIST })
    }

    const setAllDataTableCreate = () => {
        const data = controlVerifications?.map(control => {
            return {
                idcontrol_verification: control.id,
                name: control.name,
                meets: 2
            }
        })
        setDataTable(data || [])
    }

    const setAllDataTableEdit = () => {
        const data = editDET?.detail_det?.map(control => {
            return {
                idcontrol_verification: control.idcontrol_verification,
                name: control.control_verification,
                meets: control.meets
            }
        })
        setDataTable(data || [])
    }

    const setMeets = (idcontrol: number, value: number) => {
        const data = dataTable?.map(control => {
            if (control.idcontrol_verification === idcontrol) {
                control.meets = value
            }
            return control
        })
        setDataTable(data || [])
    }

    const handleAllChecked = () => {
        setLoading(true)
        const data = dataTable?.map(control => {
            return {
                ...control,
                meets: Object.values(dataTable).every(value => value.meets === 1) ? 0 : 1
            }
        })
        setDataTable(data || [])
        setLoading(false)
    }

    return (
        <CustomForm>
            {/* {workOrderStatus === ApiStatus.FETCHING && (<CustomBackdrop open={true}/>)}
            {controlStatus === ApiStatus.FETCHING && (<CustomBackdrop open={true}/>)} */}
            {detStatus === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            {loading && (<CustomBackdrop open={true} />)}
            <CustomFormHeader
                title={!isEdit ? 'Nueva DET' : 'Editar DET'}
                text={!isEdit ? 'Ingrese los datos de la nueva DET' : 'Actualice los datos de la DET'}
                goBack={RoutesMap.DET_LIST}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Orden de trabajo:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-request"
                                                value={selectedWorkOrder}
                                                options={workOrders || []}
                                                getOptionLabel={(option) => option.correlative || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedWorkOrder(newValue);
                                                    setFieldValue('idwork_order', newValue?.id || 0)
                                                }}
                                                readOnly={isEdit || Object.entries(editDET).length === 1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Turno:</FormLabel>
                                            <Select
                                                labelId="turn"
                                                id="turn"
                                                value={values.turn}
                                                onChange={(e) => setFieldValue('turn', e.target.value)}
                                                fullWidth
                                                size="small"
                                            >
                                                {
                                                    turnos.map(turno => <MenuItem value={turno.id}>{turno.name}</MenuItem>)
                                                }
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Fecha de ejecución:</FormLabel>
                                            <TextField
                                                id="date"
                                                type="date"
                                                name="date"
                                                fullWidth
                                                size="small"
                                                value={values.date}
                                                onChange={handleChange}
                                                error={errors.date && touched.date ? true : false}
                                                helperText={errors.date && touched.date ? errors.date : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Descripción:</FormLabel>
                                            <TextField
                                                id="comment"
                                                type="text"
                                                name="comment"
                                                fullWidth
                                                size="small"
                                                value={values.comment}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12} sx={{ textAlign: "right" }}>
                                                <Tooltip title={Object.values(dataTable).every(value => value.meets === 1) ? 'Marcar todos los items con "No"' : 'Marcar todos los items con "Si"'} >
                                                    <Button
                                                        onClick={handleAllChecked}
                                                        sx={{
                                                            border: "solid 1px #73B2FF",
                                                            m: "10px",
                                                            color: "#73B2FF",
                                                            "&:hover": {
                                                                bgcolor: "#73B2FF",
                                                                color: "#fff",
                                                            },
                                                        }}
                                                    >
                                                        {Object.values(dataTable).every(value => value.meets === 1) ? "Desmarcar todo" : "Marcar todo"}
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead sx={{ background: "#D8E8F7" }}>
                                                        <TableRow>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>DESCRIPCIÓN</StyledTableCell>
                                                            <StyledTableCell className="headTable" align="center" colSpan={1}>CUMPLE</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            dataTable.length > 0 && selectedWorkOrder
                                                                ?
                                                                dataTable?.map((row, index) =>
                                                                    <TableRow key={index}>
                                                                        <TableCell align="center">{index + 1}</TableCell>
                                                                        <TableCell align="center">{row.name}</TableCell>
                                                                        <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <FormGroup row={true}>
                                                                                <FormControlLabel control={<Radio size="small" checked={row.meets === 1} onClick={() => setMeets(row.idcontrol_verification, 1)} />} label="Si" />
                                                                                <FormControlLabel control={<Radio size="small" checked={row.meets === 0} onClick={() => setMeets(row.idcontrol_verification, 0)} />} label="No" />
                                                                            </FormGroup>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                                :
                                                                (<TableRow>
                                                                    <TableCell colSpan={7}>
                                                                        <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                            Seleccione una orden de trabajo
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>)
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}