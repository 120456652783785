import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { Company } from "../../types/slices/companyType";
import { useCompanyStore } from "../../hooks/useCompanyStore";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { ApiStatus } from "../../types/api/status";
import { RoutesMap } from "../../types";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'business_name', label: 'RAZÓN SOCIAL', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'address', label: 'DIRECCIÓN', align: 'center' },
    { type: 'text', field: 'email', label: 'EMAIL', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELÉFONO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const CompanyList : FC = () => {

    const navigate   = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status: companyStatus, companies, deleteCompany, getCompanies, setSelectedCompany } = useCompanyStore()

    const [ loading, setLoading ]         = useState<boolean>(false)
    const [ page, setPage ]               = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ rowSelected, setRowSelected ]           = useState<Company>({} as Company)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)

    useEffect(()=>{
        getCompanyApi()
    },[search])

    const getCompanyApi = async () => {
        try {
          setLoading(true)
          let payload:any = {}
          if (search != '') {
            payload = {
                business_name: search
            }
          }
          await getCompanies(payload)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        navigate({ pathname: RoutesMap.COMPANY_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedCompany(rowSelected)
        navigate({ pathname: RoutesMap.COMPANY_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteCompany(rowSelected.id)
        if(response === true){
            setSelectedCompany({} as Company)
            setShowModalConfirm(false)
            getCompanyApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Empresas'}
                columns={columns || []}
                loading={loading}
                rows={companies || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                    status2={companyStatus == ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}