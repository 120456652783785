import { api } from "./configs/axiosConfigs";

export const IdentifyImageAPI = {
    getIdentifyImage: async (data?: any) => {
        const response = await api
            .get('/identification', { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => { console.error(error); return error.response.data })
        return response;
    },
    sendImage: async (data: any) => {
        const response = await api
            .post('/identification/register', data)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },
    editIdentifyImage: async (id: number, data: any) => {
        const response = await api
            .patch(`/identification/${id}`, { ...data },
                { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },
    deleteIdentifyImage: async (id: number) => {
        const response = await api
            .delete(`/identification/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
    changeStatus: async (id:number,data:any) => {
        const response = await api.patch(
        `/identification/changeStatus/${id}`,
        {
            ...data
        })
        .then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    updateOrCreate: async (data: any) => {
        const response = await api
            .post('/identification/updateOrCreate', data)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },
    getIdentifyPDF: async (ididentify:number) => {
        const response = await api.get(
            `/identification/pdf/${ididentify}`,
        {
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    }
};