import { api } from "../configs/axiosConfigs";

export const ControlBarriersAPI = {
    getControlBarriers: async (data?: any) => {
        const response = await api
            .get("/controlBarries", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createControlBarriers: async (data: any) => {
        const response = await api
            .post("/controlBarries/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editControlBarriers: async (id: number, data: any) => {
        const response = await api
            .patch(`/controlBarries/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteControlBarriers: async (id: number) => {
        const response = await api
            .delete(`/controlBarries/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}