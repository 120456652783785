import { useDispatch, useSelector } from "react-redux";
import { GroupActivityD2API } from "../apis/GroupActivityD2API";
import { selectGroupActivityD2, changeStatus, onFetchGroupActivityD2 } from "../redux/slices/groupActivityD2Slice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useGroupActivityD2Store = () => {
    const dispatch = useDispatch();
    const { groupActivitiesD2, status } = useSelector(selectGroupActivityD2);

    const getGroupStructureD2Store = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await GroupActivityD2API.getGroupActivityD2(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchGroupActivityD2(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
            return true
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    return {
        groupActivitiesD2,
        status,
        getGroupStructureD2Store,
    }
}