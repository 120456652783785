import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { RoutesMap } from "../../types";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { TypeAttention } from "../../types/slices/typeAttentionType";
import { useTypeAttention } from "../../hooks/useTypeAttention";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'name', label: 'NOMBRES', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const TypeAttentionList: FC = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status, typeAttentions, getTypeAttentionStore, deleteTypeAttentionStore, setSelectedTypeAttention } = useTypeAttention()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<TypeAttention>({} as TypeAttention)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)

    useEffect(() => {
        getTypeAttentionApi()
    }, [search])

    const getTypeAttentionApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    name: search
                }
            }
            await getTypeAttentionStore(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedTypeAttention({} as TypeAttention)
        navigate({ pathname: RoutesMap.TYPE_ATTENTION_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedTypeAttention(rowSelected)
        navigate({ pathname: RoutesMap.TYPE_ATTENTION_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteTypeAttentionStore(rowSelected.id)
        if (response === true) {
            setSelectedTypeAttention({} as TypeAttention)
            setShowModalConfirm(false)
            getTypeAttentionApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }



    return (
        <>
            <CustomTable
                title={'Tipos de atención'}
                columns={columns || []}
                loading={loading}
                rows={typeAttentions || []}
                onRowClick={() => { }}
                hasOptions
                onAddFn={onAdd}
                onEdit={onEdit}
                onDelete={onDelete}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        status2={status === ApiStatus.FETCHING ? true : false}
                    />
                )
            }
        </>
    )
}

export default TypeAttentionList