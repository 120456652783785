import { Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { usePatternTStore } from "../../hooks/usePatternTStore";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../hooks";
import { ApiStatus } from "../../types/api/status";
import { PatternT } from "../../types/slices/patternTType";
import { Role } from "../../types/roles/roleTypes";
import CustomPatternFilter from "../../components/common/CustomPatternFilter/CustomPatternFilter";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'mileage', label: "ODÓMETRO", format: (row) => `${row.mileage} Km`, align: 'center' },
    { type: 'text', field: 'entry_date', label: 'FECHA DE ENTRADA', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsAdmin = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'type_vehicle', label: 'TIPO DE VEHÍCULO', align: 'center' },
    { type: 'text', field: 'trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'mileage', label: "ODÓMETRO", format: (row) => `${row.mileage} Km`, align: 'center' },
    { type: 'text', field: 'entry_date', label: 'FECHA DE ENTRADA', align: 'center' },
    { type: 'text', field: 'mechanic', label: 'MECÁNICO', format: (row: any) => `${row.name_mechanic} ${row.surname_mechanic}` },
    { type: 'text', field: '', label: "RUT MECÁNICO", format: (row) => row.rut_mechanic, align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const PatternTList: FC = () => {

    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status: patternTStatus, patternTs, getPatternTs, completePatternT, setEditPatternT, deletePatternT } = usePatternTStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState({} as PatternT)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const userRole: Role | any = user.userType

    useEffect(() => {
        getPatternTApi()
    }, [search])

    const getPatternTApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.MECHANIC) {
                payload = {
                    ...payload,
                    idmechanic: user?.data?.id
                }
            }
            if (userRole === Role.ADMIN) {
                payload = {
                    ...payload,
                    status_mechanic: 1,
                    status_boss: 1
                }
            }
            await getPatternTs(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setEditPatternT({} as PatternT)
        navigate({ pathname: RoutesMap.PATTERN_T_FORM })
    }

    const onEdit = (rowSelected) => {
        setEditPatternT(rowSelected)
        navigate({ pathname: RoutesMap.PATTERN_T_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true)
    }

    const onDeleteConfirm = async () => {
        await deletePatternT(rowSelected.id)
        setShowModalConfirm(false)
        getPatternTApi()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onDetails = (rowSelected) => {
        navigate(`/pt-t-signature/${rowSelected.id}`)
    }

    return (<>
        <Grid item xs={12}>
            {
                userRole !== Role.ADMIN && (
                    <>
                        <Grid item container sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "-5px",
                        }}>
                            <Typography variant="h6"
                                component="h1"
                                gutterBottom
                                color="#808080"
                                className="tittle"
                                sx={{ mt: 1, ml: 1, fontWeight: 600 }}>PAUTA T</Typography>
                        </Grid>
                        <Grid item container xs={12} sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "10px",
                        }}>
                            <CustomPatternFilter loading={setLoading} getData={getPatternTs} />
                        </Grid>
                    </>

                )
            }
            {
                userRole === Role.ADMIN && (
                    <CustomTable
                        title={'Pauta T'}
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={patternTs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                (userRole === Role.MAINTENANCE || userRole === Role.SUPERVISOR) && (
                    <CustomTable
                        columns={columnsAdmin || []}
                        loading={loading}
                        rows={patternTs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onEdit={onEdit}
                        onDeletePattern={onDelete}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
            {
                userRole === Role.MECHANIC && (
                    <CustomTable
                        columns={columns || []}
                        loading={loading}
                        rows={patternTs || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        // onDelete={onDelete}
                        onEditPattern={onEdit}
                        onDeletePattern={onDelete}
                        onDetails={onDetails}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )
            }
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={() => { setShowModalConfirm(false) }}
                    onCancel={() => { setShowModalConfirm(false) }}
                    onConfirm={onDeleteConfirm}
                    status2={patternTStatus === ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}
