import { FC, useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks";
import { useRepairRequest } from "../../hooks/useRepairRequest";
import { RoutesMap } from "../../types";
import { RepairRequest } from "../../types/slices/repairRequestType";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Autocomplete, Divider, FormLabel, Grid, InputAdornment, TextField, TextareaAutosize, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ApiStatus } from "../../types/api/status";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useWorkOrderStore } from "../../hooks/useWorkOrderStore";
import { Role } from "../../types/roles/roleTypes";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";
import { WorkDone } from "../../types/slices/workDondeType";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useReplacementPartStore } from "../../hooks/useReplacementPartStore";
import { ReplacementPart } from "../../types/slices/replacementPartType";
import { withStyles } from "@mui/styles";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useInformMechanicalStore } from "../../hooks/useInformMechanicalStore";
import { InformMechanical } from "../../types/slices/informMechanicalType";
import { useSupervisorStore } from "../../hooks/useSupervisorStore";
import { useNotificationStore } from "../../hooks/useNotificationStore";
import { createNotification } from "../../types/slices/notificationType";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

type WorkOrderDataForm = {
    id: number
    idrepair_request: number
    idmechanic: number
    technical_review: string
    odometer: string
    start_date: string
}

type Replacement = {
    idreplacement_parts: number
    part: string
    quantity: number
}

const date = new Date()
const año = date.getFullYear()
const mes = String(date.getMonth() + 1).padStart(2, '0')
const día = String(date.getDate()).padStart(2, '0')
const today = `${año}-${mes}-${día}`;

export const WorkOrderForm: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { repairRequests, getRepairRequestStore, getOneRepairRequest } = useRepairRequest()
    const { status: statusWorkOrder, editWorkOrder, createWorkOrder, updateWorkOrder } = useWorkOrderStore()
    const { workDones, getWorkDones } = useWorkDoneStore()
    const { replacementParts, getReplacementParts } = useReplacementPartStore()
    const { getSupervisors, supervisors } = useSupervisorStore()
    const { createNotifications } = useNotificationStore()
    // const { status: statusInform, informsMechanical, getInformsMechanical } = useInformMechanicalStore() 

    const [loading, setLoading] = useState<boolean>(false)
    const [towable, setTowable] = useState<number>(2)
    const [partsTable, setPartsTable] = useState<Replacement[]>([])
    const [selectedParts, setSelectedParts] = useState<ReplacementPart[]>([])
    const [selectedWorks, setSelectedWorks] = useState<WorkDone[]>([])
    // const [selectedInforms, setSelectedInforms] = useState<InformMechanical[]>([])
    const [selectedRequest, setSelectedRequest] = useState<RepairRequest | null>(null)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<WorkOrderDataForm>({
        id: 0,
        idrepair_request: 0,
        idmechanic: 0,
        technical_review: '',
        odometer: '',
        start_date: today
    })

    const userRole: Role | any = user.userType

    useEffect(() => {
        getCombosBoxes()
    }, [])

    useEffect(() => {
        if (Object.entries(editWorkOrder).length > 0 && repairRequests.length > 0) {
            const request = repairRequests.find(request => request.id === editWorkOrder.idrepair_request)
            setSelectedRequest(request || null)
        }
    }, [repairRequests])

    // useEffect(()=>{
    //     if (Object.entries(editWorkOrder).length > 0 && informsMechanical.length > 0) {
    //         const informs:any = editWorkOrder?.informMechanical?.map(mechanical => {
    //             const inform = informsMechanical?.find(inform => inform.id === mechanical.idinform_mechanical)
    //             if (inform) return inform
    //         })
    //         setSelectedInforms(informs || [])
    //     }
    // },[informsMechanical])

    useEffect(() => {
        if (Object.entries(editWorkOrder).length > 0 && workDones.length > 0) {
            const Works: any = editWorkOrder?.workDone?.map(workDone => {
                const work = workDones?.find(work => work.id === workDone.idwork_done)
                if (work) return work
            })
            setSelectedWorks(Works || [])
        }
    }, [workDones])

    useEffect(() => {
        if (Object.entries(editWorkOrder).length > 0 && replacementParts.length > 0) {
            const replaceParts: any = editWorkOrder?.replacement_parts?.map(replacementPart => {
                const part = replacementParts?.find(part => part.id === replacementPart.idreplacement_parts)
                if (part) return part
            })
            setSelectedParts(replaceParts || [])
        }
    }, [replacementParts])

    useEffect(() => {
        if (Object.entries(editWorkOrder).length > 0) {
            onChangePartsTable()
        }
    }, [selectedParts])

    useEffect(() => {
        setData({
            ...data,
            idrepair_request: selectedRequest?.id || 0,
            odometer: ''
        })
    }, [selectedRequest])

    const getCombosBoxes = async () => {
        setLoading(true)
        if (Object.entries(editWorkOrder).length > 0) {
            await getOneRepairRequest(editWorkOrder.idrepair_request)
            await getReplacementParts()
            await getSupervisors()
            setDataEdit()
        } else {
            await getRepairRequestStore({ withoutWorkOrder: true })
        }
        await getWorkDones()
        setLoading(false)
        // await getInformsMechanical()
    }

    const setDataEdit = () => {
        setIsEdit(true)
        if (Object.entries(editWorkOrder).length > 1) {
            setData({
                id: editWorkOrder?.id,
                idrepair_request: editWorkOrder?.idrepair_request,
                idmechanic: editWorkOrder?.idmechanic,
                technical_review: editWorkOrder?.technical_review || '',
                start_date: editWorkOrder?.start_date || today,
                odometer: editWorkOrder?.odometer || ''
            })
            setTowable(parseInt(editWorkOrder?.towable) || 2)
        }
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.odometer) errors.odometer = "Odómetro es requerido";
        if (!values.start_date) errors.start_date = "Fecha de ejecución es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        if (!selectedRequest) return CustomSnackbar('warning', 'Seleccione una solicitud')
        // if(selectedInforms.length < 1) return CustomSnackbar('warning', 'Seleccione detalles mecánicos')
        if (!values.technical_review) return CustomSnackbar('warning', 'Ingrese revisión técnica')
        if (editWorkOrder.status_ot === '1') {
            if (selectedWorks.length < 1) return CustomSnackbar('warning', 'Seleccione trabajos realizados')
            // if(selectedParts.length < 1) return CustomSnackbar('warning', 'Seleccione repuestos')
        }

        // const a_idInformMechanical = selectedInforms?.map(inform => inform.id)
        const a_idWorkDone = selectedWorks?.map(work => work.id)

        let payload: any = {
            idrepair_request: values.idrepair_request,
            technical_review: values.technical_review,
            odometer: values.odometer,
            start_date: values.start_date,
            towable: towable,
            a_idwork_done: a_idWorkDone,
            // a_idinform_mechanical: a_idInformMechanical,
        }

        if (userRole === Role.MECHANIC) {
            payload = {
                ...payload,
                idmechanic: user?.data?.id,
            }
        }

        if (userRole === Role.MECHANIC && isEdit && editWorkOrder.status_ot === '1') {
            const a_parts = partsTable?.map(part => {
                return {
                    idreplacement_parts: part.idreplacement_parts,
                    quantity: part.quantity
                }
            })
            payload = {
                ...payload,
                replacement_parts: a_parts
            }
        }

        const response = await ((!isEdit || Object.entries(editWorkOrder).length === 1) ? createWorkOrder(payload) : updateWorkOrder(editWorkOrder.id, payload))
        if (response.id) navigate({ pathname: RoutesMap.WORK_ORDER_LIST })
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.WORK_ORDER_LIST })
    }

    const onChangePartsTable = () => {
        const parts = selectedParts?.map(part => {
            const partTable = partsTable?.find(partTable => partTable.idreplacement_parts === part.id)
            const partEdit = editWorkOrder?.replacement_parts?.find(partEdit => partEdit?.idreplacement_parts === part?.id)
            return {
                idreplacement_parts: part?.id,
                part: part?.name,
                quantity: partTable ? partTable?.quantity : (partEdit ? partEdit.quantity : 1)
            }
        })
        setPartsTable(parts || [])
    }

    const handleChangeQuantity = (idreplacement_parts: number, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target
        const parts = partsTable?.map(part => {
            if (part.idreplacement_parts === idreplacement_parts) {
                part.quantity = parseInt(value)
            }
            return part
        })
        setPartsTable(parts || [])
    }

    return (
        <CustomForm>
            {loading && (<CustomBackdrop open={true} />)}
            {statusWorkOrder === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            <CustomFormHeader
                title={(!isEdit || Object.entries(editWorkOrder).length === 1) ? 'Nueva Orden de Trabajo' : 'Editar Orden de Trabajo'}
                text={(!isEdit || Object.entries(editWorkOrder).length === 1) ? 'Ingrese los datos de la nueva orden de trabajo' : 'Modifique los datos de la orden de trabajo'}
                goBack={RoutesMap.WORK_ORDER_LIST}
            >
            </CustomFormHeader>
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Solicitud:</FormLabel>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-request"
                                                value={selectedRequest}
                                                options={repairRequests || []}
                                                getOptionLabel={(option) => option.correlative || ""}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} size="small" />}
                                                onChange={(e, newValue) => {
                                                    setSelectedRequest(newValue);
                                                    setFieldValue('idrepair_request', newValue?.id || 0)
                                                }}
                                                readOnly={isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Patente:</FormLabel>
                                            {/* <TextField
                                                id="patent"
                                                type="text"
                                                name="patent"
                                                fullWidth
                                                size="small"
                                                value={selectedRequest?.patent || ''}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            /> */}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Marca:</FormLabel>
                                            {/* <TextField
                                                id="trademark"
                                                type="text"
                                                name="trademark"
                                                fullWidth
                                                size="small"
                                                value={selectedRequest?.trademark_vehicle || ''}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            /> */}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormLabel>Modelo:</FormLabel>
                                            {/* <TextField
                                                id="model"
                                                type="text"
                                                name="model"
                                                fullWidth
                                                size="small"
                                                value={selectedRequest?.model_vehicle || ''}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                            /> */}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>{'Motivo(s):'}</FormLabel>
                                            <TextareaAutosize
                                                id="razon"
                                                name="razon"
                                                style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }}
                                                value={
                                                    selectedRequest?.name_fails_resource?.map(element => element.name).join('\n') || ''
                                                }
                                                readOnly={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Descripción:</FormLabel>
                                            <TextareaAutosize
                                                id="description"
                                                name="description"
                                                style={{ width: '100%', minHeight: '50px', resize: 'none', padding: '10px', fontFamily: 'inherit', fontSize: '15px' }}
                                                value={selectedRequest?.description || ''}
                                                readOnly={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormLabel>Odómetro:</FormLabel>
                                            <TextField
                                                id="odometer"
                                                type="number"
                                                name="odometer"
                                                fullWidth
                                                size="small"
                                                value={values.odometer || ''}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">Km</InputAdornment>
                                                }}
                                                inputProps={{ min: 0 }}
                                                error={errors.odometer && touched.odometer ? true : false}
                                                helperText={errors.odometer && touched.odometer ? errors.odometer : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormLabel>Fecha de ejecución:</FormLabel>
                                            <TextField
                                                id="start_date"
                                                type="date"
                                                name="start_date"
                                                fullWidth
                                                size="small"
                                                value={values.start_date}
                                                onChange={handleChange}
                                                error={errors.start_date && touched.start_date ? true : false}
                                                helperText={errors.start_date && touched.start_date ? errors.start_date : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormLabel>Remolcable:</FormLabel>
                                            <FormGroup row={true}>
                                                <FormControlLabel control={<Checkbox checked={towable === 1} onClick={() => setTowable(1)} />} label="Si" />
                                                <FormControlLabel control={<Checkbox checked={towable === 2} onClick={() => setTowable(2)} />} label="No" />
                                            </FormGroup>
                                        </Grid>
                                        {/* <Grid item xs={12} md={12}>
                                            <FormLabel>Detalles mecánicos:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-inform-mechanical"
                                                value={selectedInforms|| []}
                                                options={informsMechanical || []}
                                                getOptionLabel={(option) => option?.description || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedInforms(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        { option.description }
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Trabajos a realizar:</FormLabel>
                                            <Autocomplete
                                                multiple={true}
                                                disablePortal
                                                id="combo-box-work-dones"
                                                value={selectedWorks || []}
                                                options={workDones || []}
                                                getOptionLabel={(option) => option?.description || ""}
                                                fullWidth
                                                isOptionEqualToValue={(option, value) => option?.description === value?.description}
                                                renderInput={
                                                    (params) => <TextField {...params} size="small" />
                                                }
                                                onChange={(e, newValue) => {
                                                    setSelectedWorks(newValue);
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} value={option.id} {...props} >
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={selected}
                                                        />
                                                        {option.description}
                                                    </li>
                                                )}
                                                filterSelectedOptions
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormLabel>Descripción de detalles mecánicos:</FormLabel>
                                            <TextareaAutosize
                                                id="technical_review"
                                                name="technical_review"
                                                style={{ width: '100%', minHeight: '50px', resize: 'vertical', padding: '10px', fontFamily: 'Arial' }}
                                                value={values.technical_review}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        {
                                            isEdit && editWorkOrder.status_ot === '1' && editWorkOrder.status_ast === '1' && editWorkOrder.status_det === '1' && (<>
                                                <Grid item xs={12} md={12}>
                                                    <FormLabel>Repuestos Utilizados:</FormLabel>
                                                    <Autocomplete
                                                        multiple={true}
                                                        disablePortal
                                                        id="combo-box-replacement-parts"
                                                        value={selectedParts || []}
                                                        options={replacementParts || []}
                                                        getOptionLabel={(option) => option?.name || ""}
                                                        fullWidth
                                                        isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                                        renderInput={
                                                            (params) => <TextField {...params} size="small" />
                                                        }
                                                        onChange={(e, newValue) => {
                                                            setSelectedParts(newValue);
                                                        }}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li key={option.id} value={option.id} {...props} >
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                    checked={selected}
                                                                />
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        filterSelectedOptions
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead sx={{ background: "#D8E8F7" }}>
                                                                <TableRow>
                                                                    <StyledTableCell className="headTable" align="center" colSpan={1}>N°</StyledTableCell>
                                                                    <StyledTableCell className="headTable" align="center" colSpan={1}>REPUESTO</StyledTableCell>
                                                                    <StyledTableCell className="headTable" align="center" colSpan={1}>CANTIDAD</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    partsTable.length > 0
                                                                        ?
                                                                        partsTable.map((row, index) =>
                                                                            <TableRow key={index}>
                                                                                <TableCell align="center">{index + 1}</TableCell>
                                                                                <TableCell align="center">{row.part}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <TextField
                                                                                        id={"quantity-" + index}
                                                                                        name={"quantity-" + index}
                                                                                        type="number"
                                                                                        size="small"
                                                                                        value={row.quantity}
                                                                                        onChange={(e) => handleChangeQuantity(row.idreplacement_parts, e)}
                                                                                        sx={{ justifyContent: "center" }}
                                                                                        inputProps={{ min: 0 }}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                        :
                                                                        (<TableRow>
                                                                            <TableCell colSpan={7}>
                                                                                <Grid sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                                    Seleccione un repuesto
                                                                                </Grid>
                                                                            </TableCell>
                                                                        </TableRow>)
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </>)
                                        }
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={(!isEdit || Object.entries(editWorkOrder).length === 1) ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}