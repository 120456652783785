import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { AxleVehicles, AxleType } from "../../types/slices/AxleType";

const initialState: AxleType = {
    status: ApiStatus.FETCHED,
    axles: [],
    errorMessage: undefined,
    selectedAxle: {} as AxleVehicles
};

const axleSlice = createSlice({
    name: "axle",
    initialState,
    reducers: {
        onFetchAxle(state, { payload }: { payload: AxleVehicles[] }) {
            state.status = ApiStatus.FETCHED;
            state.axles = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedAxle(state, { payload }: { payload: AxleVehicles }) {
            state.selectedAxle = payload;
        },
    }
});

export const selectAxle = (state: RootState) => state.axleSlice;
export default axleSlice.reducer;

export const {
    onFetchAxle,
    changeStatus,
    onSetSelectedAxle,
} = axleSlice.actions;