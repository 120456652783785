import React, { FC, useState, useEffect } from 'react';
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useStructureD2Store } from '../../hooks/useStructureD2Store';
import { StructureD2 } from '../../types/slices/structureD2Type';
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useGroupActivityD2Store } from '../../hooks/useGroupActivityD2Store';

type StructureD2DataForm = {
    id: number;
    item: string;
    idgroup_activity_d2: number;
}

type errorsForm = {
    item?: string;
    idgroup_activity_d2?: string;
}

const StructureD2Form: FC = () => {
    const navigate = useNavigate();
    const { status, selectedStructureD2, setSelectedStructureD2, createStructureD2Store, editStructureD2Store } = useStructureD2Store();

    const { groupActivitiesD2, getGroupStructureD2Store } = useGroupActivityD2Store();

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<StructureD2DataForm>({
        id: 0,
        item: "",
        idgroup_activity_d2: 0,
    })

    useEffect(() => {
        getGroupStructureD2Store();
        if (Object.entries(selectedStructureD2).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedStructureD2.id,
                item: selectedStructureD2.item,
                idgroup_activity_d2: selectedStructureD2.idgroup_activity_d2,
            })
            setSelectedStructureD2({} as StructureD2);
        }
    }, []);

    const onSubmit = async (values: any) => {
        const payload = {
            item: values.item,
            idgroup_activity_d2: values.idgroup_activity_d2,
        }

        const method = !isEdit
            ? createStructureD2Store(payload)
            : editStructureD2Store(data.id, payload);

        const response = await method;
        if (response === true) navigate({ pathname: RoutesMap.STRUCTURE_D2 });
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.STRUCTURE_D2 });
    };

    const validateForm = (values: any) => {
        let errors: errorsForm = {};
        if (!values.item) errors.item = "Ítem es requerido";
        if (!values.idgroup_activity_d2) errors.idgroup_activity_d2 = "Grupo de actividad es requerido";
    }

    return (
        <CustomForm>
            {status === ApiStatus.FETCHING && (<CustomBackdrop open={true}></CustomBackdrop>)}
            <CustomFormHeader
                title={!isEdit ? "Nuevo ítem" : "Editar ítem"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo ítem"
                        : "Ingrese los datos del ítem"
                }
                goBack={RoutesMap.STRUCTURE_D2}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>ítem:</FormLabel>
                                            <TextField
                                                id="item"
                                                type="text"
                                                name="item"
                                                fullWidth
                                                size="small"
                                                value={values.item}
                                                onChange={handleChange}
                                                error={
                                                    errors.item && touched.item
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.item && touched.item
                                                        ? errors.item
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>Grupo de actividad:</InputLabel>
                                            <Select
                                                id="idgroup_activity_d2"
                                                name="idgroup_activity_d2"
                                                fullWidth
                                                value={values.idgroup_activity_d2}
                                                onChange={handleChange}
                                                error={
                                                    errors.idgroup_activity_d2 &&
                                                        touched.idgroup_activity_d2
                                                        ? true
                                                        : false
                                                }
                                                style={{ height: '52%' }}
                                            >
                                                {groupActivitiesD2?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name_group}</MenuItem>))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={
                                        !isEdit ? "Guardar" : "Actualizar"
                                    }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>)
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm >
    )
}

export default StructureD2Form