import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { TypeAttentionType, TypeAttention } from "../../types/slices/typeAttentionType";

const initialState: TypeAttentionType = {
    status: ApiStatus.FETCHED,
    typeAttentions: [],
    errorMessage: undefined,
    selectedTypeAttention: {} as TypeAttention
};

const typeAttentionSlice = createSlice({
    name: "typeAttention",
    initialState,
    reducers: {
        onFetchTypeAttention(state, { payload }: { payload: TypeAttention[] }) {
            state.status = ApiStatus.FETCHED;
            state.typeAttentions = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedTypeAttention(state, { payload }: { payload: TypeAttention }) {
            state.selectedTypeAttention = payload;
        },
    }
});

export const selectTypeAttention = (state: RootState) => state.typeAttentionSlice;
export default typeAttentionSlice.reducer;

export const {
    onFetchTypeAttention,
    changeStatus,
    onSetSelectedTypeAttention,
} = typeAttentionSlice.actions;