import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Mechanic, MechanicType } from "../../types/slices/mechanicType";

const initialState: MechanicType = {
    status: ApiStatus.FETCHED,
    mechanics: [],
    errorMessage: undefined,
    editMechanic: {} as Mechanic,
    selectedMechanic: {} as Mechanic,
};

const mechanicSlice = createSlice({
    name: "mechanic",
    initialState,
    reducers: {
        onFetchMechanic(state, { payload }: { payload: Mechanic[] }) {
            state.status = ApiStatus.FETCHED;
            state.mechanics = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditMechanic(state, { payload }: { payload: Mechanic }) {
            state.editMechanic = payload;
        },
        onSetSelectedMechanic(state, { payload }: { payload: Mechanic }) {
            state.selectedMechanic = payload;
        },
    },
});

export const selectMechanicState = (state: RootState) => state.mechanicSlice;
export default mechanicSlice.reducer;

export const {
    onFetchMechanic,
    changeStatus,
    onSetEditMechanic,
    onSetSelectedMechanic,
} = mechanicSlice.actions;
