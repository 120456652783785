import React, { useEffect, useState } from 'react'
import CustomTable from '../../components/common/CustomTable/CustomTable'
import { useNavigate } from 'react-router-dom'
import { RoutesMap } from '../../types'
import { useSelector } from 'react-redux'
import { selectHeaderState } from '../../redux/slices/headerSlice'
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm'
import { useCustomerStore } from '../../hooks/useCustomerStore'
import { Customer } from '../../types/slices/customerType'
import { ApiStatus } from '../../types/api/status'
import { useUserStore } from '../../hooks/useUserStore'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { IdentificationModalAdmin } from "../MyProfile/components/IdentificationModalAdmin";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'name_rz', label: 'NOMBRE', align: 'center' },
    { type: 'text', field: 'fantasy_name', label: 'NOMBRE DE FANTASÍA', align: 'center' },
    { type: 'text', field: 'giro', label: 'GIRO', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELÉFONO', align: 'center' },
    {
        type: "text", field: "address", label: "DIRECCIÓN", align: 'center', format: (row: Customer) => {
            const address = row.address === null ? "No registra" : row.address
            return address;
        }
    },
    { type: 'text', field: 'fax', label: 'FAX', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const ClientList = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState);
    const { customers, status, getCustomerStore, setSelectedCustomer, deleteCustomerStore } = useCustomerStore()
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<Customer>({} as Customer);
    const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);

    const [userRut, setUserRut] = useState<string>('');
    const [showModalIdentification, setShowModalIdentification] = useState<boolean>(false);

    const { forgotPasswordUser } = useUserStore()

    useEffect(() => {
        getCustomerApi();
    }, [search]);


    const getCustomerApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getCustomerStore(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        setSelectedCustomer({} as Customer);
        navigate({ pathname: RoutesMap.CLIENT_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedCustomer(rowSelected)
        navigate({ pathname: RoutesMap.CLIENT_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteCustomerStore(rowSelected.id);
        if (response === true) {
            setSelectedCustomer({} as Customer);
            setModalDeleteConfirm(false);
            getCustomerApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onPersonIcon = (row) => {
        setUserRut(row.rut)
        setShowModalIdentification(true)
    }

    const sendCredentials = async (rowSelected) => {
        setLoading(true)
        console.log(rowSelected)
        await forgotPasswordUser(rowSelected.rut, 8)
        setLoading(false)
    }

    return (
        <>
            <CustomTable
                title={'Clientes'}
                columns={columns || []}
                rows={customers || []}
                loading={loading}
                onRowClick={() => { }}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                onPersonIcon={onPersonIcon}
                onHelper={sendCredentials}
                tooltip_helper={'Enviar credenciales'}
                icon_helper={<ContactMailIcon fontSize="small" />}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                />
            )}
            {
                showModalIdentification && (
                    <IdentificationModalAdmin
                        open={showModalIdentification}
                        closeModal={() => setShowModalIdentification(false)}
                        rut={userRut}
                    />
                )
            }
        </>
    )
}

export default ClientList