import { useDispatch, useSelector } from "react-redux";
import { changeStatus, onFetchNameFail, onSetSelectedNameFail, selectNameFailState } from '../redux/slices/nameFailSlice';
import { ApiStatus } from "../types/api/status"
import { NameFailsAPI } from "../apis/NameFailsAPI";
import { toast } from "react-hot-toast";
import { NameFail } from "../types/slices/nameFailType";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useNameFails = () => {
    const dispatch = useDispatch();
    const { nameFails, status, selectedNameFail } = useSelector(selectNameFailState);

    const getNameFailStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await NameFailsAPI.getNameFails(data)
            if (!response?.status) {
                toast.error(response.data.message)
                throw new Error(`${response.data.message}`)
            }
            const { detail } = response.data
            dispatch(onFetchNameFail(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.error(error)
        }
    };

    const createNameFailStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await NameFailsAPI.createNameFails(data)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message || "")
            }
            CustomSnackbar('success', response.data.message || "")
            const { detail } = response.data
            const id = detail.id;
            return id
        } catch (error) {
            console.log(error)
        }
    };

    const createNameFailStore2 = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await NameFailsAPI.createNameFails(data)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message || "")
            }
            CustomSnackbar('success', response.data.message || "")
            return true
        } catch (error) {
            console.log(error)
        }
    };

    const editNameFailStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await NameFailsAPI.editNameFails(id, data)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message || "")
            }
            CustomSnackbar('success', response.data.message || "")
            return true
        } catch (error) {
            console.log(error)
        }
    };

    const deleteNameFailStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await NameFailsAPI.deleteNameFails(id)
            dispatch(changeStatus(ApiStatus.FETCHED))
            if (!response?.status) {
                return CustomSnackbar('error', response.data.message || "")
            }
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
        }
    };

    const setSelectedNameFail = (nameFail: NameFail) => {
        try {
            dispatch(onSetSelectedNameFail(nameFail))
        } catch (error) {
            console.log(error)
        }
    };


    return {
        //states
        nameFails,
        status,
        selectedNameFail,
        //actions
        getNameFailStore,
        createNameFailStore,
        editNameFailStore,
        deleteNameFailStore,
        setSelectedNameFail,
        createNameFailStore2
    }
}