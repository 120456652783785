import { useEffect, useState } from 'react'
import { Autocomplete, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Button, IconButton, TablePagination } from "@mui/material"
import Skeleton from "@mui/material/Skeleton";
import { withStyles } from "@mui/styles";
import { useMaintenanceTypeStore } from '../../hooks/useMaintenanceTypeStore';
import { useModelMaintenance } from '../../hooks/useModelMaintenance';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { useModelVehicleStore } from '../../hooks/useModelVehicleStore';
import { useMileageMaintenance } from '../../hooks/useMileageMaintenance';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { ModelVehicle } from '../../types/slices/modelVehicleType';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import { ApiStatus } from '../../types/api/status';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const FormMaintenance = () => {

    const { getMaintenanceTypes, maintenanceTypes } = useMaintenanceTypeStore();
    const { getModelMaintenanceStore, modelMaintenances, createModelMaintenanceStore, editModelMaintenanceStore, modelMaintenanceStatus } = useModelMaintenance()
    const { selectedModelVehicle, setSelectedModelVehicle } = useModelVehicleStore();
    const { getMileageMaintenanceStore, mileages } = useMileageMaintenance();
    const [tableData, setTableData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);
    const [rowSelected, setRowSelected] = useState<any>({});
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const navigate = useNavigate()

    useEffect(() => {
        setTableData([]);
        getMaintenancesAPI();
    }, []);

    useEffect(() => {
        if (modelMaintenances && modelMaintenances.length > 0) {
            const dataApi = modelMaintenances[0].maintenance_km;
            const newArray = dataApi.map((objeto: any) => ({
                ...objeto,
                edit: true
            }))
            setTableData(newArray);
        }
    }, [modelMaintenances])

    const getMaintenancesAPI = async () => {
        try {
            setLoading(true)
            await getMaintenanceTypes();
            await getMileageMaintenanceStore();
            await getModelMaintenanceStore(selectedModelVehicle.id);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    };

    const handleAddRow = () => {
        navigate({ pathname: RoutesMap.MAINTENANCE_MODEL_INPUT })
    }

    const editRow = (order: number) => {
        const newData = [...tableData];
        newData[order].edit = false;
        setTableData(newData);
    }

    const goBack = () => {
        setSelectedModelVehicle({} as ModelVehicle);
        setTableData([]);
        navigate({ pathname: RoutesMap.MODEL_VEHICLES });
    }

    const onSubmit = async () => {
        const payload = {
            idmodel: selectedModelVehicle.id,
            maintenance_km: tableData,
        }
        if (modelMaintenances.length === 0) {
            const response = await createModelMaintenanceStore(payload);
            if (response) {
                const newStates = tableData?.map((row) => ({
                    ...row,
                    edit: true,
                }))
                setTableData(newStates);
                CustomSnackbar('sucess', 'Nueva mantención creada correctamente')
            } else {
                CustomSnackbar('warning', 'Sucedió un problema al crear la mantención')
            }
        } else {
            const detailId = modelMaintenances?.find((model) => model.idmodel === selectedModelVehicle.id)?.id || 0;
            const response = await editModelMaintenanceStore(detailId, payload)
            if (response) {
                const newStates = tableData?.map((row) => ({
                    ...row,
                    edit: true,
                }))
                setTableData(newStates)
                CustomSnackbar('sucess', 'Nueva mantención creada correctamente')
            } else {
                CustomSnackbar('warning', 'Sucedió un problema al crear la mantención')
            }
        }
    }

    const deleteRow = (row) => {
        setRowSelected(row)
        setModalDeleteConfirm(true)
    }

    const onDelete = async () => {
        const detailId = modelMaintenances?.find((model) => model.idmodel === selectedModelVehicle.id)?.id || 0;
        const newData = [...tableData]
        const index = newData.findIndex(item => item.order === rowSelected.order)
        newData.splice(index, 1);
        for (let i = index; i < newData.length; i++) {
            newData[i].order = i + 1
        }
        const payload = {
            idmodel: selectedModelVehicle.id,
            maintenance_km: newData,
        }
        const response = await editModelMaintenanceStore(detailId, payload)
        if (response) {
            setModalDeleteConfirm(false);
            CustomSnackbar('sucess', 'Mantención eliminada correctamente')
            setTableData(newData)
        } else {
            setModalDeleteConfirm(false);
            CustomSnackbar('warning', 'Ocurrió un problema al eliminar la mantención')
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <>
            {modelMaintenanceStatus === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            <Grid container xs={12}>
                <Grid item container sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: "-5px",
                }}>
                    <Typography variant="h6"
                        component="h1"
                        gutterBottom
                        color="#808080"
                        className="tittle"
                        sx={{ mt: 1, ml: 1, fontWeight: 600 }}>MANTENCIONES POR MODELO: {selectedModelVehicle.name}</Typography>
                </Grid>
                <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft: '-25px' }}>
                    <IconButton size="small" color="primary" aria-label="view" onClick={() => goBack()}>
                        <ArrowBackIcon fontSize='small' />
                    </IconButton>
                    <Typography>Volver</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ textAlign: "right" }}
                >
                    <Button
                        onClick={handleAddRow}
                        sx={{
                            border: "solid 1px #73B2FF",
                            mt: "10px",
                            color: "#73B2FF",
                            "&:hover": {
                                bgcolor: "#73B2FF",
                                color: "#fff",
                            },
                            marginBottom: "15px"
                        }}
                    >
                        AGREGAR MANTENCIÓN
                    </Button>
                </Grid>
                <TableContainer style={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead sx={{ background: "#D8E8F7" }}>
                            <TableRow>
                                <StyledTableCell className="headTable" align="center">MANTENCIÓN</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">KILOMETRAJE</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">TIPO</StyledTableCell>
                                <StyledTableCell className="headTable" align="center">ACCIONES</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (<TableRow>
                                <TableCell >
                                    <Skeleton
                                        sx={{ height: 20 }}
                                        animation="wave"
                                        variant="rectangular"
                                    />
                                </TableCell>
                                <TableCell >
                                    <Skeleton
                                        sx={{ height: 20 }}
                                        animation="wave"
                                        variant="rectangular"
                                    />
                                </TableCell>
                                <TableCell >
                                    <Skeleton
                                        sx={{ height: 20 }}
                                        animation="wave"
                                        variant="rectangular"
                                    />
                                </TableCell>
                                <TableCell >
                                    <Skeleton
                                        sx={{ height: 20 }}
                                        animation="wave"
                                        variant="rectangular"
                                    />
                                </TableCell>
                            </TableRow >) : tableData?.length ? tableData?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">Mantención {row.order}</TableCell>
                                    <TableCell align="center">
                                        <Autocomplete
                                            id={`model-maintenance-${row.order}-km`}
                                            value={mileages.find((option) => option.km === row.km) || null}
                                            options={mileages}
                                            getOptionLabel={(option) => option.km.toString()}
                                            onChange={(event, newValue) => {
                                                const targetIndex = row.order - 1;
                                                console.log("que fue", targetIndex)
                                                const newData = [...tableData];
                                                newData[targetIndex] = {
                                                    ...newData[targetIndex],
                                                    km: newValue ? newValue.km : 0,
                                                };
                                                setTableData(newData);

                                            }}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            readOnly={row.edit}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Autocomplete
                                            id={`model-maintenance-${row.order}`}
                                            value={
                                                maintenanceTypes.find((type) => type.id === tableData[row.order - 1]?.type_maintenance) || null
                                            }
                                            options={maintenanceTypes}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(event, newValue) => {
                                                const newData = [...tableData];
                                                newData[row.order - 1].type_maintenance = newValue ? newValue.id : null;
                                                setTableData(newData);
                                            }}
                                            readOnly={row.edit}
                                        />

                                    </TableCell>
                                    <TableCell align="center">
                                        {row.edit ? (<Button sx={{ margin: '2px' }} onClick={() => editRow(row.order - 1)} variant="outlined" startIcon={<EditIcon />}>
                                            EDITAR
                                        </Button>) : (<Button onClick={() => onSubmit()} variant="outlined" startIcon={<SaveIcon />}>
                                            GUARDAR
                                        </Button>)}
                                        {
                                            row.edit && <Button sx={{ margin: '2px' }} onClick={() => deleteRow(row)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                ELIMINAR
                                            </Button>
                                        }

                                    </TableCell>
                                </TableRow>
                            )) : (<TableRow>
                                <TableCell
                                    colSpan={5}
                                >
                                    <Grid
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <div>{"Añada una mantención"}</div>
                                    </Grid>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {tableData.length > 1 &&
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[10, 25, 100]}
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
                {modalDeleteConfirm && (
                    <ModalConfirm
                        open={modalDeleteConfirm}
                        closeModal={() => {
                            setModalDeleteConfirm(false);
                        }}
                        onCancel={() => {
                            setModalDeleteConfirm(false);
                        }}
                        onConfirm={onDelete}
                        status2={modelMaintenanceStatus === ApiStatus.FETCHING ? true :false}
                    />
                )}
            </Grid>
        </>
    )
}

export default FormMaintenance