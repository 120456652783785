import React, { FC, useEffect, useState } from 'react'
import { Autocomplete, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Button } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { withStyles } from "@mui/styles";
import { useMaintenanceTypeStore } from '../../hooks/useMaintenanceTypeStore';
import { useTrademarkMaintenance } from '../../hooks/useTrademarkMaintenance';
import { useTrademarkVehicleStore } from '../../hooks/useTrademarkVehicleStore';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { RoutesMap } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useMileageMaintenance } from '../../hooks/useMileageMaintenance';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import { ApiStatus } from '../../types/api/status';

type dataInput = {
    order: number,
    km: number,
    type_maintenance: number,
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const MaintenanceTrademarkInput: FC<any> = () => {
    const { getMaintenanceTypes, maintenanceTypes } = useMaintenanceTypeStore();
    const { getTrademarkMaintenanceStore, trademarkMaintenances, createTrademarkMaintenanceStore, editTrademarkMaintenanceStore, trademarkMaintenanceStatus } = useTrademarkMaintenance();
    const { selectedTrademarkVehicle } = useTrademarkVehicleStore();
    const { getMileageMaintenanceStore, mileages } = useMileageMaintenance();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState<dataInput[]>([])
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getMaintenancesAPI().then(() => {
            setIsButtonEnabled(true);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        const i = trademarkMaintenances[0]?.maintenance_km?.length || 0;
        setTableData([
            { order: i + 1, km: 0, type_maintenance: 0 },
            { order: i + 2, km: 0, type_maintenance: 0 },
            { order: i + 3, km: 0, type_maintenance: 0 },
            { order: i + 4, km: 0, type_maintenance: 0 },
            { order: i + 5, km: 0, type_maintenance: 0 }
        ])
    }, [trademarkMaintenances]);

    const getMaintenancesAPI = async () => {
        try {
            setLoading(true);
            await getMaintenanceTypes();
            await getMileageMaintenanceStore();
            await getTrademarkMaintenanceStore(selectedTrademarkVehicle.id);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const onNext = async () => {
        const oldData = trademarkMaintenances[0]?.maintenance_km || []
        const payload = {
            idtrademark: selectedTrademarkVehicle.id,
            maintenance_km: [...oldData, ...tableData]
        }
        if (trademarkMaintenances.length === 0) {
            const method = createTrademarkMaintenanceStore(payload);
            const response = await method;
            if (response) {
                CustomSnackbar('sucess', 'Nuevas mantención creada correctamente')
                await getMaintenancesAPI();
            } else {
                CustomSnackbar('warning', 'Sucedió un problema al crear la mantención')
            }
        } else {
            const detailId = trademarkMaintenances?.find((model) => model.idtrademark === selectedTrademarkVehicle.id)?.id || 0;
            const method = editTrademarkMaintenanceStore(detailId, payload);
            const response = await method;
            if (response) {
                await getMaintenancesAPI();
                CustomSnackbar('sucess', 'Nuevas mantención creada correctamente')
            } else {
                CustomSnackbar('warning', 'Sucedió un problema al crear la mantención')
            }
        }
    };

    const onSubmit = () => {
        setLoading(true)
        onNext();
        setLoading(false)
        navigate({ pathname: RoutesMap.MAINTENANCE_TRADEMARK_FORM });
    };

    return (
        <>
            {loading && (<CustomBackdrop open={true} />)}
            {trademarkMaintenanceStatus === ApiStatus.FETCHING && (<CustomBackdrop open={true} />)}
            <Grid item container sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                marginBottom: "5px",
            }}>
                <Typography variant="h6"
                    component="h1"
                    gutterBottom
                    color="#808080"
                    className="tittle"
                    sx={{ mt: 1, ml: 1, fontWeight: 600 }}>CREACIÓN DE MANTENCIONES - MARCA: {selectedTrademarkVehicle.name}</Typography>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead sx={{ background: "#D8E8F7" }}>
                        <TableRow>
                            <StyledTableCell className="headTable" align="center">MANTENCIÓN</StyledTableCell>
                            <StyledTableCell className="headTable" align="center">KILOMETRAJE</StyledTableCell>
                            <StyledTableCell className="headTable" align="center">TIPO</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (<TableRow>
                            <TableCell >
                                <Skeleton
                                    sx={{ height: 20 }}
                                    animation="wave"
                                    variant="rectangular"
                                />
                            </TableCell>
                            <TableCell >
                                <Skeleton
                                    sx={{ height: 20 }}
                                    animation="wave"
                                    variant="rectangular"
                                />
                            </TableCell>
                            <TableCell >
                                <Skeleton
                                    sx={{ height: 20 }}
                                    animation="wave"
                                    variant="rectangular"
                                />
                            </TableCell>
                        </TableRow >) : tableData?.map((row) => {
                            const i = trademarkMaintenances[0]?.maintenance_km?.length || 0;
                            const index = i + 1;
                            return (
                                <TableRow key={row.order}>
                                    <TableCell align="center">Mantención {row.order}</TableCell>
                                    <TableCell align="center">
                                        <Autocomplete
                                            id={`trademark-maintenance-${row.order}-km`}
                                            value={mileages.find((option) => option.km === row.km) || null}
                                            options={mileages}
                                            getOptionLabel={(option) => option.km.toString()}
                                            onChange={(event, newValue) => {
                                                const newData = [...tableData];
                                                newData[row.order - index].km = newValue ? newValue.km : 0;
                                                setTableData(newData);
                                            }}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            id={`trademark-maintenance-${row.order}-type`}
                                            value={maintenanceTypes?.find((type) => type.id === tableData[row.order - index]?.type_maintenance) || null}
                                            options={maintenanceTypes}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(event, newValue) => {
                                                const newData = [...tableData];
                                                newData[row.order - index].type_maintenance = newValue ? newValue.id : 0;
                                                setTableData(newData);
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: '10px' }}>
                <Grid item>
                    <Button variant="contained" color="warning" onClick={() => navigate({ pathname: RoutesMap.MAINTENANCE_TRADEMARK_FORM })}>
                        SALIR
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="success" onClick={() => onNext()} disabled={!isButtonEnabled} >
                        GUARDAR Y SEGUIR
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => onSubmit()} disabled={!isButtonEnabled} >
                        GUARDAR Y FINALIZAR
                    </Button>
                </Grid>
            </Grid >
        </>
    )
}

export default MaintenanceTrademarkInput