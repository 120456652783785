import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useInformMechanicalStore } from "../../hooks/useInformMechanicalStore";
import { InformMechanical } from "../../types/slices/informMechanicalType";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { WorkDone } from "../../types/slices/workDondeType";
import { useWorkDoneStore } from "../../hooks/useWorkDoneStore";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'description', label: 'DESCRIPCIÓN', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const WorkDoneList:FC = () => {
    const navigate = useNavigate()
    const { search } = useSelector(selectHeaderState)
    const { status, workDones, getWorkDones, deleteWorkDone, setEditWorkDone } = useWorkDoneStore()

    const [ loading, setLoading ] = useState<boolean>(false)
    const [ page, setPage ] = useState<number>(0)
    const [ rowsPerPage, setRowsPerPage ] = useState<number>(10)
    const [ rowSelected, setRowSelected ]           = useState<WorkDone>({} as WorkDone)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)

    useEffect(()=>{
        getWorkDonesApi()
    },[search])

    const getWorkDonesApi = async () => {
        try {
          setLoading(true)
          let payload:any = {}
          if (search !== '') {
            payload = {
                ...payload,
                search
            }
          }
          await getWorkDones(payload)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        setEditWorkDone({} as WorkDone)
        navigate({ pathname: RoutesMap.WORK_DONE_FORM })
    }

    const onEdit = (rowSelected) => {
        setEditWorkDone(rowSelected)
        navigate({ pathname: RoutesMap.WORK_DONE_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteWorkDone(rowSelected.id)
        if (response === true) {
            setEditWorkDone({} as WorkDone)
            setShowModalConfirm(false)
            getWorkDonesApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return(<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Trabajos realizados'}
                columns={columns || []}
                loading={loading}
                rows={workDones || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDelete={onDelete}
                onEdit={onEdit}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                    status2={status === ApiStatus.FETCHING ? true : false}
                />
            )
        }
    </>)
}