import { createSlice } from "@reduxjs/toolkit";
import { GroupStructureD2, StructureD2, StructureD2Type } from "../../types/slices/structureD2Type";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: StructureD2Type = {
    status: ApiStatus.FETCHED,
    structureD2: [],
    errorMessage: undefined,
    editStructureD2: {} as StructureD2,
    selectedStructureD2: {} as StructureD2,
    groupsStructureD2: []
}

const structureD2Slice = createSlice({
    name: "structureD2",
    initialState,
    reducers: {
        onFetchStructureD2(state, { payload }: { payload: StructureD2[] }) {
            state.status = ApiStatus.FETCHED;
            state.structureD2 = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditStructureD2(state, { payload }: { payload: StructureD2 }) {
            state.editStructureD2 = payload;
        },
        onSetSelectedStructureD2(state, { payload }: { payload: StructureD2 }) {
            state.selectedStructureD2 = payload;
        },
        onFetchGroupsStructureD2(state, { payload }: { payload: GroupStructureD2[] }) {
            state.status = ApiStatus.FETCHED;
            state.groupsStructureD2 = payload;
            state.errorMessage = undefined;
        },
    },
});

export const selectStructureD2State = (state: RootState) => state.structureD2Slice;

export default structureD2Slice.reducer;

export const {
    onFetchStructureD2,
    changeStatus,
    onSetEditStructureD2,
    onSetSelectedStructureD2,
    onFetchGroupsStructureD2
} = structureD2Slice.actions;