import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Technical, TechnicalType } from "../../types/slices/technicalType";

const initialState: TechnicalType = {
    status: ApiStatus.FETCHED,
    technicals: [],
    errorMessage: undefined,
    editTechnical: {} as Technical,
    selectedTechnical: {} as Technical,
};

const technicalSlice = createSlice({
    name: "technical",
    initialState,
    reducers: {
        onFetchTechnical(state, { payload }: { payload: Technical[] }) {
            state.status = ApiStatus.FETCHED;
            state.technicals = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditTechnical(state, { payload }: { payload: Technical }) {
            state.editTechnical = payload;
        },
        onSetSelectedTechnical(state, { payload }: { payload: Technical }) {
            state.selectedTechnical = payload;
        },
    },
});

export const selectTechnicalState = (state: RootState) => state.technicalSlice;
export default technicalSlice.reducer;

export const {
    onFetchTechnical,
    changeStatus,
    onSetEditTechnical,
    onSetSelectedTechnical,
} = technicalSlice.actions;
