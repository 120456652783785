import { useDispatch, useSelector } from "react-redux";
import { MainStagesWorksAPI } from "../apis/AST/MainStagesWorksAPI";
import { changeStatus, onFetchMainStageWork, onSetSelectedMainStageWork, selectMainStageWorkState } from "../redux/slices/mainStagesWorkSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { MainStageWork } from "../types/slices/mainStagesWorksType";

export const useMainStagesWorksStore = () => {
    const dispatch = useDispatch();
    const { status, mainStagesWorks, selectedMainStageWork } = useSelector(selectMainStageWorkState);


    const getMainStagesWorks = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MainStagesWorksAPI.getMainStagesWorks(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchMainStageWork(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createMainStagesWorksStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MainStagesWorksAPI.createMainStagesWorks(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editMainStagesWorksStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MainStagesWorksAPI.editMainStagesWorks(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteMainStagesWorksStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await MainStagesWorksAPI.deleteMainStagesWorks(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedMainStagesWorks = (mainStagesWork: MainStageWork) => {
        try {
            dispatch(onSetSelectedMainStageWork(mainStagesWork));
        } catch (error) {
            console.log(error);
        }
    };


    return {
        //states
        status, mainStagesWorks, selectedMainStageWork,
        //actions
        getMainStagesWorks,
        createMainStagesWorksStore,
        editMainStagesWorksStore,
        deleteMainStagesWorksStore,
        setSelectedMainStagesWorks,
    };
};