import { api } from "./configs/axiosConfigs";

export const MileageMaintenanceAPI = {
    getMileage: async (data?: any) => {
        const response = await api
            .get('/mileage/mileageData', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            });
        return response;
    }
}