import { FC, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useSupervisorStore } from "../../hooks/useSupervisorStore";
import { Supervisor } from "../../types/slices/supervisorsType";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { IdentificationModalAdmin } from "../MyProfile/components/IdentificationModalAdmin";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { useUserStore } from "../../hooks/useUserStore";
import ContactMailIcon from '@mui/icons-material/ContactMail';

const colums = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "rut", label: "RUT", align: 'center' },
    { type: "text", field: "name", label: "NOMBRES", align: 'center' },
    { type: "text", field: "surname", label: "APELLIDOS", align: 'center' },
    { type: "text", field: "email", label: "EMAIL", align: 'center' },
    { type: "text", field: "phone", label: "TELÉFONO", align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
];

const Supervisors: FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState);

    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<Supervisor>(
        {} as Supervisor
    );
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);
    const userRole: Role | any = user.userType;

    const [showModalIdentification, setShowModalIdentification] = useState<boolean>(false)
    const [userRut, setUserRut] = useState<string>('')

    const {
        supervisors,
        status: supervisorStatus,
        getSupervisors,
        setSelectedSupervisor,
        deleteSupervisors,
    } = useSupervisorStore()
    const { forgotPasswordUser } = useUserStore()

    useEffect(() => {
        getSupervisorApi();
    }, [search]);

    const getSupervisorApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getSupervisors(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        navigate({ pathname: RoutesMap.SUPERVISORS_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedSupervisor(rowSelected);
        navigate({ pathname: RoutesMap.SUPERVISORS_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteSupervisors(rowSelected.id);
        if (response === true) {
            setSelectedSupervisor({} as Supervisor);
            setModalDeleteConfirm(false);
            getSupervisorApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onPersonIcon = (row) => {
        setUserRut(row.rut)
        setShowModalIdentification(true)
    }

    const sendCredentials = async (rowSelected) => {
        setLoading(true)
        await forgotPasswordUser(rowSelected.rut, rowSelected.iduser_type)
        setLoading(false)
    }

    return (
        <>
            <Grid item xs={12}>
                {
                    userRole === Role.ADMIN
                    ?
                    <CustomTable
                        title={"Supervisores"}
                        columns={colums}
                        loading={loading}
                        rows={supervisors || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onPersonIcon={onPersonIcon}
                        onHelper={sendCredentials}
                        tooltip_helper={'Enviar credenciales'}
                        icon_helper={<ContactMailIcon fontSize="small"/>}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    :
                    <CustomTable
                        title={"Supervisores"}
                        columns={colums}
                        loading={loading}
                        rows={supervisors || []}
                        onRowClick={() => { }}
                        hasOptions
                        onAddFn={onAdd}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status={supervisorStatus}
                />
            )}
            {
                showModalIdentification && (
                    <IdentificationModalAdmin
                        open={showModalIdentification}
                        closeModal={()=>setShowModalIdentification(false)}
                        rut={userRut}
                    />
                )
            }
        </>
    );
};

export default Supervisors;
