import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { InformMechanical, InformMechanicalType } from "../../types/slices/informMechanicalType";
import { RootState } from "../store";

const initialState:InformMechanicalType = {
    status: ApiStatus.FETCHED,
    informsMechanical: [],
    errorMessage: undefined,
    editInformMechanical: {} as InformMechanical
}

const InformMechanicalSlice = createSlice({
    name: 'InformMechanical',
    initialState,
    reducers: {
        onFetchInformsMechanical (state, { payload }: { payload: InformMechanical[] }) {
            state.status            = ApiStatus.FETCHED
            state.informsMechanical        = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditInformMechanical (state, { payload }: { payload: InformMechanical }) {
            state.editInformMechanical = payload
        }
    }
})

export const selectInformMechanicalState = (state: RootState) => state.InformMechanicalSlice
export default InformMechanicalSlice.reducer

export const {
    onFetchInformsMechanical,
    changeStatus,
    onSetEditInformMechanical
} = InformMechanicalSlice.actions