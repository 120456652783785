import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Modal } from '@mui/material';
import { Search, FilterList } from '@mui/icons-material'
import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

//estilos para el modal
const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        zIndex: 1300, // Asegúrate de que sea mayor que el zIndex del contenido de la aplicación
    },
    modal: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        height: '100%',
        transition: 'transform 1.5s ease-in-out',
        transform: 'translateX(100%)',
    },
    modalContent: {
        flexGrow: 1,
        backgroundColor: 'white',
        overflow: 'auto',
        padding: 16,
    },
    modalOpen: {
        transform: 'translateX(0%)', // Cambio en la transformación al abrir el modal
    },
    modalClosed: {
        transform: 'translateX(100%)', // Transición inversa al cerrar
    },
}));

interface VehiclesFilterProps {
    loading: (isLoading: boolean) => void;
    getData: (data?: any) => Promise<any>
}

const typeFilter = [
    { id: 1, type: "No firmado" },
    { id: 2, type: "Firmado por mecánico" },
    { id: 3, type: "Firmado por jefe de mantenimiento" },
    { id: 4, type: "Firmado" }
]


const CustomPatternFilter: FC<VehiclesFilterProps> = ({ loading, getData }) => {
    const classes = useStyles();
    const [isResponsive, setIsResponsive] = useState<boolean>(window.innerWidth <= 760);
    const [filterButton, setFilterButton] = useState<boolean>(false)

    const [filters, setFilters] = useState<number>(0)

    useEffect(() => {
        const handleResize = (): void => {
            setIsResponsive(window.innerWidth <= 760);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onFilters = (e) => {
        const { value } = e.target
        setFilters(value)
    }

    const handleSubmit = async () => {
        loading(true);
        setFilterButton(false)
        let payload: any = {}
        switch (filters) {
            case 1:
                payload = {
                    ...payload,
                    status_mechanic: 0,
                    status_boss: 0
                }
                break;
            case 2:
                payload = {
                    ...payload,
                    status_mechanic: 1,
                    status_boss: 0
                }
                break;
            case 3:
                payload = {
                    ...payload,
                    status_mechanic: 0,
                    status_boss: 1
                }
                break;
            case 4:
                payload = {
                    ...payload,
                    status_mechanic: 1,
                    status_boss: 1
                }
                break;
            default:
                payload = { ...payload }
        }
        await getData(payload)
        loading(false);
    }

    const handleCancel = async () => {
        loading(true);
        setFilters(0)
        setFilterButton(false)
        await getData();
        loading(false);
    }

    const filterModal = () => {
        setFilterButton(!filterButton)
    }

    return (
        <>
            {!isResponsive ? (<Grid item container xs={12} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: 'solid 1px #e9e2e2', borderRadius: '10px' }}>
                <Grid xs={12} sm={12} md={9} lg={9} xl={9} sx={{ padding: '10px' }} marginBottom="-10px" justifyContent="center">
                    <FormControl sx={{ margin: "5px" }} >
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>
                            FILTROS
                        </InputLabel>
                        <Select
                            id="combo-box-filter"
                            name='filter'
                            size="small"
                            label="FILTROS"
                            value={filters}
                            onChange={onFilters}
                            sx={{ width: '250px', fontSize: "12px" }}>
                            {typeFilter?.map((filter) => (<MenuItem key={filter.id} sx={{ width: "300px" }} value={filter.id}>{filter.type}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item display='flex' justifyContent='center' padding='5px' xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Button
                        startIcon={<Search />}
                        size="small"
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ width: '140px', height: '40px', backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", marginRight: '3px', lineHeight: '12px' }}>
                        Filtrar búsqueda
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleCancel}
                        sx={{ width: '140px', height: '40px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#a79f9f" }, fontSize: "12px" }}>
                        Cancelar filtros
                    </Button>
                </Grid>
            </Grid >) : (
                <>
                    {/* Botón de filtro - responsive */}
                    <Button startIcon={<FilterList />}
                        size="small" variant="contained" sx={{ height: "40px", marginBottom: "-30px", backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", lineHeight: '12px', marginRight: "auto", alignItems: "center" }} onClick={filterModal}>Filtros</Button>
                </>
            )}
            {filterButton &&
                <div>
                    <Modal
                        open={filterButton}
                        onClose={filterModal}
                        className={classes.modalContainer}
                    >
                        <div className={`${classes.modal} ${filterButton ? classes.modalOpen : classes.modalClosed}`}>
                            <div className={classes.modalContent}>
                                <h2>FILTROS</h2>
                                <Grid sx={{ padding: '5px' }} justifyContent="center">
                                    <FormControl sx={{ margin: "5px", marginBottom: "15px" }} >
                                        <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>
                                            FILTROS
                                        </InputLabel>
                                        <Select
                                            id="combo-box-filter"
                                            name='filter'
                                            size="small"
                                            label="MARCA"
                                            value={filters}
                                            onChange={onFilters}
                                            sx={{ width: '200px', fontSize: "12px" }}>
                                            {typeFilter?.map((filter) => (<MenuItem key={filter.id} sx={{ width: "200px" }} value={filter.id}>{filter.type}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item display='flex' justifyContent='center' padding='5px' xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Button
                                        startIcon={<Search />}
                                        size="small"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{ width: '120px', height: '40px', backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", marginRight: '3px', lineHeight: '12px' }}>
                                        Filtrar búsqueda
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={handleCancel}
                                        sx={{ width: '120px', height: '40px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 7px', "&:hover": { backgroundColor: "#a79f9f" }, fontSize: "12px" }}>
                                        Cancelar filtros
                                    </Button>
                                </Grid>
                            </div>
                        </div>
                    </Modal>
                </div>}
        </>
    )
};

export default CustomPatternFilter;