import { useDispatch, useSelector } from "react-redux";
import { UnwantedEventAPI } from "../apis/AST/UnwantedEventAPI";
import { selectUnwantedEventState, changeStatus, onFetchUnwantedEvent, onSetSelectedUnwantedEvent } from "../redux/slices/unwantedEventSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { UnwantedEvent } from "../types/slices/unwantedEventsType";

export const useUnwantedEventStore = () => {
    const dispatch = useDispatch();
    const { status, unwantedEvents, selectedUnwantedEvent } = useSelector(selectUnwantedEventState);


    const getUnwantedEvent = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await UnwantedEventAPI.getUnwantedEvent(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchUnwantedEvent(detail));
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createUnwantedEventStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await UnwantedEventAPI.createUnwantedEvent(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editUnwantedEventStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await UnwantedEventAPI.editUnwantedEvent(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteUnwantedEventStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await UnwantedEventAPI.deleteUnwantedEvent(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedUnwantedEvent = (unwantedEvent: UnwantedEvent) => {
        try {
            dispatch(onSetSelectedUnwantedEvent(unwantedEvent));
        } catch (error) {
            console.log(error);
        }
    };


    return {
        //states
        status, unwantedEvents, selectedUnwantedEvent,
        //actions
        getUnwantedEvent,
        createUnwantedEventStore,
        editUnwantedEventStore,
        deleteUnwantedEventStore,
        setSelectedUnwantedEvent,
    };
};