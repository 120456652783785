import { api } from "./configs/axiosConfigs"

export const CustomerIncompleteAPI = {
    getCustomerIncomplete: async (data?: any) => {
        const response = await api.get('/customerIncomplete', { params: { ...data } })
            .then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },

    updateCustomerIncomplete: async (id: number, data: any) => {
        const response = await api.patch(`/customerIncomplete/${id}`, { ...data })
            .then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },

}