import { useDispatch, useSelector } from "react-redux";
import { customerAPI } from "../apis/CustomerAPI";
import { onFetchCustomer, changeStatus, onSetEditCustomer, onSetSelectedCustomer, selectCustomerState } from "../redux/slices/customerSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Customer } from "../types/slices/customerType";

export const useCustomerStore = () => {
    const dispatch = useDispatch();
    const { status, customers, editCustomer, selectedCustomer } =
        useSelector(selectCustomerState);

    const getCustomerStore = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await customerAPI.getCustomer(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchCustomer(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createCustomerStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await customerAPI.createCustomer(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editCustomerStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await customerAPI.editCustomer(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return response;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteCustomerStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await customerAPI.deleteCustomer(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedCustomer = (customer: Customer) => {
        try {
            dispatch(onSetSelectedCustomer(customer));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditCustomer = (customer: Customer) => {
        try {
            dispatch(onSetEditCustomer(customer));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        //states
        customers,
        editCustomer,
        selectedCustomer,
        status,
        //actions
        getCustomerStore,
        createCustomerStore,
        editCustomerStore,
        deleteCustomerStore,
        setSelectedCustomer,
        setEditCustomer
    };
};