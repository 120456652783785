import { useDispatch, useSelector } from "react-redux";
import { SupervisorAPI } from "../apis/SupervisorAPI";
import {
    selectSupervisorState,
    changeStatus,
    onFetchSupervisor,
    onSetEditSupervisor,
    onSetSelectedSupervisor,
} from "../redux/slices/supervisorSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { Supervisor } from "../types/slices/supervisorsType";

export const useSupervisorStore = () => {
    const dispatch = useDispatch();
    const { supervisors, status, editSupervisor, selectedSupervisor } =
        useSelector(selectSupervisorState);

    const idcompany = 1;

    const getSupervisors = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await SupervisorAPI.getSupervisors(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchSupervisor(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createSupervisorStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await SupervisorAPI.createSupervisors(data, idcompany);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editSupervisorStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await SupervisorAPI.editSupervisors(id, data, idcompany);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteSupervisors = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await SupervisorAPI.deleteSupervisors(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedSupervisor = (supervisor: Supervisor) => {
        try {
            dispatch(onSetSelectedSupervisor(supervisor));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditSupervisor = (supervisor: Supervisor) => {
        try {
            dispatch(onSetEditSupervisor(supervisor));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        //states
        supervisors,
        status,
        editSupervisor,
        selectedSupervisor,
        //actions
        getSupervisors,
        createSupervisorStore,
        editSupervisorStore,
        deleteSupervisors,
        setSelectedSupervisor,
        setEditSupervisor,
    };
};
