import { api } from "./configs/axiosConfigs"

export const ControlVerificationAPI = {
    getControlVerifications: async (data?:any) => {
        const response = await api.get(
            '/controlVerification',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/controlVerification/register', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    update: async (id: number, data: any) => {
        const response = await api.patch(
            `/controlVerification/${id}`,
            {
                ...data
            }
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
            `/controlVerification/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
}