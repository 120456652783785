import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Maintenance, MaintenanceType } from "../../types/slices/maintenanceType";

const initialState: MaintenanceType = {
    status: ApiStatus.FETCHED,
    maintenanceTypes: [],
    errorMessage: undefined,
    selectedMaintenanceType: {} as Maintenance,
}

const maintenanceTypeSlice = createSlice({
    name: "maintenanceType",
    initialState,
    reducers: {
        onFetchMaintenanceType(state, { payload }: { payload: Maintenance[] }) {
            state.status = ApiStatus.FETCHED;
            state.maintenanceTypes = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedMaintenanceType(state, { payload }: { payload: Maintenance }) {
            state.selectedMaintenanceType = payload;
        },
    }
});

export const selectMaintenanceTypesState = (state: RootState) => state.maintenanceTypeSlice;
export default maintenanceTypeSlice.reducer;

export const {
    onFetchMaintenanceType,
    changeStatus,
    onSetSelectedMaintenanceType,
} = maintenanceTypeSlice.actions