import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { useTrademarkVehicleStore } from '../../hooks/useTrademarkVehicleStore';
import { TrademarkVehicle } from '../../types/slices/trademarkVehicleType';
import { ModalConfirm } from '../../components/common/ModalConfirm/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { selectHeaderState } from '../../redux/slices/headerSlice';
import { useSelector } from 'react-redux';
import { useTrademarkMaintenance } from '../../hooks/useTrademarkMaintenance';
import { ApiStatus } from '../../types/api/status';

const columns = [
    { type: "options", field: "options", label: "OPCIONES", align: 'center' },
    { type: "text", field: "name", label: "NOMBRE", align: 'center' },
    { type: 'detail', field: 'detail', label: 'MANTENCIONES', align: 'center' },
    { type: "text", field: "", label: "", align: 'center' },
]

const TrademarkVehicleList: FC<any> = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<TrademarkVehicle>(
        {} as TrademarkVehicle
    );
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);

    const { trademarkVehicles, trademarkStatus, getTrademarkVehicles, setSelectedTrademark, deleteTrademarkStore } = useTrademarkVehicleStore();
    const { deleteStateTrademarkMaintenance } = useTrademarkMaintenance();

    useEffect(() => {
        getTrademarkVehiclesApi();
    }, [search]);

    const getTrademarkVehiclesApi = async () => {
        try {
            setLoading(true);
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getTrademarkVehicles(payload);
            deleteStateTrademarkMaintenance();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onAdd = () => {
        navigate({ pathname: RoutesMap.TRADEMARK_VEHICLES_FORM });
    };

    const onEdit = (rowSelected) => {
        setSelectedTrademark(rowSelected);
        navigate({ pathname: RoutesMap.TRADEMARK_VEHICLES_FORM });
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteTrademarkStore(rowSelected.id);
        if (response === true) {
            setSelectedTrademark({} as TrademarkVehicle);
            setModalDeleteConfirm(false);
            getTrademarkVehiclesApi();
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onDetail = (rowSelected) => {
        setSelectedTrademark(rowSelected);
        navigate({ pathname: RoutesMap.MAINTENANCE_TRADEMARK_FORM });
    }


    return (
        <>
            <Grid container xs={12}>
                <CustomTable
                    title={'Marca de Vehículos'}
                    columns={columns}
                    loading={loading}
                    rows={trademarkVehicles || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    onDetailMaintenance={onDetail}
                />
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status2={trademarkStatus === ApiStatus.FETCHING ? true : false}
                />
            )}
        </>
    )
}

export default TrademarkVehicleList