import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNameFails } from "../../hooks/useNameFails";
import { RoutesMap } from "../../types";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Divider, FormLabel, Grid, TextField } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useInformMechanicalStore } from "../../hooks/useInformMechanicalStore";

type InformMechanicalFormData ={
    description:string
}

export const InformMechanicalForm:FC = () => {
    const navigate = useNavigate()
    const { status, editInformMechanical, createInformMechanical, updateInformMechanical } = useInformMechanicalStore()

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<InformMechanicalFormData>({
        description: ''
    })

    useEffect(() => {
        if (Object.entries(editInformMechanical).length > 0) {
            setIsEdit(true)
            setData({
                description: editInformMechanical.description
            })
        }
    }, [])

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.description) errors.description = "Descripción es requerido";
        return errors;
    }

    const onSubmit = async (values) => {
        const payload = {
            description: values.description
        }
        const method = !isEdit ? createInformMechanical(payload) : updateInformMechanical(editInformMechanical.id, payload)
        const response = await method
        if (response === true) navigate({ pathname: RoutesMap.INFORM_WORK_ORDER_LIST })  
    }

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.INFORM_WORK_ORDER_LIST })
    }

    return (
        <CustomForm>
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            <CustomFormHeader
                title={!isEdit ? 'Nuevo informe mecánico' : 'Editar informe mecánico'}
                text={!isEdit ? 'Ingrese los datos del nuevo informe mecánico' : 'Ingrese los datos del informe mecánico'}
                goBack={RoutesMap.INFORM_WORK_ORDER_LIST}
            />
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{ padding: '20px' }}>
                                    <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                                        <Grid item xs={12}>
                                            <FormLabel>Descripción:</FormLabel>
                                            <TextField
                                                id="description"
                                                type="text"
                                                name="description"
                                                fullWidth
                                                size="small"
                                                value={values.description}
                                                onChange={handleChange}
                                                error={errors.description && touched.description ? true : false}
                                                helperText={errors.description && touched.description ? errors.description : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <CustomFormFooter
                                    buttonType="submit"
                                    confirmText={!isEdit ? 'Guardar' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}