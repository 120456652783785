import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { Driver } from "../../types/slices/driverType";
import { useDriverStore } from "../../hooks/useDriverStore";
import { RoutesMap } from "../../types";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { IdentificationModalAdmin } from "../MyProfile/components/IdentificationModalAdmin";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { useUserStore } from "../../hooks/useUserStore";
import ContactMailIcon from '@mui/icons-material/ContactMail';

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'name', label: 'NOMBRES', align: 'center' },
    { type: 'text', field: 'surname', label: 'APELLIDOS', align: 'center' },
    { type: 'text', field: 'email', label: 'EMAIL', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELÉFONO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

export const DriverList: FC = () => {

    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status: driverStatus, drivers, getDrivers, setSelectedDriver, deleteDriver } = useDriverStore()
    const { forgotPasswordUser } = useUserStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<Driver>({} as Driver)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [showModalIdentification, setShowModalIdentification] = useState<boolean>(false)
    const [userRut, setUserRut] = useState<string>('')
    const userRole: Role|any = user.userType;

    useEffect(() => {
        getDriverApi()
    }, [search])

    const getDriverApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    search
                }
            }
            await getDrivers(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        navigate({ pathname: RoutesMap.DRIVER_FORM })
    }

    const onEdit = (rowSelected) => {
        setSelectedDriver(rowSelected)
        navigate({ pathname: RoutesMap.DRIVER_FORM })
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteDriver(rowSelected.id)
        if (response === true) {
            setSelectedDriver({} as Driver)
            setShowModalConfirm(false)
            getDriverApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onPersonIcon = (row) => {
        setUserRut(row.rut)
        setShowModalIdentification(true)
    }

    const sendCredentials = async (rowSelected) => {
        setLoading(true)
        await forgotPasswordUser(rowSelected.rut, rowSelected.iduser_type)
        setLoading(false)
    }

    return (<>
        <Grid item xs={12}>
            {
                userRole === Role.ADMIN
                ?
                <CustomTable
                    title={'Conductores'}
                    columns={columns || []}
                    loading={loading}
                    rows={drivers || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onPersonIcon={onPersonIcon}
                    onHelper={sendCredentials}
                    tooltip_helper={'Enviar credenciales'}
                    icon_helper={<ContactMailIcon fontSize="small"/>}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                :
                <CustomTable
                    title={'Conductores'}
                    columns={columns || []}
                    loading={loading}
                    rows={drivers || []}
                    onRowClick={() => { }}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
            
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={() => { setShowModalConfirm(false) }}
                    onCancel={() => { setShowModalConfirm(false) }}
                    onConfirm={onDeleteConfirm}
                    status2={driverStatus == ApiStatus.FETCHING ? true : false}
                />
            )
        }
        {
            showModalIdentification && (
                <IdentificationModalAdmin
                    open={showModalIdentification}
                    closeModal={()=>setShowModalIdentification(false)}
                    rut={userRut}
                />
            )
        }
    </>)
}