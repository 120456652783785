import { id } from "date-fns/locale";
import { api } from "./configs/axiosConfigs";

export const RepairRequestAPI = {
    getRepairRequest: async (data?: any) => {
        const response = await api
            .get("/repairRequest", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    getDriverRepairRequest: async (iddriver: number, data?: any) => {
        const response = await api
            .get(`/repairRequest?iddriver=${iddriver}`, { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    getOne: async (id:number) => {
        const response = await api
            .get(`/repairRequest/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createRepairRequest: async (data: any) => {
        const response = await api
            .post("/repairRequest/register", data)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editRepairRequest: async (id: number, data: any) => {
        const response = await api
            .post(`/repairRequest/${id}`, data)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteRepairRequest: async (id: number) => {
        const response = await api
            .delete(`/repairRequest/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    getPDF: async (id:number) => {
        const response = await api.get(
        `/repairRequest/pdf/${id}`,
        {
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },

    getReport: async (data?: any) => {
        const response = await api
            .get("/repairRequest/report", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    validate: async (id: number, data: any) => {
        const response = await api
            .patch(`/repairRequest/validate/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },
};