import { useDispatch, useSelector } from "react-redux";
import { TrademarkVehicleAPI } from "../apis/TrademarkVehicleAPI";
import { selectTrademarkVehicle, changeStatus, onFetchTrademarkVehicle, onSetEditTrademarkVehicle, onSetSelectedTrademarkVehicle } from "../redux/slices/trademarkVehicleSlice";
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { TrademarkVehicle } from "../types/slices/trademarkVehicleType";

export const useTrademarkVehicleStore = () => {
    const dispatch = useDispatch();
    const {
        trademarkVehicles,
        status: trademarkStatus,
        editTrademarkVehicle,
        selectedTrademarkVehicle
    } = useSelector(selectTrademarkVehicle);

    const getTrademarkVehicles = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkVehicleAPI.getTrademarkVehicle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchTrademarkVehicle(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createTrademarkStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkVehicleAPI.createTrademarkVehicle(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editTrademarkStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkVehicleAPI.editTrademarkVehicle(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteTrademarkStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkVehicleAPI.deleteTrademarkVehicle(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };


    const setSelectedTrademark = (type: TrademarkVehicle) => {
        try {
            dispatch(onSetSelectedTrademarkVehicle(type))
        } catch (error) {
            console.log(error)
        }
    };

    const setEditTrademark = (type: TrademarkVehicle) => {
        try {
            dispatch(onSetEditTrademarkVehicle(type));
        } catch (error) {
            console.log(error)
        }
    };

    return {
        //states
        trademarkVehicles,
        trademarkStatus,
        editTrademarkVehicle,
        selectedTrademarkVehicle,
        //actions
        getTrademarkVehicles,
        createTrademarkStore,
        editTrademarkStore,
        deleteTrademarkStore,
        setSelectedTrademark,
        setEditTrademark
    }
}