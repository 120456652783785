import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { UnwantedEvent, UnwantedEventType } from "../../types/slices/unwantedEventsType";

const initialState: UnwantedEventType = {
    status: ApiStatus.FETCHED,
    unwantedEvents: [],
    errorMessage: undefined,
    selectedUnwantedEvent: {} as UnwantedEvent,
};

const unwantedEventSlice = createSlice({
    name: "controlCriticalRisk",
    initialState,
    reducers: {
        onFetchUnwantedEvent(state, { payload }: { payload: UnwantedEvent[] }) {
            state.status = ApiStatus.FETCHED;
            state.unwantedEvents = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedUnwantedEvent(state, { payload }: { payload: UnwantedEvent }) {
            state.selectedUnwantedEvent = payload;
        },
    }
})

export const selectUnwantedEventState = (state: RootState) => state.unwantedEventSlice;

export default unwantedEventSlice.reducer;

export const {
    onFetchUnwantedEvent,
    changeStatus,
    onSetSelectedUnwantedEvent,
} = unwantedEventSlice.actions;