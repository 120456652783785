import { createSlice } from "@reduxjs/toolkit";
import { RepairRequest, RepairRequestType } from "../../types/slices/repairRequestType";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: RepairRequestType = {
    status: ApiStatus.FETCHED,
    repairRequests: [],
    errorMessage: undefined,
    editRepairRequest: {} as RepairRequest,
    selectedRepairRequest: {} as RepairRequest,
}

const repairRequestSlice = createSlice({
    name: "repairRequest",
    initialState,
    reducers: {
        onFetchRepairRequest(state, { payload }: { payload: RepairRequest[] }) {
            state.status = ApiStatus.FETCHED;
            state.repairRequests = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditRepairRequest(state, { payload }: { payload: RepairRequest }) {
            state.editRepairRequest = payload;
        },
        onSetSelectedRepairRequest(state, { payload }: { payload: RepairRequest }) {
            state.selectedRepairRequest = payload;
        },
    }
})

export const selectRepairRequestState = (state: RootState) => state.repairRequestSlice;
export default repairRequestSlice.reducer;

export const {
    onFetchRepairRequest,
    changeStatus,
    onSetEditRepairRequest,
    onSetSelectedRepairRequest
} = repairRequestSlice.actions