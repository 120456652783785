import { api } from "./configs/axiosConfigs"

export const PatternTAPI = {
    get: async (data?: any) => {
        const response = await api.get(
            '/patternT',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getReport: async (params) => {
        const response = await api.get(
            `/patternT/reportByVehicle`, {
                params: {
                    ...params
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getReportDetail: async (params) => {
        const response = await api.get(
            `/patternT/reportByVehicleDetail`, {
                params: {
                    ...params
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/patternT/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.patch(
        `/patternT/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
        `/patternT/${id}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    getOne: async (id:number|string) => {
        const response = await api.get(
            `/patternT/${id}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getPatternTPDF: async (id:number) => {
        const response = await api.get(
        `/patternT/pdf/${id}`,
        {
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
    signature: async (id: number, data:any) => {
        const response = await api.post(
        `/signingPatternT/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    complete: async (id:number, status:number) => {
        const response = await api.patch(
        `/patternT/complete/${id}`,
        {
            status: status
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}
