import { useDispatch, useSelector } from "react-redux";
import { changeStatus, onFetchMaintenanceCompany, onSetSelectedMaintenanceCompany, selectMaintenanceCompanyState } from "../redux/slices/maintenanceCompanySlice";
import { ApiStatus } from "../types/api/status"
import { MaintenanceCompanyAPI } from "../apis/MaintenanceCompanyAPI";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { MaintenanceCompany } from "../types/slices/maintenanceCompanyType";

export const useMaintenanceCompanyStore = () => {
    const dispatch = useDispatch()
    const { maintenanceCompanies, status, selectedMaintenanceCompany } = useSelector(selectMaintenanceCompanyState)

    const getMaintenanceCompany = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceCompanyAPI.get(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchMaintenanceCompany(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const createMaintenanceCompany = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceCompanyAPI.create(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "")
                return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true;
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const editExternalMaintenance = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceCompanyAPI.edit(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const deleteExternalMaintenance = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await MaintenanceCompanyAPI.delete(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
            console.log(error)
            return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    };

    const setSelectedExternalMaintenance = (maintenanceCompany: MaintenanceCompany) => {
        try {
            dispatch(onSetSelectedMaintenanceCompany(maintenanceCompany))
        } catch (error) {
            console.log(error)
        }
    };

    return {
        //states
        maintenanceCompanies,
        status,
        selectedMaintenanceCompany,
        //actions
        getMaintenanceCompany,
        createMaintenanceCompany,
        editExternalMaintenance,
        deleteExternalMaintenance,
        setSelectedExternalMaintenance,
    }
}