import { api } from "./configs/axiosConfigs"

export const TechnicalReportAPI = {
    getWorkOrders: async (data?:any) => {
        const response = await api.get(
            '/technicalReport',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    getOne: async (id:number) => {
        const response = await api.get(
            `/technicalReport/${id}`
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/technicalReport/register', data
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
    update: async (id: number, data: any) => {
        const response = await api.post(
            `/technicalReport/update/${id}`, data
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
            `/technicalReport/${id}`
        ).then(response => response.data)
            .catch((error) => {
                return error.response.data
            })
        return response
    },
    getPDF: async (id:number) => {
        const response = await api.get(
        `/technicalReport/pdf/${id}`,
        {
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
    signature: async (id: number, data:any) => {
        const response = await api.post(
        `/technicalReport/signature/${id}`, data
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    finalized: async (id:number) => {
        const response = await api.post(
            `/technicalReport/finalized/${id}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
}