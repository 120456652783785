import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { RepairRequest } from "../../types/slices/repairRequestType";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { useRepairRequest } from "../../hooks/useRepairRequest";
import { RepairRequestModalValidate } from "./RepairRequestModalValidate";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

const columnsAdmin = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    { type: 'text', field: 'customer', label: 'CLIENTE', align: 'center' },
    { type: 'text', field: 'rut_customer', label: 'RUT', align: 'center' },
    { type: 'text', field: 'fail_name', label: 'MOTIVO', align: 'center', format: (row: RepairRequest) => row.name_fails_resource?.map(element => element.name).join(' - ') },
    { type: 'text', field: 'created_by', label: 'REGISTRADO POR', align: 'center' },
    { type: 'text', field: 'role', label: 'ROL', align: 'center' },
    { type: 'text', field: 'name_status_request', label: 'ESTADO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsCustomer = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    { type: 'text', field: 'rut_customer', label: 'RUT', align: 'center' },
    { type: 'text', field: 'fail_name', label: 'MOTIVO', align: 'center', format: (row: RepairRequest) => row.name_fails_resource?.map(element => element.name).join(' - ') },
    { type: 'text', field: 'name_status_request', label: 'ESTADO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsTechnical = [
    { type: 'text', field: 'correlative', label: 'CORRELATIVO', align: 'center' },
    { type: 'text', field: 'date', label: 'FECHA', align: 'center' },
    { type: 'text', field: 'rut_customer', label: 'RUT', align: 'center' },
    { type: 'text', field: 'fail_name', label: 'MOTIVO', align: 'center', format: (row: RepairRequest) => row.name_fails_resource?.map(element => element.name).join(' - ') },
    { type: 'text', field: 'name_status_request', label: 'ESTADO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const RepairRequestList: FC = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)
    const { status, repairRequests, getRepairRequestStore, deleteRepairRequestStore, setSelectedRepairRequest } = useRepairRequest()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<any>({} as RepairRequest)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [showModalValidate, setShowModalValidate] = useState<boolean>(false)
    const userRole:Role|any = user.userType

    useEffect(() => {
        getRepairRequestApi()
    }, [search])

    const getRepairRequestApi = async () => {
        try {
            setLoading(true)
            let payload: any = {}
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.CUSTOMER) {
                payload = {
                    ...payload,
                    idcustomer: user.data?.id
                }
            }
            if (userRole === Role.TECHNICAL) {
                payload = {
                    ...payload,
                    status_request: 1
                }
            }
            await getRepairRequestStore(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedRepairRequest({} as RepairRequest)
        navigate({ pathname: RoutesMap.REPAIR_REQUEST_FORM })
    }

    const onEdit = (rowSelected) => {
        if (userRole === Role.CUSTOMER && rowSelected.status_request == 1 ) return CustomSnackbar('warning', 'La solicitud ya ha sido aprobada por lo tanto no puede ser modificada.')
        setSelectedRepairRequest(rowSelected)
        navigate({ pathname: RoutesMap.REPAIR_REQUEST_FORM })
    }

    const onDelete = (rowSelected) => {
        if (userRole === Role.CUSTOMER && rowSelected.status_request == 1 ) return CustomSnackbar('warning', 'La solicitud ya ha sido aprobada por lo tanto no puede ser eliminada.')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteRepairRequestStore(rowSelected.id)
        if (response === true) {
            setSelectedRepairRequest({} as RepairRequest)
            setShowModalConfirm(false)
            getRepairRequestApi()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onValidateRequest = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalValidate(true)
    }

    const onDetails = (rowSelected) => {
        navigate(`/repair-request-pdf/${rowSelected.id}`)
    }

    return (
        <>
            <Grid item xs={12}>
                {
                    userRole === Role.ADMIN && 
                        <CustomTable
                            title={'Solicitudes de reparación'}
                            columns={columnsAdmin || []}
                            rows={repairRequests || []}
                            onRowClick={() => { }}
                            hasOptions
                            onAddFn={onAdd}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            onDetails={onDetails}
                            onValidateRequest={onValidateRequest}
                            loading={loading}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                }
                {
                    userRole === Role.CUSTOMER && 
                        <CustomTable
                            title={'Solicitudes de reparación'}
                            columns={columnsCustomer || []}
                            rows={repairRequests || []}
                            onRowClick={() => { }}
                            hasOptions
                            onAddFn={onAdd}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            onDetails={onDetails}
                            loading={loading}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                }
                {
                    userRole === Role.TECHNICAL && 
                        <CustomTable
                            title={'Solicitudes de reparación disponibles'}
                            columns={columnsTechnical || []}
                            rows={repairRequests || []}
                            onRowClick={() => { }}
                            hasOptions
                            onDetails={onDetails}
                            loading={loading}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                }
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        status2={status === ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModalValidate && (
                    <RepairRequestModalValidate
                        open={showModalValidate}
                        closeModal={() => { setShowModalValidate(false) }}
                        onList={() => getRepairRequestApi()}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}

export default RepairRequestList