import { useDispatch, useSelector } from "react-redux";
import { TrademarkMaintenanceAPI } from "../apis/TrademarkMaintenanceAPI";
import { selectTrademarkMaintenanceState, changeStatus, onFetchTrademarkMaintenance, onSetEditTrademarkMaintenance, onSetSelectedTrademarkMaintenance, restartTrademarkMaintenance } from "../redux/slices/trademarkMaintenanceSlice"
import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { TrademarkMaintenance } from "../types/slices/trademarkMaintenanceType";

export const useTrademarkMaintenance = () => {
    const dispatch = useDispatch();
    const {
        trademarkMaintenances,
        editTrademarkMaintenance,
        selectedTrademarkMaintenance,
        status: trademarkMaintenanceStatus,
    } = useSelector(selectTrademarkMaintenanceState);

    const getTrademarkMaintenanceStore = async (id: number, data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkMaintenanceAPI.getTrademarkMaintenance(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchTrademarkMaintenance(detail));
            dispatch(changeStatus(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const createTrademarkMaintenanceStore = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkMaintenanceAPI.createTrademarkMaintenance(data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const editTrademarkMaintenanceStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkMaintenanceAPI.editTrademarkMaintenance(id, data);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const deleteTrademarkMaintenanceStore = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING));
            const response = await TrademarkMaintenanceAPI.deleteTrademarkMaintenance(id);
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatus(ApiStatus.FETCHED));
        }
    };

    const setSelectedTrademarkMaintenanceStore = (trademark: TrademarkMaintenance) => {
        try {
            dispatch(onSetSelectedTrademarkMaintenance(trademark));
        } catch (error) {
            console.log(error);
        }
    }

    const setEditTrademarkMaintenanceStore = (trademark: TrademarkMaintenance) => {
        try {
            dispatch(onSetEditTrademarkMaintenance(trademark));
        } catch (error) {
            console.log(error);
        }
    }

    const deleteStateTrademarkMaintenance = () => {
        try {
            dispatch(restartTrademarkMaintenance());
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        trademarkMaintenances,
        editTrademarkMaintenance,
        selectedTrademarkMaintenance,
        trademarkMaintenanceStatus,
        //actions
        getTrademarkMaintenanceStore,
        createTrademarkMaintenanceStore,
        editTrademarkMaintenanceStore,
        deleteTrademarkMaintenanceStore,
        setSelectedTrademarkMaintenanceStore,
        setEditTrademarkMaintenanceStore,
        deleteStateTrademarkMaintenance
    }

}