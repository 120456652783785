import { api } from "../configs/axiosConfigs";

export const UnwantedEventAPI = {
    getUnwantedEvent: async (data?: any) => {
        const response = await api
            .get("/unwantedEvent", { params: { ...data } })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createUnwantedEvent: async (data: any) => {
        const response = await api
            .post("/unwantedEvent/register", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editUnwantedEvent: async (id: number, data: any) => {
        const response = await api
            .patch(`/unwantedEvent/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteUnwantedEvent: async (id: number) => {
        const response = await api
            .delete(`/unwantedEvent/${id}`, {
                params: {
                    idcompany: 1
                }
            })
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },
}