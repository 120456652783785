import { useEffect, useState } from "react"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { Formik } from "formik"

import { Visibility, VisibilityOff } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import { useNavigate } from "react-router-dom"
import { usePatternTStore } from "../../hooks/usePatternTStore"
import { useNotificationStore } from "../../hooks/useNotificationStore"
import { useAuthStore } from "../../hooks"
import { Role } from "../../types/roles/roleTypes"
import { useBossStore } from "../../hooks/useBossStore"
import { useMechanicStore } from "../../hooks/useMechanicStore"
import { createNotification } from "../../types/slices/notificationType"

export const PatternTSignatureModal: React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, idpatternT } = props

    const navigate = useNavigate()
    const { signature, selectedPatternT } = usePatternTStore()

    const [data, setData] = useState({
        pin: ''
    })
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const { createNotifications } = useNotificationStore()
    const { user } = useAuthStore()
    const userRole: Role | any = user.userType;
    const { getBoss, boss } = useBossStore()
    const { getMechanics, mechanics } = useMechanicStore()

    useEffect(() => {
        getApiBoss()
    }, [])

    const getApiBoss = async () => {
        await getBoss();
        await getMechanics();
    }

    const validatePinForm = (values) => {
        let errors: any = {};
        if (!values.pin) errors.pin = "PIN requerido";
        return errors;
    }

    const handleClickShowPassword = () => {
        if (showPassword) setShowPassword(false)
        if (!showPassword) setShowPassword(true)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const onSubmit = async (values) => {
        setLoading(true)
        const response = await signature(idpatternT, { pin: values.pin })
        if (response === true) {
            if (userRole === Role.MECHANIC) {
                boss?.map(async (element) => {
                    const notify: createNotification = {
                        idreceiver: element.id_user,
                        rut_receiver: element.rut,
                        iduser_type: 4,
                        title: "Pauta R firmada",
                        details: `El ${user.userType} ${user.data?.name} ${user.data?.surname} ha firmado una pauta R.`,
                        idview: 2,
                        idcompany: 1,
                        idregister: null
                    }
                    await createNotifications(notify)
                })
            }
            closeModal()
            navigate(`/pt-t-signature/${idpatternT}`)
        }
        setLoading(false)
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder' }}>
                    INGRESE SU PIN
                </Typography>
                <Formik initialValues={data} enableReinitialize validate={(values) => validatePinForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (<>
                            <Grid container sx={{ margin: '15px 0px' }}>
                                <Grid item xs={8} sx={{ margin: '0px auto' }}>
                                    <TextField
                                        id="pin"
                                        name="pin"
                                        variant="standard"
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.pin}
                                        onChange={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                setFieldValue('pin', e.target.value)
                                            }
                                        }}
                                        size='small'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'  >
                                                    <LockIcon sx={{ color: '#29333B' }} />
                                                </InputAdornment>),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        sx={{ color: '#29333B' }}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                maxLength: 6
                                            }
                                        }}
                                        error={errors.pin && touched.pin ? true : false}
                                        helperText={errors.pin && touched.pin ? errors.pin : ''}
                                    />
                                </Grid>
                                <Grid container sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            onClick={() => handleSubmit()}
                                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                                        >
                                            {
                                                loading
                                                    ?
                                                    <CircularProgress color="inherit" size={20} sx={{ color: '#fff', marginRight: "10px" }} />
                                                    :
                                                    'Confirmar'
                                            }
                                        </Button>
                                        <Button
                                            disabled={loading}
                                            onClick={() => closeModal()}
                                            sx={{ backgroundColor: '#73B2FF', color: '#fff', float: 'right', height: '25px', margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": { backgroundColor: "#73B2FF" } }}
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>)
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}