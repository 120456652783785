import { FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useVehicleStore } from "../../hooks/useVehicleStore";
import { Vehicles } from "../../types/slices/vehiclesType";
import { RoutesMap } from "../../types";
import { Divider, FormLabel, Grid, TextField, Select, MenuItem, Checkbox } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useModelVehicleStore } from "../../hooks/useModelVehicleStore";
import { useTypeVehicleStore } from "../../hooks/useTypeVehicleStore";
import { useTrademarkVehicleStore } from "../../hooks/useTrademarkVehicleStore";
import { useContrataStore } from "../../hooks/useContrataStore";
import { Role } from "../../types/roles/roleTypes";
import { useAuthStore } from "../../hooks";

type VehiclesDataForm = {
    id: number;
    idtype_vehicle: number;
    n_chassis: string;
    idtrademark: number;
    idmodel: number;
    patent: string;
    year: string;
    tire_quantity: number;
    idcompany: number;
    idcontrata: number;
    engine: number
}

const VehiclesForm: FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthStore()
    const { vehicleStatus, selectedVehicles, setSelectedVehicle, createVehicleStore, editVehicleStore } = useVehicleStore();

    const { modelVehicles, getModelVehicles } = useModelVehicleStore();
    const { typeVehicles, getTypeVehicles } = useTypeVehicleStore();
    const { trademarkVehicles, getTrademarkVehicles } = useTrademarkVehicleStore();
    const { contratas, getContratas } = useContrataStore()

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [data, setData] = useState<VehiclesDataForm>({
        id: 0,
        idtype_vehicle: 0,
        n_chassis: "",
        idtrademark: 0,
        idmodel: 0,
        patent: "",
        year: "",
        tire_quantity: 0,
        idcompany: 1,
        idcontrata: 0,
        engine: 1
    });

    const userRole:Role|any = user.userType;

    useEffect(() => {
        getTypeVehicles();
        getModelVehicles();
        getTrademarkVehicles();
        getContratas();
        if (Object.entries(selectedVehicles).length > 0) {
            setIsEdit(true);
            setData({
                id: selectedVehicles.id,
                idtype_vehicle: selectedVehicles.idtype_vehicle,
                n_chassis: selectedVehicles.n_chassis,
                idtrademark: selectedVehicles.idtrademark,
                idmodel: selectedVehicles.idmodel,
                patent: selectedVehicles.patent,
                year: selectedVehicles.year,
                tire_quantity: selectedVehicles.tire_quantity,
                idcompany: selectedVehicles.idcompany,
                idcontrata: selectedVehicles.idcontrata,
                engine: parseInt(selectedVehicles.engine)
            });
            setSelectedVehicle({} as Vehicles);
        }
    }, []);

    const setTireQuantity = () => {
        const typeVehicle = typeVehicles.find(type => type.id === data.idtype_vehicle)
        if (!typeVehicle) return
        setData({
            ...data,
            tire_quantity: typeVehicle.tire_quantity
        })
    }

    const onSubmit = async (values: any) => {
        const payload = {
            idtype_vehicle: values.idtype_vehicle,
            n_chassis: values.n_chassis,
            idtrademark: values.idtrademark,
            idmodel: values.idmodel,
            patent: values.patent,
            year: values.year,
            tire_quantity: values.tire_quantity,
            idcompany: values.idcompany,
            idcontrata: userRole === Role.MAINTENANCE ? user.data?.idcontrata : values.idcontrata,
            engine: values.engine
        };
        const method = !isEdit ? createVehicleStore(payload) : editVehicleStore(data.id, payload);
        const response = await method;
        if (response === true) {
            navigate({ pathname: RoutesMap.VEHICLES })
        }
    };

    const onCancel = async () => {
        navigate({ pathname: RoutesMap.VEHICLES });
    };

    const validateForm = (values: any) => {
        let errors: any = {};
        if (!values.patent) errors.patent = "Patente requerido"
        if (!values.idtype_vehicle) errors.idtype_vehicle = "Tipo de vehículo requerido"
        if (!values.idmodel) errors.idmodel = "Modelo requerido"
        if (!values.n_chassis) errors.n_chassis = "N° chasis requerido"
        if (!values.idtrademark) errors.idtrademark = "Marca requerida"
        if (!values.year) errors.year = "Año requerido"
        if (values.year.length < 4 || values.year < 1990) errors.year = "Necesitas ingresar un año válido"
        if (!values.tire_quantity || values.tire_quantity === 0) errors.tire_quantity = "Cantidad de neumáticos requerido"
        if (!values.idcompany) errors.idcompany = "Compañia requerida"
        if (!values.idcontrata && userRole !== Role.MAINTENANCE) errors.idcontrata = "Contrata requerida"
        return errors;
    }

    return (
        <CustomForm>
            {vehicleStatus === ApiStatus.FETCHING && (
                <CustomBackdrop open={true} />
            )}
            <CustomFormHeader
                title={!isEdit ? "Nuevo Vehículo" : "Editar Vehículo"}
                text={
                    !isEdit
                        ? "Ingrese los datos del nuevo vehículo"
                        : "Ingrese los datos del vehículo"
                }
                goBack={RoutesMap.VEHICLES}
            />
            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (<form onSubmit={handleSubmit}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Patente:</FormLabel>
                                    <TextField
                                        id="patent"
                                        type="text"
                                        name="patent"
                                        inputProps={{
                                            maxLength: 45,
                                        }}
                                        fullWidth
                                        size="small"
                                        value={values.patent}
                                        onChange={handleChange}
                                        error={
                                            errors.patent && touched.patent
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.patent && touched.patent
                                                ? errors.patent
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Tipo de vehículo:</FormLabel>
                                    <Select
                                        id="idtype_vehicle"
                                        name="idtype_vehicle"
                                        fullWidth
                                        value={values.idtype_vehicle}
                                        onChange={(e) => {
                                            setFieldValue('idtype_vehicle', e.target.value)
                                            const typeVehicle = typeVehicles.find(type => type.id === e.target.value)
                                            if (!typeVehicle) return
                                            setFieldValue('tire_quantity', typeVehicle.tire_quantity)
                                        }}
                                        error={
                                            errors.idtype_vehicle &&
                                                touched.idtype_vehicle
                                                ? true
                                                : false
                                        }
                                        style={{ height: '52%' }}
                                    >
                                        {typeVehicles?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Modelo:</FormLabel>
                                    <Select
                                        id="idmodel"
                                        name="idmodel"
                                        fullWidth
                                        value={values.idmodel}
                                        onChange={handleChange}
                                        error={
                                            errors.idmodel &&
                                                touched.idmodel
                                                ? true
                                                : false
                                        }
                                        style={{ height: '52%' }}
                                    >
                                        {modelVehicles?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Marca:</FormLabel>
                                    <Select
                                        id="idtrademark"
                                        name="idtrademark"
                                        fullWidth
                                        value={values.idtrademark}
                                        onChange={handleChange}
                                        error={
                                            errors.idtrademark &&
                                                touched.idtrademark
                                                ? true
                                                : false
                                        }
                                        style={{ height: '52%' }}
                                    >
                                        {trademarkVehicles?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Año:</FormLabel>
                                    <TextField
                                        id="year"
                                        type="number"
                                        name="year"
                                        fullWidth
                                        inputProps={{ min: 1990, max: 2023 }}
                                        size="small"
                                        value={values.year}
                                        onChange={(event) => {
                                            const input = event.target.value;
                                            const regex = /^\d{0,4}$/;
                                            if (regex.test(input)) {
                                                if (parseInt(input) <= 2023) {
                                                    setFieldValue("year", input);
                                                } else {
                                                    setFieldValue("year", "");
                                                }
                                            }
                                        }}
                                        error={
                                            errors.year && touched.year
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.year && touched.year
                                                ? errors.year
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>N° Chasis:</FormLabel>
                                    <TextField
                                        id="n_chassis"
                                        type="text"
                                        name="n_chassis"
                                        fullWidth
                                        size="small"
                                        inputProps={{
                                            maxLength: 45,
                                        }}
                                        value={values.n_chassis}
                                        onChange={handleChange}
                                        error={
                                            errors.n_chassis && touched.n_chassis
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.n_chassis && touched.n_chassis
                                                ? errors.n_chassis
                                                : ""
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormLabel>Cantidad de Neumáticos:</FormLabel>
                                    <TextField
                                        id="tire_quantity"
                                        type="number"
                                        name="tire_quantity"
                                        fullWidth
                                        size="small"
                                        value={values.tire_quantity}
                                        error={
                                            errors.tire_quantity && touched.tire_quantity
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            errors.tire_quantity && touched.tire_quantity
                                                ? errors.tire_quantity
                                                : ""
                                        }
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                {
                                    userRole !== Role.MAINTENANCE && (
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Contrata:</FormLabel>
                                            <Select
                                                id="idcontrata"
                                                name="idcontrata"
                                                fullWidth
                                                value={values.idcontrata}
                                                onChange={handleChange}
                                                error={
                                                    errors.idcontrata &&
                                                        touched.idcontrata
                                                        ? true
                                                        : false
                                                }
                                                style={{ height: '52%' }}
                                            >
                                                {contratas?.map((option) => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                                            </Select>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={4} >
                                    <FormLabel>Vehículo Motorizado:</FormLabel>
                                    <Checkbox color="primary" size="medium"
                                        checked={values.engine === 1 ? true : false} 
                                        onChange={(e) => {
                                            const { checked } = e.target
                                            setFieldValue('engine', checked ? 1 : 2)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <CustomFormFooter
                                buttonType="submit"
                                confirmText={
                                    !isEdit ? "Guardar" : "Actualizar"
                                }
                                cancelText={"Cancelar"}
                                onConfirm={handleSubmit}
                                onCancel={onCancel}
                            />
                        </form>)
                    }}
                </Formik>
            </CustomFormBody>
        </CustomForm>
    )
}

export default VehiclesForm