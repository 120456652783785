import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { GroupActivityD2, GroupActivityD2Type } from "../../types/slices/groupActivitiD2Type";

const initialState: GroupActivityD2Type = {
    status: ApiStatus.FETCHED,
    groupActivitiesD2: [],
    errorMessage: undefined,
    selectedGroupActivityD2: {} as GroupActivityD2
};

const groupActivitiD2Slice = createSlice({
    name: "groupActivityD2",
    initialState,
    reducers: {
        onFetchGroupActivityD2(state, { payload }: { payload: GroupActivityD2[] }) {
            state.status = ApiStatus.FETCHED;
            state.groupActivitiesD2 = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetSelectedGroupActivityD2(state, { payload }: { payload: GroupActivityD2 }) {
            state.selectedGroupActivityD2 = payload;
        },
    }
});

export const selectGroupActivityD2 = (state: RootState) => state.groupActivityD2Slice;
export default groupActivitiD2Slice.reducer;

export const {
    onFetchGroupActivityD2,
    changeStatus,
    onSetSelectedGroupActivityD2,
} = groupActivitiD2Slice.actions;