import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { ExternalMaintenance } from "../../types/slices/externalMaintenanceType";
import { useAuthStore } from "../../hooks";
import { Role } from "../../types/roles/roleTypes";
import { useExternalMaintenanceStore } from "../../hooks/useExternalMaintenanceStore";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES', align: 'center' },
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'type_vehicle', label: 'TIPO DE VEHÍCULO', align: 'center' },
    { type: 'text', field: 'model_vehicle', label: 'MODELO', align: 'center' },
    { type: 'text', field: 'odometer', label: 'ODÓMETRO', align: 'center', format: (row: any) => `${row.odometer} Km` },
    { type: 'text', field: 'execution_date', label: 'FECHA DE EJECUCION', align: 'center' },
    { type: 'text', field: 'main_companybusiness_name', label: 'EMPRESA EJECUTORA', align: 'center' },
    { type: 'text', field: 'main_company_rut', label: 'RUT EMPRESA', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const columnsAdmin = [
    { type: 'text', field: 'patent', label: 'PATENTE', align: 'center' },
    { type: 'text', field: 'trademark_vehicle', label: 'MARCA', align: 'center' },
    { type: 'text', field: 'type_vehicle', label: 'TIPO DE VEHÍCULO', align: 'center' },
    { type: 'text', field: 'model_vehicle', label: 'MODELO', align: 'center' },
    { type: 'text', field: 'odometer', label: 'ODÓMETRO', align: 'center', format: (row: any) => `${row.odometer} Km` },
    { type: 'text', field: 'execution_date', label: 'FECHA DE EJECUCION', align: 'center' },
    { type: 'text', field: 'main_companybusiness_name', label: 'EMPRESA EJECUTORA', align: 'center' },
    { type: 'text', field: 'main_company_rut', label: 'RUT EMPRESA', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
]

const ExternalMaintenanceList: FC<any> = () => {
    const navigate = useNavigate()
    const { user } = useAuthStore()
    const { search } = useSelector(selectHeaderState)

    const { status: externalStatus, externalMaintenanceS, getExternalMaintenance, deleteExternalMaintenance, setSelectedExternalMaintenance } = useExternalMaintenanceStore()

    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [rowSelected, setRowSelected] = useState<any>({} as ExternalMaintenance)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const userRole: Role | any = user.userType

    useEffect(() => {
        getExternalMaintenanceApi()
    }, [search])

    const getExternalMaintenanceApi = async () => {
        try {
            setLoading(true)
            let payload: any = {
                status_document: 1
            }
            if (search !== '') {
                payload = {
                    ...payload,
                    search
                }
            }
            if (userRole === Role.MECHANIC) {
                payload = {
                    ...payload,
                    idmechanic: user?.data?.id
                }
            }
            await getExternalMaintenance(payload)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onAdd = () => {
        navigate({ pathname: RoutesMap.EXTERNAL_MAINTENANCE_FORM })
    }

    // const onEdit = (rowSelected) => {
    //     setSelectedPatternR(rowSelected)
    //     navigate({ pathname: RoutesMap.PT_R_FORM })
    // }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteExternalMaintenance(rowSelected.id)
        if (response === true) {
            setSelectedExternalMaintenance({} as ExternalMaintenance)
            setShowModalConfirm(false)
            getExternalMaintenanceApi()
        }
    }

    const onDetails = (rowSelected) => {
        const win:any = window.open(`${process.env.REACT_APP_ROOT_URL}` + rowSelected.document_pdf, '_blank')
        win.focus()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <>
            <Grid item xs={12}>
                {
                    (userRole === Role.ADMIN || userRole === Role.SUPERVISOR) && (
                        <CustomTable
                            title={'Mantenciones Preventivas Externas'}
                            columns={columnsAdmin || []}
                            loading={loading}
                            rows={externalMaintenanceS || []}
                            onRowClick={() => { }}
                            hasOptions
                            onDetails={onDetails}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    )
                }
                {
                    userRole === Role.MAINTENANCE && (
                        <CustomTable
                            title={'Mantenciones Preventivas Externas'}
                            columns={columns || []}
                            loading={loading}
                            rows={externalMaintenanceS || []}
                            onRowClick={() => { }}
                            hasOptions
                            // onAddFn={onAdd}
                            onDelete={onDelete}
                            // onEdit={onEdit}
                            onDetails={onDetails}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    )
                }
                {
                    userRole === Role.MECHANIC && (
                        <CustomTable
                            title={'Mantenciones Preventivas Externas'}
                            columns={columns || []}
                            loading={loading}
                            rows={externalMaintenanceS || []}
                            onRowClick={() => { }}
                            hasOptions
                            onAddFn={onAdd}
                            onDelete={onDelete}
                            // onEdit={onEdit}
                            onDetails={onDetails}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    )
                }
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onCancel={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        status2={externalStatus === ApiStatus.FETCHING ? true : false}
                    />
                )
            }
        </>)
}

export default ExternalMaintenanceList