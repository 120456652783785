import { useDispatch, useSelector } from "react-redux"
import { selectWorkDoneState, changeStatus, onFetchWorkDones, onSetEditWorkDone } from "../redux/slices/workDoneSlice"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { WorkDone } from "../types/slices/workDondeType"
import { WorkDoneAPI } from "../apis/WorkDoneAPI"

export const useWorkDoneStore = () => {
    const dispatch = useDispatch()
    const { workDones, status, editWorkDone } = useSelector(selectWorkDoneState)

    const getWorkDones = async (data?:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkDoneAPI.getWorkDones(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchWorkDones(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createWorkDone = async (data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkDoneAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateWorkDone = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkDoneAPI.update(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteWorkDone = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await WorkDoneAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setEditWorkDone = (workDone:WorkDone) => {
        try {
            dispatch(onSetEditWorkDone(workDone))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        workDones,
        status,
        editWorkDone,
        getWorkDones,
        createWorkDone,
        updateWorkDone,
        deleteWorkDone,
        setEditWorkDone
    }
}